import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ModalDataButton } from '../../services/modal.service';
import _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-show-pdf',
  templateUrl: './show-pdf.component.html',
  styleUrls: ['./show-pdf.component.scss']
})
export class ShowPdfComponent implements OnInit {

  pdfUrl!: any;

  constructor(
    private sanitizer: DomSanitizer,
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig,
  ) { }
  
  ngOnInit(): void {

    if (_.has(this.config, 'data.data.pdfData')) {

      const base64Pdf = _.get(this.config, 'data.data.pdfData');
      const dataUrl = this.convertBase64ToDataUrl(base64Pdf)

      this.pdfUrl = dataUrl;

    } else if (_.has(this.config.data.data, 'pdfUrl')) {
      this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.config.data.data.pdfUrl);
    }

  }

  convertBase64ToDataUrl(base64: string): string {
    return `data:application/pdf;base64,${base64}`;
  }

  onClick(button: ModalDataButton) {

    if (!button.handler) {

      return this.ref.close(button.key);

    }

  }

}
