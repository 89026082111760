import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateTransferComponent } from './create-transfer.component';
import { TransferItemModule } from '../../forms/components/transfer-item/transfer-item.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';



@NgModule({
  declarations: [
    CreateTransferComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    TransferItemModule,
  ],
  exports: [
    CreateTransferComponent
  ]
})
export class CreateTransferModule { }
