import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TransferItemComponent } from './transfer-item.component';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { RippleModule } from 'primeng/ripple';

import { PipeModule } from 'src/app/shared/pipe.module';

import { AddressWithHeadingModule } from '../address-with-heading/address-with-heading.module';
import { AddressModule } from '../address/address.module';
import { ContactModule } from '../contact/contact.module';
import { DateModule } from '../date/date.module';
import { DateV2Module } from '../date-v2/date-v2.module';
import { FinancialLineItemsModule } from '../financial-line-items/financial-line-items.module';
import { FinancialModule } from '../financial/financial.module';
import { NameModule } from '../name/name.module';
import { SelectModule } from '../select/select.module';
import { FormDirectivesModule } from '../../directives/form-directives.module';
import { BusinessCardModule } from 'src/app/shared/features/business-card/business-card.module';

@NgModule({
  declarations: [
    TransferItemComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    InputNumberModule,
    DropdownModule,
    ButtonModule,
    RippleModule,
    DateModule,
    DateV2Module,
    SelectModule,
    NameModule,
    DividerModule,
    FieldsetModule,
    MenuModule,
    AddressModule,
    ContactModule,
    InputSwitchModule,
    PipeModule,
    FinancialModule,
    CardModule,
    FinancialLineItemsModule,
    AddressWithHeadingModule,
    FormDirectivesModule,
    BusinessCardModule,
    DropdownModule,
  ],
  exports: [
    TransferItemComponent
  ],
})
export class TransferItemModule { }
