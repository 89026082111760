<p-card *ngIf="service | async as S" styleClass="shadow-1 bg-blue-50">
  
  <ng-template pTemplate="title">
  
    <div class="flex align-items-center justify-content-between text-left flex-wrap gap-3">
      <p-avatar *ngIf="S.name" class="flex-none" [label]="S.name.charAt(0)" styleClass="mr-3" size="large" shape="circle"></p-avatar>
      <div class="business-card-name" *ngIf="S.name">
        {{ S.name }}
      </div>
      <div *ngIf="S.address" class="business-card-address">
        <strong>Address:</strong> <span class="font-normal pl-1">{{ getAddress(S) }}</span>
      </div>
    </div>
  
  </ng-template>

  <ng-template pTemplate="content">

    <div class="mt-3 pt-3 border-none border-top-1 border-solid border-300 text-xs text-900 flex align-items-center">

      <p-button styleClass="w-full" class="w-3 flex-auto py-2 mx-0" [label]="getPhone(S)" icon="pi pi-phone" (onClick)="onCopyToClipboard($event, getPhone(S))"></p-button>

      <p-splitButton styleClass="w-full" class="w-3 flex-auto mx-3 py-2" [label]="getEmail(S)" icon="pi pi-envelope" (onClick)="onSend($event)" [model]="items"></p-splitButton>

      <p-button styleClass="w-full" class="w-3 flex-auto mx-3 py-2" [label]="getName(S)" icon="pi pi-user" (onClick)="onCopyToClipboard($event, getName(S))"></p-button>

      <p-button styleClass="w-full" class="w-3 flex-auto mx-0 py-2" label="Read Receipts" icon="pi pi-bookmark" (onClick)="onReadReceipt($event, S)"></p-button>

    </div>

  </ng-template>
</p-card>
