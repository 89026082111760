import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, SkipSelf, ViewChildren } from '@angular/core';
import { AbstractControl, ControlContainer, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import _ from 'lodash';
import moment from 'moment';
import { InputSwitch } from 'primeng/inputswitch';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { startWith, distinctUntilChanged, pairwise, debounceTime, first, tap, finalize, takeUntil, map, filter } from 'rxjs/operators';
import { changeDetection } from 'src/app/shared/change-detection';
import { Arrangement } from 'src/app/shared/classes/arrangement';
import { Service } from 'src/app/shared/classes/service';
import { DynamicComponents } from 'src/app/shared/dynamic/services/dynamic.service';
import { ServiceProviderService } from 'src/app/shared/services/service-provider.service';
import { TransferFromAddressType, TransferToAddressType, TransferType, dateOfPickupType, eventTransferType } from '../../form-enum';
import { AddressComponent } from '../address/address.component';
import { dateOfPickupTypeValue, eventIndexesValue, secondNoteTransferTypesValue, serviceTypesFormValue, transferFromAddressTypesValue, transferServiceTypesFormValue, transferToAddressTypesValue, transferTypesValue } from '../../form-values';
import { SiteService } from 'src/app/shared/services/site.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import ordinal from 'ordinal';
import { AddressToStringPipe } from 'src/app/shared/address-to-string.pipe';
import { FormAddressStructure, FormStructure, FormStructureType, FormStructureValueType, FormTransfersStructure } from '../../form-structure';
import { FormGenerator } from '../../form-generator';
import { detailedDiff } from 'deep-object-diff';
import { ModalService } from 'src/app/shared/services/modal.service';
import { FormValidation } from '../../form-validation-alert';
import { makeSimple } from 'src/app/shared/makeSimple';
import { addLinkedTransferId, removeLinkedTransferIds } from 'src/app/shared/validate-linked-transfers';
import { hasValidAddress } from 'src/app/shared/has-valid-address';

export enum AlertType {
  Mortuary = 'mortuary',
  PlaceOfPassing = 'place-of-passing',
  Event = 'event',
  Transfer = 'transfer',
}

export enum LinkedTransferIdType {
  Pickup = 'pickup',
  DropOff = 'drop-off'
}

export interface LinkedTransferId { 
  id: string;
  type: LinkedTransferIdType;
}

export interface TargetLinkedTransfer {
  transferId: string;
  transferType: LinkedTransferIdType;
  targetElement?: 'place-of-passing' | 'preparation' | 'service-provider' | 'transfer' | 'event';
  targetElementId?: string;
}

@Component({
  selector: 'app-transfer-item',
  templateUrl: './transfer-item.component.html',
  styleUrls: ['./transfer-item.component.scss'],
  animations: [
    trigger('slideUpDown', [
      state('true', style({ height: '*', opacity: 1, overflow: 'hidden' })),
      state('false', style({ height: '0px', opacity: 0, overflow: 'hidden' })),
      transition('true <=> false', animate('300ms ease-in-out'))
    ])
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]]
    }
  ]
})
export class TransferItemComponent implements OnInit, OnDestroy, AfterViewInit  {

  private unsubscribe$ = new Subject<void>();

  @ViewChildren('transferFromAddress') transferFromAddress!: QueryList<AddressComponent>;
  @ViewChildren('transferToAddress') transferToAddress!: QueryList<AddressComponent>;
  @ViewChildren('useTransferServiceProviderAddress') useTransferServiceProviderAddress!: QueryList<InputSwitch>;

  @Input() isNestedTransfer!: boolean;
  @Input() ignoreAlerts!: AlertType[];
  @Input() eventId!: string;

  @Output() onValueChanged = new EventEmitter<boolean>();

  originalPreparationServiceProvider!: Service | null;

  formValidation!: FormValidation;

  serviceProviders$ = this.serviceProviderService.allServiceProviders$;

  form!: FormGroup;
  formRoot!: FormGroup;
  formDisplay!: FormGroup;
  arrangement!: Arrangement;

  transferFromAddressOptions: any[][];
  transferToAddressOptions: any[][];

  fromAddressStringSource: BehaviorSubject<string>;
  fromAddressString$: Observable<string>;

  toAddressStringSource: BehaviorSubject<string>;
  toAddressString$: Observable<string>;

  fromAddressesSource: BehaviorSubject<any[]>;
  fromAddresses$: Observable<any[]>;

  toAddressesSource: BehaviorSubject<any[]>;
  toAddresses$: Observable<any[]>;

  transfersSource: BehaviorSubject<any[]>;
  transfers$: Observable<any[]>;

  eventsSource: BehaviorSubject<any[]>;
  events$: Observable<any[]>;

  transfersAsOptionsSource: BehaviorSubject<any[]>;
  transfersAsOptions$: Observable<any[]>;

  eventsAsOptionsSource: BehaviorSubject<any[]>;
  eventsAsOptions$: Observable<any[]>;

  pickupExistingTransferAddressTypeAsOptionsSource: BehaviorSubject<any[]>;
  pickupExistingTransferAddressTypeAsOptions$: Observable<any[]>;

  dropOffExistingTransferAddressTypeAsOptionsSource: BehaviorSubject<any[]>;
  dropOffExistingTransferAddressTypeAsOptions$: Observable<any[]>;

  datePickupDateTypeOptions = dateOfPickupTypeValue;

  transferFromAddressTypeOptions = transferFromAddressTypesValue;
  transferToAddressTypeOptions = transferToAddressTypesValue;
  eventTypeOptions = serviceTypesFormValue;

  subscriptions: Subscription[];

  placeOfPassingSubscription?: Subscription;

  transferItemsVisibility: boolean;

  isReady: boolean;

  businessCardSource: BehaviorSubject<Service | null>;
  businessCard: Observable<Service | null>;

  accordionTitle: boolean = false;
  accordionServiceProvider: boolean = false;
  accordionPickUp: boolean = false;
  accordionDropOff: boolean = false;
  accordionPricing: boolean = false;
  accordionNotes: boolean = false;

  skipOnValueChangedEmit: boolean = false;

  targetFromLinkedTransfer!: TargetLinkedTransfer;
  targetToLinkedTransfer!: TargetLinkedTransfer;

  get hasSupplier(): boolean {
    return this.formDisplay.get('service')?.value ? true : false;
  }

  get serviceProviderTypes() {
    return transferServiceTypesFormValue;
  }

  get eventTransferTypeEnum() {
    return eventTransferType;
  }

  get dateOfPickupTypeEnum() {
    return dateOfPickupType;
  }

  get transferFromAddressTypeEnum() {
    return TransferFromAddressType;
  }

  get transferToAddressTypeEnum() {
    return TransferToAddressType;
  }

  get transferServiceType(): DynamicComponents {
    return DynamicComponents.Transport;
  }

  get initialPickupIsPlaceOfPassingAddressFormControl(): FormControl {
    return this.formRoot.get('transfer.initialPickupIsPlaceOfPassingAddress') as FormControl;
  }

  get currentDateOfPickupTypeValue(): string {

    const v = this.formDisplay.get('from.dateOfPickupType')?.value;

    if (v) {

      if (v.value) {

        return v.value;

      }

    }

    return '';

  }

  get secondNoteTransferType(): any [] {
    return secondNoteTransferTypesValue;
  };

  get transferType() {
    return TransferType;
  }

  get transferTypes(): any [] {
    return transferTypesValue;
  }

  get eventIndex(): any[] {
    return eventIndexesValue;
  }

  constructor(
    private siteService: SiteService,
    private controlContainer: ControlContainer,
    private serviceProviderService: ServiceProviderService,
    private modalService: ModalService,
  ) {

    this.businessCardSource = new BehaviorSubject<Service | null>(null);
    this.businessCard = this.businessCardSource.asObservable();

    this.subscriptions = [];

    this.transferFromAddressOptions = [];
    this.transferToAddressOptions = [];

    this.transferItemsVisibility = false;

    this.fromAddressStringSource = new BehaviorSubject<string>('');
    this.toAddressStringSource = new BehaviorSubject<string>('');
    this.fromAddressesSource = new BehaviorSubject<any[]>([]);
    this.toAddressesSource = new BehaviorSubject<any[]>([]);
    this.transfersSource = new BehaviorSubject<any[]>([]);
    this.eventsSource = new BehaviorSubject<any[]>([]);
    this.transfersAsOptionsSource = new BehaviorSubject<any[]>([]);
    this.eventsAsOptionsSource = new BehaviorSubject<any[]>([]);
    this.pickupExistingTransferAddressTypeAsOptionsSource = new BehaviorSubject<any[]>([]);
    this.dropOffExistingTransferAddressTypeAsOptionsSource = new BehaviorSubject<any[]>([]);

    this.fromAddressString$ = this.fromAddressStringSource.asObservable();
    this.toAddressString$ = this.toAddressStringSource.asObservable();
    this.fromAddresses$ = this.fromAddressesSource.asObservable();
    this.toAddresses$ = this.toAddressesSource.asObservable();
    this.transfers$ = this.transfersSource.asObservable();
    this.events$ = this.eventsSource.asObservable();
    this.transfersAsOptions$ = this.transfersAsOptionsSource.asObservable();
    this.eventsAsOptions$ = this.eventsAsOptionsSource.asObservable();
    this.pickupExistingTransferAddressTypeAsOptions$ = this.pickupExistingTransferAddressTypeAsOptionsSource.asObservable();
    this.dropOffExistingTransferAddressTypeAsOptions$ = this.dropOffExistingTransferAddressTypeAsOptionsSource.asObservable();

    this.isReady = false;

  }

  ngOnInit(): void {

    const control = this.controlContainer.control;

    if (control) {

      this.form = control as FormGroup;
      this.formRoot = this.form.root as FormGroup;

      if (this.formRoot) {

        this.arrangement = this.formRoot.get('_arrangement')?.value as Arrangement;

        if (!this.arrangement) {

          console.error('No arrangement found within `this.formRoot`');

        }

      } else {
          
        console.error('No form root found within `this.form`');
        
      }

      this.formDisplay = this.generateDisplayForm(this.form, FormTransfersStructure);
      this.formValidation = new FormValidation(this.formDisplay, this.modalService);

      this.setTargetFromLinkedTransfer();
      this.setTargetToLinkedTransfer();

      if (this.isNestedTransfer) {

        // Using `as any` to avoid TypeScript error
        (this.transferFromAddressTypeOptions[1] as any).disabled = true;
        (this.transferFromAddressTypeOptions[2] as any).disabled = true;

      }

      this.setValidators();
      // this.createTransferObservable();

    }

  }

  setInitialValues() {

    this.setInitialAddressStrings();

    this.setPickupTransferLocationOptions();
    this.setDropOffTransferLocationOptions();

    this.convertDeprecatedTransfer();

    if (this.eventId) {
      changeDetection(() => {
        const eventOption = this.eventsAsOptionsSource.value.find(event => event.value === this.eventId);
        this.formDisplay.get('eventId')?.patchValue(eventOption);
      });
    }
    
  }

  ngAfterViewInit(): void {

    this.formDisplay.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      map((value: any) => {

        const spreadA = {...this.form.value};
        const spreadB = {...value};

        spreadA.service = _.get(spreadA, 'service._meta.id');
        spreadB.service = _.get(spreadB, 'service._meta.id');

        const _fromAddressTypeServiceProviderA = makeSimple(_.get(spreadA, 'from._addressTypeServiceProvider'), [
          { src: '_meta.id', target: 'id' },
          { src: 'serviceProviderId', target: 'serviceProviderId' },
        ]);

        const _fromAddressTypeServiceProviderB = makeSimple(_.get(spreadB, 'from._addressTypeServiceProvider'), [
          { src: '_meta.id', target: 'id' },
          { src: 'serviceProviderId', target: 'serviceProviderId' },
        ]);

        const _toAddressTypeServiceProviderA = makeSimple(_.get(spreadA, 'to._addressTypeServiceProvider'), [
          { src: '_meta.id', target: 'id' },
          { src: 'serviceProviderId', target: 'serviceProviderId' },
        ]);

        const _toAddressTypeServiceProviderB = makeSimple(_.get(spreadB, 'to._addressTypeServiceProvider'), [
          { src: '_meta.id', target: 'id' },
          { src: 'serviceProviderId', target: 'serviceProviderId' },
        ]);

        // We don't want to run `detailedDiff` on the service class found in _addressTypeServiceProvider, so lets make it simple...
        spreadA.from = {...spreadA.from, _addressTypeServiceProvider: _fromAddressTypeServiceProviderA };
        spreadB.from = {...spreadB.from, _addressTypeServiceProvider: _fromAddressTypeServiceProviderB };

        spreadA.to = {...spreadA.to, _addressTypeServiceProvider: _toAddressTypeServiceProviderA };
        spreadB.to = {...spreadB.to, _addressTypeServiceProvider: _toAddressTypeServiceProviderB };

        const diff = detailedDiff(spreadA, spreadB);

        const hasAdded = Object.keys(diff.added).length > 0;
        const hasDeleted = Object.keys(diff.deleted).length > 0;
        const hasUpdated = Object.keys(diff.updated).length > 0;
        
        const res = hasAdded || hasDeleted || hasUpdated;

        return res;

      }),
      filter(res => res),
      distinctUntilChanged(),
    ).subscribe({
      next: (changed) => {
        // There are times when we don't want to emit that the value has changed, mainly when we're refreshing the linked address values
        if (this.skipOnValueChangedEmit === false) {
          this.onValueChanged.emit(changed);
        }
      }
    });

    const existingTransfers = this.formRoot.get('transfer.transfers') as FormArray;
    const existingEvents = this.formRoot.get('events') as FormArray;
    const lineItemsFormArray = this.formDisplay.get('lineItems') as FormArray;

    if (existingTransfers) {

      if (existingTransfers.value.length > 0) {

        this.transfersSource.next(existingTransfers.value);

        const asOptions = this.generateTransferAsOptions(existingTransfers.value);

        this.transfersAsOptionsSource.next(asOptions);

      }

    }

    if (existingEvents) {
        
      if (existingEvents.value.length > 0) {

        this.eventsSource.next(existingEvents.value);

        const asOptions = this.generateEventsAsOptions(existingEvents.value);

        this.eventsAsOptionsSource.next(asOptions);

      }

    }

    this.setInitialValues();
    this.setFromAddressSubscriptions();
    this.setToAddressSubscriptions();

    if (lineItemsFormArray) {

      this.subscriptions.push(lineItemsFormArray.valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        distinctUntilChanged(),
      ).subscribe({
        next: (value: any[]) => {

          let total = 0;

          value.forEach((lineItem: any) => {
            total += _.get(lineItem, 'cost.amount', 0);
          });

          this.formDisplay.get('cost.amount')?.patchValue(total);

        }
      }));

    }

    this.accordionTitle = true;

    changeDetection(() => {

      this.isReady = true;

    });

  }

  setFromAddressSubscriptions() {

    const addressGroup = this.formDisplay.get('from.address') as FormGroup;
    const addressTypeControl = this.formDisplay.get('from._addressType') as FormControl;
    const addressTypeServiceProvider = this.formDisplay.get('from._addressTypeServiceProvider') as FormControl;
    const addressExistingTransferControl = this.formDisplay.get('from._addressTypeExistingTransfer') as FormControl;
    const addressExistingTransferAddressTypeControl = this.formDisplay.get('from._addressTypeExistingTransferAddressType')as FormControl;
    const addressExistingEventControl = this.formDisplay.get('from._addressTypeExistingEvent')as FormControl;

    if (addressTypeControl) {

      let isInitExecute = false; // Used to skip clearing `fromAddressGroup` when the component is first initialised

      this.subscriptions.push(addressTypeControl.valueChanges.pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe({
        next: (addressTypeValue: { name: string, value: TransferFromAddressType }) => {
          
          changeDetection(() => {

            if (!isInitExecute) {
              
              // We reset the address group to clear any previous values
              this.fromAddressStringSource.next('');
            
            }

            // We reset the address group to clear any previous values because we're going to set new values
            if (!isInitExecute && addressGroup) {

              const formGenerator = new FormGenerator();

              formGenerator.generate(_.clone(FormAddressStructure), {}, addressGroup);
      
            }

            if (addressTypeValue && addressTypeValue.value === TransferFromAddressType.PlaceOfPassing) {

              const placeOfPassingLinkedTransferIdFormControl = this.formRoot.get('deceased.placeOfPassing.linkedTransferId') as FormControl;
              const placeOfPassingFormGroup = this.formRoot.get('deceased.placeOfPassing.address') as FormGroup;

              // We're using Place of death address so we need to update the linked transfer id
              if (placeOfPassingLinkedTransferIdFormControl) {

                if (!isInitExecute) {

                  this.setTargetFromLinkedTransfer('place-of-passing');

                }

              }

              if (placeOfPassingFormGroup) {

                if (!isInitExecute) {

                  this.setTransferFromAddress(placeOfPassingFormGroup.value);

                }

                if (!isInitExecute) {

                  this.setFromAddressString('Place of Death: ', placeOfPassingFormGroup.value);

                } 

                if (isInitExecute) {

                  this.setFromAddressString('Place of Death: ', this.form.get('from.address')?.value);

                }

              }
              
            } else {

              if (!isInitExecute) {
                  
                // We're not using Place of Death address so we need to update the linked transfer id
                this.setTargetFromLinkedTransfer();

              }

            }

            if (addressTypeValue && addressTypeValue.value === TransferFromAddressType.ServiceProvider) {
              // Intentionally left blank for now
            } else {
              this.formDisplay.get('from._addressTypeServiceProviderType')?.patchValue(null);
              this.formDisplay.get('from._addressTypeServiceProvider')?.patchValue(null);
            }

            if (addressTypeValue && addressTypeValue.value === TransferFromAddressType.ExistingTransfer) {
              // Intentionally left blank for now
            } else {
              this.formDisplay.get('from._addressTypeExistingTransfer')?.patchValue(null);
              this.formDisplay.get('from._addressTypeExistingTransferAddressType')?.patchValue(null);
            }

            if (addressTypeValue && addressTypeValue.value === TransferFromAddressType.ExistingEvent) {
              // Intentionally left blank for now
            } else {
              this.formDisplay.get('from._addressTypeExistingEvent')?.patchValue(null);
            }

            if (isInitExecute) {
              isInitExecute = false;
            }

          });

        }
      }));

    }

    if (addressTypeServiceProvider) {

      this.subscriptions.push(addressTypeServiceProvider.valueChanges.pipe(
        // startWith(null), // Provide an initial value
        startWith(addressTypeServiceProvider.value),
        pairwise(),
        takeUntil(this.unsubscribe$)
      ).subscribe({
        next: ([previous, current]: [Service, Service]) => {

          if (!current) { // Don't do anything if we don't have a service provider
            return;
          }

          const currentPreparationServiceProviderFormControl = this.arrangement.form.get('preparation.service') as FormControl;
          const currentPreparationLinkedTransferIdFormControl = this.arrangement.form.get('preparation.linkedTransferId') as FormControl;
          const serviceProviderFormControl = this.formDisplay.get('from._addressTypeServiceProvider') as FormControl;
          const serviceProviderTypeFormControl = this.formDisplay.get('from._addressTypeServiceProviderType') as FormControl;
          const transferIdFormControl = this.formDisplay.get('_meta.id');
      
          if (
            currentPreparationServiceProviderFormControl && 
            currentPreparationLinkedTransferIdFormControl && 
            serviceProviderFormControl && 
            serviceProviderTypeFormControl &&
            transferIdFormControl
          ) {
      
            const currentPreparationServiceProvider = currentPreparationServiceProviderFormControl.value as Service;
            const currentPreparationLinkedTransferId = currentPreparationLinkedTransferIdFormControl.value as LinkedTransferId[];
            const displayFormServiceProvider = serviceProviderFormControl.value as Service;
            const displayFormServiceProviderType = serviceProviderTypeFormControl.value as { name: string, value: string };
            const transferId = transferIdFormControl.value as string;
      
            if (displayFormServiceProviderType.value === DynamicComponents.Mortuary && !this.ignoreAlerts.includes(AlertType.Mortuary)) {
      
              if (!currentPreparationServiceProvider) {
      
                const modal = this.modalService.generic({
                  title: `Set Service Provider as Preparation Service Provider`,
                  copy: [`Would you also like to set <strong>${displayFormServiceProvider.name}</strong> as the Preparation Service Provider?`],
                  buttons: [
                    {
                      label: 'Yes',
                      key: 'yes',
                      class: 'p-button-primary',
                    },
                    {
                      label: 'No',
                      key: 'no',
                      class: 'p-button-secondary',
                    }
                  ]
                });
      
                modal.onClose.subscribe({
                  next: (key) => {
      
                    if (key === 'yes') {

                      if (!this.originalPreparationServiceProvider) {
                        this.originalPreparationServiceProvider = currentPreparationServiceProvider;
                      }

                      currentPreparationServiceProviderFormControl.patchValue(displayFormServiceProvider);

                      this.setTransferFromAddress(displayFormServiceProvider.address);
                      this.setFromAddressString(`${displayFormServiceProvider.name}: `, displayFormServiceProvider.address);

                      this.setTargetFromLinkedTransfer('preparation');

                    }
                  
                  }
                });
      
              } else if (currentPreparationServiceProvider.serviceProviderId !== displayFormServiceProvider.serviceProviderId || _.get(currentPreparationServiceProvider, '_meta.id') !== _.get(displayFormServiceProvider, '_meta.id')) {
      
                if (currentPreparationLinkedTransferId.length > 0) {
      
                  const addressToStringPipe = new AddressToStringPipe();
      
                  const modal = this.modalService.generic({
                    title: `Preparation Linked to Existing Transfer`,
                    copy: [
                      `We've noticed that you've already linked the preparation service provider to an existing transfer.`,
                      `Would you also like to replace <strong>${currentPreparationServiceProvider.name}</strong> with <strong>${displayFormServiceProvider.name}</strong> to be the arrangement's Preparation Service Provider?`,
                      `If so, you should consider if changes to the linked transfer are required.`,
                    ],
                    buttons: [
                      {
                        label: 'Yes, replace',
                        key: 'yes',
                        class: 'p-button-primary',
                      },
                      {
                        label: 'No, don\'t replace',
                        key: 'no',
                        class: 'p-button-secondary',
                      },
                      {
                        label: 'Cancel',
                        key: 'cancel',
                        class: 'p-button-secondary',
                      }
                    ]
                  });

                  modal.onClose.subscribe({
                    next: (key) => {
      
                      if (key === 'yes') {

                        if (!this.originalPreparationServiceProvider) {
                          this.originalPreparationServiceProvider = currentPreparationServiceProvider;
                        }

                        currentPreparationServiceProviderFormControl.patchValue(displayFormServiceProvider);

                        this.setTransferFromAddress(displayFormServiceProvider.address);
                        this.setFromAddressString(`${displayFormServiceProvider.name}: `, displayFormServiceProvider.address);

                        this.setTargetFromLinkedTransfer('preparation');

                      } else if (key === 'no') {
                        // Do nothing
                      } else if (key === 'cancel') { // Change value back to previous

                        changeDetection(() => {

                          serviceProviderFormControl.patchValue(previous, { emitEvent: false }); // Don't trigger change detection because it will display all the alerts again

                          // This will already be set so no need to set it again
                          // this.setTransferFromAddress(serviceProvider.address);
                          // this.setFromAddressString(`${serviceProvider.name}: `, serviceProvider.address);

                        });

                      }
                    
                    }
                  });
      
                } else {
      
                  const modal = this.modalService.generic({
                    title: `Replace Current Preparation Service Provider`,
                    copy: [`Would you also like to replace <strong>${currentPreparationServiceProvider.name}</strong> with <strong>${displayFormServiceProvider.name}</strong> to be the arrangement's Preparation Service Provider?`],
                    buttons: [{
                        label: 'Cancel',
                        key: 'cancel',
                        class: 'p-button-secondary',
                      }, {
                        label: 'No',
                        key: 'no',
                        class: 'p-button-secondary',
                      }, {
                        label: 'Yes',
                        key: 'yes',
                        class: 'p-button-primary',
                      },
                    ]
                  });
        
                  modal.onClose.subscribe({
                    next: (key) => {
        
                      if (key === 'yes') {

                        if (!this.originalPreparationServiceProvider) {
                          this.originalPreparationServiceProvider = currentPreparationServiceProvider;
                        }

                        currentPreparationServiceProviderFormControl.patchValue(displayFormServiceProvider);

                        this.setTransferFromAddress(displayFormServiceProvider.address);
                        this.setFromAddressString(`${displayFormServiceProvider.name}: `, displayFormServiceProvider.address);

                        this.setTargetFromLinkedTransfer('preparation');
                        
                      } else if (key === 'no') {

                        this.setTransferFromAddress(displayFormServiceProvider.address);
                        this.setFromAddressString(`${displayFormServiceProvider.name}: `, displayFormServiceProvider.address);

                        this.setTargetFromLinkedTransfer('service-provider');

                      }
                    
                    }
                  });
      
                }
      
              } else { 

                if (
                  currentPreparationServiceProvider.serviceProviderId === displayFormServiceProvider.serviceProviderId && 
                  _.get(currentPreparationServiceProvider, '_meta.id') === _.get(displayFormServiceProvider, '_meta.id')
                ) {
                  this.setTargetFromLinkedTransfer('preparation');
                }

                this.setTransferFromAddress(displayFormServiceProvider.address);
                this.setFromAddressString(`${displayFormServiceProvider.name}: `, displayFormServiceProvider.address);

              }
      
            } else {

              this.setTransferFromAddress(displayFormServiceProvider.address);
              this.setFromAddressString(`${displayFormServiceProvider.name}: `, displayFormServiceProvider.address);

            }
      
          }

        }
      }));

    }

    if (addressExistingTransferControl) {

      let isInitExecute = false; // Used to ignore the `.patchValue(null)` when the component is first initialised

      this.subscriptions.push(addressExistingTransferControl.valueChanges.pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe({
        next: (value: string) => {

          // We sometimes manually set the value to null when changing pickup location type, so we need to ignore null values
          if (value === null) {
            return;
          }

          const transferId = _.get(value, 'value', null);

          if (!transferId) {

            this.modalService.generic({
              title: 'No Transfer ID found',
              copy: ['We couldn\'t find a Transfer ID for the selected transfer. Please try again.'],
              buttons: [
                { label: 'Close', key: 'close', class: 'p-button-primary' }
              ]
            });

            return;

          }

          const transfer = this.transfersSource.value.find(transfer => transfer._meta.id === transferId);

          if (!transfer) {

            this.modalService.generic({
              title: 'Transfer not found',
              copy: ['We couldn\'t find the selected transfer. Please try again.'],
              buttons: [
                { label: 'Close', key: 'close', class: 'p-button-primary' }
              ]
            });

            return;

          }

          if (!isInitExecute) {
            this.formDisplay.get('from._addressTypeExistingTransferAddressType')?.patchValue(null);
          } else {
            isInitExecute = false;
          }

          this.setPickupTransferLocationOptions();

        }
      }));

    }

    if (addressExistingTransferControl && addressExistingTransferAddressTypeControl) {

      combineLatest([
        addressExistingTransferControl.valueChanges.pipe(startWith(addressExistingTransferControl.value)),
        addressExistingTransferAddressTypeControl.valueChanges.pipe(startWith(addressExistingTransferAddressTypeControl.value))
      ]).pipe(
        takeUntil(this.unsubscribe$),
        map(([transferValue, addressTypeValue]) => ({ transferValue, addressTypeValue })),
      ).subscribe(({ transferValue, addressTypeValue }) => {

        if (!transferValue || !addressTypeValue) {

          // this.fromAddressStringSource.next('');

          return;

        }

        let addressPrefix = '';

        const transfer = this.transfersSource.value.find(transfer => transfer._meta.id === transferValue.value);

        if (transfer) {

          let title = _.get(transfer, 'title');

          if (!title) {
            title = 'Existing Transfer';
          }

          if (addressTypeValue.value === 'pickup-location') {

            addressPrefix = `${title} - Pickup Address:`;

          } else if (addressTypeValue.value === 'drop-off-location') {

            addressPrefix = `${title} - Drop Off Address:`;

          }
  
          this.setTransferFromAddress(transfer.from.address);
          this.setFromAddressString(addressPrefix, transfer.from.address);

          this.setTargetFromLinkedTransfer('transfer', _.get(transfer, '_meta.id'));

        }

      });
    }

    if (addressExistingEventControl) {
      
      this.subscriptions.push(addressExistingEventControl.valueChanges.pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe({
        next: (eventValue: any) => {

          if (!eventValue) {

            return;
  
          }
  
          let addressPrefix = '';
  
          const event = this.eventsSource.value.find(event => event._meta.id === eventValue.value);
  
          if (event) {
  
            let title = _.get(event, 'title');
  
            if (!title) {
              title = 'Existing Event';
            }
  
            addressPrefix = `${title}`;

            const venueService = _.get(event, 'venue.service');

            if (venueService) {
              addressPrefix += ` - ${venueService.name}`;
            }

            addressPrefix += ':';
  
          }

          this.setTransferFromAddress(event.venue.address);
          this.setFromAddressString(addressPrefix, event.venue.address);

          this.setTargetFromLinkedTransfer('event', _.get(event, '_meta.id'));

        }
      }));
      
    }

  }

  setToAddressSubscriptions() {

    const addressGroup = this.formDisplay.get('to.address') as FormGroup;
    const addressTypeControl = this.formDisplay.get('to._addressType') as FormControl;
    const addressTypeServiceProvider = this.formDisplay.get('to._addressTypeServiceProvider') as FormControl;
    const addressExistingTransferControl = this.formDisplay.get('to._addressTypeExistingTransfer') as FormControl;
    const addressExistingTransferAddressTypeControl = this.formDisplay.get('to._addressTypeExistingTransferAddressType')as FormControl;
    const addressExistingEventControl = this.formDisplay.get('to._addressTypeExistingEvent')as FormControl;

    if (addressTypeControl) {

      let isInitExecute = false; // Used to skip clearing `fromAddressGroup` when the component is first initialised

      this.subscriptions.push(addressTypeControl.valueChanges.pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe({
        next: (addressTypeValue: { name: string, value: TransferFromAddressType }) => {
          
          changeDetection(() => {

            if (!isInitExecute) {
              
              // We reset the address group to clear any previous values
              this.toAddressStringSource.next('');
            
            }

            // We reset the address group to clear any previous values because we're going to set new values
            if (!isInitExecute && addressGroup) {

              const formGenerator = new FormGenerator();

              formGenerator.generate(_.clone(FormAddressStructure), {}, addressGroup);
      
            }

            // if (addressTypeValue && addressTypeValue.value === TransferToAddressType.PlaceOfPassing) {

            //   const placeOfPassingLinkedTransferIdFormControl = this.formRoot.get('deceased.placeOfPassing.linkedTransferId') as FormControl;
            //   const placeOfPassingFormGroup = this.formRoot.get('deceased.placeOfPassing.address') as FormGroup;

            //   // We're using Place of Death address so we need to update the linked transfer id
            //   if (placeOfPassingLinkedTransferIdFormControl) {

            //     if (!isInitExecute) {

            //       this.setTargetFromLinkedTransfer('place-of-passing');

            //     }

            //   }

            //   if (placeOfPassingFormGroup) {

            //     if (!isInitExecute) {

            //       this.setTransferFromAddress(placeOfPassingFormGroup.value);

            //     }

            //     if (!isInitExecute) {

            //       this.setFromAddressString('Place of Death: ', placeOfPassingFormGroup.value);

            //     } 

            //     if (isInitExecute) {

            //       this.setFromAddressString('Place of Death: ', this.form.get('from.address')?.value);

            //     }

            //   }
              
            // } else {

            //   if (!isInitExecute) {
                  
            //     // We're not using Place of Death address so we need to update the linked transfer id
            //     this.setTargetFromLinkedTransfer();

            //   }

            // }

            if (addressTypeValue && addressTypeValue.value === TransferFromAddressType.ServiceProvider) {
              // Intentionally left blank for now
            } else {
              this.formDisplay.get('to._addressTypeServiceProviderType')?.patchValue(null);
              this.formDisplay.get('to._addressTypeServiceProvider')?.patchValue(null);
            }

            if (addressTypeValue && addressTypeValue.value === TransferFromAddressType.ExistingTransfer) {
              // Intentionally left blank for now
            } else {
              this.formDisplay.get('to._addressTypeExistingTransfer')?.patchValue(null);
              this.formDisplay.get('to._addressTypeExistingTransferAddressType')?.patchValue(null);
            }

            if (addressTypeValue && addressTypeValue.value === TransferFromAddressType.ExistingEvent) {
              // Intentionally left blank for now
            } else {
              this.formDisplay.get('to._addressTypeExistingEvent')?.patchValue(null);
            }

            if (isInitExecute) {
              isInitExecute = false;
            }

          });

        }
      }));

    }

    if (addressTypeServiceProvider) {

      this.subscriptions.push(addressTypeServiceProvider.valueChanges.pipe(
        // startWith(null), // Provide an initial value
        startWith(addressTypeServiceProvider.value),
        pairwise(),
        takeUntil(this.unsubscribe$)
      ).subscribe({
        next: ([previous, current]: [Service, Service]) => {

          if (!current) { // Don't do anything if we don't have a service provider
            return;
          }

          const currentPreparationServiceProviderFormControl = this.arrangement.form.get('preparation.service') as FormControl;
          const currentPreparationLinkedTransferIdFormControl = this.arrangement.form.get('preparation.linkedTransferId') as FormControl;
          const serviceProviderFormControl = this.formDisplay.get('to._addressTypeServiceProvider') as FormControl;
          const serviceProviderTypeFormControl = this.formDisplay.get('to._addressTypeServiceProviderType') as FormControl;
          const transferIdFormControl = this.formDisplay.get('_meta.id');
      
          if (
            currentPreparationServiceProviderFormControl && 
            currentPreparationLinkedTransferIdFormControl && 
            serviceProviderFormControl && 
            serviceProviderTypeFormControl &&
            transferIdFormControl
          ) {
      
            const currentPreparationServiceProvider = currentPreparationServiceProviderFormControl.value as Service;
            const currentPreparationLinkedTransferId = currentPreparationLinkedTransferIdFormControl.value as LinkedTransferId[];
            const displayFormServiceProvider = serviceProviderFormControl.value as Service;
            const displayFormServiceProviderType = serviceProviderTypeFormControl.value as { name: string, value: string };
            const transferId = transferIdFormControl.value as string;
      
            if (displayFormServiceProviderType.value === DynamicComponents.Mortuary && !this.ignoreAlerts.includes(AlertType.Mortuary)) {
      
              if (!currentPreparationServiceProvider) {
      
                const modal = this.modalService.generic({
                  title: `Set Service Provider as Preparation Service Provider`,
                  copy: [`Would you also like to set <strong>${displayFormServiceProvider.name}</strong> as the Preparation Service Provider?`],
                  buttons: [
                    {
                      label: 'Yes',
                      key: 'yes',
                      class: 'p-button-primary',
                    },
                    {
                      label: 'No',
                      key: 'no',
                      class: 'p-button-secondary',
                    }
                  ]
                });
      
                modal.onClose.subscribe({
                  next: (key) => {
      
                    if (key === 'yes') {

                      if (!this.originalPreparationServiceProvider) {
                        this.originalPreparationServiceProvider = currentPreparationServiceProvider;
                      }

                      currentPreparationServiceProviderFormControl.patchValue(displayFormServiceProvider);

                      this.setTransferToAddress(displayFormServiceProvider.address);
                      this.setToAddressString(`${displayFormServiceProvider.name}: `, displayFormServiceProvider.address);

                      this.setTargetToLinkedTransfer('preparation');

                    }
                  
                  }
                });
      
              } else if (currentPreparationServiceProvider.serviceProviderId !== displayFormServiceProvider.serviceProviderId || _.get(currentPreparationServiceProvider, '_meta.id') !== _.get(displayFormServiceProvider, '_meta.id')) {
      
                if (currentPreparationLinkedTransferId.length > 0) {
      
                  const addressToStringPipe = new AddressToStringPipe();
      
                  const modal = this.modalService.generic({
                    title: `Preparation Linked to Existing Transfer`,
                    copy: [
                      `We've noticed that you've already linked the preparation service provider to an existing transfer.`,
                      `Would you also like to replace <strong>${currentPreparationServiceProvider.name}</strong> with <strong>${displayFormServiceProvider.name}</strong> to be the arrangement's Preparation Service Provider?`,
                      `If so, you should consider if changes to the linked transfer are required.`,
                    ],
                    buttons: [
                      {
                        label: 'Yes, replace',
                        key: 'yes',
                        class: 'p-button-primary',
                      },
                      {
                        label: 'No, don\'t replace',
                        key: 'no',
                        class: 'p-button-secondary',
                      },
                      {
                        label: 'Cancel',
                        key: 'cancel',
                        class: 'p-button-secondary',
                      }
                    ]
                  });

                  modal.onClose.subscribe({
                    next: (key) => {
      
                      if (key === 'yes') {

                        if (!this.originalPreparationServiceProvider) {
                          this.originalPreparationServiceProvider = currentPreparationServiceProvider;
                        }

                        currentPreparationServiceProviderFormControl.patchValue(displayFormServiceProvider);

                        this.setTransferToAddress(displayFormServiceProvider.address);
                        this.setToAddressString(`${displayFormServiceProvider.name}: `, displayFormServiceProvider.address);

                        this.setTargetToLinkedTransfer('preparation');

                      } else if (key === 'no') {
                        // Do nothing
                      } else if (key === 'cancel') { // Change value back to previous

                        changeDetection(() => {

                          serviceProviderFormControl.patchValue(previous, { emitEvent: false }); // Don't trigger change detection because it will display all the alerts again

                          // This will already be set so no need to set it again
                          // this.setTransferToAddress(serviceProvider.address);
                          // this.setToAddressString(`${serviceProvider.name}: `, serviceProvider.address);

                        });

                      }
                    
                    }
                  });
      
                } else {
      
                  const modal = this.modalService.generic({
                    title: `Replace Current Preparation Service Provider`,
                    copy: [`Would you also like to replace <strong>${currentPreparationServiceProvider.name}</strong> with <strong>${displayFormServiceProvider.name}</strong> to be the arrangement's Preparation Service Provider?`],
                    buttons: [{
                        label: 'Cancel',
                        key: 'cancel',
                        class: 'p-button-secondary',
                      }, {
                        label: 'No',
                        key: 'no',
                        class: 'p-button-secondary',
                      }, {
                        label: 'Yes',
                        key: 'yes',
                        class: 'p-button-primary',
                      },
                    ]
                  });
        
                  modal.onClose.subscribe({
                    next: (key) => {
        
                      if (key === 'yes') {

                        if (!this.originalPreparationServiceProvider) {
                          this.originalPreparationServiceProvider = currentPreparationServiceProvider;
                        }

                        currentPreparationServiceProviderFormControl.patchValue(displayFormServiceProvider);

                        this.setTransferToAddress(displayFormServiceProvider.address);
                        this.setToAddressString(`${displayFormServiceProvider.name}: `, displayFormServiceProvider.address);

                        this.setTargetToLinkedTransfer('preparation');
                        
                      } else if (key === 'no') {

                        this.setTransferToAddress(displayFormServiceProvider.address);
                        this.setToAddressString(`${displayFormServiceProvider.name}: `, displayFormServiceProvider.address);

                        this.setTargetToLinkedTransfer('service-provider');

                      }
                    
                    }
                  });
      
                }
      
              } else { 

                if (
                  currentPreparationServiceProvider.serviceProviderId === displayFormServiceProvider.serviceProviderId && 
                  _.get(currentPreparationServiceProvider, '_meta.id') === _.get(displayFormServiceProvider, '_meta.id')
                ) {
                  this.setTargetToLinkedTransfer('preparation');
                }

                this.setTransferToAddress(displayFormServiceProvider.address);
                this.setToAddressString(`${displayFormServiceProvider.name}: `, displayFormServiceProvider.address);

              }
      
            } else {

              this.setTransferToAddress(displayFormServiceProvider.address);
              this.setToAddressString(`${displayFormServiceProvider.name}: `, displayFormServiceProvider.address);

            }
      
          }

        }
      }));

    }

    if (addressExistingTransferControl) {

      let isInitExecute = false; // Used to ignore the `.patchValue(null)` when the component is first initialised

      this.subscriptions.push(addressExistingTransferControl.valueChanges.pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe({
        next: (value: string) => {

          // We sometimes manually set the value to null when changing pickup location type, so we need to ignore null values
          if (value === null) {
            return;
          }

          const transferId = _.get(value, 'value', null);

          if (!transferId) {

            this.modalService.generic({
              title: 'No Transfer ID found',
              copy: ['We couldn\'t find a Transfer ID for the selected transfer. Please try again.'],
              buttons: [
                { label: 'Close', key: 'close', class: 'p-button-primary' }
              ]
            });

            return;

          }

          const transfer = this.transfersSource.value.find(transfer => transfer._meta.id === transferId);

          if (!transfer) {

            this.modalService.generic({
              title: 'Transfer not found',
              copy: ['We couldn\'t find the selected transfer. Please try again.'],
              buttons: [
                { label: 'Close', key: 'close', class: 'p-button-primary' }
              ]
            });

            return;

          }

          if (!isInitExecute) {
            this.formDisplay.get('to._addressTypeExistingTransferAddressType')?.patchValue(null);
          } else {
            isInitExecute = false;
          }

          this.setDropOffTransferLocationOptions();

        }
      }));

    }

    if (addressExistingTransferControl && addressExistingTransferAddressTypeControl) {

      combineLatest([
        addressExistingTransferControl.valueChanges.pipe(startWith(addressExistingTransferControl.value)),
        addressExistingTransferAddressTypeControl.valueChanges.pipe(startWith(addressExistingTransferAddressTypeControl.value))
      ]).pipe(
        takeUntil(this.unsubscribe$),
        map(([transferValue, addressTypeValue]) => ({ transferValue, addressTypeValue })),
      ).subscribe(({ transferValue, addressTypeValue }) => {

        if (!transferValue || !addressTypeValue) {

          // this.fromAddressStringSource.next('');

          return;

        }

        let addressPrefix = '';

        const transfer = this.transfersSource.value.find(transfer => transfer._meta.id === transferValue.value);

        if (transfer) {

          let title = _.get(transfer, 'title');

          if (!title) {
            title = 'Existing Transfer';
          }

          if (addressTypeValue.value === 'pickup-location') {

            addressPrefix = `${title} - Pickup Address:`;

          } else if (addressTypeValue.value === 'drop-off-location') {

            addressPrefix = `${title} - Drop Off Address:`;

          }
  
          this.setTransferToAddress(transfer.to.address);
          this.setToAddressString(addressPrefix, transfer.to.address);

          this.setTargetToLinkedTransfer('transfer', _.get(transfer, '_meta.id'));

        }

      });
    }

    if (addressExistingEventControl) {
      
      this.subscriptions.push(addressExistingEventControl.valueChanges.pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe({
        next: (eventValue: any) => {

          if (!eventValue) {

            return;
  
          }
  
          let addressPrefix = '';
  
          const event = this.eventsSource.value.find(event => event._meta.id === eventValue.value);
  
          if (event) {
  
            let title = _.get(event, 'title');
  
            if (!title) {
              title = 'Existing Event';
            }
  
            addressPrefix = `${title}`;

            const venueService = _.get(event, 'venue.service');

            if (venueService) {
              addressPrefix += ` - ${venueService.name}`;
            }

            addressPrefix += ':';
  
          }

          this.setTransferToAddress(event.venue.address);
          this.setToAddressString(addressPrefix, event.venue.address);

          this.setTargetToLinkedTransfer('event', _.get(event, '_meta.id'));

        }
      }));
      
    }

  }

  toggleAccordion(type: string): boolean {

    if (type === 'title') {

      this.accordionTitle = !this.accordionTitle;
      
    } else if (type === 'service-provider') {

      this.accordionServiceProvider = !this.accordionServiceProvider;
      
    } else if (type === 'pick-up') {

      this.accordionPickUp = !this.accordionPickUp;

    } else if (type === 'drop-off') {

      this.accordionDropOff = !this.accordionDropOff;

    } else if (type === 'pricing') {

      this.accordionPricing = !this.accordionPricing;

    } else if (type === 'notes') {

      this.accordionNotes = !this.accordionNotes;

    } else {

      console.warn(`Supplied type of "${type}" isn't supported`);

    }

    return false;

  }

  onCreateNewTransfer(event: any): void {
      
    const modal = this.modalService.createTransfer({});
    
    modal.onClose.subscribe({
      next: (closeType) => {
        console.log('Modal result: ', closeType);
      }
    });

  }

  onToAddressClick(event: any): void {
    this.arrangement.processAndUpdateAddresses();
  }

  onToChange(event: any): void {

    const formGroup = this.formDisplay.get('to') as FormGroup;

    this.updateAddressAndProperties(formGroup, event.value.value);

  }

  ngOnDestroy(): void {

    console.log('TransferItemComponent->ngOnDestroy()');

    this.subscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });

    if (this.placeOfPassingSubscription) {
      this.placeOfPassingSubscription.unsubscribe();
    }

    this.unsubscribe$.next();
    this.unsubscribe$.complete();

  }

  onSelectFromServiceProviderType(event: { name: string, value: string }): void {

    // Do nothing for now

  }

  onSelectFromServiceProvider(event: any): void {

    // Do nothing for now

    return;

  }

  onSelectTransferServiceProvider(event: any): void {

    const serviceFormControl = this.formDisplay.get('service') as FormControl;

    if (serviceFormControl) {

      // this.addServiceProviderToAddresses(serviceFormControl);

      const service: Service = serviceFormControl.value;

      this.businessCardSource.next(service);

    }

    this.transferItemsVisibility = false;

    changeDetection(() => {
      this.transferItemsVisibility = true;
    });

  }

  onRefreshFromAddress(event: any): void {

    this.skipOnValueChangedEmit = true;

    const fromAddressTypeValue = this.formDisplay.get('from._addressType')?.value;
    const fromAddressTypeServiceProviderTypeValue = this.formDisplay.get('from._addressTypeServiceProviderType')?.value;
    const fromAddressTypeServiceProviderValue = this.formDisplay.get('from._addressTypeServiceProvider')?.value;
    const fromAddressExistingTransferValue = this.formDisplay.get('from._addressTypeExistingTransfer')?.value;
    const fromAddressExistingTransferAddressTypeValue = this.formDisplay.get('from._addressTypeExistingTransferAddressType')?.value;
    const fromAddressExistingEventValue = this.formDisplay.get('from._addressTypeExistingEvent')?.value;
    const fromSelectContainerValue = this.formDisplay.get('from._selectContainer')?.value;

    this.formDisplay.get('from._addressType')?.patchValue(null);
    this.formDisplay.get('from._addressTypeServiceProviderType')?.patchValue(null);
    this.formDisplay.get('from._addressTypeServiceProvider')?.patchValue(null);
    this.formDisplay.get('from._addressTypeExistingTransfer')?.patchValue(null);
    this.formDisplay.get('from._addressTypeExistingTransferAddressType')?.patchValue(null);
    this.formDisplay.get('from._addressTypeExistingEvent')?.patchValue(null);
    this.formDisplay.get('from._selectContainer')?.patchValue(null);

    changeDetection(() => {

      if (fromAddressTypeValue) {
        this.formDisplay.get('from._addressType')?.patchValue(fromAddressTypeValue);
      }

      if (fromAddressTypeServiceProviderTypeValue) {
        this.formDisplay.get('from._addressTypeServiceProviderType')?.patchValue(fromAddressTypeServiceProviderTypeValue);
      }

      if (fromAddressExistingTransferValue) {
        this.formDisplay.get('from._addressTypeExistingTransfer')?.patchValue(fromAddressExistingTransferValue);
      }

      if (fromSelectContainerValue) {
        this.formDisplay.get('from._selectContainer')?.patchValue(fromSelectContainerValue);
      }

      changeDetection(() => {

        if (fromAddressExistingTransferAddressTypeValue) {
          this.formDisplay.get('from._addressTypeExistingTransferAddressType')?.patchValue(fromAddressExistingTransferAddressTypeValue);
        }
  
        if (fromAddressExistingEventValue) {
          this.formDisplay.get('from._addressTypeExistingEvent')?.patchValue(fromAddressExistingEventValue);
        }

        if (fromAddressTypeServiceProviderValue) {
          this.formDisplay.get('from._addressTypeServiceProvider')?.patchValue(fromAddressTypeServiceProviderValue);
        }

        // Update the arrangement form with the values from the transfer item
        const formGenerator = new FormGenerator();

        formGenerator.generate(FormTransfersStructure, this.formDisplay.value, this.form);

        changeDetection(() => {

          addLinkedTransferId(this.targetFromLinkedTransfer, this.arrangement);

          this.arrangement.save({}).subscribe({
            next: (response: any) => {
              this.skipOnValueChangedEmit = false;
              this.modalService.generic({
                title: 'Transfer Link Updated',
                copy: ['The transfer link has been successfully updated.', 'Please review the pricing details to ensure they are still accurate.'],
                buttons: [
                  { label: 'Close', key: 'close', class: 'p-button-primary' }
                ]
              });
            },
            error: (error: any) => {
              this.skipOnValueChangedEmit = false;
              this.modalService.error(error);
            }
          });
    
        });

      });

    });

  }

  onSelectFromAddressType(event: { name: string, value: TransferFromAddressType }): void {
  }

  onSelectToServiceProviderType(event: { name: string, value: string }): void {

    // Do nothing for now

  }

  onSelectToServiceProvider(event: any): void {

    // Do nothing for now

    return;

  }

  onRefreshToAddress(event: any): void {

    this.skipOnValueChangedEmit = true;

    const addressTypeValue = this.formDisplay.get('to._addressType')?.value;
    const addressTypeServiceProviderTypeValue = this.formDisplay.get('to._addressTypeServiceProviderType')?.value;
    const addressTypeServiceProviderValue = this.formDisplay.get('to._addressTypeServiceProvider')?.value;
    const addressExistingTransferValue = this.formDisplay.get('to._addressTypeExistingTransfer')?.value;
    const addressExistingTransferAddressTypeValue = this.formDisplay.get('to._addressTypeExistingTransferAddressType')?.value;
    const addressExistingEventValue = this.formDisplay.get('to._addressTypeExistingEvent')?.value;
    const selectContainerValue = this.formDisplay.get('to._selectContainer')?.value;

    this.formDisplay.get('to._addressType')?.patchValue(null);
    this.formDisplay.get('to._addressTypeServiceProviderType')?.patchValue(null);
    this.formDisplay.get('to._addressTypeServiceProvider')?.patchValue(null);
    this.formDisplay.get('to._addressTypeExistingTransfer')?.patchValue(null);
    this.formDisplay.get('to._addressTypeExistingTransferAddressType')?.patchValue(null);
    this.formDisplay.get('to._addressTypeExistingEvent')?.patchValue(null);
    this.formDisplay.get('to._selectContainer')?.patchValue(null);

    changeDetection(() => {

      if (addressTypeValue) {
        this.formDisplay.get('to._addressType')?.patchValue(addressTypeValue);
      }

      if (addressTypeServiceProviderTypeValue) {
        this.formDisplay.get('to._addressTypeServiceProviderType')?.patchValue(addressTypeServiceProviderTypeValue);
      }

      if (addressExistingTransferValue) {
        this.formDisplay.get('to._addressTypeExistingTransfer')?.patchValue(addressExistingTransferValue);
      }

      if (selectContainerValue) {
        this.formDisplay.get('to._selectContainer')?.patchValue(selectContainerValue);
      }

      changeDetection(() => {

        if (addressExistingTransferAddressTypeValue) {
          this.formDisplay.get('to._addressTypeExistingTransferAddressType')?.patchValue(addressExistingTransferAddressTypeValue);
        }
  
        if (addressExistingEventValue) {
          this.formDisplay.get('to._addressTypeExistingEvent')?.patchValue(addressExistingEventValue);
        }

        if (addressTypeServiceProviderValue) {
          this.formDisplay.get('to._addressTypeServiceProvider')?.patchValue(addressTypeServiceProviderValue);
        }

        // Update the arrangement form with the values from the transfer item
        const formGenerator = new FormGenerator();

        formGenerator.generate(FormTransfersStructure, this.formDisplay.value, this.form);

        changeDetection(() => {

          addLinkedTransferId(this.targetToLinkedTransfer, this.arrangement)

          this.arrangement.save({}).subscribe({
            next: (response: any) => {
              this.skipOnValueChangedEmit = false;
              this.modalService.generic({
                title: 'Transfer Link Updated',
                copy: ['The transfer link has been successfully updated.', 'Please review the pricing details to ensure they are still accurate.'],
                buttons: [
                  { label: 'Close', key: 'close', class: 'p-button-primary' }
                ]
              });
            },
            error: (error: any) => {
              this.skipOnValueChangedEmit = false;
              this.modalService.error(error);
            }
          });
    
        });

      });

    });

  }

  onSelectToAddressType(event: { name: string, value: TransferToAddressType }): void { }

  /**
   * Set the validators for this form
   */
  private setValidators(): void {

    this.formValidation.addValidators([
      {
        path: `title`,
        label: 'Transfer Title',
        validators: [Validators.required]
      },
      {
        path: `type`,
        label: 'Transfer Type',
        validators: [Validators.required]
      },
      {
        path: `service`,
        label: 'Transfer Service Provider',
        validators: [Validators.required]
      },
    ]);

    const dateOfPickupTypeFormControl = this.formDisplay.get('from.dateOfPickupType');
    const fromSelectContainerFormControl = this.formDisplay.get('from._selectContainer');
    const toSelectContainerFormControl = this.formDisplay.get('to._selectContainer');
    const serviceControl = this.formDisplay.get('service') as FormControl;

    if (dateOfPickupTypeFormControl) {

      const currentValue = dateOfPickupTypeFormControl.value;

      if (currentValue) {

        if (currentValue.value === this.dateOfPickupTypeEnum.On) {
          this.dateOfPickupTypeOnValidators();
        } else if (currentValue.value === this.dateOfPickupTypeEnum.Between) {
          this.dateOfPickupTypeBetweenValidators();
        }

      }

      this.siteService.addSubscriptionLog(this, 'transfer-item.component.ts->setValidators->this.subscriptions.push(dateOfPickupTypeFormControl.valueChanges');

      this.subscriptions.push(dateOfPickupTypeFormControl.valueChanges.pipe(
        finalize(() => this.siteService.setSubscriptionLogFinalised('transfer-item.component.ts->setValidators->this.subscriptions.push(dateOfPickupTypeFormControl.valueChanges')),
        takeUntil(this.unsubscribe$),
      ).subscribe({
        next: (value) => {
          if (value.value === this.dateOfPickupTypeEnum.On) {
            this.dateOfPickupTypeOnValidators();
          } else if (value.value === this.dateOfPickupTypeEnum.Between) {
            this.dateOfPickupTypeBetweenValidators();
          } else {
            this.arrangement.removeFormValidators([
              `from.dateOfPickup`,
              `from.dateOfPickupFrom`,
              `from.dateOfPickupTo`,
            ]);
          }
        }
      }));

    }

    if (fromSelectContainerFormControl) {
    
      const selectType = fromSelectContainerFormControl.value;

      if (_.has(selectType, 'value')) {

        this.pickupAddressValidators(selectType.value);

        this.siteService.addSubscriptionLog(this, 'transfer-item.component.ts->setValidators->this.subscriptions.push(fromSelectContainerFormControl.valueChanges');

        this.subscriptions.push(fromSelectContainerFormControl.valueChanges.pipe(
          finalize(() => this.siteService.setSubscriptionLogFinalised('transfer-item.component.ts->setValidators->this.subscriptions.push(fromSelectContainerFormControl.valueChanges')),
          takeUntil(this.unsubscribe$),
        ).subscribe({
          next: (value) => {
            this.pickupAddressValidators(_.has(value, 'value') ? value.value : false);
          }
        }));

      }
    
    }

    if (toSelectContainerFormControl) {
    
      const selectType = toSelectContainerFormControl.value;

      if (_.has(selectType, 'value')) {
  
        this.deliveryAddressValidators(selectType.value);
  
        this.siteService.addSubscriptionLog(this, 'transfer-item.component.ts->setValidators->this.subscriptions.push(toSelectContainerFormControl.valueChanges');

        this.subscriptions.push(toSelectContainerFormControl.valueChanges.pipe(
          finalize(() => this.siteService.setSubscriptionLogFinalised('transfer-item.component.ts->setValidators->this.subscriptions.push(toSelectContainerFormControl.valueChanges')),
          takeUntil(this.unsubscribe$),
        ).subscribe({
          next: (value) => {
            this.deliveryAddressValidators(value.value);
          }
        }));

      }
    
    }

  }

  private pickupAddressValidators(value: any): void {

    if (value === false) {

      this.formValidation.addValidators([
        {
          path: `from.address.street`,
          label: 'Transfer Pickup Address Street',
          validators: [Validators.required]
        },
        {
          path: `from.address.suburb`,
          label: 'Transfer Pickup Address Suburb',
          validators: [Validators.required]
        },
        {
          path: `from.address.state`,
          label: 'Transfer Pickup Address State',
          validators: [Validators.required]
        },
        {
          path: `from.address.postcode`,
          label: 'Transfer Pickup Address Postcode',
          validators: [Validators.required]
        },
      ]);

    } else {

      this.arrangement.removeFormValidators([
        `from.address.street`,
        `from.address.suburb`,
        `from.address.state`,
        `from.address.postcode`,
      ]);

    }

  }

  private deliveryAddressValidators(value: any): void {

    if (value === false) {

      this.formValidation.addValidators([
        {
          path: `to.address.street`,
          label: 'Transfer Delivery Address Street',
          validators: [Validators.required]
        },
        {
          path: `to.address.suburb`,
          label: 'Transfer Delivery Address Suburb',
          validators: [Validators.required]
        },
        {
          path: `to.address.state`,
          label: 'Transfer Delivery Address State',
          validators: [Validators.required]
        },
        {
          path: `to.address.postcode`,
          label: 'Transfer Delivery Address Postcode',
          validators: [Validators.required]
        },
      ]);

    } else {

      this.formValidation.removeFormValidators([
        `to.address.street`,
        `to.address.suburb`,
        `to.address.state`,
        `to.address.postcode`,
      ]);

    }

  }

  private dateOfPickupTypeOnValidators(): void {

    this.formValidation.addValidators([
      {
        path: `from.dateOfPickup`,
        label: 'Transfer Pickup Date',
        validators: [Validators.required]
      }
    ]);

    this.formValidation.removeFormValidators([
      `from.dateOfPickupFrom`,
      `from.dateOfPickupTo`,
    ]);

  }

  private dateOfPickupTypeBetweenValidators(): void {

    this.formValidation.addValidators([
      {
        path: `from.dateOfPickupFrom`,
        label: 'Transfer Pickup From Date',
        validators: [Validators.required]
      },
      {
        path: `from.dateOfPickupTo`,
        label: 'Transfer Pickup To Date',
        validators: [Validators.required]
      }
    ]);

    this.formValidation.removeFormValidators([
      `from.dateOfPickup`,
    ]);

  }

  private updateAddressAndProperties(formGroup: FormGroup, newValue: any): void {

    if (formGroup) {

      const addressFormGroup = formGroup.get('address');

      const serviceProviderIdFormControl = formGroup.get('serviceProviderId');
      const serviceIdFormControl = formGroup.get('serviceId');
      const transferIdFormControl = formGroup.get('transferId');
      const eventIdFormControl = formGroup.get('eventId');
      const usePreparationServiceProviderFormControl = formGroup.get('usePreparationServiceProvider');
      const usePlaceOfDeathFormControl = formGroup.get('usePlaceOfDeath');
      const useUsualResidenceFormControl = formGroup.get('useUsualResidence');

      const hasValue = (newValue) ? true : false;

      if (addressFormGroup) {

        const address = (hasValue) ? _.get(newValue, 'address') : {
          search: null,
          isInternational: false,
          street: null,
          suburb: null,
          state: null,
          postcode: null,
          country: null,
          place: null
        };

        addressFormGroup.patchValue(address);

      }

      if (serviceProviderIdFormControl) {

        const serviceProviderId = (hasValue) ? _.get(newValue, 'serviceProviderId') : null;

        serviceProviderIdFormControl.patchValue(serviceProviderId);

      }

      if (serviceIdFormControl) {

        const serviceId = (hasValue) ? _.get(newValue, 'serviceId') : null;

        serviceIdFormControl.patchValue(serviceId);

      }

      if (transferIdFormControl) {

        const transferId = (hasValue) ? _.get(newValue, 'transferId') : null;

        transferIdFormControl.patchValue(transferId);

      }

      if (eventIdFormControl) {

        const eventId = (hasValue) ? _.get(newValue, 'eventId') : null;

        eventIdFormControl.patchValue(eventId);

      }

      if (usePreparationServiceProviderFormControl) {

        const usePreparationServiceProvider = (hasValue) ? _.get(newValue, 'usePreparationServiceProvider') : false;

        usePreparationServiceProviderFormControl.patchValue(usePreparationServiceProvider);

      }

      if (usePlaceOfDeathFormControl) {

        const usePlaceOfDeath = (hasValue) ? _.get(newValue, 'usePlaceOfDeath') : false;

        usePlaceOfDeathFormControl.patchValue(usePlaceOfDeath);

      }

      if (useUsualResidenceFormControl) {

        const useUsualResidence = (hasValue) ? _.get(newValue, 'useUsualResidence') : false;

        useUsualResidenceFormControl.patchValue(useUsualResidence);

      }

    }

  }

  private createTransferObservable(): void {

    console.log('createTransferObservable();');

    const transfersFormArray = this.formRoot.get('transfer.transfers') as FormArray;

    if (transfersFormArray) {

      console.log('transfersFormArray');

      this.subscriptions.push(transfersFormArray.valueChanges.pipe(
        finalize(() => this.siteService.setSubscriptionLogFinalised('transfer-item.component.ts->createTransferObservable->this.subscriptions.push(transfersFormArray.valueChanges')),
        takeUntil(this.unsubscribe$),
      ).subscribe({
        next: (value) => {
          console.log('transfersFormArray value');
          console.log(value);
        }
      }));

    }

  }

  private generateTransferAsOptions(value: any[]): any[] {
    
    const currentTransferId = this.formDisplay.get('_meta.id')?.value;

    const addressToStringPipe = new AddressToStringPipe();

    const options = value.map((transfer, index) => {
      return {
        disabled: false,
        name: (_.get(transfer, 'title', '')) ? transfer.title : `${ordinal(index + 1)} Transfer`,
        secondaryName: '<strong>From:</strong> ' + addressToStringPipe.transform(transfer.from.address) + '<br /><strong>To:</strong> ' + addressToStringPipe.transform(transfer.to.address),
        value: transfer._meta.id,
      };
    });

    if (currentTransferId) {

      const index = options.findIndex(option => option.value === currentTransferId);

      if (index > -1) {

        options[index].disabled = true;
        options[index].name += ' (Current Transfer)';

      }

    }

    return options;

  }

  private generateEventsAsOptions(value: any[]): any[] {

    const addressToStringPipe = new AddressToStringPipe();

    const options = value.map((event, index) => {

      let name = '';
      let secondaryName = '<strong>Venue: </strong> ';
  
      if (_.get(event, 'title', '')) {
  
        name += event.title;

      } else {

        name += `${ordinal(index + 1)} Event`;
  
      }

      if (_.get(event, 'type.name', '')) {

        name += ` (${event.type.name})`;

      }

      if (_.get(event, 'venue.service', null)) {

        const service: Service = event.venue.service;

        if (service) {

          secondaryName += `${service.name} - `;

        }

      }

      secondaryName += addressToStringPipe.transform(event.venue.address);

      return {
        disabled: false,
        name: name,
        secondaryName: secondaryName,
        value: event._meta.id,
      };
      
    });

    return options;

  }

  private generateDisplayForm(sourceFormGroup: FormGroup, structure: FormStructure): FormGroup {

    const targetForm = new FormGroup({});

    const formGenerator = new FormGenerator();

    formGenerator.generate(structure, sourceFormGroup.value, targetForm);

    return targetForm;

  }

  private updatePreparationLinkedTransferId(idsToAdd: LinkedTransferId[], idsToRemove: LinkedTransferId[]): void {

    const preparationLinkedTransferIdFormControl = this.arrangement.form.get('preparation.linkedTransferId') as FormControl;
  
    // Remove any idsToAdd from existing linkedTransferId
    for (const idToAdd of idsToAdd) {
        
      this.removeExistingLinkedTransferIds(idToAdd.id, idToAdd.type, this.arrangement);
      
    }

    let preparationLinkedTransferIdValue: LinkedTransferId[] = preparationLinkedTransferIdFormControl.value;

    // Remove any items that don't have an 'id' or 'type'
    preparationLinkedTransferIdValue = preparationLinkedTransferIdValue.filter(item => item.id && item.type);
  
    // Add idsToAdd to preparationLinkedTransferIdValue
    preparationLinkedTransferIdValue = preparationLinkedTransferIdValue.concat(idsToAdd);
  
    // Remove idsToRemove based on both 'id' and 'type'
    preparationLinkedTransferIdValue = preparationLinkedTransferIdValue.filter(item => !idsToRemove.some(removeId => removeId.id === item.id && removeId.type === item.type));
  
    // Remove duplicates based on both 'id' and 'type'
    preparationLinkedTransferIdValue = _.uniqBy(preparationLinkedTransferIdValue, item => item.id + item.type);
  
    preparationLinkedTransferIdFormControl.patchValue(preparationLinkedTransferIdValue);
  
  }

  private updatePlaceOfPassingLinkedTransferId(idsToAdd: LinkedTransferId[], idsToRemove: LinkedTransferId[]): void {

    const deceasedPlaceOfPassingLinkedTransferIdFormControl = this.arrangement.form.get('deceased.placeOfPassing.linkedTransferId') as FormControl;
  
    let deceasedPlaceOfPassingLinkedTransferIdValue: LinkedTransferId[] = deceasedPlaceOfPassingLinkedTransferIdFormControl.value || [];
  
    // Remove any idsToAdd from existing linkedTransferId
    for (const idToAdd of idsToAdd) {
        
      this.removeExistingLinkedTransferIds(idToAdd.id, idToAdd.type, this.arrangement);
      
    }

    // Remove any items that don't have an 'id' or 'type'
    deceasedPlaceOfPassingLinkedTransferIdValue = deceasedPlaceOfPassingLinkedTransferIdValue.filter(item => item.id && item.type);

    // Add idsToAdd
    deceasedPlaceOfPassingLinkedTransferIdValue = deceasedPlaceOfPassingLinkedTransferIdValue.concat(idsToAdd);
  
    // Remove idsToRemove based on both 'id' and 'type'
    deceasedPlaceOfPassingLinkedTransferIdValue = deceasedPlaceOfPassingLinkedTransferIdValue.filter(item => !idsToRemove.some(removeId => removeId.id === item.id && removeId.type === item.type));
  
    // Remove duplicates based on both 'id' and 'type'
    deceasedPlaceOfPassingLinkedTransferIdValue = _.uniqBy(deceasedPlaceOfPassingLinkedTransferIdValue, item => item.id + item.type);
  
    deceasedPlaceOfPassingLinkedTransferIdFormControl.patchValue(deceasedPlaceOfPassingLinkedTransferIdValue);

  }

  private updateTransferLinkedTransferId(transferId: string, type: string, idsToAdd: LinkedTransferId[], idsToRemove: LinkedTransferId[]): void {

    const transfersFormArray = this.arrangement.form.get('transfer.transfers') as FormArray;

    const transferFormGroup = transfersFormArray.controls.find(
      (control: AbstractControl) => (control as FormGroup).get('_meta.id')?.value === transferId
    ) as FormGroup | undefined;

    if (transferFormGroup) {

      const linkedTransferId = transferFormGroup.get(`${type}.linkedTransferId`) as FormControl;

      // Remove any idsToAdd from existing linkedTransferId
      for (const idToAdd of idsToAdd) {
        
        this.removeExistingLinkedTransferIds(idToAdd.id, idToAdd.type, this.arrangement);

      }

      let linkedTransferIdValue: LinkedTransferId[] = linkedTransferId.value;

      // Remove any items that don't have an 'id' or 'type'
      linkedTransferIdValue = linkedTransferIdValue.filter(item => item.id && item.type);

      // Add idsToAdd to linkedTransferIdValue
      linkedTransferIdValue = linkedTransferIdValue.concat(idsToAdd);
    
      // Remove idsToRemove from linkedTransferIdValue
      linkedTransferIdValue = linkedTransferIdValue.filter(item => !idsToRemove.some(removeId => removeId.id === item.id && removeId.type === item.type));

      // Remove duplicates
      linkedTransferIdValue = _.uniqBy(linkedTransferIdValue, item => item.id + item.type);

      // Update the form control with final value
      linkedTransferId.patchValue(linkedTransferIdValue);

    }

  }

  private updateEventLinkedTransferId(eventId: string, idsToAdd: LinkedTransferId[], idsToRemove: LinkedTransferId[]): void {

    const eventsFormArray = this.arrangement.form.get('events') as FormArray;

    const eventFormGroup = eventsFormArray.controls.find(
      (control: AbstractControl) => (control as FormGroup).get('_meta.id')?.value === eventId
    ) as FormGroup | undefined;

    if (eventFormGroup) {

      const linkedTransferId = eventFormGroup.get('venue.linkedTransferId') as FormControl;

      // Remove any idsToAdd from existing linkedTransferId
      for (const idToAdd of idsToAdd) {
        
        this.removeExistingLinkedTransferIds(idToAdd.id, idToAdd.type, this.arrangement);

      }

      let linkedTransferIdValue: LinkedTransferId[] = linkedTransferId.value;

      // Remove any items that don't have an 'id' or 'type'
      linkedTransferIdValue = linkedTransferIdValue.filter(item => item.id && item.type);

      // Add idsToAdd to linkedTransferIdValue
      linkedTransferIdValue = linkedTransferIdValue.concat(idsToAdd);
   
      // Remove idsToRemove from linkedTransferIdValue
      linkedTransferIdValue = linkedTransferIdValue.filter(item => !idsToRemove.some(removeId => removeId.id === item.id && removeId.type === item.type));

      // Remove duplicates
      linkedTransferIdValue = _.uniqBy(linkedTransferIdValue, item => item.id + item.type);

      // Update the form control with final value
      linkedTransferId.patchValue(linkedTransferIdValue);

    }

  }

  private setTransferFromAddress(addressObj: any): void {

    const addressGroup = this.formDisplay.get('from.address') as FormGroup;

    if(!addressGroup) {

      console.error(`We couldn't find the 'from.address' FormGroup so we can't set the address`);

      return;
    }

    addressGroup.patchValue(addressObj);

  }

  private setFromAddressString(prefix: string, addressObj: any): void {

    const addressToStringPipe = new AddressToStringPipe();
      
    const addressString = `<strong>${prefix}</strong> ${addressToStringPipe.transform(addressObj)}`;

    this.fromAddressStringSource.next(addressString);

  }

  private setTransferToAddress(addressObj: any): void {

    const addressGroup = this.formDisplay.get('to.address') as FormGroup;

    if(!addressGroup) {

      console.error(`We couldn't find the 'to.address' FormGroup so we can't set the address`);

      return;
    }

    addressGroup.patchValue(addressObj);

  }

  private setToAddressString(prefix: string, addressObj: any): void {

    const addressToStringPipe = new AddressToStringPipe();
      
    const addressString = `<strong>${prefix}</strong> ${addressToStringPipe.transform(addressObj)}`;

    this.toAddressStringSource.next(addressString);

  }

  private setInitialAddressStrings(): void {

    const setFromAddressString = () => {

      const addressGroup = this.formDisplay.get('from.address') as FormGroup;
      const addressTypeControl = this.formDisplay.get('from._addressType') as FormControl;

      const addressTypeValue: { name: string, value: TransferFromAddressType } = addressTypeControl.value;

      if (addressTypeValue && addressTypeValue.value === TransferFromAddressType.PlaceOfPassing) {

        this.setFromAddressString('Place of Death: ', addressGroup.value);

      } else if (addressTypeValue && addressTypeValue.value === TransferFromAddressType.ServiceProvider) {

        const addressTypeServiceProviderControl = this.formDisplay.get('from._addressTypeServiceProvider') as FormControl;

        if (addressTypeServiceProviderControl) {

          const service: Service = addressTypeServiceProviderControl.value;

          if (service) {

            this.setFromAddressString(`${service.name}: `, service.address);

          }

        }

      } else if (addressTypeValue && addressTypeValue.value === TransferFromAddressType.ExistingTransfer) {

        const addressTypeExistingTransferControl = this.formDisplay.get('from._addressTypeExistingTransfer') as FormControl;
        const addressTypeExistingTransferAddressTypeControl = this.formDisplay.get('from._addressTypeExistingTransferAddressType') as FormControl;

        if (addressTypeExistingTransferControl && addressTypeExistingTransferAddressTypeControl) {

          let addressPrefix = '';

          const transfer = this.transfersSource.value.find(transfer => transfer._meta.id === addressTypeExistingTransferControl.value);
  
          if (transfer) {
  
            let title = _.get(transfer, 'title');
  
            if (!title) {
              title = 'Existing Transfer';
            }
  
            if (addressTypeExistingTransferAddressTypeControl.value === 'pickup-location') {
  
              addressPrefix = `${title} - Pickup Address:`;
  
            } else if (addressTypeExistingTransferAddressTypeControl.value === 'drop-off-location') {
  
              addressPrefix = `${title} - Drop Off Address:`;
  
            }
  
            this.setFromAddressString(addressPrefix, transfer.from.address);
            
          }

        }

      } else if (addressTypeValue && addressTypeValue.value === TransferFromAddressType.ExistingEvent) {

        const addressTypeExistingEventControl = this.formDisplay.get('from._addressTypeExistingEvent') as FormControl;

        if (addressTypeExistingEventControl) {

          const event = this.eventsSource.value.find(event => event._meta.id === _.get(addressTypeExistingEventControl.value, 'value'));

          if (event) {

            let addressPrefix = '';
  
            let title = _.get(event, 'title');
  
            if (!title) {
              title = 'Existing Event';
            }
  
            addressPrefix = `${title}`;
  
            const venueService = _.get(event, 'venue.service');
  
            if (venueService) {
              addressPrefix += ` - ${venueService.name}`;
            }
  
            addressPrefix += ':';
  
            this.setFromAddressString(addressPrefix, event.venue.address);

          }

        }

      }

    };

    setFromAddressString();

  }

  private removeExistingLinkedTransferIds(id: string, type: LinkedTransferIdType, arrangement: Arrangement): void {

    const removed = removeLinkedTransferIds(id, type, this.arrangement);

    if (removed) {

      if (this.skipOnValueChangedEmit === false) {
        this.onValueChanged.emit(true); // Flag the save and close button
      }

    }

  }

  /**
   * 
   * @param type 'place-of-passing' | 'preparation' | 'service-provider' | 'transfer' | 'event'
   * @param elementId ID of the element
   * @note This is used to set the target for the linked transfer. Passing no arguments will clear the target
   */
  private setTargetFromLinkedTransfer(type?: string, elementId?: string): void {

    this.targetFromLinkedTransfer = {
      transferId: this.formDisplay.get('_meta.id')?.value,
      transferType: LinkedTransferIdType.Pickup,
      targetElement: type as any,
      targetElementId: elementId,
    };

  }

  /**
   * 
   * @param type 'place-of-passing' | 'preparation' | 'service-provider' | 'transfer' | 'event'
   * @param elementId ID of the element
   * @note This is used to set the target for the linked transfer. Passing no arguments will clear the target
   */
  private setTargetToLinkedTransfer(type?: string, elementId?: string): void {

    this.targetToLinkedTransfer = {
      transferId: this.formDisplay.get('_meta.id')?.value,
      transferType: LinkedTransferIdType.DropOff,
      targetElement: type as any,
      targetElementId: elementId,
    };

  }

  private setPickupTransferLocationOptions(): void {

    const transferValue = this.formDisplay.get('from._addressTypeExistingTransfer')?.value;

    if (!transferValue) {
      return;
    }

    if (!transferValue.value) {
      return;
    }

    const currentTransfer = this.transfersSource.value.find(transfer => transfer._meta.id === transferValue.value);

    if (!currentTransfer) {
      return;
    }

    const addressToStringPipe = new AddressToStringPipe();

    let options: any[] = [];

    options.push({
      name: 'Pickup Location',
      secondaryName: addressToStringPipe.transform(currentTransfer.from.address),
      value: 'pickup-location',
    });

    options.push({
      name: 'Drop Off Location',
      secondaryName: addressToStringPipe.transform(currentTransfer.to.address),
      value: 'drop-off-location',
    });

    this.pickupExistingTransferAddressTypeAsOptionsSource.next(options);

  }

  private setDropOffTransferLocationOptions(): void {

    const transferValue = this.formDisplay.get('to._addressTypeExistingTransfer')?.value;

    if (!transferValue) {
      return;
    }

    if (!transferValue.value) {
      return;
    }

    const currentTransfer = this.transfersSource.value.find(transfer => transfer._meta.id === transferValue.value);

    if (!currentTransfer) {
      return;
    }

    const addressToStringPipe = new AddressToStringPipe();

    let options: any[] = [];

    options.push({
      name: 'Pickup Location',
      secondaryName: addressToStringPipe.transform(currentTransfer.from.address),
      value: 'pickup-location',
    });

    options.push({
      name: 'Drop Off Location',
      secondaryName: addressToStringPipe.transform(currentTransfer.to.address),
      value: 'drop-off-location',
    });

    this.dropOffExistingTransferAddressTypeAsOptionsSource.next(options);

  }

  private convertDeprecatedTransfer() {
    
    const fromAddressTypeValue = this.formDisplay.get('from._addressType')?.value;
    const fromAddressValue = this.formDisplay.get('from.address')?.value;

    const toAddressTypeValue = this.formDisplay.get('to._addressType')?.value;
    const toAddressValue = this.formDisplay.get('from.address')?.value;

    if (!fromAddressTypeValue && hasValidAddress(fromAddressValue)) {
      this.formDisplay.get('from._addressType')?.patchValue(transferFromAddressTypesValue.find(type => type.value === TransferFromAddressType.Custom));
      this.formDisplay.get('from.address')?.patchValue(fromAddressValue);
    }
    
    if (!toAddressTypeValue && hasValidAddress(toAddressValue)) {
      this.formDisplay.get('to._addressType')?.patchValue(transferToAddressTypesValue.find(type => type.value === TransferToAddressType.Custom));
      this.formDisplay.get('to.address')?.patchValue(toAddressValue);
    }

  }
  
}
