import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { BdmVicAuthenticateComponent } from './bdm-vic-authenticate.component';



@NgModule({
  declarations: [
    BdmVicAuthenticateComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    InputTextModule,
    PasswordModule,
    DropdownModule,
    ButtonModule,
  ],
  exports: [
    BdmVicAuthenticateComponent
  ]
})
export class BdmVicAuthenticateModule { }
