import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpHeaders, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CardComponent } from './shared/components/card/card.component';
import { DeviceService } from './shared/services/device.service';
import { StorageService } from './shared/services/storage.service';
import { StorageFactory } from './shared/storage-factory';
import { RequestInterceptor } from './shared/request.interceptor';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { ErrorComponent } from './shared/modals/error/error.component';

import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { split, ApolloClientOptions, InMemoryCache } from '@apollo/client/core';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { FormBuilder } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { Base64 } from 'js-base64';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../environments/environment';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { MessageService } from 'primeng/api';
import { ModalService } from './shared/services/modal.service';
import { ErrorModule } from './shared/modals/error/error.module';
import { GenericComponent } from './shared/modals/generic/generic.component';
import { GenericModule } from './shared/modals/generic/generic.module';
import { CompilerModule } from './shared/modals/compiler/compiler.module';
import { CompilerComponent } from './shared/modals/compiler/compiler.component';
import { InputNameValuePairModule } from './shared/modals/input-name-value-pair/input-name-value-pair.module';
import { InputTextAreaModule } from './shared/modals/input-text-area/input-text-area.module';

import { NgxMaskModule } from 'ngx-mask';
import { InputTextAreaComponent } from './shared/modals/input-text-area/input-text-area.component';
import { InputNameValuePairComponent } from './shared/modals/input-name-value-pair/input-name-value-pair.component';
import { AccountContactModule } from './shared/modals/account-contact/account-contact.module';
import { AccountContactComponent } from './shared/modals/account-contact/account-contact.component';
import { AccountContactListModule } from './shared/modals/account-contact-list/account-contact-list.module';
import { AccountContactListComponent } from './shared/modals/account-contact-list/account-contact-list.component';
import { AccountArrangementSourceListModule } from './shared/modals/account-arrangement-source-list/account-arrangement-source-list.module';
import { AccountArrangementSourceListComponent } from './shared/modals/account-arrangement-source-list/account-arrangement-source-list.component';
import { AccountReligionListModule } from './shared/modals/account-religion-list/account-religion-list.module';
import { AccountReligionListComponent } from './shared/modals/account-religion-list/account-religion-list.component';
import { AccountHospitalAndNursingHomeModule } from './shared/modals/account-hospital-and-nursing-home/account-hospital-and-nursing-home.module';
import { AccountHospitalAndNursingHomeListComponent } from './shared/modals/account-hospital-and-nursing-home-list/account-hospital-and-nursing-home-list.component';
import { AccountHospitalAndNursingHomeListModule } from './shared/modals/account-hospital-and-nursing-home-list/account-hospital-and-nursing-home-list.module';
import { AccountHospitalAndNursingHomeComponent } from './shared/modals/account-hospital-and-nursing-home/account-hospital-and-nursing-home.component';
import { InputSignatureModule } from './shared/modals/input-signature/input-signature.module';
import { AccountEventTypeListComponent } from './shared/modals/account-event-type-list/account-event-type-list.component';
import { AccountEventTypeListModule } from './shared/modals/account-event-type-list/account-event-type-list.module';
import { RegisterModule } from './pages/register/register.module';
import { AccountCharityListModule } from './shared/modals/account-charity-list/account-charity-list.module';
import { BdmNswAuthenticateComponent } from './shared/modals/bdm-nsw-authenticate/bdm-nsw-authenticate.component';
import { BdmNswAuthenticateModule } from './shared/modals/bdm-nsw-authenticate/bdm-nsw-authenticate.module';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ImageCropperModalModule } from './shared/modals/image-cropper/image-cropper-modal.module';
import { SavePromptModule } from './shared/modals/save-prompt/save-prompt.module';
import { InputTextInputModule } from './shared/modals/input-text-input/input-text-input.module';
import { AccountFirstCallSourceModule } from './shared/modals/account-firstcall-source/account-firstcall-source.module';
import { AccountFirstCallSourceGroupListModule } from './shared/modals/account-firstcall-sourcegroup-list/account-firstcall-sourcegroup-list.module';
import { CreateTransferModule } from './shared/modals/create-transfer/create-transfer.module';
import { CreateEventModule } from './shared/modals/create-event/create-event.module';
import { CreateTransferComponent } from './shared/modals/create-transfer/create-transfer.component';
import { CreateEventComponent } from './shared/modals/create-event/create-event.component';
import { BdmVicAuthenticateModule } from './shared/modals/bdm-vic-authenticate/bdm-vic-authenticate.module';
import { BdmQldAuthenticateModule } from './shared/modals/bdm-qld-authenticate/bdm-qld-authenticate.module';
import { MortuaryPreparationOptionsModule } from './shared/modals/mortuary-preparation-options/mortuary-preparation-options.module';
import { BdmVicAuthenticateComponent } from './shared/modals/bdm-vic-authenticate/bdm-vic-authenticate.component';
import { BdmQldAuthenticateComponent } from './shared/modals/bdm-qld-authenticate/bdm-qld-authenticate.component';
import { MortuaryPreparationOptionsComponent } from './shared/modals/mortuary-preparation-options/mortuary-preparation-options.component';
import { ShowPdfComponent } from './shared/modals/show-pdf/show-pdf.component';
import { ShowPdfModule } from './shared/modals/show-pdf/show-pdf.module';
import { AccountLocalityModule } from './shared/modals/account-locality/account-locality.module';
import { AccountLocalityGroupListModule } from './shared/modals/account-localitygroup-list/account-localitygroup-list.module';
import { AccountArrangementLocalityListModule } from './shared/modals/account-arrangement-locality-list/account-arrangement-locality-list.module';

const graphqlPrinter = require("graphql/language/printer");

// @ts-ignore
class UUIDOperationIdSubscriptionClient extends SubscriptionClient {

  authFunction;
  originalUrl;
  
  constructor(url: any, args: any, authFunction: any) {
  super(url, args);
  this.authFunction = authFunction;
  this.originalUrl = url;
  }
  
  connect = async () => {
  const authInfo = await this.authFunction();
  const asBase64EncodedJson = (value: any) => btoa(JSON.stringify(value));
  /* @see https://docs.aws.amazon.com/appsync/latest/devguide/real-time-websocket-client.html#iam */
  // @ts-ignore
  this.url = `${this.originalUrl}?header=${asBase64EncodedJson(authInfo)}&payload=${asBase64EncodedJson({})}`;
  
  // @ts-ignore
  super.connect();
  };
  
  generateOperationId() {
    return uuidv4();
  }
  
  processReceivedData(receivedData: any) {
  try {
  const parsedMessage = JSON.parse(receivedData);
  if (parsedMessage?.type === 'start_ack') return;
  } catch (e) {
  throw new Error('Message must be JSON-parsable. Got: ' + receivedData);
  }

  // @ts-ignore
  super.processReceivedData(receivedData);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    CardComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    DynamicDialogModule,
    ErrorModule,
    GenericModule,
    ImageCropperModalModule,
    CompilerModule,
    AccountArrangementSourceListModule,
    AccountArrangementLocalityListModule,
    AccountContactModule,
    AccountContactListModule,
    AccountReligionListModule,
    AccountHospitalAndNursingHomeModule,
    AccountHospitalAndNursingHomeListModule,
    AccountEventTypeListModule,
    AccountCharityListModule,
    InputTextAreaModule,
    InputNameValuePairModule,
    InputSignatureModule,
    InputTextInputModule,
    RegisterModule,
    NgxMaskModule.forRoot(),
    BdmNswAuthenticateModule,
    BdmVicAuthenticateModule,
    BdmQldAuthenticateModule,
    BlockUIModule,
    ProgressSpinnerModule,
    SavePromptModule,
    AccountFirstCallSourceModule,
    AccountFirstCallSourceGroupListModule,
    CreateTransferModule,
    CreateEventModule,
    MortuaryPreparationOptionsModule,
    ShowPdfModule,
    PdfViewerModule,
  ],
  entryComponents: [
    ErrorComponent,
    GenericComponent,
    CompilerComponent,
    AccountArrangementSourceListComponent,
    AccountContactComponent,
    AccountContactListComponent,
    InputTextAreaComponent,
    InputNameValuePairComponent,
    AccountReligionListComponent,
    AccountHospitalAndNursingHomeComponent,
    AccountHospitalAndNursingHomeListComponent,
    AccountEventTypeListComponent,
    BdmNswAuthenticateComponent,
    BdmVicAuthenticateComponent,
    BdmQldAuthenticateComponent,
    AccountFirstCallSourceModule,
    AccountFirstCallSourceGroupListModule,
    CreateTransferComponent,
    CreateEventComponent,
    MortuaryPreparationOptionsComponent,
    ShowPdfComponent,
    AccountLocalityModule,
    AccountLocalityGroupListModule,
  ],
  providers: [
    DialogService,
    MessageService,
    FormBuilder,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: StorageService, useFactory: StorageFactory, deps: [DeviceService], multi: true },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {

        const httpHeader = new HttpHeaders({
          'X-Api-Key': environment.appSync.apiKey
        });

        // Create an http link:
        const http = httpLink.create({
          uri: environment.appSync.graphql.http,
          headers: httpHeader
        });

        const api_header = {
          'host': environment.appSync.graphql.host,
          'x-api-key': environment.appSync.apiKey
        };
        
        const wsHeader = Base64.encode(JSON.stringify(api_header));

        const wsPayload = Base64.encode(JSON.stringify({}));

        const createAppSyncGraphQLOperationAdapter = () => ({
          applyMiddleware: async (options: any, next: any) => {
              // AppSync expects GraphQL operation to be defined as a JSON-encoded object in a "data" property
              options.data = JSON.stringify({
                  query: typeof options.query === 'string' ? options.query : graphqlPrinter.print(options.query),
                  variables: options.variables
              });
        
              // AppSync only permits authorized operations
              options.extensions = {'authorization': api_header};
        
              // AppSync does not care about these properties
              delete options.operationName;
              delete options.variables;
              // Not deleting "query" property as SubscriptionClient validation requires it
        
              next();
          }
        });

        // Create a WebSocket link:
        const ws = new WebSocketLink(
          new UUIDOperationIdSubscriptionClient(
            `${environment.appSync.graphql.wss}`,
            {
              timeout: 5601000,
              reconnect: true
            },
            async () => api_header
            ).use([createAppSyncGraphQLOperationAdapter()])
          // {
          //   uri: `${environment.appSync.graphql.wss}?header=${wsHeader}&payload=${wsPayload}`,
          //   options: {
          //     reconnect: true,
          //   },
          // }
        );

        // using the ability to split links, you can send data to each link
        // depending on what kind of operation is being sent
        const link = split(
          // split based on operation type
          ({query}) => {
            let definition = getMainDefinition(query);

            return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
          },
          ws,
          http,
        );

        return {
          cache: new InMemoryCache(),
          link,
          // ... options
        };

        // return {
        //   cache: new InMemoryCache(),
        //   link: httpLink.create({
        //     uri: environment.appSync.graphqlUrl,
        //     headers: new HttpHeaders({
        //       'X-Api-Key': environment.appSync.apiKey
        //     })
        //   }),
        // };

      },
      deps: [HttpLink],
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
