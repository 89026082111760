import _, { isEmpty, upperFirst } from 'lodash';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, from, Observable, of, PartialObserver, Subscription, combineLatest, zip, Subject } from 'rxjs';
import { easyFormatter } from '../easyFormatter';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment-timezone';
import { SiteService } from '../services/site.service';
import { UserService } from '../services/user.service';
import { Account } from '../models/account';
import { Arrangement as ArrangementModel, ArrangementResponse, ArrangementStatus, ArrangementType, ArrangementUpdateResponse, Contact as ContactModel, RelationToDeceased, Timeline as TimelineModel } from '../models/arrangement';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { User } from './user';
import { AccountService } from '../services/account.service';
import { debounceTime, distinctUntilChanged, map, startWith, first, filter, switchMap, tap, finalize, takeUntil } from 'rxjs/operators';
import { Contact } from './contact';
import { arraysToObjects } from '../array-fix/functions/array-to-object';
import { FinancialStructure, FormAddressStructure, FormArrangementMeetingStructure, FormCentrelinkStructure, FormContactStructure, FormDepartmentOfVeteranAffairsStructure, FormItemStructure, FormEventStructure, FormFamilyStructure, FormMarriageStructure, FormNameStructure, FormNewspaperNotificationStructure, FormOccupationStructure, FormPensionerStructure, FormSignatureStructure, FormStructure, FormStructureType, FormStructureValueType, FormTransfersStructure, FormTransferStructure, ProductLineItemStructure, FormCremationAshesRecipientStructure, FormDocumentLibraryStructure, FormJewelleryInstructionStructure, FormClothingInstructionStructure, MetaStructure, NoteStructure, TaskStructure, FormBirthsDeathsAndMarriagesNswStructure, FormBirthsDeathsAndMarriagesVicStructure, FormBirthsDeathsAndMarriagesQldStructure } from '../forms/form-structure';
import { v4 as uuidv4, v4 } from 'uuid';
import { objectsToArrays } from '../array-fix/functions/object-to-array';
import * as dot from 'dot-object';
import { FormGenerator } from '../forms/form-generator';
import { Service } from './service';
import { ServiceProviderService } from '../services/service-provider.service';
import { clothingInstructionValue, CODCertificationTypeValue, dateOfPickupTypeValue, eventIndexesValue, eventServiceTypesFormValue, hospitalOrNursingHomeValue, isCoronersCaseValue, isStillBornBabyValue, jewelleryInstructionValue, methodOfDisposalTypeValue, nswBirthDeathsAndMarriagesCertificateTypeValue, nswBirthDeathsAndMarriagesDeliveryTypeValue, nswBirthDeathsAndMarriagesMarriageStatusTypeValue, nswBirthDeathsAndMarriagesMarriageTypeValue, nswBirthDeathsAndMarriagesParentRelationshipTypeValue, placeOfPassingTypeValue, relationshipTypesFormValue, serviceFeeIncludedValue, serviceTypesFormValue, taskStatusValue, taskTypeValue, secondNoteTransferTypesValue, transferTypesValue, transferFromAddressTypesValue } from '../forms/form-values';
import { BirthDeathsAndMarriagesApiTarget, dateOfPassingType, methodOfDisposalType, nswBirthDeathsAndMarriagesCertificateTypeType, nswBirthDeathsAndMarriagesDeliveryTypeType, secondNoteTransferType, PlaceOfPassingType, taskStatusType, taskType, TransferFromAddressType, TransferType, TransferToAddressType } from '../forms/form-enum';
import { ConvertToDateString } from '../dates';
import { DynamicComponents } from '../dynamic/services/dynamic.service';
import { ServiceProvider } from './service-provider';
import { ArrangementCosts } from '../forms/arrangement/cost-summary/cost-summary.component';
import { ModalService } from '../services/modal.service';
import { FormValidation, Validation, ValidationWithValidators } from '../forms/form-validation-alert';
import { ArrangementTasks } from 'src/app/shared/classes/tasks';
import ordinal from 'ordinal';
import { changeDetection } from '../change-detection';
import { AddressToStringPipe } from '../address-to-string.pipe';
import { hasValidAddress } from '../has-valid-address';
import { LinkedTransferValidationResponse, validateLinkedTransfers } from '../validate-linked-transfers';
import { placeAndStreetAreDifferent } from '../place-and-street-are-different';
import { ClientTypeNamePipe } from '../client-type-name.pipe';

export enum PDFType {
  MortuaryPreparation = 'mortuary-preparation',
}

export enum TransferAddressType {
  From = 'from',
  To = 'to',
}

export interface ArrangementFormValidator {
  path: string;
  validators: ValidatorFn[];
}

export interface FormControlSubscription {
  id: string;
  paths: string[];
  subscription: Subscription;
}

export interface PendingFormControlSubscription {
  id: string;
  paths: string[];
  observer: PartialObserver<any>;
}

export interface SaveArrangementOptions {
  showResponseModal?: {
    tabIndex: number;
  },
  showValidationModal?: boolean;
}

export interface InvoiceItem {
  invalid?: boolean;
  invalidValue?: string;
  formControlId?: string;
  path?: string;
  xeroTaskId?: string; 
  invoiceLabel?: string | undefined;
  title: string;
  description: string;
  price: number;
  quantity: number;
  cost: number;
  group: {
    order: number;
    title: string; 
    description: string; 
  };
}

export interface GenerateSimpleDataPdfPayload {
  pdfTitle: string
  rows: SimpleDataPdfRow[]
}

export interface SimpleDataPdfRow {
  title: string
  // either value or children must have value
  value?: string | null
  children?: SimpleDataPdfRow[]
}

export interface XeroResponse {
  [key: string]: any;
}

export const ArrangementFirstCallSource = [];

export interface NotesAndTasksBadges {
  tasks: { [key: string]: number };
  notes: { [key: string]: number };
}

export interface TasksCounts {
  nearingOverDue: number;
  overDue: number;
  completed: number;
  total: number;
  byStep: {[key: string]: {
    nearingOverDue: number;
    overDue: number;
    completed: number;
    total: number;
  }}
}

export interface TransferTransferOption {
  name: string;
  value: {
    id: string;
    type: TransferAddressType;
  };
}

export interface TransferEventOption {
  name: string;
  value: string;
}

export class Arrangement {

  subscriptions: Subscription[] = [];
  externalSaveDetected: boolean = false;
  logOnly: boolean = false;

  isPartial: boolean;

  // Properties are real time synced by AppSync
  isRecentlyCreated: boolean = false;
  isRecentlyUpdated: boolean = false;

  id: number | null;
  createdAt: Date | null;
  userId: number | null;

  user$!: Observable<User>;
  account$: Observable<Account> = this.accountService.account$;

  contacts: Contact[] = [];
  timeline: TimelineModel[] = [];

  form!: FormGroup;
  formValidation!: FormValidation;
  rawResponseData!: any;
  obj!: any;

  isPreNeed: boolean;

  arrangementReady$: Observable<boolean>;
  contacts$: Observable<Contact[]>;
  relevantServices$: Observable<Service[]>;
  addresses$: Observable<any[]>;
  noteAndTaskBadges$: Observable<any>;
  taskCounts$: Observable<TasksCounts>;
  arrangementSaved$: Observable<boolean>;
  arrangementRetrieved$: Observable<boolean>;
  arrangementLocked$: Observable<boolean>;
  arrangementLockedUser$: Observable<User | null>;
  transferTransferOptions$: Observable<TransferTransferOption[]>;
  transferEventOptions$: Observable<TransferEventOption[]>;
  validateLinkedTransfers$: Observable<LinkedTransferValidationResponse[]>;
  hasAccess$: Observable<boolean>;

  snapShot: any;

  snapShotTime!: number;
  lastFormValueChangeTime!: number;
  hasBeenTouched!: boolean;

  public contactsSource: BehaviorSubject<Contact[]>
  public relevantServicesSource: BehaviorSubject<Service[]>

  private unsubscribe$ = new Subject<void>();
  
  private arrangementReadySource: BehaviorSubject<boolean>;
  private addressesSource: BehaviorSubject<any[]>;
  private noteAndTaskBadgesSource: BehaviorSubject<any>;
  private taskCountsSource: BehaviorSubject<TasksCounts>;
  private arrangementSavedSource: BehaviorSubject<boolean>;
  private arrangementRetrievedSource: BehaviorSubject<boolean>;
  public arrangementLockedSource: BehaviorSubject<boolean>;
  public arrangementLockedUserSource: BehaviorSubject<User | null>;
  public transferTransfersSource: BehaviorSubject<TransferTransferOption[]>;
  public transferEventsSource: BehaviorSubject<TransferEventOption[]>;
  private validateLinkedTransfersSource: BehaviorSubject<LinkedTransferValidationResponse[]>;
  private hasAccessSource: BehaviorSubject<boolean>

  private validatorPaths: string[];
  private persistentFormControlSubscriptions: FormControlSubscription[];
  private pendingFormControlSubscriptions: PendingFormControlSubscription[];
  private formControlSubscriptions: FormControlSubscription[];

  constructor(
    private http: HttpClient,
    private siteService: SiteService,
    private userService: UserService,
    private accountService: AccountService,
    private serviceProviderService: ServiceProviderService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    data?: any,
  ) {

    this.id = null;
    this.createdAt = null;
    this.userId = null;
    this.isPartial = false;
    this.isPreNeed = false;

    this.validatorPaths = [];
    this.persistentFormControlSubscriptions = [];
    this.formControlSubscriptions = [];
    this.pendingFormControlSubscriptions = [];

    this.arrangementReadySource = new BehaviorSubject<boolean>(false);
    this.arrangementReady$ = this.arrangementReadySource.asObservable();

    this.contactsSource = new BehaviorSubject<any[]>([]);
    this.contacts$ = this.contactsSource.asObservable();

    this.relevantServicesSource = new BehaviorSubject<Service[]>([]);
    this.relevantServices$ = this.relevantServicesSource.asObservable();

    this.addressesSource = new BehaviorSubject<any[]>([]);
    this.addresses$ = this.addressesSource.asObservable();

    this.noteAndTaskBadgesSource = new BehaviorSubject<any>({});
    this.noteAndTaskBadges$ = this.noteAndTaskBadgesSource.asObservable();

    this.taskCountsSource = new BehaviorSubject<TasksCounts>({ nearingOverDue: 0, overDue: 0, completed: 0, total: 0, byStep: {}});
    this.taskCounts$ = this.taskCountsSource.asObservable();

    this.arrangementSavedSource = new BehaviorSubject<boolean>(false);
    this.arrangementSaved$ = this.arrangementSavedSource.asObservable();

    this.arrangementRetrievedSource = new BehaviorSubject<boolean>(false);
    this.arrangementRetrieved$ = this.arrangementRetrievedSource.asObservable();

    this.arrangementLockedSource = new BehaviorSubject<boolean>(false);
    this.arrangementLocked$ = this.arrangementLockedSource.asObservable();

    this.arrangementLockedUserSource = new BehaviorSubject<User | null>(null);
    this.arrangementLockedUser$ = this.arrangementLockedUserSource.asObservable();

    this.transferTransfersSource = new BehaviorSubject<TransferTransferOption[]>([]);
    this.transferTransferOptions$ = this.transferTransfersSource.asObservable();

    this.transferEventsSource = new BehaviorSubject<TransferEventOption[]>([]);
    this.transferEventOptions$ = this.transferEventsSource.asObservable();

    this.validateLinkedTransfersSource = new BehaviorSubject<LinkedTransferValidationResponse[]>([]);
    this.validateLinkedTransfers$ = this.validateLinkedTransfersSource.asObservable();
    this.hasAccessSource = new BehaviorSubject<boolean>(false);
    this.hasAccess$ = this.hasAccessSource.asObservable();

    if (data) {

      this.id = data.id;
      this.createdAt = moment(data.createdAt).toDate();
      
      const user = this.userService.getById(data.userId);

      if (user) {

        this.user$ = of(user);
        this.userId = user.data.id;
        
      }

      this.obj = objectsToArrays(data.data);

      // No need to unsubscribe as this uses pipe(first())
      this.siteService.addSubscriptionLog(this, 'arrangement.ts->constructor->this.postDataAsFormData');

      this.postDataAsFormData(this.obj as ArrangementModel).pipe(
        finalize(() => this.siteService.setSubscriptionLogFinalised('arrangement.ts->constructor->this.postDataAsFormData')),
        takeUntil(this.unsubscribe$),
        first()
      ).subscribe({
        next: formData => {

          this.initialiseListingForm(formData);

          this.processAndUpdateNoteAndTaskBadges(formData);

          this.processAndUpdateAddresses(formData);

          // Process access
          const currentUser = this.userService.userSourceValue;
          const accessUsers = this.form.get('accessUsers')?.value;

          const isOwner = currentUser.data.id === this.userId;
          const isPrivateModeOn = Array.isArray(accessUsers);
          const isHavingAccess = (accessUsers || []).find((user: any) => user.value === currentUser.data.id)

          const hasAccess = isOwner || !isPrivateModeOn || (isPrivateModeOn && isHavingAccess);

          this.hasAccessSource.next(hasAccess);

          // Process locked
          const locked = this.form.get('locked')?.value;

          if (_.get(locked, 'status') === true) {

            const lockedUserId = _.get(locked, 'userId');

            this.arrangementLockedSource.next(true);

            if (lockedUserId) {

              const lockedUser = this.userService.allUsersSourceValue.find(user => user.data.id === lockedUserId);

              if (lockedUser) {

                this.arrangementLockedUserSource.next(lockedUser);

              }

            }

            // Unlock if it's the current user that has locked it
            if (lockedUserId === currentUser.data.id) {

              this.unlock(true).subscribe({
                next: () => {
                  // console.warn('Arrangement Constructor: Arrangement unlocked because it was locked by the current user')
                }
              });

            }

            changeDetection(() => {
              this.arrangementReadySource.next(true);
            });

          } else {

            changeDetection(() => {
              this.arrangementReadySource.next(true);
            });

          }

        }
      });
      
    } else {

      this.initialiseCreateForm();

      this.arrangementReadySource.next(true);

    }

  }

  isLockedByCurrentUser(): boolean {
    return this.arrangementLockedSource.value && this.arrangementLockedUserSource.value?.data.id === this.userService.userSourceValue.data.id;
  }

  public logValidationErrors(): void {

    const getFormValidationErrors = (form: FormGroup, parentKey: string = '') => {

      const result: any = [];

      Object.keys(form.controls).forEach(key => {

        const formProperty = form.get(key);

        if (formProperty) {

          if (formProperty instanceof FormGroup) {
  
            result.push(...getFormValidationErrors(formProperty, (parentKey) ? parentKey + '.' + key : key));
  
          } else {
  
            const controlErrors: ValidationErrors | null = formProperty.errors;
    
            if (controlErrors) {
    
              Object.keys(controlErrors).forEach(keyError => {
                result.push({
                  'path': parentKey + '.' + key,
                  'control': key,
                  'error': keyError,
                  'value': controlErrors[keyError]
                });
              });
    
            }
  
          }

        }

      });
    
      return result;

    }

    // console.log(getFormValidationErrors(this.form));

  }

  /**
   * Add form validators
   */
  public addFormValidators(validators: ValidationWithValidators[], clearExistingValidators: boolean = true): void {

    if (clearExistingValidators) {

      this.formValidation.clearExistingValidators();

    }

    this.formValidation.addValidators(validators);

    this.updateLabelForExistingValidators();

  }

  /**
   * Update label for existing validators
   */
  public updateLabelForExistingValidators(): void {

    const clientTypeNamePipe = new ClientTypeNamePipe();

    this.formValidation.validations = this.formValidation.validations.map(validation => {
      
      validation.label = clientTypeNamePipe.transform(validation.label, this);

      return validation;

    });

  }
  
  /**
   * Remove form validators
   */
  public removeFormValidators(paths: string[]): void {

    this.formValidation.removeFormValidators(paths);

  }

  /**
   * Clear any existing form validators
   */
  public clearExistingValidators(): void {

    this.formValidation.clearExistingValidators();

  }

  /**
   * Get existing form validators
   */
  public getExistingValidators(): Validation[] {
    
    return this.formValidation.validations;

  }

  public refreshExistingValidatorsCss(): void {
    
    return this.formValidation.refreshCssClasses();

  }

  public processMissingValidators(): void {

    return this.formValidation.processMissingValidators();

  }

  public delete(): Observable<boolean> {

    const url = easyFormatter(environment.api.host + environment.api.paths.api.arrangement.delete, { id: (this.id as number).toString() });

    if (!this.id) {

      // No need to unsubscribe as this uses subscribe.complete()
      return new Observable<any>((subscribe) => {
        subscribe.next(false);
        subscribe.complete();
      });

    }

    if (this.logOnly) {
  
      // No need to unsubscribe as this uses subscribe.complete()
      return new Observable<any>((subscribe) => {
        subscribe.next(true);
        subscribe.complete();
      });

    } else {

      this.siteService.addSubscriptionLog(this, 'arrangement.ts->delete->this.http.delete');

      return this.http.delete<ArrangementUpdateResponse>(url).pipe(
        finalize(() => this.siteService.setSubscriptionLogFinalised('arrangement.ts->delete->this.http.delete')),
        takeUntil(this.unsubscribe$),
        map(res => {
          return true;
        })
      );

    }

  }

  public reset(): void {

    this.form.get('isSubmitted')?.patchValue(false);
    this.clearExistingValidators();
    this.removeFormControlSubscriptions();
    this.hasBeenTouched = false;

    if (Array.isArray(this.subscriptions)) {

      this.subscriptions.forEach(subscription => {
        if (subscription) {
          subscription.unsubscribe();
        }
      });

    }

    this.unsubscribe$.next();
    this.unsubscribe$.complete();

  }

  /**
   * Save our arrangement data
   * 
   * Note: If no existing arrangement ID is set, `this.create()` is used. If we have an existing arrangement ID, we use `this.update()`
   */
  public save(options?: SaveArrangementOptions, logOnly?: boolean): Observable<boolean | ArrangementUpdateResponse> {

    this.logOnly = logOnly === true;

    this.externalSaveDetected = false;
    
    const currentUser = this.userService.userSourceValue;
    const lockedUser = this.arrangementLockedUserSource.value;

    if (this.arrangementLockedSource.value && currentUser.data.id !== lockedUser?.data.id) {

      this.modalService.generic({
        title: 'Arrangement Locked',
        copy: ['This arrangement is currently locked by another user. You can not save this arrangement until it is unlocked.'],
        buttons: [{ label: 'OK', key: 'ok', class: '' }],
      });

      return of(false);

    }

    if (this.id) {

      return this.update(options);

    } else {

      return this.create(options);

    }

  }

  /**
   * Get the whole arrangement data object (rather than just the few basic properties needed for the listing page) from the DB
   */
  public retrieve(doLock: boolean = false): Observable<boolean> {

    let url = easyFormatter(environment.api.host + environment.api.paths.api.arrangement.getById, { arrangementId: (this.id as number).toString() });
    
    if (doLock) {
      
      url = easyFormatter(environment.api.host + environment.api.paths.api.arrangement.lockById, { arrangementId: (this.id as number).toString() });

    }

    this.siteService.addSubscriptionLog(this, 'arrangement.ts->retrieve->this.http.get');

    return this.http.get<ArrangementResponse>(url).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('arrangement.ts->retrieve->this.http.get')),
      takeUntil(this.unsubscribe$),
      map(res => {
        
        // This is the raw DB response
        const arrangementObj = res.data;

        // Store the ID
        this.id = arrangementObj.id;

        this.externalSaveDetected = false;

        return arrangementObj;

      }),
      switchMap((arrangementObj: { [key: string]: any }) => {

        // This converts the DB response into a valid arrangement
        return this.processRemoteResponse(arrangementObj.data);

      }),
      switchMap(processedResponse => {
      
        this.arrangementRetrievedSource.next(true);

        this.takeSnapShotTime();

        // For easier debug
        (window as any).currentArrangement = this;

        return of(processedResponse);
        
      })
    );

  }

  public lock(): Observable<boolean | null> {

    if (this.arrangementLockedSource.value) {

      return this.retrieve(false);

    }

    this.siteService.addSubscriptionLog(this, 'arrangement.ts->lock->this.retrieve');

    return this.retrieve(true).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('arrangement.ts->lock->this.retrieve')),
      takeUntil(this.unsubscribe$),
      map(res => {

        this.arrangementLockedSource.next(true);
        this.arrangementLockedUserSource.next(this.userService.userSourceValue);

        return res;

      })
    );
    
  }

  public unlock(forceUnlock: boolean = false): Observable<boolean | null> {

    const isLocked = this.arrangementLockedSource.value;
    const lockedUser = this.arrangementLockedUserSource.value;
    const currentUser = this.userService.userSourceValue;

    if (!isLocked) {
      return of(null);
    }

    if (forceUnlock === false && lockedUser && lockedUser.data.id !== currentUser.data.id) {
      return of(null);
    }

    let url = easyFormatter(environment.api.host + environment.api.paths.api.arrangement.unlockById, { arrangementId: (this.id as number).toString() });
    
    const uId = this.form.get('locked.uId')?.value;

    this.siteService.addSubscriptionLog(this, 'arrangement.ts->unlock->this.http.post');

    return this.http.post<boolean>(url, { uId: uId, lockedUserId: lockedUser?.data.id }).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('arrangement.ts->unlock->this.http.post')),
      takeUntil(this.unsubscribe$),
      map(res => {

        this.arrangementLockedSource.next(false);
        this.arrangementLockedUserSource.next(null);

        return res;

      })
    );
    
  }

  /**
   * Add a new formControlSubscription
   */
  public addFormControlSubscription(paths: string[], observer: PartialObserver<any>): string {

    const id = v4();

    this.pendingFormControlSubscriptions.push({ id, paths, observer });

    return id;

  }

  /**
   * Remove a specific formControlSubscription
   */
  public removeFormControlSubscription(path: string) {

    // // Find out specific subscription
    // const subscriptionIndex = _.findIndex(this.formControlSubscriptions, formControlSubscription => formControlSubscription.path === path);

    // // If not found, return false
    // if (subscriptionIndex === -1) {
      
    //   return false;

    // }

    // const subscription = this.formControlSubscriptions[subscriptionIndex].subscription;

    // // If no subscription found (unlikely), return false 
    // if (!subscription) {

    //   return false;

    // }

    // // Unsubscribe
    // subscription.unsubscribe();

    // // Now, remove the subscription from the array to reduce memory footprint
    // this.formControlSubscriptions.splice(subscriptionIndex, 1);

    // // Everything is done, return true
    // return true;

  }

  /**
   * Remove all formControlSubscriptions
   */
  public removeFormControlSubscriptions(): void {

    // Clear any pending subscriptions first
    this.pendingFormControlSubscriptions = [];

    // Unsubscribe from all the `this.formControlSubscription` subscriptions
    this.formControlSubscriptions.forEach(formControlSubscription => {
      if (formControlSubscription.subscription) {
        formControlSubscription.subscription.unsubscribe();
      }
    });

    // Overwrite the old array to regain memory
    this.formControlSubscriptions = [];

  }

  public lodgeDRS(username: string | null, password: string | null): Observable<any> {

    return this.bdmNswLodge(username, password);

  }

  /**
   * This is used to convert the arrangement into a format that can be used to save as a PDF
   * Note: It's still a work in progress. We use it for the Mortuary Preparation PDF
   */
  public arrangementToCompleteObject(): Observable<any> {

    let url = easyFormatter(environment.api.host + environment.api.paths.api.arrangement.getById, { arrangementId: (this.id as number).toString() });

    return this.http.get<ArrangementResponse>(url).pipe(
      takeUntil(this.unsubscribe$),
      map(res => {
        
        const arrangementObj = objectsToArrays(res.data.data);

        return arrangementObj;

      }),
      switchMap((arrangementObj: { [key: string]: any }) => {

        return this.postDataAsFormData(arrangementObj as ArrangementModel);
        
      }),
      switchMap((arrangementObj: { [key: string]: any }) => {

        const serviceProviders = this.serviceProviderService.allServiceProvidersSourceValue;
        const events = _.get(arrangementObj, 'events', []);
        const transfers = _.get(arrangementObj, 'transfer.transfers', []);

        const getPlaceOfPassingAddress = (arrangement: any): any => {
          
          const address = _.get(arrangementObj, 'deceased.placeOfPassing.address');

          return address;

        };

        const getServiceProviderAddress = (addressTypeServiceProvider: any): any => {

          const serviceProviderId = _.get(addressTypeServiceProvider, 'serviceProviderId');
          const serviceType = _.get(addressTypeServiceProvider, 'type.value');

          if (serviceProviderId && serviceType) {

            const serviceProvider = serviceProviders.find((serviceProvider: ServiceProvider) => serviceProvider.id === serviceProviderId);

            if (serviceProvider) {

              const service: Service = serviceProvider.form.value.services.find((service: Service) => service.type === serviceType);

              return service.address;

            }

          }

          return {};

        };

        const getTransferAddress = (addressTypeExistingTransfer: any, addressTypeExistingTransferAddressType: any): any => {

          const transferId = _.get(addressTypeExistingTransfer, 'value');
          const addressType = _.get(addressTypeExistingTransferAddressType, 'value');

          if (transferId) {

            const transfer = transfers.find((transfer: any) => transfer._meta.id === transferId);

            if (transfer) {

              if (addressType === 'pickup-location') {

                return transfer.from.address;

              } else if (addressType === 'drop-off-location') {

                return transfer.to.address;

              }

            }

          }

          return {};

        };

        const getEventAddress = (addressTypeExistingEvent: any): any => {

          const eventId = _.get(addressTypeExistingEvent, 'value');

          if (eventId) {

            const event = events.find((event: any) => event._meta.id === eventId);

            if (event) {

              return event.venue.address;

            }

          }

          return {};

        };

        const populateTransferLinkedAddress = (transfers: any[]) => {

          const setFromAddress = (from: any): any => {

            const addressType = _.get(from, '_addressType.value');
            const addressTypeServiceProvider = _.get(from, '_addressTypeServiceProvider');
            const addressTypeExistingEvent = _.get(from, '_addressTypeExistingEvent');
            const addressTypeExistingTransfer = _.get(from, '_addressTypeExistingTransfer');
            const addressTypeExistingTransferAddressType = _.get(from, '_addressTypeExistingTransferAddressType');

            if (addressType === TransferFromAddressType.PlaceOfPassing) {

              const address = getPlaceOfPassingAddress(arrangementObj);

              _.set(from, 'address', address);
              
            } else if (addressType === TransferFromAddressType.ServiceProvider) {
              
              const address = getServiceProviderAddress(addressTypeServiceProvider);

              _.set(from, 'address', address);
              
            } else if (addressType === TransferFromAddressType.ExistingTransfer) {
              
              const address = getTransferAddress(addressTypeExistingTransfer, addressTypeExistingTransferAddressType);

              _.set(from, 'address', address);

            } else if (addressType === TransferFromAddressType.ExistingEvent) {

              const address = getEventAddress(addressTypeExistingEvent);

              _.set(from, 'address', address);

            }

            return from;

          };

          const setToAddress = (to: any): any => {

            const addressType = _.get(to, '_addressType.value');
            const addressTypeExistingTransfer = _.get(to, '_addressTypeExistingTransfer');
            const addressTypeExistingEvent = _.get(to, '_addressTypeExistingEvent');
            const addressTypeExistingTransferAddressType = _.get(to, '_addressTypeExistingTransferAddressType');

            if (addressType === TransferToAddressType.ServiceProvider) {

              const address = getPlaceOfPassingAddress(arrangementObj);

              _.set(from, 'address', address);

            } else if (addressType === TransferToAddressType.ExistingTransfer) {

              const address = getTransferAddress(addressTypeExistingTransfer, addressTypeExistingTransferAddressType);

              _.set(from, 'address', address);

            } else if (addressType === TransferToAddressType.ExistingEvent) {

              const address = getEventAddress(addressTypeExistingEvent);

              _.set(from, 'address', address);

            }

            return to;

          };

          transfers = transfers.map((transfer: any) => {

            transfer.from = setFromAddress(transfer.from);
            transfer.to = setToAddress(transfer.to);

            return transfer;

          });

          return transfers;

        };

        const processObject = (obj: any) => {
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {

              let value = obj[key];

              if (value instanceof User) {
                obj[key] = value.data;
              } else if (value instanceof Contact) {
                obj[key] = value.form.value;
              } else if (value instanceof ServiceProvider) {
                obj[key] = value.form.value;
              } else if (value instanceof Service) {

                obj[key] = {
                  id: value._meta?.id,
                  name: value.name,
                  type: value.type,
                  serviceProviderId: value.serviceProviderId,
                  serviceProviderName: value.serviceProviderName,
                  address: value.address,
                  contact: value.contact,
                };
                
              }
        
              // Process the replaced value as well
              if (typeof obj[key] === 'object' && obj[key] !== null) {
                processObject(obj[key]);
              }

            }
          }
          return obj;
        };

        arrangementObj = processObject(arrangementObj);

        // transfers don't always have a populated 'from' or 'to' address. This is because the address is linked to another input (preparation, event, etc)
        // so we need to manually populate the address here...
        const updatedTransfers = populateTransferLinkedAddress(_.get(arrangementObj, 'transfer.transfers'));

        _.set(arrangementObj, 'transfer.transfers', updatedTransfers);

        return of(arrangementObj);
        
      })
    );

  }

  public getArrangementInvoice(): Observable<InvoiceItem[]> {

    return this.processArrangementInvoice();

  }

  public getArrangementDraftBDM(): Observable<GenerateSimpleDataPdfPayload> {
    const arrangementData = this.form.value;
    const clientTypeNamePipe = new ClientTypeNamePipe();

    const deceased = _.get(arrangementData, "deceased");
    const deceasedAgeAtPassingNumber = _.get(arrangementData, "deceased.ageAtPassing");
    const deceasedAgeAtPassingUnit = _.get(arrangementData, "deceased.ageAtPassingUnit");
    const deceasedOccupationTitle = _.get(arrangementData, "deceased.occupation.title");
    const deceasedOccupationActivity = _.get(arrangementData, "deceased.occupation.tasksPerformed");
    const deceasedAboriginalOrTorresStraightIslander = _.get(arrangementData, "deceased.aboriginalOrTorresStraightIslander.name");
    const deceasedRetiredAtPassing = _.get(arrangementData, "deceased.retiredAtPassing.name");

    const parents = _.get(arrangementData, "family.parents");
    const children = _.get(arrangementData, "family.children");
    const marriageStatusAtPassing = _.get(arrangementData, "marriage.statusAtPassing.value");
    const marriages = _.get(arrangementData, "marriage.marriages", []);

    const pensionerAtPassing = _.get(arrangementData, "pensioner.pensionerAtPassing.name");
    const pensionTypeAtPassing = _.get(arrangementData, "pensioner.type.name");

    const dateOfPassingTypeValue = _.get(arrangementData, "deceased.dateOfPassingType.value");
    const dateOfPassingOn = _.get(arrangementData, "deceased.dateOfPassing");
    const dateOfPassingFrom = _.get(arrangementData, "deceased.dateOfPassingFrom");
    const dateOfPassingTo = _.get(arrangementData, "deceased.dateOfPassingTo");
    const timeOfPassing = _.get(arrangementData, "deceased.timeOfPassing");
    const dateOfBirth = _.get(arrangementData, "deceased.dateOfBirth");

    const deceasedPlaceOfPassingType = _.get(arrangementData, "deceased.placeOfPassing.type.value");
    const deceasedOriginalPlaceOfPassingPlace = _.get(arrangementData, "deceased.placeOfPassing.address.place");
    const deceasedPlaceOfPassingStreet = _.get(arrangementData, "deceased.placeOfPassing.address.street");
    const deceasedPlaceOfPassingSuburb = _.get(arrangementData, "deceased.placeOfPassing.address.suburb");
    const deceasedPlaceOfPassingState = _.get(arrangementData, "deceased.placeOfPassing.address.state.value");
    const deceasedPlaceOfPassingPostcode = _.get(arrangementData, "deceased.placeOfPassing.address.postcode");
    const deceasedPlaceOfPassingCountry = _.get(arrangementData, "deceased.placeOfPassing.address.country");

    const causeOfDeath = _.get(arrangementData, "causeOfDeath.type.name");
    const disposalType = _.get(arrangementData, "disposal.type.name");
    const disposalDate = _.get(arrangementData, "disposal.date") ? moment(_.get(arrangementData, "disposal.date")).format("DD/MM/YYYY") : null;

    let deceasedPlaceOfPassingPlace = "";

    if (deceasedPlaceOfPassingType && deceasedPlaceOfPassingType !== PlaceOfPassingType.UsualResidence && deceasedPlaceOfPassingType !== PlaceOfPassingType.None) {
      const hospitalOrNursingHomeName = _.get(arrangementData, "deceased.placeOfPassing.hospitalOrNursingHome.value.name");

      if (hospitalOrNursingHomeName) {
        deceasedPlaceOfPassingPlace = hospitalOrNursingHomeName;
      }
    } else {

      deceasedPlaceOfPassingPlace = placeAndStreetAreDifferent(deceasedOriginalPlaceOfPassingPlace, deceasedPlaceOfPassingStreet) ? deceasedOriginalPlaceOfPassingPlace : ""

    }

    const deceasedAgeAtPassing = deceasedAgeAtPassingNumber && deceasedAgeAtPassingUnit ? `${deceasedAgeAtPassingNumber} ${deceasedAgeAtPassingUnit}` : null;
    const deceasedDateOfPassing =
      dateOfPassingTypeValue === dateOfPassingType.Between
        ? (dateOfPassingFrom ? moment(dateOfPassingFrom).format("DD/MM/YYYY") : 'N/A') + " - " + (dateOfPassingTo ? moment(dateOfPassingTo).format("DD/MM/YYYY") : 'N/A')
        : moment(dateOfPassingOn).format("DD/MM/YYYY");
    const deceasedDateOfBirth = dateOfBirth ? moment(dateOfBirth).format("DD/MM/YYYY") : null;
    const deceasedTimeOfPassing = timeOfPassing ? moment(timeOfPassing, "HH:mm").format("hh:mm A") : "";
    const deceasedPlaceOfPassing = [deceasedPlaceOfPassingPlace, deceasedPlaceOfPassingStreet, deceasedPlaceOfPassingSuburb, deceasedPlaceOfPassingState, deceasedPlaceOfPassingPostcode, deceasedPlaceOfPassingCountry]
      .filter((v) => v)
      .join(", ");

    const certificateType = _.get(arrangementData, "birthsDeathsAndMarriages.nsw.certificateType.name");
    const certificateQuantity = _.get(arrangementData, "birthsDeathsAndMarriages.nsw.quantity");
    const certificateDeliveryType = _.get(arrangementData, "birthsDeathsAndMarriages.nsw.deliveryType.name");
    const certificateDeliveryContact = _.get(arrangementData, "birthsDeathsAndMarriages.nsw.deliveryContact");

    const witness = _.get(arrangementData, "birthsDeathsAndMarriages.nsw.deliveryContact");
    const informant = _.get(arrangementData, "birthsDeathsAndMarriages.nsw.informant");

    const rows: SimpleDataPdfRow[] = [];

    const processContact = (contact?: any, allowedKeys: string[] = []): SimpleDataPdfRow[] => {
      return [
        {
          title: "Name",
          value: [contact?.name?.title?.name, contact?.name?.first, contact?.name?.middle, contact?.name?.last].filter((v) => v).map(v => upperFirst(v)).join(" ")
        },
        {
          title: "Last name at Birth",
          value: contact?.name?.lastNameAtBirth,
        },
        {
          title: "As known as",
          value: contact?.name?.aka,
        },
        {
          title: "Gender",
          value: contact?.gender?.name,
        },
        {
          title: "Date of Birth",
          value: contact?.dateOfBirth ? moment(contact.dateOfBirth).format("DD/MM/YYYY") : null,
        },
        {
          title: "Email",
          value: [contact?.email?.primary, contact?.email?.secondary].filter((v) => v).join(" / "),
        },
        {
          title: "Phone",
          value: [contact?.phone?.primary, contact?.phone?.secondary].filter((v) => v).join(" / "),
        },
        {
          title: "Address",
          value: processAddress(contact?.address),
        },
        {
          title: "Mailing address",
          value: processAddress(contact?.mailingAddress),
        },
        {
          title: "Life status",
          value: contact?.lifeStatus?.name,
        },
      ].filter(({ title }) => allowedKeys.includes(title));
    };

    const processAddress = (address?: any): null | string => {
      if (!address) {
        return null;
      }

      // Country is always set as Australia by default
      // Detect empty by other fields
      if (!address.street && !address.suburb && !address.state && !address.postcode) {
        return null;
      }
      
      return [
          placeAndStreetAreDifferent(address.place, address.street) ? address.place : "",
          address.street,
          address.suburb,
          address.state?.value || address.state,
          address.postcode,
          address.country,
        ].filter((v) => v).join(", ");
    };

    rows.push({
      title: clientTypeNamePipe.transform(`Deceased's Details`, this),
      children: processContact(deceased, ["Name", "Last name at Birth", "As known as", "Gender"]),
    });

    rows.push({
      title: `Relevant Dates`,
      children: [
        {
          title: "Date of Birth",
          value: deceasedDateOfBirth,
        },
        {
          title: "Date of Death",
          value: deceasedDateOfPassing,
        },
        {
          title: "Time of Passing",
          value: deceasedTimeOfPassing,
        },
        {
          title: "Age at Death",
          value: deceasedAgeAtPassing,
        },
      ],
    });

    rows.push({
      title: `Relevant Locations`,
      children: [
        {
          title: "Place of Death",
          value: deceasedPlaceOfPassing,
        },
        {
          title: "Usual Residential Address",
          value: processAddress(deceased.usualResidence),
        },
        {
          title: "Place of Birth",
          value: processAddress(deceased.placeOfBirth),
        },
      ],
    });

    rows.push({
      title: `Marriage Details`,
      children: [
        {
          title: "Marital status at time of death",
          value: marriageStatusAtPassing,
        },
        {
          title: "List marriages",
          children: marriages.map((marriage: any, index: number) => {
            return {
              title: `Marriage #${index + 1}`,
              children: [
                {
                  title: "Marriage Type",
                  value: marriage.type.name,
                },
                {
                  title: "Location of Marriage",
                  value: processAddress(marriage.address),
                },
                {
                  title: "Age at Commencement",
                  value: marriage.age,
                },
                {
                  title: "Year of Marriage",
                  value: marriage.year,
                },
                {
                  title: "Spouse",
                  children: processContact(marriage.spouse, ["Name", "Last name at Birth", "Gender", "Phone", "Address"]),
                },
              ],
            };
          }),
        },
      ],
    });

    rows.push({
      title: clientTypeNamePipe.transform(`Children of the Deceased`, this),
      children: children.map((child: any, index: number) => {
        return {
          title: `Child #${index + 1}`,
          children: processContact(child.child, ["Name", "Last name at Birth", "Gender", "Date of Birth", "Address", "Life status"]),
        };
      }),
    });

    rows.push({
      title: clientTypeNamePipe.transform(`Parents of the Deceased`, this),
      children: parents.map((parent: any, index: number) => {
        return {
          title: `Parent #${index + 1}`,
          children: [
            {
              title: "Parent type",
              value: parent.type.name,
            },
            ...processContact(parent.parent, ["Name", "Last name at Birth", "Gender", "Address"]),
            {
              title: "Occupation",
              value: parent.occupation?.title,
            },
            {
              title: "Main occupation activity",
              value: parent.occupation?.tasksPerformed,
            },
          ],
        };
      }),
    });

    rows.push({
      title: `Type of Death Certificate`,
      children: [
        {
          title: "Type",
          value: causeOfDeath,
        },
      ],
    });

    rows.push({
      title: disposalType ? `${disposalType} Details` : `Disposal Details`,
      children: [
        {
          title: "Type",
          value: disposalType,
        },
        {
          title: "Date",
          value: disposalDate,
        },
        {
          title: "Informant",
          children: processContact(informant, ["Name", "Email", "Phone", "Address"]),
        },
        {
          title: "Witness",
          children: processContact(witness, ["Name", "Email", "Phone", "Address"]),
        },
      ],
    });

    rows.push({
      title: `Certificate Request Settings`,
      children: [
        {
          title: "Certificate Type",
          value: certificateType,
        },
        {
          title: "Certificate Quantity",
          value: certificateQuantity,
        },
        {
          title: "Delivery Type",
          value: certificateDeliveryType,
        },
        {
          title: "Delivery Contact",
          children: processContact(certificateDeliveryContact, ["Name", "Email", "Phone", "Address", "Mailing address"]),
        },
      ],
    });

    rows.push({
      title: `Other Details`,
      children: [
        {
          title: "Occupation",
          value: deceasedOccupationTitle,
        },
        {
          title: "Main occupation activity",
          value: deceasedOccupationActivity,
        },
        {
          title: "Aboriginal or Torres Strait Islander Origin",
          value: deceasedAboriginalOrTorresStraightIslander,
        },
        {
          title: "Retired at Date of Death",
          value: deceasedRetiredAtPassing,
        },
        {
          title: "Pension Details",
          children: [
            {
              title: "Pensioner at Date of Death",
              value: pensionerAtPassing,
            },
            {
              title: "Pension Type",
              value: pensionTypeAtPassing,
            },
          ],
        },
      ],
    });

    return of({
      pdfTitle: "BDM Submission Draft",
      rows,
    });
  }

  public syncProjectTasks(invoiceItems: InvoiceItem[], costs: ArrangementCosts): Observable<XeroResponse> {
    const data = this.getSyncOrGenerateProjectData(invoiceItems, costs);

    const url = easyFormatter(
      environment.api.host + environment.api.paths.api.arrangement.projectEstimate.post,
      {
        arrangementId: this.id!.toString(),
      }
    );

    this.siteService.addSubscriptionLog(this, 'arrangement.ts->syncProjectTasks->this.http.post');

    return this.http.post<any>(url, data).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('arrangement.ts->syncProjectTasks->this.http.post')),
      takeUntil(this.unsubscribe$),
      map(res => {
        
        /**
         * If you need to add more properties to the form look at `formStructure.invoice.value` within `initialiseCompleteForm()`
         * You can use the `projectId` or `referenceId` objects as a base for your new property
         */
        this.form.get('invoice.projectId')?.patchValue(res.data.reference);
        this.form.get('invoice.referenceId')?.patchValue(res.data.invoiceID);
        this.form.get('invoice.url')?.patchValue(res.data.url);

        return res;

      })
    );

  }
 
  public generateDraftBDM(data: GenerateSimpleDataPdfPayload): Observable<XeroResponse> {

    const url = easyFormatter(
      environment.api.host + environment.api.paths.api.arrangement.simpleDataPdf.post,
      {
        arrangementId: this.id!.toString(),
      }
    );

    this.siteService.addSubscriptionLog(this, 'arrangement.ts->generateDraftBDM->this.http.post');

    return this.http.post<any>(url, data).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('arrangement.ts->generateDraftBDM->this.http.post')),
      takeUntil(this.unsubscribe$),
    );

  }

  public notifySendingInvoice(data: any): Observable<any> {

    const url = easyFormatter(
      environment.api.host + environment.api.paths.api.arrangement.notifySendingInvoice.post,
      {
        arrangementId: this.id!.toString(),
      }
    );

    this.siteService.addSubscriptionLog(this, 'arrangement.ts->notifySendingInvoice->this.http.post');

    return this.http.post<any>(url, data).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('arrangement.ts->notifySendingInvoice->this.http.post')),
      takeUntil(this.unsubscribe$),
    );

  }
  
  public generateProjectQuote(invoiceItems: InvoiceItem[], costs: ArrangementCosts): Observable<XeroResponse> {
    const data = this.getSyncOrGenerateProjectData(invoiceItems, costs);

    const url = easyFormatter(
      environment.api.host + environment.api.paths.api.arrangement.projectQuote.post,
      {
        arrangementId: this.id!.toString(),
      }
    );

    this.siteService.addSubscriptionLog(this, 'arrangement.ts->generateProjectQuote->this.http.post');

    return this.http.post<any>(url, data).pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('arrangement.ts->generateProjectQuote->this.http.post')),
      takeUntil(this.unsubscribe$),
    );

  }
  
  public resetXeroProject(): Observable<XeroResponse> {

    const url = easyFormatter(
      environment.api.host + environment.api.paths.api.arrangement.projectEstimate.reset,
      {
        arrangementId: this.id!.toString(),
      }
    );

    return this.http.post<any>(url, { });

  }

  public processAndUpdateNoteAndTaskBadges(data?: any): void {

    if (!data) {

      data = this.formAsPostData();

    }

    const now = moment();

    const counts: TasksCounts = {
      nearingOverDue: 0,
      overDue: 0,
      completed: 0,
      total: 0,
      byStep: {}
    };

    for (const stepName of Object.values(taskType)) {
      counts.byStep[stepName] = {
        nearingOverDue: 0,
        overDue: 0,
        completed: 0,
        total: 0,
      };
    }

    const badges: NotesAndTasksBadges = {
      tasks: {},
      notes: {},
    };

    const tasks = _.get(data, 'tasks');
    const notes = _.get(data, 'notes');

    for (const taskKey in tasks) {

      if (tasks[taskKey] && tasks[taskKey].length) {

        let count = 0;

        for (let i = 0; i <= tasks[taskKey].length; i++) {

          const task = tasks[taskKey][i];

          if (task && task.removed !== true) {
            
            const taskFormGroup = this.form.get(['tasks', taskKey, i]);

            if (taskFormGroup) {

              taskFormGroup.get('overDue')?.patchValue(false, { emitEvent: false });
              taskFormGroup.get('nearingOverDue')?.patchValue(false, { emitEvent: false });

            }

            if (_.get(task, 'status.value') !== taskStatusType.NotRequired) {
  
              counts.total++;
              counts.byStep[taskKey].total++;
  
              if (_.get(task, 'status.value') !== taskStatusType.Completed) {
  
                count++;
  
                if (task.dueDate) {
  
                  const dueDate = moment(task.dueDate);
    
                  if (task.autoDueDateOffset < 0) {
    
                    dueDate.subtract(task.autoDueDateOffset, 'days');
    
                  } else if (task.autoDueDateOffset > 0) {
    
                    dueDate.add(task.autoDueDateOffset, 'days');
    
                  }
    
                  if (now.isSameOrAfter(dueDate, 'day')) {
  
                    if (taskFormGroup) {
  
                      taskFormGroup.get('overDue')?.patchValue(true, { emitEvent: false });
  
                    }
    
                    counts.overDue++;
                    counts.byStep[taskKey].overDue++;
    
                  } else {
    
                    if (now.isSameOrAfter(dueDate.clone().subtract(48, 'hours'), 'day')) {
  
                      if (taskFormGroup) {
    
                        taskFormGroup.get('nearingOverDue')?.patchValue(true, { emitEvent: false });
    
                      }
      
                      counts.nearingOverDue++;
                      counts.byStep[taskKey].nearingOverDue++;
      
                    }
    
                  }
    
                }
  
              }
  
              if (_.get(task, 'status.value') === taskStatusType.Completed) {
  
                counts.completed++;
                counts.byStep[taskKey].completed++;
  
              }

            }

          }

        }

        _.set(badges, ['tasks', taskKey], count);

      }

    }

    for (const noteKey in notes) {

      if (notes[noteKey] && notes[noteKey].length) {

        let count = 0;

        for (let i = 0; i <= notes[noteKey].length; i++) {

          const note = notes[noteKey][i];

          if (note && note.text) {

            count++;

          }

        }

        _.set(badges, ['notes', noteKey], count);

      }

    }

    this.noteAndTaskBadgesSource.next(badges);
    this.taskCountsSource.next(counts);

  }

  // This is used to create items for the address select menu within Transfers
  public createAddress(address: any, type: string, label: string, id: string | null = null): any {

    let addressAsArray: string[] = [];

    if (address) {

      if (address.street) {

        addressAsArray.push(address.street);

      }

      if (address.suburb) {
        addressAsArray.push(address.suburb);
      }

      if (address.state) {
        if (address.state.value) {
          addressAsArray.push(address.state.value);
        } else if (typeof address.state === 'string') {
          addressAsArray.push(address.state);
        }
      }

      if (address.postcode) {
        addressAsArray.push(address.postcode);
      }

      if (address.country) {
        if (address.country.toString() !== 'australia') {
          addressAsArray.push(address.country);
        }
      }

    }

    if (addressAsArray.length) {

      return {
        name: `<span class="label">${label}</span>` + ((address && address.place && type !== 'service provider' && type !== 'preparation') ? `<span class="place">${address.place}</span>` : ``) + `<span class="address">${addressAsArray.join(', ')}</span>`,
        value: {
          serviceProviderId: null,
          serviceId: null,
          transferId: (id && type.toLowerCase() === 'transfer') ? id : null,
          isTransferFrom: false,
          isTransferTo: false,
          eventId: (id && type.toLowerCase() === 'event') ? id : null,
          usePreparationServiceProvider: (type.toLowerCase() === 'preparation') ? true : false,
          usePlaceOfDeath: (type.toLowerCase() === 'place-of-death') ? true : false,
          useUsualResidence: (type.toLowerCase() === 'usual-residence') ? true : false,
          address: address,
        }
      };

    } else {

      return null;

    }

  };

  public processAndUpdateAddresses(data?: any): void {

    if (!data) {

      data = this.formAsPostData();

    }

    const addresses: any[] = [];

    const deceasedUsualResidence = _.get(data, 'deceased.usualResidence');
    const deceasedPlaceOfPassingAddress = _.get(data, 'deceased.placeOfPassing.address');
    const preparationService = _.get(data, 'preparation.service');
    const transfers = _.get(data, 'transfer.transfers');
    const events = _.get(data, 'events');

    if (deceasedUsualResidence) {

      const a = this.createAddress(deceasedUsualResidence, 'usual-residence', '<strong>Usual Residence</strong>');

      if (a) {
        addresses.push(a);
      }

    }

    if (deceasedPlaceOfPassingAddress) {

      const a = this.createAddress(deceasedPlaceOfPassingAddress, 'place-of-death', '<strong>Place of Death</strong>');

      if (a) {
        addresses.push(a);
      }
      
    }

    if (preparationService) {

      const serviceProviderId = _.get(preparationService, 'serviceProviderId');
      const serviceId = _.get(preparationService, 'service.id');

      if (serviceProviderId && serviceId) {

        const service = this.serviceProviderService.getServiceById(serviceProviderId, serviceId);
  
        if (service) {
  
          const a = this.createAddress(service.address, 'preparation', `<strong>Preparation - ${service.name}</strong>`);
    
          if (a) {
            addresses.push(a);
          }
  
        }

      }

    }
    
    if (Array.isArray(transfers)) {
      
      for (let i = 0; i < transfers.length; i++) {
        
        const o = ordinal(i + 1);
        const id = _.get(transfers, `${i}._meta.id`);
        
        const fromAddress = _.get(transfers, `${i}.from.address`);
        const fromLabel = `${o} Transfer (Pickup Location)`;
        
        const toAddress = _.get(transfers, `${i}.to.address`);
        const toLabel = `${o} Transfer (Drop-off Location)`;

        const fromA = this.createAddress(fromAddress, 'transfer', fromLabel, id);
        const toA = this.createAddress(toAddress, 'transfer', toLabel, id);

        if (fromA) {
          fromA.value.isTransferFrom = true;
        }

        if (toA) {
          toA.value.isTransferTo = true;
        }

        if (fromA) {
          addresses.push(fromA);
        }

        if (toA) {
          addresses.push(toA);
        }

      }

    }
    
    if (Array.isArray(events)) {
      
      for (let i = 0; i < events.length; i++) {
        
        const o = ordinal(i + 1);
        const id = _.get(events, `${i}._meta.id`);
        
        const type = _.get(events, `${i}.type.name`);
        const address = _.get(events, `${i}.venue.address`);
        const label = `${o} Event - ${type}`;

        const a = this.createAddress(address, 'event', label, id);
        
        if (a) {
          addresses.push(a);
        }

      }

    }

    addresses.push({ name: '<span class="label">Custom Address</span> <span class="address">Enter an address manually</span>', value: false });

    this.addressesSource.next(addresses);

  }

  public processAndUpdateTransferOptions(data?: any): void {

    const currentSource = [...this.transferTransfersSource.value];

    const formTransfersFormArray = this.form.get('transfer.transfers') as FormArray;

    const formTransfersValue = formTransfersFormArray?.value;

    if (formTransfersValue) {

      for (let i = 0; i < formTransfersValue.length; i++) {

        const transfer = formTransfersValue[i];
  
        if (transfer) {
  
          const addressToStringPipe = new AddressToStringPipe();
  
          const fromAddress =  _.get(transfer, 'from.address');
          const toAddress = _.get(transfer, 'to.address');
          
          const hasFromAddress = hasValidAddress(fromAddress);
          const hasToAddress = hasValidAddress(fromAddress);
  
          if (hasFromAddress) {
  
            const fromAddressString = addressToStringPipe.transform(fromAddress);
  
            const fromTransferOption = {
              name: ordinal(i + 1) + ' Transfer ' + ' - Pickup - ' + fromAddressString,
              value: {
                id: _.get(transfer, '_meta.id'),
                type: TransferAddressType.From,
              }
            };
  
            // Check if the transfer option already exists
            const existingFromTransferOptionIndex = currentSource.findIndex(transferOption => transferOption.value.id === fromTransferOption.value.id);
  
            // If it exists, update it. If not, add it
            if (existingFromTransferOptionIndex > -1) {
  
              currentSource[existingFromTransferOptionIndex] = fromTransferOption;
  
            } else {
  
              currentSource.push(fromTransferOption);
  
            }
  
          }
  
          if (hasToAddress) {
              
            const toAddressString = addressToStringPipe.transform(toAddress);
  
            const toTransferOption = {
              name: ordinal(i + 1) + ' Transfer ' + ' - Drop Off - ' + toAddressString,
              value: {
                id: _.get(transfer, '_meta.id'),
                type: TransferAddressType.To,
              }
            };
  
            // Check if the transfer option already exists
            const existingFromTransferOptionIndex = currentSource.findIndex(transferOption => transferOption.value.id === toTransferOption.value.id);
  
            // If it exists, update it. If not, add it
            if (existingFromTransferOptionIndex > -1) {
  
              currentSource[existingFromTransferOptionIndex] = toTransferOption;
  
            } else {
  
              currentSource.push(toTransferOption);
  
            }
          
          }
  
        }
  
      }

    }

    this.transferTransfersSource.next(currentSource);

  }

  public processAndUpdateEventOptions(data?: any): void {
  }

  public getSnapShotTime(): any {
    return this.snapShotTime;
  }

  public takeSnapShotTime(): void {

    setTimeout(() => {
  
      // console.log('Snapshot taken');
  
      this.snapShotTime = Date.now();

    }, 2000);
    
  }

  public hasChanged(): any {

    if (!this.snapShotTime) {

      return null;

    } else if (!this.hasBeenTouched) {
      
      return null;
      
    } else {
      
      return this.snapShotTime < this.lastFormValueChangeTime;
      
    }

  }

  public validateLinkedTransfers(): void {

    this.validateLinkedTransfersSource.next(validateLinkedTransfers(this));

  }

  public printPdf(type: PDFType, options: any): Observable<any> {

    const url = easyFormatter(
      environment.api.host + environment.api.paths.api.arrangement.pdf.post,
      {
        arrangementId: this.id!.toString(),
        type,
      }
    );

    return this.arrangementToCompleteObject().pipe(
      switchMap((arrangement: any) => {
        return this.http.post<any>(url, { arrangement, options });
      }),
      takeUntil(this.unsubscribe$)
    );

  }

  /**
   * This updates (patch) an existing DB record
   */
  private update(options?: SaveArrangementOptions): Observable<ArrangementUpdateResponse> {

    const url = easyFormatter(environment.api.host + environment.api.paths.api.arrangement.patch, { id: (this.id as number).toString() });

    this.processCheckList();

    const data = this.formAsPostData();

    this.processAndUpdateNoteAndTaskBadges(data);
    this.processAndUpdateAddresses(data);

    const arrangementDataToSend = arraysToObjects(data);

    if (this.logOnly) {
  
      this.arrangementSavedSource.next(true);

      // No need to unsubscribe as this uses subscribe.complete()
      return new Observable<any>((subscribe) => {
        subscribe.next(true);
        subscribe.complete();
      });

    } else {

      this.siteService.addSubscriptionLog(this, 'arrangement.ts->update->this.http.patch');

      return this.http.patch<ArrangementUpdateResponse>(url, arrangementDataToSend).pipe(
        finalize(() => this.siteService.setSubscriptionLogFinalised('arrangement.ts->update->this.http.patch')),
        takeUntil(this.unsubscribe$),
        switchMap(res => {

          this.validateLinkedTransfersSource.next(validateLinkedTransfers(this));

          this.arrangementSavedSource.next(true);

          this.takeSnapShotTime();

          return of(res);

        })
      );

    }

  }

  /**
   * This creates (post) a new DB record
   */
  private create(options?: SaveArrangementOptions): Observable<boolean> {

    const createUrl = environment.api.host + environment.api.paths.api.arrangement.post;

    this.enableDeathCertificateAutoCost();
    this.processCheckList();

    const data = this.formAsPostData();

    this.processAndUpdateNoteAndTaskBadges(data);
    this.processAndUpdateAddresses(data);

    const arrayToObject = arraysToObjects(data);

    if (this.logOnly) {
  
      this.arrangementSavedSource.next(true);
  
      // No need to unsubscribe as this uses subscribe.complete()
      return new Observable<boolean>((subscribe) => {
        subscribe.next(true);
        subscribe.complete();
      });

    } else {

      this.siteService.addSubscriptionLog(this, 'arrangement.ts->create->this.http.post');

      return this.http.post<ArrangementResponse>(createUrl, {}).pipe(
        finalize(() => this.siteService.setSubscriptionLogFinalised('arrangement.ts->create->this.http.post')),
        takeUntil(this.unsubscribe$),
        switchMap((createRes: any) => {
    
          // This is the raw DB response
          const arrangementId = createRes.data.arrangementId;
    
          // Store the ID
          this.id = arrangementId;

          const updateUrl = easyFormatter(environment.api.host + environment.api.paths.api.arrangement.patch, { id: (this.id as number).toString() });

          return this.http.patch<ArrangementUpdateResponse>(updateUrl, arrayToObject);

        }), map(updateRes => {

          this.takeSnapShotTime();
      
          this.arrangementSavedSource.next(true);
          
          return true;

        })
      );

    }

  }

  /**
   * If we want to automatically add the Death Certificate cost, we need to enable it
   * Update by Ivan: The original value was `true` but I changed it to `2` so we can detect if we should
   * use the `cost.amountEdited` value when deciding the cost of the Death Certificate. I chose `2` as it's
   * value because it's both "truthy" and a value we can differentiate from.
   */
  private enableDeathCertificateAutoCost() {
    
    const autoCostControl = this.form.get('birthsDeathsAndMarriages.autoCost');

    if (autoCostControl) {
      // autoCostControl.patchValue(true);
      autoCostControl.patchValue(2);
    }

  }

  /**
   * Convert the response from a get / post / patch request into a usable arrangement
   */
  private processRemoteResponse(data: any): Observable<boolean> {

    return new Observable<boolean>(subscriber => {

      this.rawResponseData = data;

      this.obj = objectsToArrays(data);

      this.postDataAsContacts(this.obj.contacts);

      // No need to unsubscribe as this uses pipe(first())
      this.siteService.addSubscriptionLog(this, 'arrangement.ts->processRemoteResponse->this.postDataAsFormData');

      this.postDataAsFormData(_.cloneDeep(this.obj) as ArrangementModel).pipe(
        finalize(() => this.siteService.setSubscriptionLogFinalised('arrangement.ts->processRemoteResponse->this.postDataAsFormData')),
        takeUntil(this.unsubscribe$),
        first()
      ).subscribe({
        next: formData => {

          this.initialiseCompleteForm(formData);

          this.processAndUpdateNoteAndTaskBadges();
          this.processAndUpdateAddresses();
          this.processRelevantServices();

          this.processAndUpdateTransferOptions();
          this.processAndUpdateEventOptions();

          this.contactsSource.value.forEach(contact => contact.populateRelationshipInputs());

          subscriber.next(true);
          subscriber.complete();

        },
        error: err => {
          console.error(err);

          subscriber.next(false);
          subscriber.complete();
        }
      });

    });

  }

  private initialiseListingForm(data?: any) {

    const formStructure: any = {
      locked: { 
        type: FormStructureType.FormGroup, 
        value: {
          status: { 
            type: FormStructureType.FormControl, 
          },
          userId: { 
            type: FormStructureType.FormControl 
          },
          uId: { 
            type: FormStructureType.FormControl 
          },
        }
      },
      accessUsers: {
        type: FormStructureType.FormControl, 
        value: null
      },
      isArrangement: { type: FormStructureType.FormControl, value: true },
      isSubmitted: { type: FormStructureType.FormControl, value: false },
      status: { type: FormStructureType.FormControl },
      type: { type: FormStructureType.FormControl },
      funeralFormat: { type: FormStructureType.FormControl },
      birthsDeathsAndMarriages: {
        type: FormStructureType.FormGroup,
        value: {
          nsw: {
            type: FormStructureType.FormGroup,
            value: {
              certificate: { 
                type: FormStructureType.FormGroup, 
                value: {
                  notification: {
                    type: FormStructureType.FormGroup, 
                    value: {
                      status: { type: FormStructureType.FormControl },
                      id: { type: FormStructureType.FormControl },
                    }
                  },
                  application: {
                    type: FormStructureType.FormGroup, 
                    value: {
                      status: { type: FormStructureType.FormControl },
                      id: { type: FormStructureType.FormControl },
                    }
                  },
                } 
              },
            }
          }
        }
      },
      deceased: { 
        type: FormStructureType.FormGroup, 
        value: {
          name: { 
            type: FormStructureType.FormGroup, 
            value: _.cloneDeep(FormNameStructure)
          },
          placeOfPassing: { 
            type: FormStructureType.FormGroup, 
            value: {
              address: {
                type: FormStructureType.FormGroup,
                value: { 
                  state: { type: FormStructureType.FormControl }, 
                },
              },
            },
          },
          dateOfBirth: {
            type: FormStructureType.FormControl,
            valueAs: FormStructureValueType.Date,
          },
          dateOfPassing: {
            type: FormStructureType.FormControl,
            valueAs: FormStructureValueType.Date,
          },
          dateOfPassingFrom: {
            type: FormStructureType.FormControl,
            valueAs: FormStructureValueType.Date,
          },
          dateOfPassingTo: {
            type: FormStructureType.FormControl,
            valueAs: FormStructureValueType.Date,
          },
        },
      },
      arrangementMeeting: {
        type: FormStructureType.FormGroup,
        value: {
          arranger: { type: FormStructureType.FormControl },
          date: { type: FormStructureType.FormControl },
        }
      },
      events: {
        type: FormStructureType.FormArray, 
        value: _.cloneDeep(FormEventStructure),
      },
      disposal: {
        type: FormStructureType.FormGroup,
        value: {
          date: { type: FormStructureType.FormControl, valueAs: FormStructureValueType.Date },
          type: { type: FormStructureType.FormControl },
        }
      },
      notes: {
        type: FormStructureType.FormGroup,
        value: {
          firstCall: { type: FormStructureType.FormArray, value: _.cloneDeep(NoteStructure) },
          bookArrangementMeeting: { type: FormStructureType.FormArray, value: _.cloneDeep(NoteStructure) },
          contacts: { type: FormStructureType.FormArray, value: _.cloneDeep(NoteStructure) },
          eventDetails: { type: FormStructureType.FormArray, value: _.cloneDeep(NoteStructure) },
          preparationDetails: { type: FormStructureType.FormArray, value: _.cloneDeep(NoteStructure) },
          disposalDetails: { type: FormStructureType.FormArray, value: _.cloneDeep(NoteStructure) },
          bdm: { type: FormStructureType.FormArray, value: _.cloneDeep(NoteStructure) },
          statutoryDeclaration: { type: FormStructureType.FormArray, value: _.cloneDeep(NoteStructure) },
          newspaperNotifications: { type: FormStructureType.FormArray, value: _.cloneDeep(NoteStructure) },
          costSummary: { type: FormStructureType.FormArray, value: _.cloneDeep(NoteStructure) },
          documentLibrary: { type: FormStructureType.FormArray, value: _.cloneDeep(NoteStructure) },
        }
      },
      tasks: {
        type: FormStructureType.FormGroup,
        value: {
          firstCall: { type: FormStructureType.FormArray, value: _.cloneDeep(TaskStructure) },
          bookArrangementMeeting: { type: FormStructureType.FormArray, value: _.cloneDeep(TaskStructure) },
          contacts: { type: FormStructureType.FormArray, value: _.cloneDeep(TaskStructure) },
          eventDetails: { type: FormStructureType.FormArray, value: _.cloneDeep(TaskStructure) },
          preparationDetails: { type: FormStructureType.FormArray, value: _.cloneDeep(TaskStructure) },
          disposalDetails: { type: FormStructureType.FormArray, value: _.cloneDeep(TaskStructure) },
          bdm: { type: FormStructureType.FormArray, value: _.cloneDeep(TaskStructure) },
          statutoryDeclaration: { type: FormStructureType.FormArray, value: _.cloneDeep(TaskStructure) },
          newspaperNotifications: { type: FormStructureType.FormArray, value: _.cloneDeep(TaskStructure) },
          costSummary: { type: FormStructureType.FormArray, value: _.cloneDeep(TaskStructure) },
          documentLibrary: { type: FormStructureType.FormArray, value: _.cloneDeep(TaskStructure) },
        }
      }
    };

    const formGenerator = new FormGenerator();

    this.form = formGenerator.generate(formStructure, data);

  }

  private initialiseCreateForm(data?: any) {

    this.initialiseCompleteForm(data);

  }

  private initialiseCompleteForm(data: any) {

    // If the date is before 2025-02-01 the default service fee is 35%. If it's after, the default is 40%
    const defaultServiceFee = moment().isBefore(moment('2025-02-01', true), 'day') ? 35 : 40;

    const formStructure: any = {
      _arrangement: {
        type: FormStructureType.FormControl, 
        value: this
      },
      locked: { 
        type: FormStructureType.FormGroup, 
        value: {
          status: { 
            type: FormStructureType.FormControl, 
          },
          userId: { 
            type: FormStructureType.FormControl 
          },
          uId: { 
            type: FormStructureType.FormControl 
          },
        }
      },
      accessUsers: {
        type: FormStructureType.FormControl, 
        value: null
      },
      xeroProjectId: {
        type: FormStructureType.FormControl, 
      },
      isArrangement: { 
        type: FormStructureType.FormControl, 
        value: true 
      },
      isSubmitted: { 
        type: FormStructureType.FormControl, 
        value: false 
      },
      status: { 
        type: FormStructureType.FormControl 
      },
      type: { 
        type: FormStructureType.FormControl 
      },
      funeralFormat: { 
        type: FormStructureType.FormControl 
      },
      deceased: { 
        type: FormStructureType.FormGroup, 
        value: {
          name: { 
            type: FormStructureType.FormGroup, 
            value: _.cloneDeep(FormNameStructure)
          },
          gender: { 
            type: FormStructureType.FormControl 
          },
          email: {
            type: FormStructureType.FormGroup, value: {
              primary: { type: FormStructureType.FormControl },
              secondary: { type: FormStructureType.FormControl },
            }
          },
          usualResidence: { 
            type: FormStructureType.FormGroup, 
            value: _.cloneDeep(FormAddressStructure), 
            valueAs: FormStructureValueType.Address 
          },
          placeOfPassing: {
            type: FormStructureType.FormGroup,
            value: {
              linkedTransferId: { type: FormStructureType.FormControl, value: [] },
              showNSWStatDec: {
                type: FormStructureType.FormControl,
                value: false,
              },
              useAutoFill: {
                type: FormStructureType.FormControl
              },
              autoFill: {
                type: FormStructureType.FormControl
              },
              address: { 
                type: FormStructureType.FormGroup, 
                value: _.cloneDeep(FormAddressStructure), 
                valueAs: FormStructureValueType.Address 
              },
              type: {
                type: FormStructureType.FormControl
              },
              description: {
                type: FormStructureType.FormControl
              },
              hospitalOrNursingHome: { 
                type: FormStructureType.FormControl
              },
            }
          },
          placeOfBirth: { 
            type: FormStructureType.FormGroup, 
            value: _.cloneDeep(FormAddressStructure), 
            valueAs: FormStructureValueType.Address 
          },
          religionAtTimeOfPassing: { // Key / Value pair
            type: FormStructureType.FormControl
          },
          dateOfBirth: {
            type: FormStructureType.FormControl,
            valueAs: FormStructureValueType.Date,
          },
          dateOfPassingType: {
            type: FormStructureType.FormControl,
          },
          dateOfPassing: {
            type: FormStructureType.FormControl,
            valueAs: FormStructureValueType.Date,
          },
          dateOfPassingFrom: {
            type: FormStructureType.FormControl,
            valueAs: FormStructureValueType.Date,
          },
          dateOfPassingTo: {
            type: FormStructureType.FormControl,
            valueAs: FormStructureValueType.Date,
          },
          timeOfPassing: {
            type: FormStructureType.FormControl,
            valueAs: FormStructureValueType.Date,
          },
          ageAtPassing: {
            type: FormStructureType.FormControl
          },
          ageAtPassingUnit: {
            type: FormStructureType.FormControl
          },
          ageAtPassingWithUnit: {
            type: FormStructureType.FormControl
          },
          bornOverseas: {
            type: FormStructureType.FormControl
          },
          yearOfAustralianArrivalKnown: {
            type: FormStructureType.FormControl
          },
          yearOfAustralianArrival: {
            type: FormStructureType.FormControl
          },
          numberOfYearsResidingInAustralia: {
            type: FormStructureType.FormControl
          },
          aboriginalOrTorresStraightIslander: {
            type: FormStructureType.FormControl
          },
          occupation: {
            type: FormStructureType.FormGroup, 
            value: _.cloneDeep(FormOccupationStructure)
          },
          retiredAtPassing: {
            type: FormStructureType.FormControl
          },
          causeOfDeath: {
            type: FormStructureType.FormGroup, 
            value: {
              method: { type: FormStructureType.FormControl }, // Options: MCCD - Medical Certificate of Cause of Death Issued; MCPD - Medical Certificate Cause of Perinatal Death Issued; Coroner's Disposal Order
              coronerDisposalOrderRequired: { type: FormStructureType.FormControl }, // Options: Not Required; With Cause of Death; Without Cause of Death
            }
          },
          isCoronersCase: {
            type: FormStructureType.FormControl
          },
          isStillBornBaby: {
            type: FormStructureType.FormControl
          }
        }
      },
      firstCall: { 
        type: FormStructureType.FormGroup, 
        value: { 
          source: { 
            type: FormStructureType.FormControl 
          },
          sourceMulti: { 
            type: FormStructureType.FormControl 
          },
          recipient: { 
            type: FormStructureType.FormControl 
          },
          date: { 
            type: FormStructureType.FormControl, 
            valueAs: FormStructureValueType.Date 
          },
          informant: { 
            type: FormStructureType.FormGroup, 
            value: _.cloneDeep(FormContactStructure),
            valueAs: FormStructureValueType.Contact 
          },
          sourceGroup: {
            type: FormStructureType.FormControl,
          }
        }
      },
      keyContacts: {
        type: FormStructureType.FormGroup, 
        value: { 
          informant: { 
            type: FormStructureType.FormGroup,
            value: _.cloneDeep(FormContactStructure),
            valueAs: FormStructureValueType.Contact 
          },
          nextOfKin: { 
            type: FormStructureType.FormGroup, 
            value: _.cloneDeep(FormContactStructure),
            valueAs: FormStructureValueType.Contact 
          },
          executor: { 
            type: FormStructureType.FormGroup, 
            value: _.cloneDeep(FormContactStructure),
            valueAs: FormStructureValueType.Contact 
          }
        }
      },
      additionalContacts: {
        type: FormStructureType.FormArray, 
        value: {
          contact: {
            type: FormStructureType.FormGroup, 
            value: _.cloneDeep(FormContactStructure),
            valueAs: FormStructureValueType.Contact 
          }
        },
      },
      contacts: { 
        type: FormStructureType.FormArray, 
        value: [],
      },
      transfer: {
        type: FormStructureType.FormGroup,
        value: _.cloneDeep(FormTransferStructure),
      },
      arrangementMeeting: {
        type: FormStructureType.FormGroup,
        value: _.cloneDeep(FormArrangementMeetingStructure),
      },
      departmentOfVeteranAffairs: {
        type: FormStructureType.FormGroup, 
        value: _.cloneDeep(FormDepartmentOfVeteranAffairsStructure),
      },
      pensioner: {
        type: FormStructureType.FormGroup, 
        value: _.cloneDeep(FormPensionerStructure),
      },
      centrelink: {
        type: FormStructureType.FormGroup, 
        value: _.cloneDeep(FormCentrelinkStructure),
      },
      marriage: {
        type: FormStructureType.FormGroup, 
        value: _.cloneDeep(FormMarriageStructure),
      },
      family: {
        type: FormStructureType.FormGroup, 
        value: _.cloneDeep(FormFamilyStructure),
      },
      events: {
        type: FormStructureType.FormArray, 
        value: _.cloneDeep(FormEventStructure),
      },
      newspaperNotifications: {
        type: FormStructureType.FormArray, 
        value: _.cloneDeep(FormNewspaperNotificationStructure),
      },
      causeOfDeath: {
        type: FormStructureType.FormGroup,
        value: {
          type: { type: FormStructureType.FormControl },
        }
      },
      preparation: {
        type: FormStructureType.FormGroup,
        value: {
          pdfIds: { type: FormStructureType.FormArray, value: { pdfId: { type: FormStructureType.FormControl } } },
          careInstructions: {
            type: FormStructureType.FormGroup,
            value: {
              familyDressing: { type: FormStructureType.FormControl },
              familyDressingNotes: { type: FormStructureType.FormControl },
              dentures: { type: FormStructureType.FormControl },
              denturesNotes: { type: FormStructureType.FormControl },
              makeup: { type: FormStructureType.FormControl },
              makeupNotes: { type: FormStructureType.FormControl },
              returnMakeup: { type: FormStructureType.FormControl },
              returnMakeupNotes: { type: FormStructureType.FormControl },
              facialHair: { type: FormStructureType.FormControl },
              facialHairNotes: { type: FormStructureType.FormControl },
              hair: { type: FormStructureType.FormControl },
              hairNotes: { type: FormStructureType.FormControl },
              printsOrMoulds: { type: FormStructureType.FormControl },
              printsOrMouldsNotes: { type: FormStructureType.FormControl },
              photoProvided: { type: FormStructureType.FormControl },
              photoProvidedNotes: { type: FormStructureType.FormControl },
              otherNotes: { type: FormStructureType.FormControl },
            }
          },
          hazardousMaterials: {
            type: FormStructureType.FormGroup,
            value: {
              doctor: { type: FormStructureType.FormControl },
              hospital: { type: FormStructureType.FormControl },
              date: { type: FormStructureType.FormControl, valueAs: FormStructureValueType.Date },
              otherNotes: { type: FormStructureType.FormControl },
            }
          },
          linkedTransferId: { type: FormStructureType.FormControl, value: [] },
          transfer: { 
            type: FormStructureType.FormGroup, 
            value: { 
              required: { type: FormStructureType.FormControl, value: false }, 
            } 
          },
          service: { type: FormStructureType.FormControl },
          notes: { type: FormStructureType.FormControl },
          lineItems: { type: FormStructureType.FormArray, value: _.clone(ProductLineItemStructure) },
          cost: { type: FormStructureType.FormGroup, value: _.cloneDeep(FinancialStructure) },
          additionalItems: {
            type: FormStructureType.FormGroup,
            value: {
              items: { type: FormStructureType.FormArray, value: _.cloneDeep(FormItemStructure) },
              cost: { type: FormStructureType.FormGroup, value: _.cloneDeep(FinancialStructure) },
            }
          },
          jewellery: { 
            type: FormStructureType.FormGroup, 
            value: { 
              atTimeOfDeath: { type: FormStructureType.FormGroup, value: {
                required: { type: FormStructureType.FormControl, value: false }, 
                contact: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormContactStructure), valueAs: FormStructureValueType.Contact },
                notes: { type: FormStructureType.FormControl },
              }},
              providedByFamily: { type: FormStructureType.FormGroup, value: {
                required: { type: FormStructureType.FormControl, value: false }, 
                contact: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormContactStructure), valueAs: FormStructureValueType.Contact },
                notes: { type: FormStructureType.FormControl },
              }},
              required: { type: FormStructureType.FormControl, value: false }, 
              instructions: { type: FormStructureType.FormArray, value: _.cloneDeep(FormJewelleryInstructionStructure) },
              contact: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormContactStructure), valueAs: FormStructureValueType.Contact },
              signature: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormSignatureStructure) },
            } 
          },
          clothing: { 
            type: FormStructureType.FormGroup, 
            value: { 
              required: { type: FormStructureType.FormControl, value: false }, 
              suppliedClothing: { type: FormStructureType.FormGroup, value: {
                clothingItems: { type: FormStructureType.FormControl, value: [] },
                underwareItems: { type: FormStructureType.FormControl, value: [] },
                accessoryItems: { type: FormStructureType.FormControl, value: [] },
                childrenClothingItems: { type: FormStructureType.FormControl, value: [] },
                otherClothing: { type: FormStructureType.FormControl }, 
                notes: { type: FormStructureType.FormControl },
                contact: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormContactStructure), valueAs: FormStructureValueType.Contact },
                specificInstructions: {
                  type: FormStructureType.FormGroup,
                  value: {
                    required: { type: FormStructureType.FormControl, value: false }, 
                    notes: { type: FormStructureType.FormControl },
                    contact: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormContactStructure), valueAs: FormStructureValueType.Contact },
                  }
                }
              }},
              instructions: { type: FormStructureType.FormArray, value: _.cloneDeep(FormClothingInstructionStructure) }, // @deprecated
              contact: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormContactStructure), valueAs: FormStructureValueType.Contact },
              signature: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormSignatureStructure) },
            } 
          },
          prepaidEstablishmentFee: {
            type: FormStructureType.FormGroup,
            value: {
              name: { type: FormStructureType.FormControl },
              cost: { type: FormStructureType.FormGroup, value: {
                ..._.cloneDeep(FinancialStructure),
                amount: { type: FormStructureType.FormControl, value: 480 }
              }}
            }
          },
        }
      },
      medicalCertificate: {
        type: FormStructureType.FormGroup,
        value: {
          attendingPractitionerMedicalCertificateCauseOfDeath: {
            type: FormStructureType.FormGroup,
            value: {
              name: { type: FormStructureType.FormGroup, value: _.clone(FormNameStructure) },
              address: { type: FormStructureType.FormGroup, value: _.clone(FormAddressStructure) },
              cost: { type: FormStructureType.FormGroup, value: _.cloneDeep(FinancialStructure) }
            }
          },
          attendingPractitionerCremationRiskAdvice: {
            type: FormStructureType.FormGroup,
            value: {
              name: { type: FormStructureType.FormControl },
              cost: { type: FormStructureType.FormGroup, value: _.cloneDeep(FinancialStructure) }
            }
          },
          medicalRefereeCremationPermit: {
            type: FormStructureType.FormGroup,
            value: {
              name: { type: FormStructureType.FormControl },
              // cost: { type: FormStructureType.FormControl },
              cost: { type: FormStructureType.FormGroup, value: _.cloneDeep(FinancialStructure) }
            }
          },
        }
      },
      nswCemeteryAndCremCost: {
        type: FormStructureType.FormGroup,
        value: {
          cremationFee: {
            type: FormStructureType.FormGroup,
            value: {
              name: { type: FormStructureType.FormControl },
              cost: { type: FormStructureType.FormGroup, value: _.cloneDeep(FinancialStructure) }
            }
          },
          burialFee: {
            type: FormStructureType.FormGroup,
            value: {
              name: { type: FormStructureType.FormControl },
              cost: { type: FormStructureType.FormGroup, value: _.cloneDeep(FinancialStructure) }
            }
          },
          ashIntermentFee: {
            type: FormStructureType.FormGroup,
            value: {
              name: { type: FormStructureType.FormControl },
              cost: { type: FormStructureType.FormGroup, value: _.cloneDeep(FinancialStructure) }
            }
          },
        }
      },
      disposal: {
        type: FormStructureType.FormGroup,
        value: {
          date: { type: FormStructureType.FormControl, valueAs: FormStructureValueType.Date },
          type: { type: FormStructureType.FormControl },
          cost: { type: FormStructureType.FormGroup, value: _.cloneDeep(FinancialStructure) },
          transfer: { 
            type: FormStructureType.FormGroup, 
            value: {
              required: { type: FormStructureType.FormControl, value: false },
            }
          },
          bodyNotRecovered: {
            type: FormStructureType.FormGroup,
            value: {}
          },
          bodyDonation: {
            type: FormStructureType.FormGroup,
            value: {
              name: { type: FormStructureType.FormControl },
              address: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormAddressStructure) },
              contact: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormContactStructure), valueAs: FormStructureValueType.Contact },
            }
          },
          repatriated: {
            type: FormStructureType.FormGroup,
            value: {
              name: { type: FormStructureType.FormControl },
              address: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormAddressStructure) },
              contact: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormContactStructure), valueAs: FormStructureValueType.Contact },
            }
          },
          burial: {
            type: FormStructureType.FormGroup,
            value: {
              address: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormAddressStructure) },
              plotLocation: { type: FormStructureType.FormControl },
              burialApplicant: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormContactStructure), valueAs: FormStructureValueType.Contact },
              burialApplicantSignature: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormSignatureStructure) },
              registeredHolderOfInterment: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormContactStructure), valueAs: FormStructureValueType.Contact },
              registeredHolderOfIntermentSignature: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormSignatureStructure) },
              notes: { type: FormStructureType.FormControl },
              service: { type: FormStructureType.FormControl },
              lineItems: { type: FormStructureType.FormArray, value: _.clone(ProductLineItemStructure) },
              cost: { type: FormStructureType.FormGroup, value: _.cloneDeep(FinancialStructure) },
            }
          },
          cremation: {
            type: FormStructureType.FormGroup,
            value: {
              address: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormAddressStructure) },
              notes: { type: FormStructureType.FormControl },
              service: { type: FormStructureType.FormControl },
              useCrematoriumAddress: { type: FormStructureType.FormControl},
              crematoriumAddress: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormAddressStructure) },
              ashIntermentSet: { type: FormStructureType.FormControl, value: false },
              lineItems: { type: FormStructureType.FormArray, value: _.clone(ProductLineItemStructure) },
              cost: { type: FormStructureType.FormGroup, value: _.cloneDeep(FinancialStructure) },
              collectionOfAshes: {
                type: FormStructureType.FormGroup,
                value: {
                  required: { type: FormStructureType.FormControl, value: false },
                  instructions: { type: FormStructureType.FormControl },
                  recipients: { type: FormStructureType.FormArray, value: _.cloneDeep(FormCremationAshesRecipientStructure) },
                  applicantSignature: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormSignatureStructure) },
                },
              },
            }
          },
          additionalItems: {
            type: FormStructureType.FormGroup,
            value: {
              items: { type: FormStructureType.FormArray, value: _.cloneDeep(FormItemStructure) },
              cost: { type: FormStructureType.FormGroup, value: _.cloneDeep(FinancialStructure) },
            }
          },
        }
      },
      birthsDeathsAndMarriages: {
        type: FormStructureType.FormGroup,
        value: {
          apiTarget: { type: FormStructureType.FormControl  },
          autoCost: { type: FormStructureType.FormControl, value: false },
          nsw: { 
            type: FormStructureType.FormGroup, 
            value: _.cloneDeep(FormBirthsDeathsAndMarriagesNswStructure),
          },
          vic: { 
            type: FormStructureType.FormGroup, 
            value: _.cloneDeep(FormBirthsDeathsAndMarriagesVicStructure),
          },
          qld: { 
            type: FormStructureType.FormGroup, 
            value: _.cloneDeep(FormBirthsDeathsAndMarriagesQldStructure),
          },
        }
      },
      documentLibrary: {
        type: FormStructureType.FormGroup,
        value: _.cloneDeep(FormDocumentLibraryStructure),
      },
      statutoryDeclaration: {
        type: FormStructureType.FormGroup,
        value: {
          applicant: { 
            type: FormStructureType.FormGroup, 
            value: _.cloneDeep(FormContactStructure),
            valueAs: FormStructureValueType.Contact 
          },
          applicantSignature: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormSignatureStructure) },
          applicantType: { type: FormStructureType.FormControl },
          applicantRelationshipToDeceased: { type: FormStructureType.FormControl },
          
          deathDueToViolence: { type: FormStructureType.FormControl },
          deathDueToPoison: { type: FormStructureType.FormControl },
          deathDueToAbuse: { type: FormStructureType.FormControl },
          deathDueToDrowning: { type: FormStructureType.FormControl },
          deathDueToSuffocation: { type: FormStructureType.FormControl },
          deathDueToBurns: { type: FormStructureType.FormControl },
          deathDueToDuringCustodialCare: { type: FormStructureType.FormControl },
          deathDueToIllegalOperation: { type: FormStructureType.FormControl },

          examinationRequired: { type: FormStructureType.FormControl },
          reasonApplicationIsBeingMade: { type: FormStructureType.FormControl },
          writtenAuthority: { type: FormStructureType.FormControl },
          
          applicantWasRequestedBy: { type: FormStructureType.FormControl },
          requestersRelationshipToDeceased: { type: FormStructureType.FormControl },
          
          allRelativesHaveBeenInformed: { type: FormStructureType.FormControl },
          anyRelativesHaveObjected: { type: FormStructureType.FormControl },
          reasonForObjection: { type: FormStructureType.FormControl },

          writtenDirections: { type: FormStructureType.FormControl },
          writtenDirectionsDetails: { type: FormStructureType.FormControl },
          writtenDirectionsMadeOfSoundMind: { type: FormStructureType.FormControl },

          witnessName: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormNameStructure) },
          witnessDateOfBirth: { type: FormStructureType.FormControl, valueAs: FormStructureValueType.Date },
          witnessQualification: { type: FormStructureType.FormControl },
          witnessSignature: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormSignatureStructure) },
          witnessDeceasedViewing: { type: FormStructureType.FormControl },
          witnessDeceasedIdentification: { type: FormStructureType.FormControl },
          witnessDeceasedIdentificationDocument: { type: FormStructureType.FormControl },

          attendingMedicalPractitionerName: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormNameStructure) },
          attendingMedicalPractitionerAddress: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormAddressStructure), valueAs: FormStructureValueType.Address },

          medicalPractitionerName: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormNameStructure) },
          medicalPractitionerAddress: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormAddressStructure), valueAs: FormStructureValueType.Address },

          registryName: { type: FormStructureType.FormControl },

          batteryPoweredDevicePresent: { type: FormStructureType.FormControl },
          batteryPoweredDeviceType: { type: FormStructureType.FormControl },
          batteryPoweredDeviceIsRemoved: { type: FormStructureType.FormControl },
          batteryPoweredDevicePermissionToRemove: { type: FormStructureType.FormControl },

          radiopharmaceuticalsPresent: { type: FormStructureType.FormControl },
          radiopharmaceuticalsType: { type: FormStructureType.FormControl },

          placeOfDeclaration: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormAddressStructure), valueAs: FormStructureValueType.Address },
          dateOfDeclaration: { type: FormStructureType.FormControl, valueAs: FormStructureValueType.Date },

        }
      },
      invoice: {
        type: FormStructureType.FormGroup,
        value: {
          projectId: { type: FormStructureType.FormControl },
          referenceId: { type: FormStructureType.FormControl },
          url: { type: FormStructureType.FormControl },
          charity: { type: FormStructureType.FormControl },
          serviceFee: {
            type: FormStructureType.FormGroup,
            value: {
              included: { type: FormStructureType.FormControl, value: serviceFeeIncludedValue[0] },
              percent: { type: FormStructureType.FormControl, value: defaultServiceFee },
              other: { type: FormStructureType.FormControl },
            }
          },
          accountContact: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormContactStructure) },
          authoriserContact: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormContactStructure) },
          authoriserSignature: { type: FormStructureType.FormGroup, value: _.cloneDeep(FormSignatureStructure) },
          arrangementInvoice: { type: FormStructureType.FormGroup, value: _.cloneDeep({}) },
          additionalInvoices: {
            type: FormStructureType.FormArray, 
            value: {           
              _meta: { type: FormStructureType.FormGroup, value: _.cloneDeep(MetaStructure) },
              invoice: { type: FormStructureType.FormGroup, value: _.cloneDeep({}) },
            }
          },
        },
      },
      notes: {
        type: FormStructureType.FormGroup,
        value: {
          firstCall: { type: FormStructureType.FormArray, value: _.cloneDeep(NoteStructure) },
          bookArrangementMeeting: { type: FormStructureType.FormArray, value: _.cloneDeep(NoteStructure) },
          contacts: { type: FormStructureType.FormArray, value: _.cloneDeep(NoteStructure) },
          eventDetails: { type: FormStructureType.FormArray, value: _.cloneDeep(NoteStructure) },
          preparationDetails: { type: FormStructureType.FormArray, value: _.cloneDeep(NoteStructure) },
          disposalDetails: { type: FormStructureType.FormArray, value: _.cloneDeep(NoteStructure) },
          bdm: { type: FormStructureType.FormArray, value: _.cloneDeep(NoteStructure) },
          statutoryDeclaration: { type: FormStructureType.FormArray, value: _.cloneDeep(NoteStructure) },
          newspaperNotifications: { type: FormStructureType.FormArray, value: _.cloneDeep(NoteStructure) },
          costSummary: { type: FormStructureType.FormArray, value: _.cloneDeep(NoteStructure) },
          documentLibrary: { type: FormStructureType.FormArray, value: _.cloneDeep(NoteStructure) },
        }
      },
      tasks: {
        type: FormStructureType.FormGroup,
        value: {
          firstCall: { type: FormStructureType.FormArray, value: _.cloneDeep(TaskStructure) },
          bookArrangementMeeting: { type: FormStructureType.FormArray, value: _.cloneDeep(TaskStructure) },
          contacts: { type: FormStructureType.FormArray, value: _.cloneDeep(TaskStructure) },
          eventDetails: { type: FormStructureType.FormArray, value: _.cloneDeep(TaskStructure) },
          preparationDetails: { type: FormStructureType.FormArray, value: _.cloneDeep(TaskStructure) },
          disposalDetails: { type: FormStructureType.FormArray, value: _.cloneDeep(TaskStructure) },
          bdm: { type: FormStructureType.FormArray, value: _.cloneDeep(TaskStructure) },
          statutoryDeclaration: { type: FormStructureType.FormArray, value: _.cloneDeep(TaskStructure) },
          newspaperNotifications: { type: FormStructureType.FormArray, value: _.cloneDeep(TaskStructure) },
          costSummary: { type: FormStructureType.FormArray, value: _.cloneDeep(TaskStructure) },
          documentLibrary: { type: FormStructureType.FormArray, value: _.cloneDeep(TaskStructure) },
        }
      }
    };

    if (!data) {

      data = {};

    }

    const formGenerator = new FormGenerator();

    // this.form should always be set, but if not lets do it now
    if (!this.form) {

      this.form = new FormGroup({});

    }
    
    formGenerator.generate(formStructure, data, this.form);

    this.validateLinkedTransfersSource.next(validateLinkedTransfers(this));

    this.formValidation = new FormValidation(this.form, this.modalService);

    this.formValueChanges();

  }

  private findSourceGroupsForSource(sourceId: any, sourceGroups: any): any {
    
    const allSourceGroups: {[key: string]: any} = {};
    if (!sourceGroups || !sourceGroups?.length) {
      return allSourceGroups;
    }
    
    for (const group  of sourceGroups) {
      const isFound = group.value?.find((source:any) => {return sourceId === source.value });
      if (isFound) allSourceGroups[group._meta.id] = group;
    }

    return allSourceGroups;
    
  }

  private postDataAsFormData(data: any): Observable<any> {

    return new Observable((subscriber) => {

      const go = async () => {

        try {

          const getOptionValueFromValue = (data: any, path: string, options: { name: string, value: string | number }[], defaultValue: any): any => {

            const pathValue = _.get(data, path, defaultValue);
  
            let destinationPath = path;
  
            if (path.endsWith('.value')) {
  
              destinationPath = path.substring(0, path.length - 6);
  
            }
  
            if (typeof pathValue === 'string') {
  
              const index = _.findIndex(options, option => option.value.toString().toLowerCase() === pathValue.toString().toLowerCase());
    
              if (index > -1) {
    
                _.set(data, destinationPath, options[index]);
    
              }
  
            } else {
  
              const index = _.findIndex(options, option => option.value === pathValue);
    
              if (index > -1) {
    
                _.set(data, destinationPath, options[index]);
    
              }
  
            }
  
            return data;
  
          }
  
          const getFirstCallRecipientUser = (data: any): any => {
    
            const firstCallRecipient: User | undefined = _.find(this.userService.allUsersSourceValue, user => {
        
              const id = _.get(data, 'firstCall.recipient.id', null);
  
              if (id) {
        
                return user.data.id === id;
        
              } else {
        
                return false;
        
              }
        
            });
      
            if (firstCallRecipient) {
      
              _.set(data, 'firstCall.recipient', firstCallRecipient);
        
            }
      
            return data;
      
          };
      
          const getFirstCallSourceOption = (data: any): Observable<any> => {
      
            return new Observable(subscriber => {
      
              const sourceValue = _.get(data, 'firstCall.source');
      
              if (!sourceValue) {
  
                _.set(data, 'firstCall.source', []);
      
                subscriber.next(data);
                subscriber.complete();
      
              } else {
      
                // No need to unsubscribe because this uses pipe(first())
                this.account$.pipe(
                  first(),
                  map(account => account.data.firstCall.source),
                ).subscribe({
                  next: accountSources => {
      
                    if (!Array.isArray(sourceValue)) {
  
                      /**
                       * This is used to convert the old arrangements that didn't use an array. After this, they'll
                       * be compatible with the updated arrangement process
                       */
  
                      const sourceIndex = _.findIndex(accountSources, (source: any) => source.value === sourceValue);
        
                      if (sourceIndex > -1) {
        
                        _.set(data, 'firstCall.source', [accountSources[sourceIndex]]);
          
                      }
  
                    } else {
  
                      const sources: any[] = [];
  
                      for (const source of sourceValue) {
  
                        const sourceIndex = _.findIndex(accountSources, (accountSource: any) => {

                          let found = false;

                          if (_.has(source, '_meta.id')) {

                            found = accountSource._meta.id === source._meta.value;

                          } else {

                            found = accountSource._meta.id === source;

                          }

                          return found;

                        });
        
                        if (sourceIndex > -1) {
          
                          sources.push(accountSources[sourceIndex]);
            
                        }
  
                      }
  
                      _.set(data, 'firstCall.source', sources);
  
                    }
      
                    subscriber.next(data);
                    subscriber.complete();
      
                  }
                })
      
              }
      
            });
      
          };

          const getFirstCallSourceGroupOption = (data: any): Observable<any> => {

            return new Observable(subscriber => {

              const sourceValue = _.get(data, 'firstCall.source');
              if (!sourceValue ) {

                _.set(data, 'firstCall.sourceGroup', []);
      
                subscriber.next(data);
                subscriber.complete();
              } else {

                this.account$.pipe(
                  first(),
                  map(account => account.data.firstCall.sourceGroup),
                ).subscribe({
                  next: accountSourceGroups => {
                                        
                    var sourceGroups: any = {};
                    for (const source of sourceValue) {
                      const parents = this.findSourceGroupsForSource(source._meta.id, accountSourceGroups);
                      sourceGroups = {...sourceGroups, ...parents};

                    }

                    _.set(data, 'firstCall.sourceGroup', Object.values(sourceGroups));
                  
                    subscriber.next(data);
                    subscriber.complete();
      
                  }
                })

              }

            });
      
          };
      
          const getDeceasedPlaceOfPassingAutoFillOption = (data: any): Observable<any> => {
      
            return new Observable(subscriber => {
      
              const deceasedPlaceOfPassingAutoFillId = _.get(data, 'deceased.placeOfPassing.autoFill.id', null);
      
              if (!deceasedPlaceOfPassingAutoFillId) {
  
                // Setting the value to NULL is valid. This will set the select menu to be empty (NULL)
                _.set(data, 'deceased.placeOfPassing.autoFill', null);
      
                subscriber.next(data);
                subscriber.complete();
      
              } else {
      
                // No need to unsubscribe because this uses pipe(first())
                this.account$.pipe(
                  first(),
                  map(account => account.data.contacts),
                ).subscribe({
                  next: contacts => {
      
                    const contactIndex = _.findIndex(contacts, (contact: any) => contact._meta.id === deceasedPlaceOfPassingAutoFillId);
      
                    if (contactIndex > -1) {
      
                      _.set(data, 'deceased.placeOfPassing.autoFill', contacts[contactIndex]);
        
                    }
      
                    subscriber.next(data);
                    subscriber.complete();
      
                  }
                })
      
              }
      
            });
      
          };
      
          const getContactFromPath = (data: any, path: string, relation?: RelationToDeceased): any => {
      
            // const id = _.get(data, `${path}.id`);
      
            // const contacts = (this.contactsSource.value.length) ? this.contactsSource.value : [];
  
            // let contact: Contact | undefined;
  
            // if (id) {
  
            //   contact = _.find(contacts, (c: Contact) => c._meta.id === id);
  
            // } else if (relation) {
  
            //   contact = _.find(contacts, (c: Contact) => c.relationships.includes(relation));
  
            // }
  
            // if (contact) {
  
            //   const formGenerator = new FormGenerator();
  
            //   const tempFormGroup = formGenerator.generate(FormContactStructure, contact.form.value);
  
            //   _.set(data, path, tempFormGroup);
      
            // }
      
            return data;
      
          };
  
          const processAdditionalContacts = (data: any): any => {
  
            let additionalContacts = _.get(data, 'additionalContacts', []);
      
            if (additionalContacts.length) {
  
              for (let i in additionalContacts) {
  
                const data: any = getContactFromPath(additionalContacts[i], 'contact');
  
                if (_.has(data, 'contact')) {
  
                  _.set(additionalContacts[i], 'contact', data.contact);
  
                }
  
              }
  
              _.set(data, 'additionalContacts', additionalContacts);
  
            }
  
            return data;
  
          };
      
          const getTransferServices = (data: any): any => {
      
            const hasTransfers = _.has(data, 'transfer.transfers');
  
            if (hasTransfers) {
      
              for (let transfer of data.transfer.transfers) {
      
                const serviceProviderId = _.get(transfer, 'service.serviceProviderId', null);
                const serviceId = _.get(transfer, 'service.service.id', null);
  
                if (!serviceProviderId || !serviceId) {
  
                  transfer.service = null;
  
                } else {
  
                  const service = this.serviceProviderService.getServiceById(serviceProviderId, serviceId);
        
                  if (service) {
        
                    transfer.service = service;
        
                  }
  
                }

                // console.log('2nd Note: ', transfer.addTo2ndNote);
                // console.log('2nd Note - type: ', transfer.type);
                // console.log('2nd Note - eventIndex: ', transfer.eventIndex);
                // console.log('Pickup dateOfPickup: ', transfer.from.dateOfPickup);
                // console.log('Pickup dateOfPickupFrom: ', transfer.from.dateOfPickupFrom);
                // console.log('Pickup dateOfPickupTo: ', transfer.from.dateOfPickupTo);
                // console.log('Delivery date: ', transfer.to.date);

                // transfer = getOptionValueFromValue(transfer, 'dateOfPickupType', dateOfPickupTypeValue, 'On');
                
                // console.log(transfer);

                const addTo2ndNote = _.get(transfer, 'addTo2ndNote', null);
                const type = _.get(transfer, 'type', null);
                const eventId = _.get(transfer, 'eventId', null);
                const _addressType = _.get(transfer, 'from._addressType', null);
                const _addressTypeServiceProviderType = _.get(transfer, 'from._addressTypeServiceProviderType', null);
                const _addressTypeServiceProvider = _.get(transfer, 'from._addressTypeServiceProvider', null);
                const _addressTypeExistingTransfer = _.get(transfer, 'from._addressTypeExistingTransfer', null);
                const _addressTypeExistingTransferAddressType = _.get(transfer, 'from._addressTypeExistingTransferAddressType', null);

                const transferEventIndex = _.get(transfer, 'eventIndex', null);

                if (addTo2ndNote) {

                  transfer = getOptionValueFromValue(transfer, 'addTo2ndNote', secondNoteTransferTypesValue, secondNoteTransferType.Collection);

                }

                if (type) {

                  transfer = getOptionValueFromValue(transfer, 'type', transferTypesValue, TransferType.Unknown);

                }

                if (_addressType) {

                  transfer = getOptionValueFromValue(transfer, 'from._addressType', transferFromAddressTypesValue, TransferFromAddressType.Custom);

                }

                if (_addressTypeServiceProviderType) {

                  transfer = getOptionValueFromValue(transfer, 'from._addressTypeServiceProviderType', serviceTypesFormValue, null);

                }

                if (_addressTypeServiceProvider) {

                  const serviceProviderId = _.get(transfer, 'from._addressTypeServiceProvider.serviceProviderId', null);
                  const serviceId = _.get(transfer, 'from._addressTypeServiceProvider.service.id', null);

                  if (!serviceProviderId || !serviceId) {
  
                    transfer.from._addressTypeServiceProvider = null;
    
                  } else {
    
                    const service = this.serviceProviderService.getServiceById(serviceProviderId, serviceId);
          
                    if (service) {
          
                      transfer.from._addressTypeServiceProvider = service;
          
                    }
    
                  }

                }

                if (_addressTypeExistingTransfer || _addressTypeExistingTransferAddressType || eventId) {

                  // This is done within shared/forms/components/transfer-item/transfer-item.component.ts because
                  // These values are dynamic and change based on which transfer has been selected. Because of that, we need to set the value onInit within the TransferItemComponent
                  
                }

                if (transferEventIndex) {

                  transfer = getOptionValueFromValue(transfer, 'eventIndex', eventIndexesValue, 'First');

                }
      
                const date = _.get(transfer, 'date', null);
  
                // If we use the deprecated `date` property we need to convert it to `dateOfPickup`
                if (date) {
  
                 transfer.dateOfPickup = date;
                 transfer.date = null;
  
                }
  
              }
  
            }
      
            return data;
      
          };
      
          const getArrangementMeetingArrangerUser = (data: any): any => {
    
            const arrangementMeetingArranger: User | undefined = _.find(this.userService.allUsersSourceValue, user => {
        
              const id = _.get(data, 'arrangementMeeting.arranger.id', null);
  
              if (id) {
        
                return user.data.id === id;
        
              } else {
        
                return false;
        
              }
        
            });
      
            if (arrangementMeetingArranger) {
      
              _.set(data, 'arrangementMeeting.arranger', arrangementMeetingArranger);
        
            }
      
            return data;
      
          };
  
          const getArrangerServiceProvider = (data: any): any => {
  
            return new Observable(subscriber => {
  
              // No need to unsubscribe because this uses pipe(first())
              this.account$.pipe(
                first(),
                map(account => account.data),
              ).subscribe({
                next: (accountData: { [key: string]: any }) => {
  
                  let service = _.get(data, 'arrangementMeeting.service', null);
  
                  if (service) {
  
                    const serviceProviderId = _.get(service, 'serviceProviderId', null);
                    const serviceMetaId = _.get(service, 'service.id', null);
  
                    if (serviceProviderId !== null && serviceMetaId !== null) {
        
                      const service = this.serviceProviderService.getServiceById(serviceProviderId, serviceMetaId);
      
                      if (service) {
      
                        _.set(data, 'arrangementMeeting.service', service);
      
                      }
      
                    } else {
      
                      _.unset(data, 'arrangementMeeting.service');
      
                    }
  
                  }
  
                  subscriber.next(data);
                  subscriber.complete();
  
                }
              });
  
            });
  
          }
  
          const getArrangementMeetingAttendees = (data: any): any => {
      
            const attendees = _.get(data, 'arrangementMeeting.attendees', []);
      
            if (attendees.length) {
  
              for (let i in attendees) {
  
                const data: any = getContactFromPath(attendees[i], 'contact');
  
                if (_.has(data, 'contact')) {
  
                  _.set(attendees[i], 'contact', data.contact);
  
                }
  
              }
  
              _.set(data, 'arrangementMeeting.attendees', attendees);
  
            }
      
            return data;
      
          };
  
          const processPlaceOfPassing = (data: any): any => {
  
            return new Observable(subscriber => {
      
              const deceasedPlaceOfPassingTypeValue = _.get(data, 'deceased.placeOfPassing.type.value');
              const deceasedPlaceOfPassingHospitalOrNursingHomeValue = _.get(data, 'deceased.placeOfPassing.hospitalOrNursingHome.id');
      
              const typeIndex = _.findIndex(placeOfPassingTypeValue, (v: any) => v.value === deceasedPlaceOfPassingTypeValue);
  
              if (typeIndex > -1) {
  
                _.set(data, 'deceased.placeOfPassing.type', placeOfPassingTypeValue[typeIndex]);
  
              }
  
              if (deceasedPlaceOfPassingHospitalOrNursingHomeValue === -1) {
  
                subscriber.next(data);
                subscriber.complete();
  
              } else {
  
                // No need to unsubscribe because this uses pipe(first())
                this.account$.pipe(
                  first(),
                  map(account => account.data.hospitalAndNursingHomes),
                ).subscribe({
                  next: hospitalAndNursingHomes => {
      
                    const hospitalAndNursingHomeIndex = _.findIndex(hospitalAndNursingHomes, (hospitalAndNursingHome: any) => hospitalAndNursingHome._meta.id === deceasedPlaceOfPassingHospitalOrNursingHomeValue);
      
                    if (hospitalAndNursingHomeIndex > -1) {
      
                      _.set(data, 'deceased.placeOfPassing.hospitalOrNursingHome', hospitalAndNursingHomes[hospitalAndNursingHomeIndex]);
        
                    }
      
                    subscriber.next(data);
                    subscriber.complete();
      
                  }
                });
  
              }
              
            });
  
          };
  
          const processEvents = (data: any): any => {
  
            const __processItemServiceType = (item: any) => {
  
              const eventServiceTypeIndex = _.findIndex(eventServiceTypesFormValue, eventServiceType => eventServiceType.value === item.serviceType.value);
  
              if (eventServiceTypeIndex > -1) {
  
                const st = eventServiceTypesFormValue[eventServiceTypeIndex];
  
                _.set(item, 'serviceType', st);
  
              } else {
  
                const st = eventServiceTypesFormValue[9]; // eventTypes[9] is the Miscellaneous service type. It's a good default if we don't find an index
  
                _.set(item, 'serviceType', st); 
  
              }
  
              return item;
  
            };
  
            const _processEventType = (event: any, eventTypes: any): any => {
  
              const eventTypeIndex = _.findIndex(eventTypes, (eventType: any) => {
                return _.get(eventType, '_meta.id') === _.get(event, 'type.id', null);
              });
  
              if (eventTypeIndex > -1) {
  
                _.set(event, 'type', eventTypes[eventTypeIndex]);
  
              } else {
  
                _.set(event, 'type', eventTypes[0]); // eventTypes[0] is the Miscellaneous event type. It's a good default if we don't find an index
  
              }
  
              return event;
  
            };
  
            const _processEventItems = (event: any): any => {
  
              let items: any[] = _.get(event, 'items', []);
  
              items = _.map(items, (item: any) => {
  
                const serviceProviderId = _.get(item, 'service.serviceProviderId', null);
                const serviceMetaId = _.get(item, 'service.service.id', null);
                const serviceType = _.get(item, 'serviceType', null);
  
                if (serviceProviderId !== null && serviceMetaId !== null) {
  
                  const service = this.serviceProviderService.getServiceById(serviceProviderId, serviceMetaId);
  
                  if (service) {
  
                    _.set(item, 'service', service);
  
                  }
  
                } else {
  
                  _.unset(item, 'service');
                  // _.unset(item, 'cost');
                  // _.unset(item, 'lineItems');
  
                }
  
                if (serviceType) {
  
                  item = __processItemServiceType(item);
                  
                } else {
  
                  _.unset(item, 'serviceType');
  
                }
  
                return item;
  
              });
              
              _.set(event, 'items', items);
  
              return event;
  
            };
  
            const _processVenue = (event: any): any => {
  
              let venue = _.get(event, 'venue', null);
  
              if (venue && venue.isServiceProviderVenue) {
  
                const serviceProviderId = _.get(venue, 'service.serviceProviderId', null);
                const serviceMetaId = _.get(venue, 'service.service.id', null);
  
                if (serviceProviderId !== null && serviceMetaId !== null) {
  
                  const service = this.serviceProviderService.getServiceById(serviceProviderId, serviceMetaId);
  
                  if (service) {
  
                    _.set(venue, 'service', service);
  
                  }
  
                } else {
  
                  _.unset(event, 'venue');
  
                }
  
              }
  
              return event;
  
            };
  
            return new Observable(subscriber => {
  
              let events = _.get(data, 'events', []);
  
              if (events.length === 0) {
  
                subscriber.next(data);
                subscriber.complete();
  
                return;
  
              }
  
              // No need to unsubscribe because this uses pipe(first())
              this.account$.pipe(
                first(),
                map(account => account.data),
              ).subscribe({
                next: (accountData: { [key: string]: any }) => {
  
                  events = _.map(events, (event: any) => {
      
                    event = _processEventType(event, accountData.eventTypes);
                    event = _processEventItems(event);
                    event = _processVenue(event);
  
                    return event;
      
                  });
  
                  _.set(data, 'events', events);
  
                  subscriber.next(data);
                  subscriber.complete();
  
                }
              });
  
            });
  
          };
  
          const processMarriages = (data: any): any => {
  
            data = getOptionValueFromValue(data, 'marriage.statusAtPassing.value', nswBirthDeathsAndMarriagesMarriageStatusTypeValue, 'unknown')
  
            let marriages = _.get(data, 'marriage.marriages', []);
  
            marriages = _.map(marriages, marriage => {
      
              marriage = getOptionValueFromValue(marriage, 'type.value', nswBirthDeathsAndMarriagesMarriageTypeValue, 'unknown')
  
              marriage = getContactFromPath(marriage, 'spouse');
      
              return marriage;
      
            });
  
            _.set(data, 'marriage.marriages', marriages);
  
            return data;
  
          };
  
          const processChildren = (data: any): any => {
  
            let children = _.get(data, 'family.children', []);
  
            children = _.map(children, child => {
  
              child = getContactFromPath(child, 'child');
  
              return child;
  
            });
  
            _.set(data, 'family.children', children);
  
            return data;
  
          };
  
          const processParents = (data: any): any => {
  
            let parents = _.get(data, 'family.parents', []);
  
            parents = _.map(parents, parent => {
  
              parent = getOptionValueFromValue(parent, 'type.value', nswBirthDeathsAndMarriagesParentRelationshipTypeValue, 'PARENT')
  
              parent = getContactFromPath(parent, 'parent');
  
              return parent;
  
            });
  
            _.set(data, 'family.parents', parents);
  
            return data;
  
          };
  
          const processBirthsDeathsAndMarriagesNsw = (data: any) => {
            
            const getServiceProvider = (d: any) => {
  
              const serviceProviderId = _.get(d, 'birthsDeathsAndMarriages.nsw.service.serviceProviderId', null);
              const serviceMetaId = _.get(d, 'birthsDeathsAndMarriages.nsw.service.service.id', null);
  
              if (serviceProviderId !== null && serviceMetaId !== null) {
  
                const service = this.serviceProviderService.getServiceById(serviceProviderId, serviceMetaId);
  
                if (service) {
  
                  _.set(d, 'birthsDeathsAndMarriages.nsw.service', service);
  
                }
  
              }
  
              return d;
  
            };
  
            data = getOptionValueFromValue(data, 'birthsDeathsAndMarriages.nsw.informantPreferredRelationship.value', relationshipTypesFormValue, 'unknown');
            
            data = getOptionValueFromValue(data, 'birthsDeathsAndMarriages.nsw.certificateType.value', nswBirthDeathsAndMarriagesCertificateTypeValue, nswBirthDeathsAndMarriagesCertificateTypeType.eNDO);
  
            data = getOptionValueFromValue(data, 'birthsDeathsAndMarriages.nsw.deliveryType.value', nswBirthDeathsAndMarriagesDeliveryTypeValue, nswBirthDeathsAndMarriagesDeliveryTypeType.RegisteredMail);
  
            data = getServiceProvider(data);
  
            data = getContactFromPath(data, 'birthsDeathsAndMarriages.nsw.informant');
            data = getContactFromPath(data, 'birthsDeathsAndMarriages.nsw.witness');
            data = getContactFromPath(data, 'birthsDeathsAndMarriages.nsw.deliveryContact');
  
            return data;
  
          };
  
          const processBirthsDeathsAndMarriagesQld = (data: any) => {

            const getServiceProvider = (d: any) => {
  
              const serviceProviderId = _.get(d, 'birthsDeathsAndMarriages.qld.service.serviceProviderId', null);
              const serviceMetaId = _.get(d, 'birthsDeathsAndMarriages.qld.service.service.id', null);
  
              if (serviceProviderId !== null && serviceMetaId !== null) {
  
                const service = this.serviceProviderService.getServiceById(serviceProviderId, serviceMetaId);
  
                if (service) {
  
                  _.set(d, 'birthsDeathsAndMarriages.qld.service', service);
  
                }
  
              }
  
              return d;
  
            };
  
            data = getOptionValueFromValue(data, 'birthsDeathsAndMarriages.qld.informantPreferredRelationship.value', relationshipTypesFormValue, 'unknown');
            
            data = getOptionValueFromValue(data, 'birthsDeathsAndMarriages.qld.certificateType.value', nswBirthDeathsAndMarriagesCertificateTypeValue, nswBirthDeathsAndMarriagesCertificateTypeType.eNDO);
  
            data = getOptionValueFromValue(data, 'birthsDeathsAndMarriages.qld.deliveryType.value', nswBirthDeathsAndMarriagesDeliveryTypeValue, nswBirthDeathsAndMarriagesDeliveryTypeType.RegisteredMail);
  
            data = getServiceProvider(data);
  
            data = getContactFromPath(data, 'birthsDeathsAndMarriages.qld.informant');
            data = getContactFromPath(data, 'birthsDeathsAndMarriages.qld.witness');
            data = getContactFromPath(data, 'birthsDeathsAndMarriages.qld.deliveryContact');

            return data;

          };
  
          const processBirthsDeathsAndMarriagesVic = (data: any) => {

            const getServiceProvider = (d: any) => {
  
              const serviceProviderId = _.get(d, 'birthsDeathsAndMarriages.vic.service.serviceProviderId', null);
              const serviceMetaId = _.get(d, 'birthsDeathsAndMarriages.vic.service.service.id', null);
  
              if (serviceProviderId !== null && serviceMetaId !== null) {
  
                const service = this.serviceProviderService.getServiceById(serviceProviderId, serviceMetaId);
  
                if (service) {
  
                  _.set(d, 'birthsDeathsAndMarriages.vic.service', service);
  
                }
  
              }
  
              return d;
  
            };
  
            data = getOptionValueFromValue(data, 'birthsDeathsAndMarriages.vic.informantPreferredRelationship.value', relationshipTypesFormValue, 'unknown');
            
            data = getOptionValueFromValue(data, 'birthsDeathsAndMarriages.vic.certificateType.value', nswBirthDeathsAndMarriagesCertificateTypeValue, nswBirthDeathsAndMarriagesCertificateTypeType.eNDO);
  
            data = getOptionValueFromValue(data, 'birthsDeathsAndMarriages.vic.deliveryType.value', nswBirthDeathsAndMarriagesDeliveryTypeValue, nswBirthDeathsAndMarriagesDeliveryTypeType.RegisteredMail);
  
            data = getServiceProvider(data);
  
            data = getContactFromPath(data, 'birthsDeathsAndMarriages.vic.informant');
            data = getContactFromPath(data, 'birthsDeathsAndMarriages.vic.witness');
            data = getContactFromPath(data, 'birthsDeathsAndMarriages.vic.deliveryContact');

            return data;

          };
  
          const processNewspaperNotifications = (data: any): any => {
  
            return new Observable(subscriber => {
  
              let newspaperNotifications = _.get(data, 'newspaperNotifications', []);
  
              if (newspaperNotifications.length === 0) {
  
                subscriber.next(data);
                subscriber.complete();
  
                return;
  
              }
  
              // No need to unsubscribe because this uses pipe(first())
              this.account$.pipe(
                first(),
                map(account => account.data),
              ).subscribe({
                next: (accountData: { [key: string]: any }) => {
  
                  newspaperNotifications = _.map(newspaperNotifications, (newspaperNotification: any) => {
      
                    let service = _.get(newspaperNotification, 'service', null);
  
                    if (service) {
        
                      const serviceProviderId = _.get(service, 'serviceProviderId', null);
                      const serviceMetaId = _.get(service, 'service.id', null);
        
                      if (serviceProviderId !== null && serviceMetaId !== null) {
        
                        const service = this.serviceProviderService.getServiceById(serviceProviderId, serviceMetaId);
        
                        if (service) {
        
                          _.set(newspaperNotification, 'service', service);
        
                        }
        
                      }
        
                    }
  
                    return newspaperNotification;
      
                  });
  
                  _.set(data, 'newspaperNotifications', newspaperNotifications);
  
                  subscriber.next(data);
                  subscriber.complete();
  
                }
              });
  
            });
  
          };
  
          const processCauseOfDeath = (data: any): any => {
            
            data = getOptionValueFromValue(data, 'causeOfDeath.type.value', CODCertificationTypeValue, 'MCCD');
  
            return data;
  
          };
  
          const processDisposal = (data: any): any => {
  
            return new Observable(subscriber => {
  
              // No need to unsubscribe because this uses pipe(first())
              this.account$.pipe(
                first(),
                map(account => account.data),
              ).subscribe({
                next: (accountData: { [key: string]: any }) => {
  
                  data = getOptionValueFromValue(data, 'disposal.type.value', methodOfDisposalTypeValue, 'Cremated');
  
                  let burialService = _.get(data, 'disposal.burial.service', null);
                  let cremationService = _.get(data, 'disposal.cremation.service', null);
            
                  let additionalItems = _.get(data, 'disposal.additionalItems.items', []);
  
                  if (burialService) {
  
                    const serviceProviderId = _.get(burialService, 'serviceProviderId', null);
                    const serviceMetaId = _.get(burialService, 'service.id', null);
  
                    if (serviceProviderId !== null && serviceMetaId !== null) {
  
                      const service = this.serviceProviderService.getServiceById(serviceProviderId, serviceMetaId);
        
                      if (service) {
        
                        _.set(data, 'disposal.burial.service', service);
        
                      }
        
                    }
  
                  }
  
                  if (cremationService) {
  
                    const serviceProviderId = _.get(cremationService, 'serviceProviderId', null);
                    const serviceMetaId = _.get(cremationService, 'service.id', null);
  
                    if (serviceProviderId !== null && serviceMetaId !== null) {
  
                      const service = this.serviceProviderService.getServiceById(serviceProviderId, serviceMetaId);
        
                      if (service) {
        
                        _.set(data, 'disposal.cremation.service', service);
        
                      }
        
                    }
  
                    // let additionalContacts = _.get(data, 'additionalContacts', []);
      
                    // if (additionalContacts.length) {
          
                    //   for (let i in additionalContacts) {
          
                    //     const data: any = getContactFromPath(additionalContacts[i], 'contact');
          
                    //     if (_.has(data, 'contact')) {
          
                    //       _.set(additionalContacts[i], 'contact', data.contact);
          
                    //     }
          
                    //   }
          
                    //   _.set(data, 'additionalContacts', additionalContacts);
          
                    // }
  
                  }
  
                  if (additionalItems) {
  
                    additionalItems = _.map(additionalItems, additionalItem => {
  
                      const serviceProviderId = _.get(additionalItem, 'service.serviceProviderId', null);
                      const serviceMetaId = _.get(additionalItem, 'service.service.id', null);
                      const serviceType = _.get(additionalItem, 'serviceType', null);
        
                      if (serviceProviderId !== null && serviceMetaId !== null) {
        
                        const service = this.serviceProviderService.getServiceById(serviceProviderId, serviceMetaId);
        
                        if (service) {
        
                          _.set(additionalItem, 'service', service);
        
                        }
        
                      } else {
        
                        _.unset(additionalItem, 'service');
        
                      }
        
                      if (serviceType) {
  
                        additionalItem = getOptionValueFromValue(additionalItem, 'serviceType.value', serviceTypesFormValue, DynamicComponents.Miscellaneous);
                        
                      } else {
        
                        _.unset(additionalItem, 'serviceType');
        
                      }
        
                      return additionalItem;
  
                    });
  
                    _.set(data, 'disposal.additionalItems.items', additionalItems);
  
                  }
  
                  let service = _.get(data, 'disposal.service', null);
  
                  if (service) {
      
                    const serviceProviderId = _.get(service, 'serviceProviderId', null);
                    const serviceMetaId = _.get(service, 'service.id', null);
      
                    if (serviceProviderId !== null && serviceMetaId !== null) {
      
                      const service = this.serviceProviderService.getServiceById(serviceProviderId, serviceMetaId);
      
                      if (service) {
      
                        _.set(data, 'disposal.service', service);
      
                      }
      
                    }
      
                  }
  
                  let collectionOfAshesRecipients = _.get(data, 'disposal.cremation.collectionOfAshes.recipients', []);
      
                  if (collectionOfAshesRecipients.length) {
        
                    for (let i in collectionOfAshesRecipients) {
        
                      const data: any = getContactFromPath(collectionOfAshesRecipients[i], 'contact');
        
                      if (_.has(data, 'contact')) {
        
                        _.set(collectionOfAshesRecipients[i], 'contact', data.contact);
        
                      }
        
                    }
        
                    _.set(data, 'disposal.cremation.collectionOfAshes.recipients', collectionOfAshesRecipients);
  
                  }
  
                  data = getContactFromPath(data, 'disposal.bodyDonation.contact');
                  data = getContactFromPath(data, 'disposal.bodyDonation.contact');
                  data = getContactFromPath(data, 'disposal.burial.burialApplicant');
                  data = getContactFromPath(data, 'disposal.burial.registeredHolderOfInterment');
                  data = getContactFromPath(data, 'disposal.repatriated.contact');
  
                  subscriber.next(data);
                  subscriber.complete();
  
                }
              });
  
            });
  
          };
  
          const processPreparation = (data: any): any => {
  
            return new Observable(subscriber => {
  
              // No need to unsubscribe because this uses pipe(first())
              this.account$.pipe(
                first(),
                map(account => account.data),
              ).subscribe({
                next: (accountData: { [key: string]: any }) => {
  
                  let service = _.get(data, 'preparation.service', null);
            
                  let additionalItems = _.get(data, 'preparation.additionalItems.items', []);
  
                  let jewelleryInstructions = _.get(data, 'preparation.jewellery.instructions', []);
                  let clothingInstructions = _.get(data, 'preparation.clothing.instructions', []);
  
                  if (service) {
  
                    const serviceProviderId = _.get(service, 'serviceProviderId', null);
                    const serviceMetaId = _.get(service, 'service.id', null);
  
                    if (serviceProviderId !== null && serviceMetaId !== null) {
        
                      const service = this.serviceProviderService.getServiceById(serviceProviderId, serviceMetaId);
      
                      if (service) {
      
                        _.set(data, 'preparation.service', service);
      
                      }
      
                    } else {
      
                      _.unset(data, 'preparation.service');
      
                    }
  
                  }
  
                  if (additionalItems) {
  
                    additionalItems = _.map(additionalItems, additionalItem => {
  
                      const serviceProviderId = _.get(additionalItem, 'service.serviceProviderId', null);
                      const serviceMetaId = _.get(additionalItem, 'service.service.id', null);
                      const serviceType = _.get(additionalItem, 'serviceType', null);
        
                      if (serviceProviderId !== null && serviceMetaId !== null) {
        
                        const service = this.serviceProviderService.getServiceById(serviceProviderId, serviceMetaId);
        
                        if (service) {
        
                          _.set(additionalItem, 'service', service);
        
                        }
        
                      } else {
        
                        _.unset(additionalItem, 'service');
        
                      }
        
                      if (serviceType) {
  
                        additionalItem = getOptionValueFromValue(additionalItem, 'serviceType.value', serviceTypesFormValue, DynamicComponents.Miscellaneous);
                        
                      } else {
        
                        _.unset(additionalItem, 'serviceType');
        
                      }
        
                      return additionalItem;
  
                    });
  
                    _.set(data, 'preparation.additionalItems.items', additionalItems);
  
                  }
  
                  data = getContactFromPath(data, 'preparation.jewellery.contact');
                  data = getContactFromPath(data, 'preparation.clothing.contact');
  
                  if (jewelleryInstructions) {
  
                    jewelleryInstructions = _.map(jewelleryInstructions, jewelleryInstruction => {
  
                      jewelleryInstruction = getContactFromPath(jewelleryInstruction, 'contact');
                      jewelleryInstruction = getOptionValueFromValue(jewelleryInstruction, 'type.value', jewelleryInstructionValue, null);
  
                      return jewelleryInstruction;
  
                    });
  
                    _.set(data, 'preparation.jewellery.instructions', jewelleryInstructions);
  
                  }
  
                  if (clothingInstructions) {
  
                    clothingInstructions = _.map(clothingInstructions, clothingInstruction => {
  
                      clothingInstruction = getContactFromPath(clothingInstruction, 'contact');
                      clothingInstruction = getOptionValueFromValue(clothingInstruction, 'type.value', clothingInstructionValue, null);
  
                      return clothingInstruction;
  
                    });
  
                    _.set(data, 'preparation.clothing.instructions', clothingInstructions);
  
                  }
  
                  subscriber.next(data);
                  subscriber.complete();
  
                }
              });
  
            });
  
          };
  
          const processInvoice = (data: any): any => {
  
            return new Observable(subscriber => {
  
              // No need to unsubscribe because this uses pipe(first())
              this.account$.pipe(
                first(),
                map(account => account.data),
              ).subscribe({
                next: (accountData: { [key: string]: any }) => {
  
                  const selectedCharityId = _.get(data, 'invoice.charity.id');
  
                  const charityIndex = _.findIndex(accountData.charities, (charity: any) => charity._meta.id === selectedCharityId);
      
                  if (charityIndex > -1) {
    
                    _.set(data, 'invoice.charity', accountData.charities[charityIndex]);
      
                  }
  
                  data = getOptionValueFromValue(data, 'invoice.serviceFee.included.value', serviceFeeIncludedValue, 'yes');
  
                  subscriber.next(data);
                  subscriber.complete();
  
                }
              });
  
            });
  
          };
  
          const processStatutoryDeclaration = (data: any): any => {
  
            data = getOptionValueFromValue(data, 'statutoryDeclaration.applicantRelationshipToDeceased.value', relationshipTypesFormValue, null);
  
            data = getOptionValueFromValue(data, 'statutoryDeclaration.requestersRelationshipToDeceased.value', relationshipTypesFormValue, null);
  
            const statutoryDeclarationAttendingMedicalPractitionerName = _.get(data, 'statutoryDeclaration.attendingMedicalPractitionerName', null);
            const statutoryDeclarationAttendingMedicalPractitionerAddress = _.get(data, 'statutoryDeclaration.attendingMedicalPractitionerAddress', null);
            
            const medicalCertificateAttendingMedicalPractitionerName = _.get(data, 'medicalCertificate.attendingPractitionerMedicalCertificateCauseOfDeath.name', null);
            const medicalCertificateAttendingMedicalPractitionerAddress = _.get(data, 'medicalCertificate.attendingPractitionerMedicalCertificateCauseOfDeath.address', null);

            // copy `statutoryDeclaration.attendingMedicalPractitionerName` to `medicalCertificate.attendingPractitionerMedicalCertificateCauseOfDeath.name`
            if (statutoryDeclarationAttendingMedicalPractitionerName && !_.get(medicalCertificateAttendingMedicalPractitionerName, 'first')) {
              
              _.set(data, 'medicalCertificate.attendingPractitionerMedicalCertificateCauseOfDeath.name', statutoryDeclarationAttendingMedicalPractitionerName);
              _.unset(data, 'statutoryDeclaration.attendingMedicalPractitionerName');
              
            }
            
            // copy `statutoryDeclaration.attendingMedicalPractitionerAddress` to `medicalCertificate.attendingPractitionerMedicalCertificateCauseOfDeath.address`
            if (statutoryDeclarationAttendingMedicalPractitionerAddress && !_.get(medicalCertificateAttendingMedicalPractitionerAddress, 'street')) {

              _.set(data, 'medicalCertificate.attendingPractitionerMedicalCertificateCauseOfDeath.address', statutoryDeclarationAttendingMedicalPractitionerAddress);
              _.unset(data, 'statutoryDeclaration.attendingMedicalPractitionerAddress');

            }

            return data;
            
          };

          const setDeprecatedTimeOfDeathToDateOfDeath = (data: any): any => {

            const timeOfDeath = _.get(data, 'deceased.timeOfPassing', null);
            const dateOfDeath = _.get(data, 'deceased.dateOfPassing', null);

            if (timeOfDeath && dateOfDeath && timeOfDeath !== dateOfDeath) {

              const dateOfDeathMoment = moment(dateOfDeath);
              const timeOfDeathMoment = moment(timeOfDeath);

              // Check if Date Of Death is a valid date and the time isn't 12am
              if (timeOfDeathMoment.isValid() && dateOfDeathMoment.isValid() && dateOfDeathMoment.get('hour') === 0 && dateOfDeathMoment.get('minute') === 0) {

                dateOfDeathMoment.set({
                  hour: timeOfDeathMoment.get('hour'),
                  minute: timeOfDeathMoment.get('minute'),
                  second: timeOfDeathMoment.get('second'),
                });

              }

              _.set(data, 'deceased.dateOfPassing', dateOfDeathMoment.toDate());

            }

            return data;

          }
  
          /**
           * Empty arrays (eg. []) / objects (eg. {}) cause problems when saving to the DB, so we remove them
           * Note: Maybe I should move this to the server?? For now it will be here...
           */
          const removeEmptyObjects = (data: any) => {
  
            let keyRemoved = false;
  
            const dotted = dot.dot(data);
  
            for (let key in dotted) {
  
              if (dotted.hasOwnProperty(key)) {
  
                if ((_.isArray(dotted[key]) || _.isObject(dotted[key]) || dotted[key] === '{}' || dotted[key] === undefined) && !_.isDate(dotted[key])) {
  
                  keyRemoved = true;
  
                  _.unset(data, key);
                }
  
              }
  
            }
  
            if (keyRemoved) {
  
              data = removeEmptyObjects(data);
  
            }
  
            return data;
  
          };
  
          data = removeEmptyObjects(data);
  
          data = getOptionValueFromValue(data, 'deceased.isStillBornBaby.value', isStillBornBabyValue, 'no');
          data = getOptionValueFromValue(data, 'deceased.isCoronersCase.value', isCoronersCaseValue, 'no');
  
          data = getFirstCallRecipientUser(data);
          data = await getFirstCallSourceOption(data).toPromise();
          data = await getFirstCallSourceGroupOption(data).toPromise()
          data = await getDeceasedPlaceOfPassingAutoFillOption(data).toPromise();
          data = setDeprecatedTimeOfDeathToDateOfDeath(data);
          data = getContactFromPath(data, 'keyContacts.informant', RelationToDeceased.Informant);
          data = getContactFromPath(data, 'keyContacts.nextOfKin', RelationToDeceased.NextOfKin);
          data = getContactFromPath(data, 'keyContacts.executor', RelationToDeceased.Executor);
          data = getContactFromPath(data, 'invoice.authoriserContact');
          data = getContactFromPath(data, 'invoice.accountContact');
          data = getContactFromPath(data, 'statutoryDeclaration.applicant');
  
          data = processAdditionalContacts(data);
  
          data = getTransferServices(data);
  
          data = getArrangementMeetingArrangerUser(data);
          data = await getArrangerServiceProvider(data).toPromise();
          data = getArrangementMeetingAttendees(data);
  
          data = await processPlaceOfPassing(data).toPromise();
          data = await processEvents(data).toPromise();
          data = processMarriages(data);
          data = processChildren(data);
          data = processParents(data);

          // If no API target is set, we should use place of death state to determine which API to use
          // We also copy the data from NSW to QLD / VIC if needed
          if (
            _.get(data, 'birthsDeathsAndMarriages.apiTarget') !== BirthDeathsAndMarriagesApiTarget.NewSouthWales
            && _.get(data, 'birthsDeathsAndMarriages.apiTarget') !== BirthDeathsAndMarriagesApiTarget.Queensland
            && _.get(data, 'birthsDeathsAndMarriages.apiTarget') !== BirthDeathsAndMarriagesApiTarget.Victoria
          ){

            // Check place of death state to determine which API to use
            const placeOfPassingStateValue = _.get(data, 'deceased.placeOfPassing.state.value');            

            if (placeOfPassingStateValue) {

              if (placeOfPassingStateValue === 'QLD') {

                _.set(data, 'birthsDeathsAndMarriages.apiTarget', BirthDeathsAndMarriagesApiTarget.Queensland);
                _.set(data, 'birthsDeathsAndMarriages.qld', _.get(data, 'birthsDeathsAndMarriages.nsw'));

              } else if (placeOfPassingStateValue === 'VIC') {

                _.set(data, 'birthsDeathsAndMarriages.apiTarget', BirthDeathsAndMarriagesApiTarget.Victoria);
                _.set(data, 'birthsDeathsAndMarriages.vic', _.get(data, 'birthsDeathsAndMarriages.nsw'));

              }

            }

          }

          data = getOptionValueFromValue(data, 'birthsDeathsAndMarriages.apiTarget.value', relationshipTypesFormValue, null);

          if (_.get(data, 'birthsDeathsAndMarriages.apiTarget') === BirthDeathsAndMarriagesApiTarget.NewSouthWales) {
            data = processBirthsDeathsAndMarriagesNsw(data);
          } else if (_.get(data, 'birthsDeathsAndMarriages.apiTarget') === BirthDeathsAndMarriagesApiTarget.Queensland) {
            data = processBirthsDeathsAndMarriagesQld(data);
          } else if (_.get(data, 'birthsDeathsAndMarriages.apiTarget') === BirthDeathsAndMarriagesApiTarget.Victoria) {
            data = processBirthsDeathsAndMarriagesVic(data);
          } else { // We fallback to NSW if no API target is set
            _.set(data, 'birthsDeathsAndMarriages.apiTarget', BirthDeathsAndMarriagesApiTarget.NewSouthWales);
            data = processBirthsDeathsAndMarriagesNsw(data);
          }
          
          data = await processNewspaperNotifications(data).toPromise();
          data = processCauseOfDeath(data);
          data = await processDisposal(data).toPromise();
          data = await processPreparation(data).toPromise();
          data = await processInvoice(data).toPromise();
          data = processStatutoryDeclaration(data);
      
          subscriber.next(data);
          subscriber.complete();

        } catch (e) {

          console.error(e);

        }

      };

      go();

    });

  }

  private postDataAsContacts(data: ContactModel[]): void {

    const existingContacts = this.contactsSource.value;

    if (Array.isArray(data)) {

      for (const d of data) {
  
        const existingContactIndex = _.findIndex(existingContacts, (existingContact: Contact) => existingContact._meta.id === d._meta?.id);
  
        if (existingContactIndex > -1) {
  
          // TODO: Move this into the Contact class as a `update` method
          if (d.relationToDeceased && Array.isArray(d.relationToDeceased)) {
  
            const existingRelationships = d.relationToDeceased;
  
            const relationships = existingContacts[existingContactIndex].relationshipOptions.filter((relationshipType: any) => {
              const found = existingRelationships.find((existingRelationship: any) => {
                return existingRelationship.value === relationshipType.value;
              });
              return found;
            });

            d.relationToDeceased = relationships;
  
          }
  
          if(d.dateOfBirth) {
            d.dateOfBirth = moment(d.dateOfBirth).toDate() as any;
          }
          
          existingContacts[existingContactIndex].form.patchValue(d);
          // End TODO
  
        } else {
  
          const contact = new Contact(this.http, this, d, true);
  
          existingContacts.push(contact);
  
        }
  
      }

    }

    this.contactsSource.next(existingContacts);

  }

  private formAsPostData() {

    const getValueFromOptionValue = (data: any, path: string) => {

      const id = _.get(data, `${path}._meta.id`);

      if (id) {

        _.set(data, `${path}.id`, id);

      }

      _.unset(data, `${path}._meta`);

      return data;

    };

    const getFirstCallRecipientId = (data: any) => {

      const firstCallRecipient: User = _.get(this.form.value, 'firstCall.recipient');

      if (firstCallRecipient && _.has(firstCallRecipient, 'data.id')) {
  
        data.firstCall.recipient = {
          id: firstCallRecipient.data.id
        };

      } else if (firstCallRecipient) {

        data.firstCall.recipient = {
          id: +firstCallRecipient
        };

      }

      return data;

    };

    const getFirstCallSourceValue = (data: any) => {

      const firstCallSource: { name: string; value: string; _meta: { id: string; index: number; deleted?: boolean } }[] = this.form.value.firstCall.source;

      if (firstCallSource && firstCallSource.length) {

        data.firstCall.source = firstCallSource.map(sourceValue => sourceValue._meta.id);

      }

      return data;

    };

    const getFirstCallSourceGroupValue = (data: any) => {

      const firstCallSourceGroup: {
        name: string; 
        _meta:{id: string; index:number; deleted?:boolean}, 
        value: {
          _meta:{id: string; index:number; deleted?:boolean}, 
          value: string}[]
      }[] = this.form.value.firstCall.sourceGroup;

      if (firstCallSourceGroup && firstCallSourceGroup.length) {

        const newSourceGroups:{groupId: string, sourceId: string}[] = [];

        firstCallSourceGroup.forEach(groupValue => {
          if (groupValue.value) {
            for (const element of groupValue.value) {
              const findIndex = _.findIndex(this.form.value.firstCall.source, (source:any) => source._meta.id === element.value);
              if (findIndex > -1) {
                newSourceGroups.push({groupId: groupValue._meta.id, sourceId: element.value});
              }
            }
          }
        })
        data.firstCall.sourceGroup = [...newSourceGroups];
      }

      return data;

    };

    const getDeceasedPlaceOfPassingAutoFillId = (data: any) => {

      const deceasedPlaceOfPassingAutoFillId = _.get(data, 'deceased.placeOfPassing.autoFill._meta.id', null);

      _.unset(data, 'deceased.placeOfPassing.autoFill');

      // Note: Setting the id to NULL is valid.
      _.set(data, 'deceased.placeOfPassing.autoFill.id', deceasedPlaceOfPassingAutoFillId);

      return data;

    };

    const getArrangementMeetingArrangerId = (data: any) => {

      const arrangerUser = _.get(data, 'arrangementMeeting.arranger')

      if (arrangerUser && _.has(arrangerUser, 'data.id')) {
  
        data.arrangementMeeting.arranger = {
          id: arrangerUser.data.id
        };

      } else if (arrangerUser) {

        data.arrangementMeeting.arranger = {
          id: +arrangerUser
        };

      }

      return data;

    };

    const processArrangerService = (data: any) => {

      let preparationService = _.get(data, 'arrangementMeeting.service', null);

      if (preparationService) {

        data = convertServices(data, ['arrangementMeeting.service']);

      }

      return data;

    }

    const convertContacts = (data: any, contactPaths: { path: string }[]) => {

      if (!_.has(data, 'contacts')) {
        data.contacts = [];
      }

      for (let contactPath of contactPaths) {

        let id = _.get(data, contactPath.path + '.id');

        if (!id) {

          // console.warn(`ID for contact at path '${ contactPath.path }' not set`);

        }

        _.unset(data, contactPath.path);

        _.set(data, contactPath.path + '.id', id);

      }

      return data;

    };

    const convertDatesOld = (data: any, dates: any[]) => {

      const dateFormat = environment.dbDateFormat;

      for (let date of dates) {

        const d = _.get(data, date.path);

        if (moment.isDate(d)) {

          _.set(data, date.path, moment(d).format(dateFormat));
          
        } else if (moment.isMoment(d)) {

          _.set(data, date.path, d.format(dateFormat));
          
        } else {

          _.set(data, date.path, null);

        }

      }

      return data;

    };

    const convertDatesNew = (data: any) => {

      const dataDot = dot.dot(data);

      for (const key in dataDot) {

        const d = _.get(dataDot, key);

        const dateAsString = ConvertToDateString(d);

        if (dateAsString) {

          _.set(dataDot, key, dateAsString);

        }

      }

      return dot.object(dataDot);

    };

    const convertServices = (data: any, paths: string[]) => {

      for (let path of paths) {

        const service: Service = _.get(data, path);

        if (service) {

          _.set(data, path, {
            serviceProviderId: service.serviceProviderId,
            service: service._meta,
            type: service.type,
          });

        }

      }

      return data;

    };

    const processTransfers = (data: any) => {

      const transfers: any[] = _.get(data, 'transfer.transfers', []);
      
      // const transferServicePaths = _.map(transfers, (transfer, index) => `transfer.transfers.${index}.service`);
      // data = convertServices(data, _.flatten([transferServicePaths]));

      for (let transfer of transfers) {

        transfer = convertServices(transfer, ['service']);
        transfer = getValueFromOptionValue(transfer, 'dateOfPickupType');
        transfer = getValueFromOptionValue(transfer, 'addTo2ndNote');
        transfer = getValueFromOptionValue(transfer, 'eventIndex');
        transfer = getValueFromOptionValue(transfer, 'type');
        transfer = getValueFromOptionValue(transfer, 'eventId');
        
        transfer = getValueFromOptionValue(transfer, 'from._addressType');
        transfer = getValueFromOptionValue(transfer, 'from._addressTypeServiceProviderType');
        transfer = convertServices(transfer, ['from._addressTypeServiceProvider']);
        transfer = getValueFromOptionValue(transfer, 'from._addressTypeExistingTransfer');
        transfer = getValueFromOptionValue(transfer, 'from._addressTypeExistingTransferAddressType');
        
        transfer = getValueFromOptionValue(transfer, 'to._addressType');
        transfer = getValueFromOptionValue(transfer, 'to._addressTypeServiceProviderType');
        transfer = convertServices(transfer, ['to._addressTypeServiceProvider']);
        transfer = getValueFromOptionValue(transfer, 'to._addressTypeExistingTransfer');
        transfer = getValueFromOptionValue(transfer, 'to._addressTypeExistingTransferAddressType');

        delete transfer._selectContainer;

      }

      if (transfers.length) {
        _.set(data, 'transfer.transfers', transfers);
      }

      return data;

    }

    const processArrangementMeetingAttendees = (data: any) => {

      const arrangementMeetingAttendeeLength = (_.has(data, 'arrangementMeeting.attendees')) ? data.arrangementMeeting.attendees.length : 0;

      if (arrangementMeetingAttendeeLength) {
  
        data = convertContacts(data, _.times(arrangementMeetingAttendeeLength, (i: number) => { 
          return { path: `arrangementMeeting.attendees.${i}.contact` }; 
        }));
  
      }

      return data;

    };

    const processAdditionalContacts = (data: any) => {

      const arrangementMeetingAttendeeLength = (_.has(data, 'additionalContacts')) ? data.additionalContacts.length : 0;

      if (arrangementMeetingAttendeeLength) {
  
        data = convertContacts(data, _.times(arrangementMeetingAttendeeLength, (i: number) => { 
          return { path: `additionalContacts.${i}.contact` }; 
        }));
  
      }

      return data;

    };

    const processPlaceOfPassing = (data: any) => {

      data = getValueFromOptionValue(data, 'deceased.placeOfPassing.type');

      // if (_.has(data, 'deceased.placeOfPassing.type')) {

      //   _.set(data, 'deceased.placeOfPassing.type', _.get(data, 'deceased.placeOfPassing.type.value', null));

      // }

      data = getValueFromOptionValue(data, 'deceased.placeOfPassing.hospitalOrNursingHome');

      // if (_.has(data, 'deceased.placeOfPassing.hospitalOrNursingHome')) {

      //   _.set(data, 'deceased.placeOfPassing.hospitalOrNursingHome', _.get(data, 'deceased.placeOfPassing.hospitalOrNursingHome._meta.id', null));

      // }

      return data;

    };

    const processEvents = (data: any) => {

      let events: any[] = _.get(data, 'events', []);

      events = _.map(events, event => {

        const _convertVenue = (event: any) => {

          let venue = _.get(event, 'venue', null);

          if (venue) {
  
            venue = convertServices(venue, ['service']);
  
          }
          
          _.set(event, 'venue.service', _.get(venue, 'service', null));

          return event;

        };

        const _convertEventItems = (event: any) => {

          let items = _.get(event, 'items', []);

          items = _.map(items, item => {

            item = getValueFromOptionValue(item, 'serviceType');

            let service = _.get(item, 'service', null);

            if (service) {

              item = convertServices(item, ['service']);

            }
            
            _.set(item, 'service', _.get(item, 'service', null));

            return item;

          });

          _.set(event, 'items', items);

          return event;

        };

        // _.set(event, 'type', _.get(event, 'type._meta.id', null));
        event = getValueFromOptionValue(event, 'type');

        event = convertDatesOld(event, [
          { path: 'startDate' },
          { path: 'endDate' },
        ]);

        event = _convertVenue(event);
        event = _convertEventItems(event);

        return event;

      });

      _.set(data, 'events', events);

      return data;

    };

    const processMarriages = (data: any) => {

      data = getValueFromOptionValue(data, 'marriage.statusAtPassing');
      
      let marriages = _.get(data, 'marriage.marriages', []);

      marriages = _.map(marriages, marriage => {

        marriage = getValueFromOptionValue(marriage, 'type');

        marriage = convertContacts(marriage, [{ path: 'spouse' }]);

        return marriage;

      });

      _.set(data, 'marriage.marriages', marriages);

      return data;

    };

    const processChildren = (data: any) => {

      let children = _.get(data, 'family.children', []);

      children = _.map(children, child => {

        child = convertContacts(child, [{ path: 'child' }]);

        return child;

      });

      _.set(data, 'family.children', children);

      return data;

    };

    const processParents = (data: any) => {

      let parents = _.get(data, 'family.parents', []);

      parents = _.map(parents, parent => {

        parent = getValueFromOptionValue(parent, 'type');

        parent = convertContacts(parent, [{ path: 'parent' }]);

        return parent;

      });

      _.set(data, 'family.parents', parents);

      return data;

    };

    const processBirthsDeathsAndMarriagesNsw = (data: any) => {

      data = getValueFromOptionValue(data, 'birthsDeathsAndMarriages.nsw.informantPreferredRelationship');
      
      data = getValueFromOptionValue(data, 'birthsDeathsAndMarriages.nsw.certificateType');
      
      data = getValueFromOptionValue(data, 'birthsDeathsAndMarriages.nsw.deliveryType');

      data = convertServices(data, ['birthsDeathsAndMarriages.nsw.service']);
      
      return data;
      
    };

    const processBirthsDeathsAndMarriagesVic = (data: any) => {

      data = getValueFromOptionValue(data, 'birthsDeathsAndMarriages.vic.informantPreferredRelationship');
      
      data = getValueFromOptionValue(data, 'birthsDeathsAndMarriages.vic.certificateType');
      
      data = getValueFromOptionValue(data, 'birthsDeathsAndMarriages.vic.deliveryType');

      data = convertServices(data, ['birthsDeathsAndMarriages.vic.service']);

      return data;
    };

    const processBirthsDeathsAndMarriagesQld = (data: any) => {

      data = getValueFromOptionValue(data, 'birthsDeathsAndMarriages.qld.informantPreferredRelationship');
      
      data = getValueFromOptionValue(data, 'birthsDeathsAndMarriages.qld.certificateType');
      
      data = getValueFromOptionValue(data, 'birthsDeathsAndMarriages.qld.deliveryType');

      data = convertServices(data, ['birthsDeathsAndMarriages.qld.service']);

      return data;

    };

    const processNewspaperNotifications = (data: any) => {

      let newspaperNotifications: any[] = _.get(data, 'newspaperNotifications', []);

      newspaperNotifications = _.map(newspaperNotifications, newspaperNotification => {

        let service = _.get(newspaperNotification, 'service', null);

        if (service) {

          newspaperNotification = convertServices(newspaperNotification, ['service']);

        }

        newspaperNotification = convertDatesOld(newspaperNotification, [
          { path: 'date' },
        ]);

        return newspaperNotification;

      });

      _.set(data, 'newspaperNotifications', newspaperNotifications);

      return data;

    };

    const processCauseOfDeath = (data: any): any => {
   
      data = getValueFromOptionValue(data, 'causeOfDeath.type');

      // const causeOfDeathTypeValue = _.get(data, 'causeOfDeath.type.value', null);
      
      // _.set(data, 'causeOfDeath.type', causeOfDeathTypeValue);
      
      return data;
      
    };

    const processDisposal = (data: any): any => {
   
      data = getValueFromOptionValue(data, 'disposal.type');
      
      let cremationAshesRecipients = _.get(data, 'disposal.cremation.collectionOfAshes.recipients', []);
      
      let burialService = _.get(data, 'disposal.burial.service', null);
      let cremationService = _.get(data, 'disposal.cremation.service', null);

      let additionalItems = _.get(data, 'disposal.additionalItems.items', []);

      data = convertContacts(data, [{ path: 'disposal.bodyDonation.contact' }]);
      data = convertContacts(data, [{ path: 'disposal.repatriated.contact' }]);
      data = convertContacts(data, [{ path: 'disposal.burial.burialApplicant' }]);
      data = convertContacts(data, [{ path: 'disposal.burial.registeredHolderOfInterment' }]);

      cremationAshesRecipients = _.map(cremationAshesRecipients, cremationAshesRecipient => {

        cremationAshesRecipient = convertContacts(cremationAshesRecipient, [{ path: 'contact' }]);

        return cremationAshesRecipient;

      });

      _.set(data, 'disposal.cremation.collectionOfAshes.recipients', cremationAshesRecipients);

      if (burialService) {

        data = convertServices(data, ['disposal.burial.service']);

      }

      if (cremationService) {

        data = convertServices(data, ['disposal.cremation.service']);

      }
      
      data = convertDatesOld(data, [
        { path: 'disposal.date' },
      ]);

      additionalItems = _.map(additionalItems, additionalItem => {

        additionalItem = getValueFromOptionValue(additionalItem, 'serviceType');

        let service = _.get(additionalItem, 'service', null);

        if (service) {

          additionalItem = convertServices(additionalItem, ['service']);

        }
        
        _.set(additionalItem, 'service', _.get(additionalItem, 'service', null));

        return additionalItem;

      });

      _.set(data, 'disposal.additionalItems.items', additionalItems);

      return data;
      
    };

    const processPreparation = (data: any): any => {

      let preparationService = _.get(data, 'preparation.service', null);
      let additionalItems = _.get(data, 'preparation.additionalItems.items', []);
      let jewelleryInstructions = _.get(data, 'preparation.jewellery.instructions', []);
      let clothingInstructions = _.get(data, 'preparation.clothing.instructions', []);

      if (preparationService) {

        data = convertServices(data, ['preparation.service']);

      }

      additionalItems = _.map(additionalItems, additionalItem => {

        additionalItem = getValueFromOptionValue(additionalItem, 'serviceType');

        let service = _.get(additionalItem, 'service', null);

        if (service) {

          additionalItem = convertServices(additionalItem, ['service']);

        }
        
        _.set(additionalItem, 'service', _.get(additionalItem, 'service', null));

        return additionalItem;

      });

      _.set(data, 'preparation.additionalItems.items', additionalItems);

      if (jewelleryInstructions) {

        data = convertContacts(data, [{ path: 'preparation.jewellery.contact' }]);

        jewelleryInstructions = _.map(jewelleryInstructions, jewelleryInstruction => {

          jewelleryInstruction = getValueFromOptionValue(jewelleryInstruction, 'type');

          jewelleryInstruction = convertContacts(jewelleryInstruction, [{ path: 'contact' }]);

          return jewelleryInstruction;

        });

        _.set(data, 'preparation.jewellery.instructions', jewelleryInstructions);

      }

      if (clothingInstructions) {

        data = convertContacts(data, [{ path: 'preparation.clothing.contact' }]);

        clothingInstructions = _.map(clothingInstructions, clothingInstruction => {

          clothingInstruction = getValueFromOptionValue(clothingInstruction, 'type');

          clothingInstruction = convertContacts(clothingInstruction, [{ path: 'contact' }]);

          return clothingInstruction;

        });

        _.set(data, 'preparation.clothing.instructions', clothingInstructions);

      }

      return data;

    };

    const processInvoice = (data: any): any => {
   
      data = getValueFromOptionValue(data, 'invoice.charity');

      data = getValueFromOptionValue(data, 'invoice.serviceFee.included');
      
      return data;
      
    };

    const processStatutoryDeclaration = (data: any): any => {

      if (_.has(data, 'statutoryDeclaration.applicantRelationshipToDeceased._meta')) {

        delete data.statutoryDeclaration.applicantRelationshipToDeceased._meta;

      }

      if (_.has(data, 'statutoryDeclaration.requestersRelationshipToDeceased._meta')) {

        delete data.statutoryDeclaration.applicantRelationshipToDeceased._meta;

      }

      return data;

    };

    /**
     * Empty arrays (eg. []) / objects (eg. {}) cause problems when saving to the DB, so we remove them
     * Note: Maybe I should move this to the server?? For now it will be here...
     */
    const removeEmptyObjects = (data: any) => {

      let keyRemoved = false;

      const dotted = dot.dot(data);

      for (let key in dotted) {

        if (dotted.hasOwnProperty(key)) {

          if ((dotted[key] === null || _.isArray(dotted[key]) || _.isObject(dotted[key]) || dotted[key] === '{}' || dotted[key] === undefined) && !_.isDate(dotted[key])) {

            keyRemoved = true;

            _.unset(data, key);
          }

        }

      }

      if (keyRemoved) {

        data = removeEmptyObjects(data);

      }

      return data;

    };

    let data = _.cloneDeep(this.form.value);

    data.contacts = this.contactsSource.value.map(contact => contact.form.value);

    delete data.isSubmitted;
    delete data._arrangement;

    data = processTransfers(data);

    data = getFirstCallRecipientId(data);
    data = getFirstCallSourceValue(data);
    data = getFirstCallSourceGroupValue(data);
    data = getDeceasedPlaceOfPassingAutoFillId(data); // This is only useful for older arrangements. Remove when going live...

    data = processPlaceOfPassing(data);

    data = getArrangementMeetingArrangerId(data);
    data = processArrangerService(data);

    data = convertContacts(data, [
      { path: 'keyContacts.informant' }, 
      { path: 'keyContacts.nextOfKin' }, 
      { path: 'keyContacts.executor' },
      { path: 'birthsDeathsAndMarriages.nsw.informant' },
      { path: 'birthsDeathsAndMarriages.nsw.witness' },
      { path: 'invoice.authoriserContact' },
      { path: 'invoice.accountContact' },
      { path: 'statutoryDeclaration.applicant' },
    ]);

    data = processAdditionalContacts(data);
    data = processArrangementMeetingAttendees(data);
    data = processEvents(data);
    data = processMarriages(data);
    data = processChildren(data);
    data = processParents(data);
    data = processBirthsDeathsAndMarriagesNsw(data);
    data = processBirthsDeathsAndMarriagesVic(data);
    data = processBirthsDeathsAndMarriagesQld(data);
    data = processNewspaperNotifications(data);
    data = processCauseOfDeath(data);
    data = processDisposal(data);
    data = processPreparation(data);
    data = processStatutoryDeclaration(data);
    data = processInvoice(data);

    data = convertDatesNew(data);

    // This must be last function called within this.formAsPostData()
    data = removeEmptyObjects(data);

    return data;

  }

  private formValueChanges(): void {

    this.siteService.addSubscriptionLog(this, 'arrangement.ts->formValueChanges->this.form.valueChanges');

    this.subscriptions.push(this.form.valueChanges.pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('arrangement.ts->formValueChanges->this.form.valueChanges')),
      takeUntil(this.unsubscribe$),
      debounceTime(100),
    ).subscribe({
      next: value => {
        this.lastFormValueChangeTime = Date.now();
        this.checkPendingFormControlSubscriptions(value);
      }
    }));

  }

  private checkPendingFormControlSubscriptions(value: any) {

    const length = this.pendingFormControlSubscriptions.length;
    const validIndexes = [];

    for (let i = 0; i < length; i++) {

      const pendingFormControlSubscription = this.pendingFormControlSubscriptions[i];

      let undefinedPaths = _.filter(pendingFormControlSubscription.paths, path => !_.has(value, path));

      if (undefinedPaths.length === 0) {

        validIndexes.push(i);

        const observables = [];

        for (let path of pendingFormControlSubscription.paths) {

          this.siteService.addSubscriptionLog(this, 'arrangement.ts->checkPendingFormControlSubscriptions->this.form.get(path)?.valueChanges');

          const abstractControl = this.form.get(path)?.valueChanges.pipe(
            finalize(() => this.siteService.setSubscriptionLogFinalised('arrangement.ts->checkPendingFormControlSubscriptions->this.form.get(path)?.valueChanges')),
            takeUntil(this.unsubscribe$),
            startWith(_.get(value, path))
          );

          if (abstractControl) {
            observables.push(abstractControl);
          } else {
            console.error(`Couldn\'t subscribe to '${path}' even though it's a valid path.`);
          }

        }

        this.siteService.addSubscriptionLog(this, 'arrangement.ts->checkPendingFormControlSubscriptions->this.formControlSubscriptions.push');

        this.formControlSubscriptions.push({
          id: pendingFormControlSubscription.id,
          paths: pendingFormControlSubscription.paths,
          subscription: combineLatest(observables).pipe(
            finalize(() => this.siteService.setSubscriptionLogFinalised('arrangement.ts->checkPendingFormControlSubscriptions->this.formControlSubscriptions.push')),
            takeUntil(this.unsubscribe$),
            debounceTime(500), 
            distinctUntilChanged((prev, curr) => {
              let isEqual = true;
              for (let i = 0; i < prev.length; i++) {
                if (!_.isEqual(prev[i], curr[i])) {
                  isEqual = false;
                  break;
                }
              }
              return isEqual;
            })
          ).subscribe(pendingFormControlSubscription.observer),
        });

      }

    }

    _.reverse(validIndexes); // We reverse the array so we don't ruin the array indexes when we remove multiple array elements

    for (let i = 0; i < validIndexes.length; i++) {
      this.pendingFormControlSubscriptions.splice(validIndexes[i], 1);
    }

  }

  private bdmNswLodge(username: string | null, password: string | null): Observable<any> {

    const getServiceProviderDetails = (arrangement: any, serviceProviders: ServiceProvider[]): any => {

      const disposalBurialService = _.get(arrangement, 'disposal.burial.service', null);
      const disposalCremationService = _.get(arrangement, 'disposal.cremation.service', null);

      if (disposalBurialService && disposalBurialService.serviceProviderId) {

        const serviceProvider = this.serviceProviderService.getServiceById(disposalBurialService.serviceProviderId, disposalBurialService.service.id)

        if (serviceProvider) {
          _.set(arrangement, 'disposal.burial.service', { name: serviceProvider.name })
        }

      }

      if (disposalCremationService && disposalCremationService.serviceProviderId) {

        const serviceProvider = this.serviceProviderService.getServiceById(disposalCremationService.serviceProviderId, disposalCremationService.service.id)

        if (serviceProvider) {
          _.set(arrangement, 'disposal.cremation.service', { name: serviceProvider.name })
        }

      }

      return arrangement;

    }

    const url = environment.api.host + environment.api.paths.api.arrangement.bdm.nsw.lodge;

    let arrangement = this.formAsPostData();

    arrangement.id = this.id;

    const all = combineLatest([
      this.accountService.account$, 
      this.serviceProviderService.allServiceProviders$
    ]);

    this.siteService.addSubscriptionLog(this, 'arrangement.ts->bdmNswLodge->return all');

    return all.pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('arrangement.ts->bdmNswLodge->return all')),
      takeUntil(this.unsubscribe$),
      switchMap(([account, serviceProviders]: [any, any]) => {
        arrangement = getServiceProviderDetails(arrangement, serviceProviders);

        const clonedAccountData = _.cloneDeep(account.data);

        clonedAccountData.bdm.nsw.funeralDirector.api.username = username;
        clonedAccountData.bdm.nsw.funeralDirector.api.password = password;

        return this.http.post<any>(url, { arrangement, account: clonedAccountData });
      }),
      map(res => res)
    );

  }

  private processArrangementInvoice(): Observable<InvoiceItem[]> {

    const formatDollarAndCents = (amount: any): number | null => {

      if (!amount) {
        amount = 0;
      }

      const dollarAmount = +(new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: false // Remove commas from the output
      }).format(amount));

      if (_.isNaN(dollarAmount)) {
        return null;
      }

      return dollarAmount;

    };

    const getServiceProvider = (serviceProviders: any[], service: any): any => {

      let serviceProvider = null;

      if (service) {

        const serviceProviderId = _.get(service, 'serviceProviderId');
        const id = _.get(service, 'service.id');

        if (serviceProviderId && id) {

          serviceProvider = this.serviceProviderService.getServiceById(serviceProviderId, id);

        }

      }

      return serviceProvider;

    };

    const processLineItem = (lineItem: any, service: any, path: string = ''): InvoiceItem | null => {

      if (!lineItem.product) {
        return null;
      }

      if (_.get(lineItem, '_meta.deleted') === true) {
        return null;
      }

      let title = lineItem.product.name;
      const isCustomLineItem = _.get(lineItem, 'isCustomItem', false);

      if (!isCustomLineItem && service && service.serviceProvider) {

        title = service.serviceProvider.name + ' - ' + title;

      }

      const cost = formatDollarAndCents(lineItem.cost.amount);

      const item: InvoiceItem = {
        invalid: cost === null,
        invoiceLabel: lineItem.product.invoiceLabel,
        formControlId: lineItem.cost.uuid,
        path: path,
        title: (lineItem.product.invoiceLabel) ? lineItem.product.invoiceLabel : title,
        description: lineItem.product.description,
        price: (lineItem.quantity === 0 || !lineItem.quantity) ? 0 : lineItem.cost.amount / lineItem.quantity,
        quantity: lineItem.quantity,
        cost: (cost === null) ? 0 : cost,
        group: { 
          order: 0,
          title: '',
          description: '', 
        }
      };

      // check if `item.title` is greater than 100 characters in length, if so trim to 97 characters and add '...' at the end
      if (item.title.length > 100) {
        item.title = item.title.substring(0, 97) + '...';
      }

      if (lineItem.cost.xeroTaskId) {
        item.xeroTaskId = lineItem.cost.xeroTaskId;
      }

      if (cost === null) {
        item.invalidValue = lineItem.cost.amount;
      }

      return item;

    };

    const processDisposal = (arrangement: any, basePath: string, serviceProviders: any[]): InvoiceItem[] => {

      const items: InvoiceItem[] = [];

      const burialLineItemsPath = `${basePath}.burial.lineItems`;
      const cremationLineItemsPath = `${basePath}.cremation.lineItems`;
      const additionalLineItemsPath = `${basePath}.additionalItems.items`;

      const burialLineItems = _.get(arrangement, burialLineItemsPath, []);
      const cremationLineItems = _.get(arrangement, cremationLineItemsPath, []);
      const additionalItemsItems = _.get(arrangement, additionalLineItemsPath, []);

      let groupTitle = '';

      if (burialLineItems && burialLineItems.length) {

        groupTitle = 'Burial';

        const service = _.get(arrangement, `${basePath}.burial.service`);

        const serviceProvider = getServiceProvider(serviceProviders, service);

        for (let i = 0; i < burialLineItems.length; i++) {

          const invoiceLineItem = processLineItem(burialLineItems[i], serviceProvider, `${burialLineItemsPath}.${i}`);

          if (invoiceLineItem) {

            invoiceLineItem.group.title = groupTitle;
            invoiceLineItem.group.order = 3;
  
            items.push(invoiceLineItem);

          }

        }

      }

      if (cremationLineItems && cremationLineItems.length) {

        groupTitle = 'Cremation';

        const service = _.get(arrangement, `${basePath}.cremation.service`);
        const serviceProvider = getServiceProvider(serviceProviders, service);

        for (let i = 0; i < cremationLineItems.length; i++) {

          const invoiceLineItem = processLineItem(cremationLineItems[i], serviceProvider, `${cremationLineItemsPath}.${i}`);
          
          if (invoiceLineItem) {

            invoiceLineItem.group.title = groupTitle;
            invoiceLineItem.group.order = 3;

            items.push(invoiceLineItem);

          }

        }

      }

      if (additionalItemsItems && additionalItemsItems.length) {

        for (let additionalItemIndex = 0; additionalItemIndex < additionalItemsItems.length; additionalItemIndex++) {

          const additionalItem = additionalItemsItems[additionalItemIndex];

          const service = _.get(additionalItem, 'service');
          const lineItems = _.get(additionalItem, 'lineItems', []);

          const serviceProvider = getServiceProvider(serviceProviders, service);
  
          for (let lineItemIndex = 0; lineItemIndex < lineItems.length; lineItemIndex++) {
  
            const invoiceLineItem = processLineItem(lineItems[lineItemIndex], serviceProvider, `${additionalLineItemsPath}.${additionalItemIndex}.lineItems.${lineItemIndex}`);
          
            if (invoiceLineItem) {
  
              invoiceLineItem.group.title = groupTitle;
              invoiceLineItem.group.order = 3;

              items.push(invoiceLineItem);

            }
  
          }

        }

      }

      return items;

    };

    const processEvents = (arrangement: any, basePath: string, serviceProviders: any[]): InvoiceItem[] => {
      
      const generateGroupTitle = (eventType: string, eventDate: string) => {
        let title = 'Event';

        if (eventType) {
          title += ': ' + eventType;
        }

        if (eventDate) {
          title += ' - ' + eventDate;
        }

        return title;
      };

      const items: InvoiceItem[] = [];

      const events = _.get(arrangement, basePath, []);

      if (Array.isArray(events)) {
        for (let eventIndex = 0; eventIndex < events.length; eventIndex++) {
          const event = events[eventIndex];

          const eventType = _.get(event, 'type.name', '');
          const venueService = _.get(event, 'venue.service', null);
          const eventItems = _.get(event, 'items', []);
          const venueLineItems = _.get(event, 'venue.lineItems', []);
          const venueServiceProvider = getServiceProvider(
            serviceProviders,
            venueService
          );

          let eventDate = _.get(event, 'startDate');

          if (eventDate) {
            eventDate = moment(eventDate, 'YYYY-MM-DD HH:mm:ss').format(
              'DD/MM/YYYY'
            );
          } else {
            eventDate = null;
          }

          for (
            let venueLineItemIndex = 0;
            venueLineItemIndex < venueLineItems.length;
            venueLineItemIndex++
          ) {
            const invoiceLineItem = processLineItem(
              venueLineItems[venueLineItemIndex],
              venueServiceProvider,
              `${basePath}.${eventIndex}.venue.lineItems.${venueLineItemIndex}`
            );

            if (invoiceLineItem) {
              invoiceLineItem.group.title = generateGroupTitle(
                eventType,
                eventDate
              );
              invoiceLineItem.group.order = 6 + (eventIndex + 1) * 0.001;

              items.push(invoiceLineItem);
            }
          }

          for (
            let eventItemIndex = 0;
            eventItemIndex < eventItems.length;
            eventItemIndex++
          ) {
            const eventItem = eventItems[eventItemIndex];

            const service = _.get(eventItem, 'service');
            const lineItems = _.get(eventItem, 'lineItems', []);

            const serviceProvider = getServiceProvider(
              serviceProviders,
              service
            );

            for (
              let lineItemIndex = 0;
              lineItemIndex < lineItems.length;
              lineItemIndex++
            ) {
              const invoiceLineItem = processLineItem(
                lineItems[lineItemIndex],
                serviceProvider,
                `${basePath}.${eventIndex}.items.${eventItemIndex}.lineItems.${lineItemIndex}`
              );

              if (invoiceLineItem) {
                invoiceLineItem.group.title = generateGroupTitle(
                  eventType,
                  eventDate
                );
                invoiceLineItem.group.order = 6 + (eventIndex + 1) * 0.001;

                items.push(invoiceLineItem);
              }
            }
          }
        }
      }

      return items;
    };

    const processNewspaperNotifications = (arrangement: any, basePath: string, serviceProviders: any[]): InvoiceItem[] => {

      const items: InvoiceItem[] = [];

      const newspaperNotifications = _.get(arrangement, basePath, []);

      if (Array.isArray(newspaperNotifications)) {

        let index = 0;

        for (let newspaperNotificationIndex = 0; newspaperNotificationIndex < newspaperNotifications.length; newspaperNotificationIndex++) {

          const newspaperNotification = newspaperNotifications[newspaperNotificationIndex];

          const service = _.get(newspaperNotification, 'service');
          const lineItems = _.get(newspaperNotification, 'lineItems', []);
          const serviceProvider = getServiceProvider(serviceProviders, service);
          
          let date = _.get(newspaperNotification, 'date', null);

          if (date) {

            date = moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');

          } else {

            date = null;

          }
  
          for (let lineItemIndex = 0; lineItemIndex < lineItems.length; lineItemIndex++) {

            const lineItem = lineItems[lineItemIndex];
  
            const invoiceLineItem = processLineItem(lineItem, serviceProvider, `${basePath}.${newspaperNotificationIndex}.lineItems.${lineItemIndex}`);
          
            if (invoiceLineItem) {
    
              if (_.has(lineItem, 'product.category.name')) {

                invoiceLineItem.title += ' - ' + lineItem.product.category.name;

              }

              if (date) {

                invoiceLineItem.title += ' - ' + date;

              }

              invoiceLineItem.group.title = 'Notification';

              // invoiceLineItem.group.order = 8 + ((index + 1) * 0.001);
              invoiceLineItem.group.order = 8;
    
              items.push(invoiceLineItem);
              
            }
  
          }

          index++;

        }

      }

      return items;

    };

    const processPreparation = (arrangement: any, basePath: string, serviceProviders: any[]): InvoiceItem[] => {

      const items: InvoiceItem[] = [];

      const service = _.get(arrangement, `${basePath}.service`);
      const lineItems = _.get(arrangement, `${basePath}.lineItems`, []);
      const additionalItemsItems = _.get(arrangement, `${basePath}.additionalItems.items`, []);

      if (lineItems && lineItems.length) {

        const serviceProvider = getServiceProvider(serviceProviders, service);

        for (let lineItemIndex = 0; lineItemIndex < lineItems.length; lineItemIndex++) {

          const lineItem = lineItems[lineItemIndex];

          const invoiceLineItem = processLineItem(lineItem, serviceProvider, `${basePath}.lineItems.${lineItemIndex}`);
          
          if (invoiceLineItem) {

            invoiceLineItem.group.title = 'Preparation';
            invoiceLineItem.group.order = 5;

            items.push(invoiceLineItem);

          }

        }

      }

      if (additionalItemsItems && additionalItemsItems.length) {

        for (let additionalItemIndex = 0; additionalItemIndex < additionalItemsItems.length; additionalItemIndex++) {

          const additionalItem = additionalItemsItems[additionalItemIndex];

          const service = _.get(additionalItem, 'service');
          const lineItems = _.get(additionalItem, 'lineItems', []);

          const serviceProvider = getServiceProvider(serviceProviders, service);
  
          for (let lineItemIndex = 0; lineItemIndex < lineItems.length; lineItemIndex++) {

            const lineItem = lineItems[lineItemIndex];
  
            const invoiceLineItem = processLineItem(lineItem, serviceProvider, `${basePath}.additionalItems.items.${additionalItemIndex}.lineItems.${lineItemIndex}`);
          
            if (invoiceLineItem) {
  
              invoiceLineItem.group.title = 'Preparation';
              invoiceLineItem.group.order = 5;
    
              items.push(invoiceLineItem);

            }
    
          }

        }

      }

      return items;

    };

    const processTransfers = (arrangement: any, basePath: string, serviceProviders: any[]): InvoiceItem[] => {

      const items: InvoiceItem[] = [];

      const transfers = _.get(arrangement, basePath, []);

      // for (const transfer of transfers) {
      for (let transferIndex = 0; transferIndex < transfers.length; transferIndex++) {

        const transfer = transfers[transferIndex];

        const transferService = _.get(transfer, 'service', null);
        const transferLineItems = _.get(transfer, 'lineItems', []);
        const transferServiceProvider = getServiceProvider(serviceProviders, transferService);

        if (transferLineItems && transferLineItems.length) {
  
          // for (const transferLineItem of transferLineItems) {
          for (let transferLineItemIndex = 0; transferLineItemIndex < transferLineItems.length; transferLineItemIndex++) {
  
            const transferLineItem = transferLineItems[transferLineItemIndex];

            const invoiceLineItem = processLineItem(transferLineItem, transferServiceProvider, `${basePath}.${transferIndex}.lineItems.${transferLineItemIndex}`);
          
            if (invoiceLineItem) {
    
              invoiceLineItem.group.title = 'Transfer';
              invoiceLineItem.group.order = 10;
    
              items.push(invoiceLineItem);

            }
  
          }
  
        }

      }

      return items;

    };

    const processBDM = (arrangement: any, basePath: string, serviceProviders: any[]): InvoiceItem[] => {

      const items: InvoiceItem[] = [];

      const service = _.get(arrangement, `${basePath}.service`);
      const lineItems = _.get(arrangement, `${basePath}.lineItems`, []);

      if (lineItems && lineItems.length) {

        const serviceProvider = getServiceProvider(serviceProviders, service);

        // for (const lineItem of lineItems) {
        for (let lineItemIndex = 0; lineItemIndex < lineItems.length; lineItemIndex++) {

          const lineItem = lineItems[lineItemIndex];

          const invoiceLineItem = processLineItem(lineItem, serviceProvider, `${basePath}.lineItems.${lineItemIndex}`);
          
          if (invoiceLineItem) {

            invoiceLineItem.group.title = 'Death Certificate';
            invoiceLineItem.group.order = 4;

            items.push(invoiceLineItem);

          }

        }

      }

      return items;

    };

    const processBDMVIC = (arrangement: any, basePath: string, serviceProviders: any[]): InvoiceItem[] => {

      const items: InvoiceItem[] = [];

      const service = _.get(arrangement, `${basePath}.service`);
      const lineItems = _.get(arrangement, `${basePath}.lineItems`, []);

      if (lineItems && lineItems.length) {

        const serviceProvider = getServiceProvider(serviceProviders, service);

        for (let lineItemIndex = 0; lineItemIndex < lineItems.length; lineItemIndex++) {

          const lineItem = lineItems[lineItemIndex];

          const invoiceLineItem = processLineItem(lineItem, serviceProvider, `${basePath}.lineItems.${lineItemIndex}`);
          
          if (invoiceLineItem) {

            invoiceLineItem.group.title = 'Death Certificate';
            invoiceLineItem.group.order = 4;

            items.push(invoiceLineItem);

            }

        }

      }

      return items;

    };

    const processBDMQLD = (arrangement: any, basePath: string, serviceProviders: any[]): InvoiceItem[] => {

      const items: InvoiceItem[] = [];

      const service = _.get(arrangement, `${basePath}.service`);
      const lineItems = _.get(arrangement, `${basePath}.lineItems`, []);

      if (lineItems && lineItems.length) {

        const serviceProvider = getServiceProvider(serviceProviders, service);

        for (let lineItemIndex = 0; lineItemIndex < lineItems.length; lineItemIndex++) {

          const lineItem = lineItems[lineItemIndex];

          const invoiceLineItem = processLineItem(lineItem, serviceProvider, `${basePath}.lineItems.${lineItemIndex}`);
          
          if (invoiceLineItem) {

            invoiceLineItem.group.title = 'Death Certificate';
            invoiceLineItem.group.order = 4;

            items.push(invoiceLineItem);

            }

        }

      }

      return items;

    };

    const processMedicalCertificates = (arrangement: any, basePath: string): InvoiceItem[] => {

      const items: InvoiceItem[] = [];

      const disposalType = _.get(arrangement, 'disposal.type.value');

      if (disposalType === methodOfDisposalType.Cremated) {
        
        const attendingPractitionerMedicalCertificateCauseOfDeath = _.get(arrangement, `${basePath}.attendingPractitionerMedicalCertificateCauseOfDeath`, {});
        const attendingPractitionerCremationRiskAdvice = _.get(arrangement, `${basePath}.attendingPractitionerCremationRiskAdvice`, {});
        const medicalRefereeCremationPermit = _.get(arrangement, `${basePath}.medicalRefereeCremationPermit`, {});

        if (attendingPractitionerMedicalCertificateCauseOfDeath.name) {

          const cost = formatDollarAndCents(attendingPractitionerMedicalCertificateCauseOfDeath.cost.amount);

          const invoiceLineItem: InvoiceItem = {
            path: `${basePath}.attendingPractitionerMedicalCertificateCauseOfDeath`,
            formControlId: attendingPractitionerMedicalCertificateCauseOfDeath.cost.uuid,
            invalid: cost === null,
            title: 'Attending Practitioner Medical Certificate Cause of Death',
            description: '',
            price: (cost === null) ? 0 : cost,
            quantity: 1,
            cost: (cost === null) ? 0 : cost,
            group: { title: 'Medical Certificates', description: '', order: 1}
          }

          if (attendingPractitionerMedicalCertificateCauseOfDeath.cost.xeroTaskId) {
            invoiceLineItem.xeroTaskId = attendingPractitionerMedicalCertificateCauseOfDeath.cost.xeroTaskId;
          }

          if (cost === null) {
            invoiceLineItem.invalidValue = attendingPractitionerMedicalCertificateCauseOfDeath.cost.amount;
          }

          items.push(invoiceLineItem);

        }

        if (attendingPractitionerCremationRiskAdvice.name) {

          const cost = formatDollarAndCents(attendingPractitionerCremationRiskAdvice.cost.amount);

          const invoiceLineItem: InvoiceItem = {
            path: `${basePath}.attendingPractitionerCremationRiskAdvice`,
            formControlId: attendingPractitionerCremationRiskAdvice.cost.uuid,
            invalid: cost === null,
            title: 'Attending Practitioner Cremation Risk Advice',
            description: '',
            price: (cost === null) ? 0 : cost,
            quantity: 1,
            cost: (cost === null) ? 0 : cost,
            group: { title: 'Medical Certificates', description: '', order: 1}
          }

          if (attendingPractitionerCremationRiskAdvice.cost.xeroTaskId) {
            invoiceLineItem.xeroTaskId = attendingPractitionerCremationRiskAdvice.cost.xeroTaskId;
          }

          if (cost === null) {
            invoiceLineItem.invalidValue = attendingPractitionerCremationRiskAdvice.cost.amount;
          }

          items.push(invoiceLineItem);

        }

        if (medicalRefereeCremationPermit.name) {

          const cost = formatDollarAndCents(medicalRefereeCremationPermit.cost.amount);

          const invoiceLineItem: InvoiceItem = {
            path: `${basePath}.medicalRefereeCremationPermit`,
            formControlId: medicalRefereeCremationPermit.cost.uuid,
            invalid: cost === null,
            title: 'Medical Referee Cremation Permit',
            description: '',
            price: (cost === null) ? 0 : cost,
            quantity: 1,
            cost: (cost === null) ? 0 : cost,
            group: { title: 'Medical Certificates', description: '', order: 1}
          }

          if (medicalRefereeCremationPermit.cost.xeroTaskId) {
            invoiceLineItem.xeroTaskId = medicalRefereeCremationPermit.cost.xeroTaskId;
          }

          if (cost === null) {
            invoiceLineItem.invalidValue = medicalRefereeCremationPermit.cost.amount;
          }

          items.push(invoiceLineItem);

        }

      }
      
      if (disposalType === methodOfDisposalType.Buried) {

        const attendingPractitionerMedicalCertificateCauseOfDeath = _.get(arrangement, `${basePath}.attendingPractitionerMedicalCertificateCauseOfDeath`, {});

        if (attendingPractitionerMedicalCertificateCauseOfDeath.name) {

          const cost = formatDollarAndCents(attendingPractitionerMedicalCertificateCauseOfDeath.cost.amount);

          const invoiceLineItem: InvoiceItem = {
            path: `${basePath}.attendingPractitionerMedicalCertificateCauseOfDeath`,
            formControlId: attendingPractitionerMedicalCertificateCauseOfDeath.cost.uuid,
            invalid: cost === null,
            title: 'Attending Practitioner Medical Certificate Cause of Death',
            description: '',
            price: (cost === null) ? 0 : cost,
            quantity: 1,
            cost: (cost === null) ? 0 : cost,
            group: { title: 'Medical Certificates', description: '', order: 1}
          }

          if (attendingPractitionerMedicalCertificateCauseOfDeath.cost.xeroTaskId) {
            invoiceLineItem.xeroTaskId = attendingPractitionerMedicalCertificateCauseOfDeath.cost.xeroTaskId;
          }

          if (cost === null) {
            invoiceLineItem.invalidValue = attendingPractitionerMedicalCertificateCauseOfDeath.cost.amount;
          }

          items.push(invoiceLineItem);

        }

      }

      return items;

    };

    const processEstablishmentFee = (arrangement: any, basePath: string): InvoiceItem[] => {

      const items: InvoiceItem[] = [];

      const isPrepaidType = _.get(arrangement, `type.value`) === ArrangementType.PrePaid;

      if (isPrepaidType) {

        const prepaidEstablishmentFee = _.get(arrangement, `${basePath}.prepaidEstablishmentFee`, {});

        const cost = formatDollarAndCents(prepaidEstablishmentFee.cost.amount);

        const invoiceLineItem: InvoiceItem = {
          path: `${basePath}.prepaidEstablishmentFee`,
          formControlId: prepaidEstablishmentFee.cost.uuid,
          invalid: cost === null,
          title: 'Prepaid Establishment Fee',
          description: '',
          price: (cost === null) ? 0 : cost,
          quantity: 1,
          cost: (cost === null) ? 0 : cost,
          group: { title: 'Additional Costs', description: '', order: 11}
        }

        if (prepaidEstablishmentFee.cost.xeroTaskId) {
          invoiceLineItem.xeroTaskId = prepaidEstablishmentFee.cost.xeroTaskId;
        }

        if (cost === null) {
          invoiceLineItem.invalidValue = prepaidEstablishmentFee.cost.amount;
        }

        items.push(invoiceLineItem);
        
      }

      return items;

    };

    // TODO: Quickfix - change to permanent solution
    const processNSWCemAndCremCost = (arrangement: any, basePath: string): InvoiceItem[] => {
      // if place of death not NSW - ignore!
      if (_.get(arrangement, 'deceased.placeOfPassing.address.state.value') !== 'NSW') {
        return [];
      }

      const items: InvoiceItem[] = [];
      const disposalType = _.get(arrangement, 'disposal.type.value');

      if (disposalType === methodOfDisposalType.Cremated) {

        const isAshInterment = _.get(arrangement, 'disposal.cremation.ashIntermentSet');
        const cremationFee = _.get(arrangement, `${basePath}.cremationFee`, {});
        
        if (cremationFee.name) {

          const cost = formatDollarAndCents(cremationFee.cost.amount);

          const invoiceLineItem: InvoiceItem = {
            path: `${basePath}.cremationFee`,
            formControlId: cremationFee.cost.uuid,
            invalid: cost === null,
            title: 'NSW Govt Cremation Levy',
            description: '',
            price: (cost === null) ? 0 : cost,
            quantity: 1,
            cost: (cost === null) ? 0 : cost,
            group: { title: 'Additional Costs', description: '', order: 11}
          }

          if (cremationFee.cost.xeroTaskId) {
            invoiceLineItem.xeroTaskId = cremationFee.cost.xeroTaskId;
          }

          if (cost === null) {
            invoiceLineItem.invalidValue = cremationFee.cost.amount;
          }

          items.push(invoiceLineItem);

        }
        
        if (isAshInterment) {
          const ashIntermentFee = _.get(arrangement, `${basePath}.ashIntermentFee`, {});

          if (ashIntermentFee.name) {

            const cost = formatDollarAndCents(ashIntermentFee.cost.amount);

            const invoiceLineItem: InvoiceItem = {
              path: `${basePath}.ashIntermentFee`,
              formControlId: ashIntermentFee.cost.uuid,
              invalid: cost === null,
              title: 'NSW Govt Ash Interment Levy',
              description: '',
              price: (cost === null) ? 0 : cost,
              quantity: 1,
              cost: (cost === null) ? 0 : cost,
              group: { title: 'Additional Costs', description: '', order: 11}
            }

            if (ashIntermentFee.cost.xeroTaskId) {
              invoiceLineItem.xeroTaskId = ashIntermentFee.cost.xeroTaskId;
            }

            if (cost === null) {
              invoiceLineItem.invalidValue = ashIntermentFee.cost.amount;
            }

            items.push(invoiceLineItem);

              
          }

        }

      }
      
      if (disposalType === methodOfDisposalType.Buried) {

        const burialFee = _.get(arrangement, `${basePath}.burialFee`, {});

        if (burialFee.name) {
          const cost = formatDollarAndCents(burialFee.cost.amount);

          const invoiceLineItem: InvoiceItem = {
            path: `${basePath}.burialFee`,
            formControlId: burialFee.cost.uuid,
            invalid: cost === null,
            title: 'NSW Govt Burial Levy',
            description: '',
            price: (cost === null) ? 0 : cost,
            quantity: 1,
            cost: (cost === null) ? 0 : cost,
            group: { title: 'Additional Costs', description: '', order: 11}
          }

          if (burialFee.cost.xeroTaskId) {
            invoiceLineItem.xeroTaskId = burialFee.cost.xeroTaskId;
          }

          if (cost === null) {
            invoiceLineItem.invalidValue = burialFee.cost.amount;
          }

          items.push(invoiceLineItem);

        }

      }

      return items;
    }

    const processArranger = (arrangement: any, basePath: string, serviceProviders: any[]): InvoiceItem[] => {

      const items: InvoiceItem[] = [];

      const service = _.get(arrangement, `${basePath}.service`);
      const lineItems = _.get(arrangement, `${basePath}.lineItems`, []);

      if (lineItems && lineItems.length) {

        const serviceProvider = getServiceProvider(serviceProviders, service);

        for (let lineItemIndex = 0; lineItemIndex < lineItems.length; lineItemIndex++) {

          const lineItem = lineItems[lineItemIndex];

          const invoiceLineItem = processLineItem(lineItem, serviceProvider, `${basePath}.lineItems.${lineItemIndex}`);
          
          if (invoiceLineItem) {

            invoiceLineItem.group.title = 'Arranger';
            invoiceLineItem.group.order = 2;

            items.push(invoiceLineItem);

          }

        }

      }

      return items;

    };

    let arrangement = this.formAsPostData();

    const all = combineLatest([
      this.accountService.account$, 
      this.serviceProviderService.allServiceProviders$
    ]);

    this.siteService.addSubscriptionLog(this, 'arrangement.ts->processArrangementInvoice->processArranger->return all');

    return all.pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('arrangement.ts->processArrangementInvoice->processArranger->return all')),
      takeUntil(this.unsubscribe$),
      switchMap(([account, serviceProviders]: [any, any[]]) => {

        let invoiceItems: InvoiceItem[] = [];

        // NOTE: The order of the invoice items is important. The invoice items are grouped by the `group.order` property.
        //       If they are duplicated, they'll be grouped together. This will cause issues when displaying the invoice items.
        //       So make sure the group order is unique for each group.

        invoiceItems = _.concat([],
          processDisposal(arrangement, 'disposal', serviceProviders), // group order 3
          processEvents(arrangement, 'events', serviceProviders), // group order 6
          processNewspaperNotifications(arrangement, 'newspaperNotifications', serviceProviders), // group order 8
          processPreparation(arrangement, 'preparation', serviceProviders), // group order 5
          processTransfers(arrangement, 'transfer.transfers', serviceProviders), // group order 10
          // processBDM(arrangement, 'birthsDeathsAndMarriages.nsw', serviceProviders), // group order 4
          // processBDM(arrangement, 'birthsDeathsAndMarriages.vic', serviceProviders), // group order 4
          // processBDM(arrangement, 'birthsDeathsAndMarriages.qld', serviceProviders), // group order 4
          processMedicalCertificates(arrangement, 'medicalCertificate'), // group order 1
          processEstablishmentFee(arrangement, 'preparation'), // group order 11
          processNSWCemAndCremCost(arrangement, 'nswCemeteryAndCremCost'), // group order 11
          processArranger(arrangement, 'arrangementMeeting', serviceProviders), // group order 2
        );

        if (arrangement.birthsDeathsAndMarriages.apiTarget === BirthDeathsAndMarriagesApiTarget.NewSouthWales) {

          invoiceItems = _.concat(invoiceItems, processBDM(arrangement, 'birthsDeathsAndMarriages.nsw', serviceProviders)); // group order 4

        } else if (arrangement.birthsDeathsAndMarriages.apiTarget === BirthDeathsAndMarriagesApiTarget.Victoria) {

          invoiceItems = _.concat(invoiceItems, processBDM(arrangement, 'birthsDeathsAndMarriages.vic', serviceProviders)); // group order 4

        } else if (arrangement.birthsDeathsAndMarriages.apiTarget === BirthDeathsAndMarriagesApiTarget.Queensland) {

          invoiceItems = _.concat(invoiceItems, processBDM(arrangement, 'birthsDeathsAndMarriages.qld', serviceProviders)); // group order 4

        } else {

          // Fall back to NSW if no API target is set
          invoiceItems = _.concat(invoiceItems, processBDM(arrangement, 'birthsDeathsAndMarriages.nsw', serviceProviders)); // group order 4

        }

        return of(invoiceItems);

      })
    );

  }

  public processCheckList(): void {

    const arrangementTasks = new ArrangementTasks();

    arrangementTasks.process(this, this.userService.userSourceValue);

  }

  public processRelevantServices() {

    const extractServices = (data: any, paths: string[]) => {

      const services: Service[] = [];

      for (const path of paths) {

        const service: Service = _.get(data, path);

        if (service) {

          services.push(service);

        }

      }

      return services;

    };

    const processTransfers = (data: any) => {

      const transfers: any[] = _.get(data, 'transfer.transfers', []);

      return transfers.flatMap(transfer => extractServices(transfer, ['service']));

    }

    const processEvents = (data: any) => {

      const services: Service[] = [];

      const events: any[] = _.get(data, 'events', []);

      events.forEach(event => {

        // Process venue
        const venue = _.get(event, 'venue', null);

        if (venue) {

          services.push(...extractServices(venue, ['service']));

        }

        // Process items
        const items = _.get(event, 'items', []);

        items.forEach((item: any) => {

          services.push(...extractServices(item, ['service']));
      
        })

      })

      return services;

    };

    const processBirthsDeathsAndMarriagesNsw = (data: any) => {

      return extractServices(data, ['birthsDeathsAndMarriages.nsw.service']);      
      
    };

    const processNewspaperNotifications = (data: any) => {

      const newspaperNotifications: any[] = _.get(data, 'newspaperNotifications', []);

      return newspaperNotifications.flatMap(newspaperNotification => {

        return extractServices(newspaperNotification, ['service'])

      })

    };

    const processDisposal = (data: any): any => {

      const services: Service[] = [];
         
      const burialService = _.get(data, 'disposal.burial.service', null);
      const cremationService = _.get(data, 'disposal.cremation.service', null);
      const additionalItems = _.get(data, 'disposal.additionalItems.items', []);

      if (burialService) {

        services.push(...extractServices(data, ['disposal.burial.service']));

      }

      if (cremationService) {

        services.push(...extractServices(data, ['disposal.cremation.service']));

      }

      additionalItems.forEach((additionalItem: any) => {

        services.push(...extractServices(additionalItem, ['service']));
        
      })
      

      return services;
      
    };

    const processPreparation = (data: any): any => {

      const services: Service[] = [];
      const additionalItems = _.get(data, 'preparation.additionalItems.items', []);

      services.push(...extractServices(data, ['preparation.service']))

      additionalItems.forEach((additionalItem: any) => {

        services.push(...extractServices(additionalItem, ['service']))

      })

      return services;

    };

    const processArrangerService = (data: any) => {

      return extractServices(data, ['arrangementMeeting.service']);
      
    }


    const data = this.form.value;

    const services: Service[] = [];

    services.push(...processTransfers(data));
    services.push(...processArrangerService(data));
    services.push(...processEvents(data));
    services.push(...processBirthsDeathsAndMarriagesNsw(data));
    services.push(...processNewspaperNotifications(data));
    services.push(...processDisposal(data));
    services.push(...processPreparation(data));

    this.relevantServicesSource.next(services);

  }

  private getSyncOrGenerateProjectData(invoiceItems: InvoiceItem[], costs: ArrangementCosts) {

    const deceased = this.form.get('deceased')?.value;

    const deceasedNameValue = this.form.get('deceased.name')!.value;
    const accountContactValue = this.form.get('invoice.accountContact')!.value;
    const authoriserContactValue = this.form.get('invoice.authoriserContact')!.value;
    const arrangerValue = this.form.get('arrangementMeeting.arranger')!.value;

    // Account contact has higher priority to be billed
    const contactToBill = accountContactValue.id ? accountContactValue : authoriserContactValue;

    // This is the Xero invoice ref (if needed)
    const xeroRef = `${deceasedNameValue.first}-${deceasedNameValue.last}`.toLowerCase();

    const authoriserContact = {
      firstName: _.get(authoriserContactValue, 'name.first', ''),
      lastName: _.get(authoriserContactValue, 'name.last', ''),
    };

    // This is the Xero Contact (you can modify it as needed)
    const xeroContact = {
      firstName: _.get(contactToBill, 'name.first', ''),
      lastName: _.get(contactToBill, 'name.last', ''),
      phone: _.get(contactToBill, 'phone.primary', ''),
      email: _.get(contactToBill, 'email.primary', ''),
      address: _.get(contactToBill, 'address', ''),
    };

    if (contactToBill.phone.length) {
      xeroContact.phone = _.get(contactToBill, 'phone.0.phone', '');
    }

    if (contactToBill.email.length) {
      xeroContact.email = _.get(contactToBill, 'email.0.email', '');
    }

    const authoriserSignature = this.form.get('invoice.authoriserSignature')?.value?.data;
    
    let authoriserSignatureDate = this.form.get('invoice.authoriserSignature')?.value?.date;

    if (moment.isDate(authoriserSignatureDate) && moment(authoriserSignatureDate, true).isValid()) {
      authoriserSignatureDate = moment(authoriserSignatureDate, true).format('DD/MM/YYYY h:mm a');
    } else {
      authoriserSignatureDate = '';
    }

    let arranger;

    if (arrangerValue && arrangerValue.data) {
      arranger = {
        firstName: arrangerValue.data.firstName,
        lastName: arrangerValue.data.lastName,
        email: arrangerValue.data.email
      }
    }

    return { deceased, xeroRef, xeroContact, authoriserContact, invoiceItems, costs, authoriserSignature, authoriserSignatureDate, arranger };

  }

  async validateAndGetInvoiceData() {

    const invoiceItems = await this.getArrangementInvoice().pipe(first()).toPromise();

    const costs = this.calculateCosts(invoiceItems);

    const invalidInvoiceItems = invoiceItems.filter(invoiceItem => invoiceItem.invalid);

    if (invalidInvoiceItems.length) {

      this.modalService.generic({
        title: 'Invalid Invoice Item Found',
        copy: ['We noticed 1 or more invoice items are invalid.', 'Before you can continue, review the line items highlighted with a <span style="font-weight: bold; color: red;">red</span> icon.'],
        buttons: [
          { label: 'Close', key: 'close', class: '' },
        ],
      });

      return;

    }

    const validInvoiceItems = _.cloneDeep(invoiceItems).filter(invoiceItem => invoiceItem.cost > 0);

    return {
      invoiceItems: validInvoiceItems,
      costs,
    }
  }

  calculateCosts(invoiceItems: InvoiceItem[]) {
    let total = 0;
    let serviceFee = 0;
    let totalIncServiceFee = 0;
    let totalIncGst = 0;
    let gst = 0;

    for (const invoiceItem of invoiceItems) {

      total += invoiceItem.cost;

    }

    const serviceFeeIncludedFormControl = this.form.get('invoice.serviceFee.included');
    const serviceFeePercentFormControl = this.form.get('invoice.serviceFee.percent');
    // Primeng inputNumber weird bug
    // If input value = 50.77, tt returns 50.77000045776367 instead
    // So we need round it beforehand :)
    const serviceFeePercent = parseFloat(serviceFeePercentFormControl!.value.toFixed(2));
  
    serviceFee = total * serviceFeePercent / 100;

    if (serviceFeeIncludedFormControl) {

      const included = _.get(serviceFeeIncludedFormControl.value, 'value', 'yes');

      if (included === 'no') {

        serviceFee = 0;

      }

    }

    totalIncServiceFee = total + serviceFee;

    totalIncGst = totalIncServiceFee * 1.1;

    gst = totalIncGst / 11;

    return { total, serviceFee, serviceFeePercent, totalIncServiceFee, totalIncGst, gst };
  }

}
