import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusinessCardComponent } from './business-card.component';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { AvatarModule } from 'primeng/avatar';
import { SplitButtonModule } from 'primeng/splitbutton';

@NgModule({
  declarations: [BusinessCardComponent],
  imports: [
    CommonModule,
    CardModule,
    ButtonModule,
    DividerModule,
    AvatarModule,
    SplitButtonModule,
  ],
  exports: [BusinessCardComponent],
})
export class BusinessCardModule { }
