import { Injectable } from '@angular/core';
import { Error } from '../models/error-response';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { ErrorComponent } from '../modals/error/error.component';
import { GenericComponent } from '../modals/generic/generic.component';
import { CompilerComponent } from '../modals/compiler/compiler.component';
import { InputTextAreaComponent } from '../modals/input-text-area/input-text-area.component';
import { InputNameValuePairComponent } from '../modals/input-name-value-pair/input-name-value-pair.component';
import { FormGroup } from '@angular/forms';
import { AccountContactComponent } from '../modals/account-contact/account-contact.component';
import { AccountContactListComponent } from '../modals/account-contact-list/account-contact-list.component';
import { AccountArrangementSourceListComponent } from '../modals/account-arrangement-source-list/account-arrangement-source-list.component';
import { AccountReligionListComponent } from '../modals/account-religion-list/account-religion-list.component';
import { AccountHospitalAndNursingHomeComponent } from '../modals/account-hospital-and-nursing-home/account-hospital-and-nursing-home.component';
import { AccountHospitalAndNursingHomeListComponent } from '../modals/account-hospital-and-nursing-home-list/account-hospital-and-nursing-home-list.component';
import { InputSignatureComponent } from '../modals/input-signature/input-signature.component';
import { ActivateAccountModalComponent } from 'src/app/pages/register/activate-account-modal/activate-account-modal.component';
import { AccountEventTypeListComponent } from '../modals/account-event-type-list/account-event-type-list.component';
import { AccountCharityListComponent } from '../modals/account-charity-list/account-charity-list.component';
import { BdmNswAuthenticateComponent } from '../modals/bdm-nsw-authenticate/bdm-nsw-authenticate.component';
import { PanelType, SiteService } from './site.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Arrangement } from '../classes/arrangement';
import { ImageCropperModalComponent } from '../modals/image-cropper/image-cropper-modal.component';
import { SavePromptComponent } from '../modals/save-prompt/save-prompt.component';
import { InputTextInputComponent } from '../modals/input-text-input/input-text-input.component';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { AccountFirstCallSource } from '../modals/account-firstcall-source/account-firstcall-source.component';
import { AccountFirstCallSourceGroupListComponent } from '../modals/account-firstcall-sourcegroup-list/account-firstcall-sourcegroup-list.component';
import { CreateTransferComponent } from '../modals/create-transfer/create-transfer.component';
import { CreateEventComponent } from '../modals/create-event/create-event.component';
import { MortuaryPreparationOptionsComponent } from '../modals/mortuary-preparation-options/mortuary-preparation-options.component';
import { ShowPdfComponent } from '../modals/show-pdf/show-pdf.component';
import { AccountLocality } from '../modals/account-locality/account-locality.component';
import { AccountLocalityGroupListComponent } from '../modals/account-localitygroup-list/account-localitygroup-list.component';
import { AccountArrangementLocalityListComponent } from '../modals/account-arrangement-locality-list/account-arrangement-locality-list.component';

export interface ModalDataButton {
  label: string;
  key: string;
  class: string;
  icon?: string,
  handler?: () => Observable<any>,
  loading?: boolean,
  disabled?: boolean,
}

export interface ModalData {
  baseZIndex?: number;
  autoZIndex?: boolean;
  isLoading?: boolean;
  preventClose?: boolean;
  title: string;
  copy?: string[];
  list?: string[];
  buttons: ModalDataButton[];
  data?: any;
};

export interface SiteContactModalData {
  _meta: { id: string; index: number; deleted?: boolean };
  name: string;
  paths: { path: string, _meta: { id: string; index: number; deleted?: boolean } }[];
  contact: any;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {

  constructor(
    private dialogService: DialogService, 
    private messageService: MessageService,
    private siteService: SiteService,
    private router: Router,
  ) { }

  showPdf(data: any): DynamicDialogRef {
    
    return this.dialogService.open(ShowPdfComponent, {
      header: data.title || 'View PDF',
      styleClass: 'modal modal-generic',
      width: '90vw',
      height: '90vh',
      baseZIndex: 10000,
      data: data,
    });

  }

  error(data: any): DynamicDialogRef {

    let title = 'Conductor Error';

    if (_.has(data, 'error.error.title')) {
      title = data.error.error.title;
    } else if (_.has(data, 'data.error.title')) {
      title = data.error.title;
    } else if (_.has(data, 'data.title')) {
      title = data.title;
    }

    return this.dialogService.open(ErrorComponent, {
      header: title,
      // width: '70%',
      // contentStyle: { "max-height": "500px", "overflow": "auto" },
      styleClass: 'modal modal-error',
      baseZIndex: 10000,
      data: data,
    });

  }

  generic(data: ModalData): DynamicDialogRef {

    const options = {
      header: data?.title,
      autoZIndex: (data.autoZIndex !== undefined) ? data.autoZIndex : true,
      baseZIndex: (data.baseZIndex !== undefined) ? data.baseZIndex : 10000,
      // width: '70%',
      // contentStyle: { "max-height": "500px", "overflow": "auto" },
      styleClass: 'modal modal-generic',
      data: data,
    };

    return this.dialogService.open(GenericComponent, options);

  }

  imageCropper(imageBase64: string): DynamicDialogRef {

    return this.dialogService.open(ImageCropperModalComponent, {
      header: 'Avatar Image',
      styleClass: 'modal modal-generic',
      baseZIndex: 10000,
      data: {
        imageBase64
      },
    });

  }

  bdmNswAuthenticate(data: any): DynamicDialogRef {

    return this.dialogService.open(BdmNswAuthenticateComponent, {
      header: 'BDM NSW Authenticate',
      styleClass: 'modal modal-generic',
      baseZIndex: 10000,
      data: data,
    });

  }

  bdmVicAuthenticate(data: any): DynamicDialogRef {

    return this.dialogService.open(BdmNswAuthenticateComponent, {
      header: 'BDM VIC Authenticate',
      styleClass: 'modal modal-generic',
      baseZIndex: 10000,
      data: data,
    });

  }

  bdmQldAuthenticate(data: any): DynamicDialogRef {

    return this.dialogService.open(BdmNswAuthenticateComponent, {
      header: 'BDM QLD Authenticate',
      styleClass: 'modal modal-generic',
      baseZIndex: 10000,
      data: data,
    });

  }

  mortuaryPreparationOptions(data: any): DynamicDialogRef {

    return this.dialogService.open(MortuaryPreparationOptionsComponent, {
      header: 'Mortuary Preparation Options',
      styleClass: 'modal modal-generic',
      baseZIndex: 10000,
      data: data,
    });

  }

  addArrangementSource(data?: any): DynamicDialogRef {

    return this.dialogService.open(InputNameValuePairComponent, {
      header: 'Add Arrangement Source',
      styleClass: 'modal',
      baseZIndex: 10000,
      data: data,
    });

  }

  listArrangementSource(data?: any): DynamicDialogRef {

    return this.dialogService.open(AccountArrangementSourceListComponent, {
      header: 'List Arrangement Source',
      styleClass: 'modal',
      baseZIndex: 10000,
      data: data,
    });

  }

  listArrangementLocality(data?: any): DynamicDialogRef {

    return this.dialogService.open(AccountArrangementLocalityListComponent, {
      header: 'List Arrangement Locality',
      styleClass: 'modal',
      baseZIndex: 10000,
      data: data,
    });

  }

  listReligion(data?: any): DynamicDialogRef {

    return this.dialogService.open(AccountReligionListComponent, {
      header: 'List Religion',
      styleClass: 'modal',
      baseZIndex: 10000,
      data: data,
    });

  }

  listEventType(data?: any): DynamicDialogRef {

    return this.dialogService.open(AccountEventTypeListComponent, {
      header: 'List Event Type',
      styleClass: 'modal',
      baseZIndex: 10000,
      data: data,
    });

  }

  listCharities(data?: any): DynamicDialogRef {

    return this.dialogService.open(AccountCharityListComponent, {
      header: 'List Charities',
      styleClass: 'modal',
      baseZIndex: 10000,
      data: data,
    });

  }

  addAccountContact(data?: SiteContactModalData): DynamicDialogRef {

    return this.dialogService.open(AccountContactComponent, {
      header: 'Add Account Contact',
      styleClass: 'modal overflow-visible',
      baseZIndex: 10000,
      data: data,
    });

  }

  listAccountContact(data: any): DynamicDialogRef {

    return this.dialogService.open(AccountContactListComponent, {
      header: 'Edit Account Contact',
      styleClass: 'modal modal-contact-list',
      baseZIndex: 10000,
      data: data,
    });

  }

  compiler(data?: any): DynamicDialogRef {

    return this.dialogService.open(CompilerComponent, {
      header: 'Compiler Example',
      styleClass: 'modal',
      baseZIndex: 10000,
      data: data,
    });

  }

  inputTextArea(data?: ModalData): DynamicDialogRef {

    return this.dialogService.open(InputTextAreaComponent, {
      header: data?.title,
      styleClass: 'modal modal-input',
      baseZIndex: 10000,
      data: data,
    });

  }

  inputTextInput(data?: ModalData): DynamicDialogRef {

    return this.dialogService.open(InputTextInputComponent, {
      header: data?.title,
      styleClass: 'modal modal-input',
      baseZIndex: 10000,
      data: data,
    });

  }

  inputNameValuePair(data?: any): DynamicDialogRef {

    return this.dialogService.open(InputNameValuePairComponent, {
      header: data?.title,
      styleClass: 'modal modal-input',
      baseZIndex: 11000,
      data: data,
    });

  }

  addAccountHospitalOrNursingHome(data?: any): DynamicDialogRef {

    return this.dialogService.open(AccountHospitalAndNursingHomeComponent, {
      header: data?.title,
      styleClass: 'modal modal-input',
      baseZIndex: 11000,
      data: data,
    });

  }

  listAccountHospitalOrNursingHome(): DynamicDialogRef {

    return this.dialogService.open(AccountHospitalAndNursingHomeListComponent, {
      header: 'Edit existing Hospitals / Nursing Homes',
      styleClass: 'modal modal-input',
      baseZIndex: 11000,
      data: null,
    });

  }

  inputSignature(data?: ModalData): DynamicDialogRef {

    return this.dialogService.open(InputSignatureComponent, {
      header: data?.title,
      styleClass: 'modal modal-signature',
      baseZIndex: 11000,
      data: data,
      closable: false,
      closeOnEscape: false
    });

  }

  activateAccount(): DynamicDialogRef {

    return this.dialogService.open(ActivateAccountModalComponent, {
      header: 'Activate Account',
      styleClass: 'modal',
      baseZIndex: 11000,
      closable:false
    });

  }

  existingArrangementSaved(showNextButton: boolean = true): DynamicDialogRef {

    const options = {
      title: 'Arrangement Updated Successfully',
      copy: [
        `You successfully updated the arrangement.`
      ],
      buttons: [
        { label: 'Stay on this Page', key: 'stay', class: 'p-button-secondary' },
      ]
    };

    if (showNextButton) {

      options.buttons.push({ label: 'Go to Next Page', key: 'next-page', class: 'p-button-primary' });

    }

    const modal = this.generic(options);

    return modal;

  }

  newArrangementSaved(arrangement: Arrangement): void {

    const modal = this.generic({
      title: 'Arrangement Created Successfully',
      copy: [
        `You successfully created a new arrangement!`,
        `Do you want to continue filling out the arrangement? Or do you want to view other existing arrangements?`
      ],
      buttons: [
        { label: 'View other existing arrangements', key: 'view-existing', class: 'p-button-secondary' },
        { label: 'Continue filling out the arrangement', key: 'continue', class: '' },
      ]
    });

    modal.onClose.subscribe({
      next: (key: string) => {

        if (key === 'view-existing') {

          this.router.navigate(['dashboard', 'arrangements', 'list']);
          
        } else {
          
          const timeoutDuration = 500;

          this.siteService.openPanel(PanelType.ArrangementDetail, { timeoutDuration: timeoutDuration, arrangement: arrangement });
          
          setTimeout(() => {

            this.router.navigate(['dashboard', 'arrangements', 'list']);

          }, timeoutDuration);

        }

      }
    });

  }

  savePromptOnNavigate(arrangement: Arrangement, compare: any): DynamicDialogRef {

    return this.dialogService.open(SavePromptComponent, {
      header: 'Save Changes?',
      styleClass: 'modal modal-generic',
      baseZIndex: 10000,
      data: { arrangement, type: 'navigate', compare },
    });

  }

  savePromptOnClose(arrangement: Arrangement, compare: any): DynamicDialogRef {

    return this.dialogService.open(SavePromptComponent, {
      header: 'Save Changes?',
      styleClass: 'modal modal-generic',
      baseZIndex: 10000,
      data: { arrangement, type: 'close', compare },
    });

  }

  addAccountFirstCallSource(data?: any): DynamicDialogRef {

    return this.dialogService.open(AccountFirstCallSource, {
      header: data?.title,
      styleClass: 'modal modal-input',
      baseZIndex: 11000,
      data: data,
    });

  }

  addAccountLocality(data?: any): DynamicDialogRef {

    return this.dialogService.open(AccountLocality, {
      header: data?.title,
      styleClass: 'modal modal-input',
      baseZIndex: 11000,
      data: data,
    });

  }

  listAccountFirstCallSourceGroup(data?: any): DynamicDialogRef {
    return this.dialogService.open(AccountFirstCallSourceGroupListComponent, {
      header: 'List Arrangement Source Group',
      styleClass: 'modal',
      baseZIndex: 10000,
      data: data,
    });

  }

  createTransfer(data: any): DynamicDialogRef {

    return this.dialogService.open(CreateTransferComponent, {
      header: 'Create New Transfer',
      styleClass: 'modal',
      baseZIndex: 10000,
      data: data,
    });

  }

  listAccountLocalityGroup(data?: any): DynamicDialogRef {

    return this.dialogService.open(AccountLocalityGroupListComponent, {
      header: 'List Arrangement Locality Group',
      styleClass: 'modal',
      baseZIndex: 10000,
      data: data,
    });

  }

  createEvent(data: any): DynamicDialogRef {

    return this.dialogService.open(CreateEventComponent, {
      header: 'Create New Event',
      styleClass: 'modal',
      baseZIndex: 10000,
      data: data,
    });

  }

}
