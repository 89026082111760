import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JailedService {

  private jailed: any;

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {

    if (!this.document.defaultView) {

      console.error(`Couldn't find Window property in DOCUMENT`);

    } else {

      this.jailed = (this.document.defaultView as any).jailed;

    }

  }

  execute(code: string, scope: any): Promise<string | number | boolean | null> {

    const jailed = this.jailed;

    return new Promise(function (resolve, reject) {

      try {

        if (!jailed || !jailed.DynamicPlugin) {

          throw 'Jailed object isn\'t available.';

        } else {

          // Start

          const boilerplate = `
            application.setInterface({
              // Function called by app to start script
              run: async (data, done) => {
                
                ${ code }

              }
            })
          `;
        
          const process = new jailed.DynamicPlugin(boilerplate);
        
          process.whenConnected(()=> {
            process.remote.run(scope, resolve)
          });
          
          // End
          
        }
  
      } catch (e) {
  
        reject(e);
  
      }

    });

  }

}
