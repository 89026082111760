<div *ngIf="isReady" class="p-inputgroup">
  <p-multiSelect 
    #multiSelectElement 
    [group]="showGrouped"
    [formControlName]="controlName" 
    [options]="clonedOptions | ignoreNull" 
    [placeholder]="placeHolder" 
    [ngClass]="{ 'ng-invalid ng-dirty' : formControl?.invalid && isSubmittedControl?.value }"
    (onChange)="_onChange($event)"
    optionLabel="name" 
    display="chip"
  >
  <ng-template let-group pTemplate="group">
    <div class="p-d-flex p-ai-center">
      <span>{{group.name}}</span>
    </div>
  </ng-template>
  </p-multiSelect>
  <ng-container *ngIf="showOptions">
    <p-menu #optionsMenu [popup]="true" [model]="menuOptions" appendTo="body"></p-menu>
    <button type="button" pButton pRipple icon="pi pi-cog" (click)="optionsMenu.toggle($event)"></button> 
  </ng-container>
</div>