import { Pipe, PipeTransform } from '@angular/core';
import { LinkedTransferValidationResponse } from './validate-linked-transfers';

@Pipe({
  name: 'linkedTransferById'
})
export class LinkedTransferByIdPipe implements PipeTransform {

  transform(value: LinkedTransferValidationResponse[], transferId: string): LinkedTransferValidationResponse {
    const item = value.find((transfer) => transfer.transferId === transferId);
    console.log('LinkedTransferByIdPipe: ', item);
    return item || { transferId: transferId, isValid: true, dropOffIsValid:true, pickupIsValid: true };
  }

}
