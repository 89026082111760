import { AfterViewInit, ApplicationRef, Component, Input, OnDestroy, OnInit, QueryList, SkipSelf, ViewChildren } from '@angular/core';
import { AbstractControl, ControlContainer, FormArray, FormControl, FormGroup } from '@angular/forms';
import _ from 'lodash';
import { MenuItem, SelectItemGroup } from 'primeng/api';
import { Table } from 'primeng/table';
import { TreeSelect } from 'primeng/treeselect';
import { Subscription } from 'rxjs';
import { changeDetection } from 'src/app/shared/change-detection';
import { Service, serviceProductCategory } from 'src/app/shared/classes/service';
import { DynamicFormStructure } from 'src/app/shared/dynamic/dynamic-form-structure';
import { ModalService } from 'src/app/shared/services/modal.service';
import { ServiceProviderService } from 'src/app/shared/services/service-provider.service';
import { PanelType, SiteService } from 'src/app/shared/services/site.service';
import { FormGenerator } from '../../form-generator';
import { ProductLineItemStructure } from '../../form-structure';
import { Arrangement } from 'src/app/shared/classes/arrangement';

@Component({
  selector: 'app-financial-line-items',
  templateUrl: './financial-line-items.component.html',
  styleUrls: ['./financial-line-items.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]]
    }
  ]
})
export class FinancialLineItemsComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() service!: AbstractControl | null;
  @Input() filter!: any[];
  @Input() canModifyLineItemsPrice!: boolean;
  @Input() preventScrollingOnProductDetail!: boolean;
  @Input() arrangement?: Arrangement;

  @ViewChildren('table') table!: QueryList<Table>;
  @ViewChildren('productTree') productTree!: QueryList<TreeSelect>;

  dropdownProducts!: SelectItemGroup[];
  productCategories!: serviceProductCategory[];

  parentForm!: FormGroup;

  isReady: boolean;

  rowHasProduct: boolean[];

  subscriptions: Subscription[];

  previousServiceProviderId: number | null;
  previousServiceMetaId: string | null;

  currentLineItemIndex?: number;
  moveDestinations: MenuItem[] = [];

  get lineItems(): FormArray {
    return this.parentForm.get('lineItems') as FormArray;
  }

  constructor(
    private appRef: ApplicationRef,
    private controlContainer: ControlContainer,
    private modalService: ModalService,
    private siteService: SiteService,
    private serviceProviderService: ServiceProviderService,
  ) {

    this.dropdownProducts = [];
    this.rowHasProduct = [];

    this.isReady = false;

    this.canModifyLineItemsPrice = false;

    this.previousServiceProviderId = null;
    this.previousServiceMetaId = null;

    this.subscriptions = [];

  }

  ngOnInit(): void {

    this.dropdownProducts = [];
    this.rowHasProduct = [];

    const parentFormGroup = this.controlContainer.control as FormGroup;

    if (!parentFormGroup) {

      console.error('Oh no! anyway...');

    }

    this.parentForm = parentFormGroup;

    if (this.preventScrollingOnProductDetail === undefined) {

      this.preventScrollingOnProductDetail = true;

    }

    if (this.service) {

      this.setProductCategoriesAndItems(this.service.value as Service);

      /**
       * This is to update the list of products available
       */
      this.subscriptions.push(this.service.valueChanges.subscribe({
        next: (value: Service) => {
          this.setProductCategoriesAndItems(value as Service);
        }
      }));

    }

    if (this.lineItems.length) {

      const lineItemControls = this.lineItems.controls as FormGroup[];

      for (const lineItemIndex in lineItemControls) {

        const lineItem = lineItemControls[lineItemIndex];
  
        if (lineItem.get('_meta.deleted')?.value !== true) {
          this.rowHasProduct[lineItemIndex] = true;
        }

        lineItem.get('isExistingItem')?.patchValue(true);

      }

    } else {
    
      this.parentForm.addControl('lineItems', new FormArray([]));

    }

    this.populateRowHasProduct();

    this.isReady = true;

  }

  ngAfterViewInit(): void {
    
    changeDetection(() => {
      
      this.populateDestinationMenu();   
    
    });

  }

  ngOnDestroy(): void {

    this.subscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });

  }

  /**
   * Populate rowHasProduct when lineItems change
   */  
  populateRowHasProduct() {

    this.subscriptions.push(

      this.lineItems.valueChanges.subscribe(() => {

        const lineItemControls = this.lineItems.controls as FormGroup[];

        this.rowHasProduct = [];
    
        for (const lineItemIndex in lineItemControls) {
    
          const lineItem = lineItemControls[lineItemIndex];
    
          if (lineItem.get('_meta.deleted')?.value !== true) {
            this.rowHasProduct[lineItemIndex] = true;
          }
            
        }

      })

    )

  }

  /**
   * Populate destination menus
   */  
  populateDestinationMenu() {

    if (!this.arrangement) {

      return;

    }

    const allEvents = (this.arrangement.form.get('events') as FormArray);

    this.subscriptions.push(

      allEvents.valueChanges.subscribe(() => {

        // Reset it first
        this.moveDestinations = [];

        // Loop through each event
        allEvents.controls.forEach((event, eventIndex) => {

          const eventItems = (event.get('items') || []) as FormArray;

          // Loop through each event's item
          eventItems.controls.forEach((eventItemControl: any, eventItemIndex: number) => {

            const eventItemHasProvider = !!eventItemControl.value?.service?.serviceProviderId;

            const eventItemIsDifferent = eventItemControl !== this.parentForm;

            // If valid, push to destination menu
            if (eventItemHasProvider && eventItemIsDifferent) {

              this.moveDestinations.push({
                escape: false,
                label: `Move to <b>Event #${eventIndex + 1}: ${event.value?.type?.name || 'N/A'}</b> - Service item #${eventItemIndex + 1}: ${eventItemControl.value?.serviceType?.name || 'N/A'}`,
                command: () => {

                  if (this.currentLineItemIndex !== undefined) {

                    this.handleMoveLineItem(this.currentLineItemIndex, eventIndex, eventItemIndex);

                  }

                }
              })

            }
          })
        })
      })
    );
  }

  onProductChange(event: { originalEvent: Event, value: any }, rowIndex: number): void {

    this.rowHasProduct[rowIndex] = true;

    this.updateLineItem(rowIndex);

  }

  onQuantityChange(event: { originalEvent: Event, value: any }, rowIndex: number): void {
    
    this.updateLineItem(rowIndex);
    
  }

  onPriceChange(event: { originalEvent: Event, value: any }, rowIndex: number): void {
    
    this.updateLineItem(rowIndex);
    
  }
  
  onBespokeProductChange(event: Event, rowIndex: number): void {

    this.updateLineItem(rowIndex);

  }
  
  onViewProduct(event: Event | null, rowIndex: number): void {

    const currentLineItem = this.lineItems.at(rowIndex) as FormGroup;

    if (!currentLineItem.get('product')?.value) {

      this.modalService.generic({
        title: 'No Product Selected',
        copy: ['Please select a product before you try to view product details'],
        buttons: [
          { label: 'Close', key: 'close', class: '' }
        ]
      });

      return;

    }

    const lineItemValue = currentLineItem.value;

    let showProductAsForm = lineItemValue.isCustomItem;

    const data: any = { 
      showProductAsForm: showProductAsForm,
      canModifyLineItemsPrice: this.canModifyLineItemsPrice,
      timeoutDuration: 0, 
      product: currentLineItem.get('product'), 
      lineItem: currentLineItem, 
      onCloseFn: (key: string) => { 

        this.rowHasProduct[rowIndex] = true;

        if (key === 'on-submit') {

          this.updateLineItem(rowIndex);

        } else {

          const product = this.lineItems.at(rowIndex).get('product')?.value;

          if (!_.get(product, 'name')) {
            this.updateLineItem(rowIndex);
          }

        }

      }
    };

    this.siteService.openPanel(PanelType.ProductDetail, data, this.preventScrollingOnProductDetail);

  }

  /**
   * Remove row from both forms
   */
  removeRow(event: Event, index: number, showConfirmation: boolean = true): void {

    if (showConfirmation === false) {

      const lineItem = this.lineItems.at(index);

      if (lineItem) {

        // lineItem.get('_meta.deleted')?.patchValue(true);
        this.lineItems.removeAt(index);
        
        this.rowHasProduct.splice(index, 1);

      }

      return;

    }

    const modal = this.modalService.generic({
      title: 'Remove Item',
      copy: ['Are you sure you want to remove the selected item?'],
      buttons: [
        { label: 'Cancel', key: 'cancel', class: 'p-button-secondary' },
        { label: 'Confirm', key: 'confirm', class: 'p-button-danger', icon: 'pi-trash' },
      ]
    });

    modal.onClose.subscribe({
      next: key => {
        if (key === 'confirm') {
          
          // const lineItem = this.lineItems.at(index);

          // if (lineItem) {

          //   lineItem.get('_meta.deleted')?.patchValue(true, { });
            
          //   // force change detection
          //   this.appRef.tick();

          //   this.rowHasProduct.splice(index, 1);

          // }

          this.lineItems.removeAt(index);
          this.rowHasProduct.splice(index, 1);

        }
      }
    });

  }
 

  handleMoveLineItem(lineItemIndex: number, targetEventIndex: number, targetEventItemIndex: number): void {

    const lineItem = this.lineItems.at(lineItemIndex);

    if (!lineItem || !this.arrangement) {

      return;

    }

    lineItem.get('isExistingItem')?.patchValue(true);

    const targetEvent = (this.arrangement.form.get('events') as FormArray).at(targetEventIndex);
    const targetEventItem = (targetEvent.get('items') as FormArray).at(targetEventItemIndex);
    const targetEventItemLineItemsControl = targetEventItem.get('lineItems') as FormArray;

    // Add to target event item
    targetEventItemLineItemsControl.push(lineItem);

    // Then remove from this event item
    this.removeRow({} as any, lineItemIndex, false);

  }

  /**
   * Add new row to table
   */
  addRow(event: Event | null, isCustomItem: boolean = false, showDetailPanel: boolean = true): void {

    const formGenerator = new FormGenerator();
    const dynamicFormStructure = new DynamicFormStructure();

    const formGroup = formGenerator.generate(ProductLineItemStructure, {});

    if (!isCustomItem) { // Line item with Product from select menu

      FormGenerator.addToFormArray(this.lineItems, formGroup);

    } else { // Line item with a custom Product

      FormGenerator.addToFormArray(this.lineItems, formGroup);

      const lineItem = this.lineItems.at(this.lineItems.length - 1);

      lineItem.get('isCustomItem')?.patchValue(true);

      const formStructure = dynamicFormStructure._getGenericProductStructure();

      const productFormGroup = formGenerator.generate(formStructure, {
        name: '',
        description: '',
        cost: { amount: 0 }
      });

      productFormGroup.addControl('isCustomProduct', new FormControl(true));

      lineItem.get('product')?.patchValue(productFormGroup.value);

      this.rowHasProduct[this.lineItems.length - 1] = true;

      if (showDetailPanel) {

        this.onViewProduct(null, this.lineItems.length - 1);

      }

    }

  }

  openMoveDestinationMenu(index: number, menuElement: any, event: any) {
    const currentLineItem = this.lineItems.at(index) as FormGroup;

    if (!currentLineItem.get('product')?.value) {

      this.modalService.generic({
        title: 'No Product Selected',
        copy: ['Please select a product before you try to move product details'],
        buttons: [
          { label: 'Close', key: 'close', class: '' }
        ]
      });

      return;

    }

    // Save temporary line item index to handle menu command() later on
    this.currentLineItemIndex = index;

    menuElement.toggle(event);
  }

  /**
   * Generate array to be used for product select menu
   * Note: This generates product array for both TreeView and DropdownMenu components for testing purposes
   */
  private generateProductItems(groupedProducts: { [key: string]: any[] }): void {

    this.dropdownProducts = [];

    const newDropdownProducts: SelectItemGroup[] = [];

    for (const key in groupedProducts) {

      let category = _.find(this.productCategories, productCategory => productCategory.value === key);

      if (category) {

        const dropdownGroup: SelectItemGroup = {
          label: category.name,
          items: [],
        };

        for (const product of groupedProducts[key]) {

          delete product._meta;

          dropdownGroup.items.push({
            label: product.name,
            value: product,
          });

        }

        newDropdownProducts.push(dropdownGroup);

      }

    }

    changeDetection(() => {
      this.dropdownProducts = newDropdownProducts;
    });

  }

  private updateLineItem(index: number): void {

    const lineItem = this.lineItems.at(index) as FormGroup;

    if (!lineItem) {

      console.error('We expected lineItem to be set, but one (or both) are missing.');

      return;

    }

    const isCustomItemControl = lineItem.get('isCustomItem') as FormControl;
    const productControl = lineItem.get('product') as FormControl;
    const quantityControl = lineItem.get('quantity') as FormControl;
    const costAmountControl = lineItem.get('cost.amount') as FormControl;

    if (!isCustomItemControl || !productControl || !quantityControl || !costAmountControl) {

      console.error('We expected isCustomItemControl, productControl, quantityControl and costAmountControl to be found.');

      return;

    }

    const productValue = productControl.value;
    const quantityValue = quantityControl.value || 1;

    const productName = _.has(productValue, 'name') ? productValue.name : '';
    const productCost = _.has(productValue, 'cost.amount') ? productValue.cost.amount : 0;

    const calculatedCost = quantityValue * productCost;

    costAmountControl.patchValue(calculatedCost);

    if (!productName) {

      const modal = this.modalService.generic({
        title: 'Missing Product Name',
        copy: ['The item you just viewed is missing a valid name. Do you want to edit this item? Or would you rather remove it?'],
        buttons: [
          { label: 'Edit Item', key: 'edit', class: 'p-button-secondary' },
          { label: 'Remove Item', key: 'remove', class: 'p-button-danger', icon: 'pi-trash' },
        ]
      });

      modal.onClose.subscribe({
        next: (key: string) => {
            
          if (key === 'remove') {

            // const lineItem = this.lineItems.at(index);

            // if (lineItem) {
  
            //   lineItem.get('_meta.deleted')?.patchValue(true);
              
            //   this.rowHasProduct.splice(index, 1);
  
            // }

            this.lineItems.removeAt(index);
            this.rowHasProduct.splice(index, 1);

          } else {
              
            this.onViewProduct(null, index);

          }
  
        }
      });

    }

  }

  private setProductCategoriesAndItems(service: Service): void {

    if (service) {

      this.isReady = false;

      const productCategories = service.getProductCategories();
      const groupedProducts = service.getGroupedProducts();
      
      if (productCategories) {
  
        this.productCategories = productCategories;

      }
      
      if (groupedProducts) {
        
        this.generateProductItems(groupedProducts);

      }

      changeDetection(() => {
        this.isReady = true;
      });

    }

  }

  private updateServiceProvider(): void {
    
    const length = this.lineItems.length;

    for (let i = 0; i < length; i++) {
      this.lineItems.removeAt(0);
    }

    this.previousServiceProviderId = null;

  }

  private revertServiceProvider(): void {
    
    if (this.previousServiceProviderId && this.previousServiceMetaId) {

      const previousServiceProvider = this.serviceProviderService.getServiceById(this.previousServiceProviderId, this.previousServiceMetaId);
  
      if (previousServiceProvider) {

        this.service?.patchValue(previousServiceProvider);

      }

    }

  }

}
