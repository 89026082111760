import { Pipe, PipeTransform } from '@angular/core';
import { LinkedTransferValidationResponse } from './validate-linked-transfers';

@Pipe({
  name: 'invalidLinkedTransfers'
})
export class InvalidLinkedTransfersPipe implements PipeTransform {

  transform(value: LinkedTransferValidationResponse[]): LinkedTransferValidationResponse[] {
    return value.filter((transfer) => !transfer.isValid);
  }

}
