<ng-container *ngIf="isReady">

  <div [formGroup]="formDisplay" class="formgrid grid">

    <div class="quick-accordion col-12">

      <div class="grid m-0">

        <section class="col-12">

          <a href="#" (click)="toggleAccordion('title')" class="head text-700">

            <div class="copy">

              <h2 *ngIf="formDisplay.get('title')?.value as title; else noTitle">Title: <span>{{ title }}</span> <span class="text-400 inline-block ml-2">{{ (formDisplay.get('type')?.value) ? '(' + formDisplay.get('type')?.value?.name + ')' : '' }}</span></h2>
  
              <ng-template #noTitle>
                <h2>Title: N/A</h2>
              </ng-template>

            </div>

            <div class="actions">

              <button 
                pButton 
                pRipple 
                type="button" 
                icon="pi pi-chevron-down" 
                class="p-button-rounded p-button-secondary p-button-text"
                ></button>

            </div>

          </a>

          <div class="content" [@slideUpDown]="accordionTitle">

            <div class="content-wrapper grid">

              <div class="field col-12 md:col-4">
                <label [appIsRequired]="'title'">Title:</label>
                <input pInputText formControlName="title" type="text" class="inputfield w-full" [ngClass]="{ 'ng-invalid ng-dirty' : formDisplay.get('title')?.invalid }" />
              </div>
          
              <div class="field col-12 md:col-4">
  
                <label [appIsRequired]="'type'">Type:</label>
                <app-select 
                  [options]="transferTypes | sort:'asc':'name'" 
                  controlName="type" 
                  placeHolder="Select Transfer Type" 
                  class="inputfield w-full"
                  dataKey="value"
                ></app-select>

              </div>
          
              <div *ngIf="formDisplay.get('type')?.value?.value === transferType.Event" class="field col-12 md:col-4">
  
                <label>Existing Event:</label>
                <app-select 
                  [options]="$any(eventsAsOptions$ | async) | sort:'asc':'name'" 
                  [showClear]="true"
                  controlName="eventId" 
                  placeHolder="Select Event" 
                  class="inputfield w-full"
                ></app-select>

              </div>

            </div>

          </div>

        </section>

        <section class="col-12">

          <a href="#" (click)="toggleAccordion('service-provider')" class="head text-700">

            <div class="copy">

              <h2 *ngIf="formDisplay.get('service')?.value as service; else noService">Supplier: <span>{{ service.name }}</span></h2>
  
              <ng-template #noService>
                <h2>Supplier</h2>
              </ng-template>

            </div>

            <div class="actions">

              <button 
                pButton 
                pRipple 
                type="button" 
                icon="pi pi-chevron-down" 
                class="p-button-rounded p-button-secondary p-button-text"
                ></button>

            </div>

          </a>

          <div class="content" [@slideUpDown]="accordionServiceProvider" *ngIf="(serviceProviders$ | async) as serviceProviders">

            <div class="content-wrapper grid">

              <div class="field col-12">

                <label [appIsRequired]="'service'">Transfer Provider:</label>
                <app-select 
                  [appClearReadOnly]="'Pricing Details'"
                  [lineItemCount]="$any(formDisplay.get('lineItems'))?.controls?.length"
                  [menuType]="'Transfer Provider'"
                  [options]="serviceProviders | serviceProviderServiceByServiceType:transferServiceType | sort:'asc':'name'" 
                  [readonly]="$any(formDisplay.get('lineItems'))?.controls?.length > 0" 
                  [showClear]="true"
                  (onSelect)="onSelectTransferServiceProvider($event)"
                  controlName="service" 
                  placeHolder="Select Service Type" 
                  class="inputfield w-full"
                  dataKey="serviceProviderId"
                ></app-select>
          
              </div>
          
              <ng-container *ngIf="$any(formDisplay.get('lineItems'))?.controls?.length > 0 || formDisplay.get('service')?.value as service">
          
                <div class="col-12">
                  <app-business-card [service]="businessCard"></app-business-card>
                </div>
          
                <!--
                <div class="col-12">
          
                  <div class="flex align-items-center pr-3 my-3 surface-ground border-round-lg border-1 border-200" *ngIf="form.get('addTo2ndNote') as control">
                    <label class="flex-auto cursor-pointer p-3" (click)="control.patchValue(!control.value)">Add Transfer to <strong>2nd Note</strong>:</label>
                    <p-inputSwitch class="flex-initial" formControlName="addTo2ndNote"></p-inputSwitch>
                  </div>
          
                </div>
          
                <ng-container *ngIf="form.get('addTo2ndNote')?.value">
          
                  <div class="col-6">
          
                    <h3 [appIsRequired]="'type'" class="flex-grow-1 text-500 border-bottom-1 border-200">2nd Note Transfer Type</h3>
                    <app-select controlName="type" [options]="secondNoteTransferType | sort:'asc':'name'" [filter]="false" [showClear]="false" placeHolder="Select Transfer Type" class="inputfield w-full" [ngClass]="{ 'ng-invalid ng-dirty': form.get('type')?.invalid }"></app-select>
            
                  </div>
          
                  <div class="col-6">
          
                    <h3 [appIsRequired]="'eventIndex'" class="flex-grow-1 text-500 border-bottom-1 border-200">2nd Note Event Position</h3>
                    <app-select controlName="eventIndex" [options]="eventIndex" [filter]="false" [showClear]="false" placeHolder="Select Event" class="inputfield w-full" [ngClass]="{ 'ng-invalid ng-dirty': form.get('eventIndex')?.invalid }"></app-select>
            
                  </div>
          
                </ng-container>
                -->
  
              </ng-container>

            </div>

          </div>

        </section>

        <section class="col-12" [class.disabled]="!hasSupplier">

          <a href="#" (click)="toggleAccordion('pick-up')" class="head text-700" [class.invalid]="($any(arrangement.validateLinkedTransfers$ | async) | linkedTransferById: formDisplay.get('_meta.id')?.value)?.pickupIsValid === false">

            <div class="copy">
  
              <h2 *ngIf="true; else noService">Pickup</h2>
  
              <ng-template #noService>
                <h2>Pickup</h2>
              </ng-template>

            </div>

            <div class="actions">

              <button 
                pButton 
                pRipple 
                type="button" 
                icon="pi pi-chevron-down" 
                class="p-button-rounded p-button-secondary p-button-text"
                ></button>

            </div>

          </a>

          <div class="content" [@slideUpDown]="accordionPickUp">
        
            <div class="content-wrapper grid">

              <ng-container *ngIf="$any(formDisplay.get('lineItems'))?.controls?.length > 0 || formDisplay.get('service')?.value as service">
          
                <ng-container formGroupName="from">
                
                  <div class="col-12">
          
                    <div class="grid pt-3">
          
                      <div class="field col-4">
          
                        <label [appIsRequired]="'dateOfPickupType'">Pickup Date Type:</label>
                        <app-select 
                          [options]="datePickupDateTypeOptions | sort:'asc':'name'" 
                          controlName="dateOfPickupType" 
                          placeHolder="Select Pickup Date Type" 
                          class="inputfield w-full"
                        ></app-select>

                      </div>
          
                      <div class="field col-4" *ngIf="currentDateOfPickupTypeValue === dateOfPickupTypeEnum.On">
                        <label [appIsRequired]="'dateOfPickup'">Pickup Date:</label>
                        <app-date-v2 controlName="dateOfPickup" placeholder="Select Transfer Pickup Date" [showTime]="true" [autoPopulate]="false" class="inputfield w-full"></app-date-v2>
                      </div>
          
                      <div class="field col-4" *ngIf="currentDateOfPickupTypeValue === dateOfPickupTypeEnum.Between">
                        <label [appIsRequired]="'dateOfPickupFrom'">Pickup Date From:</label>
                        <app-date-v2 controlName="dateOfPickupFrom" placeholder="Select Transfer Pickup Date From" [showTime]="true" [autoPopulate]="false" class="inputfield w-full"></app-date-v2>
                      </div>
          
                      <div class="field col-4" *ngIf="currentDateOfPickupTypeValue === dateOfPickupTypeEnum.Between">
                        <label [appIsRequired]="'dateOfPickupTo'">Pickup Date To:</label>
                        <app-date-v2 controlName="dateOfPickupTo" placeholder="Select Transfer Pickup Date To" [showTime]="true" [autoPopulate]="false" class="inputfield w-full"></app-date-v2>
                      </div>
          
                    </div>
          
                    <div class="grid pt-2">
  
                      <div class="field col-4">
            
                        <label [appIsRequired]="'_addressType'">Pickup Location Type:</label>
                        <app-select 
                          [options]="transferFromAddressTypeOptions" 
                          [showClear]="true"
                          (onSelect)="onSelectFromAddressType($event)"
                          controlName="_addressType" 
                          placeHolder="Select Pickup Location Type" 
                          class="inputfield w-full"
                        ></app-select>
  
                      </div>

                      <ng-container *ngIf="formDisplay.get('from._addressType')?.value?.value === transferFromAddressTypeEnum.ServiceProvider">

                        <div class="field col-4">

                          <label [appIsRequired]="'_addressTypeServiceProviderType'">Service Provider Type:</label>
                          <app-select 
                            [appClearReadOnly]="'Service Provider type'"
                            [lineItemCount]="0"
                            [showClear]="true"
                            [menuType]="'Service Provider Type'"
                            [options]="serviceProviderTypes | sort:'asc':'name'" 
                            [readonly]="false" 
                            (onSelect)="onSelectFromServiceProviderType($event)"
                            controlName="_addressTypeServiceProviderType" 
                            placeHolder="Select Service Provider" 
                            class="inputfield w-full"
                            dataKey="value"
                          ></app-select>
                    
                        </div>

                      </ng-container>

                      <ng-container *ngIf="formDisplay.get('from._addressType')?.value?.value === transferFromAddressTypeEnum.ServiceProvider && formDisplay.get('from._addressTypeServiceProviderType')?.value && (serviceProviders$ | async) as serviceProviders">

                        <div class="field col-4">

                          <label [appIsRequired]="'_addressTypeServiceProvider'">Service Provider:</label>
                          <app-select 
                            [appClearReadOnly]="'Service Provider'"
                            [lineItemCount]="0"
                            [showClear]="true"
                            [menuType]="'Service Provider'"
                            [options]="serviceProviders | serviceProviderServiceByServiceType:formDisplay.get('from._addressTypeServiceProviderType')?.value?.value | sort:'asc':'name'" 
                            [readonly]="false" 
                            (onSelect)="onSelectFromServiceProvider($event)"
                            controlName="_addressTypeServiceProvider" 
                            placeHolder="Select Service Provider" 
                            class="inputfield w-full"
                            dataKey="serviceProviderId"
                          ></app-select>
                    
                        </div>

                        <ng-container *ngIf="formDisplay.get('from._addressTypeServiceProvider')?.value as serviceProvider">

                          <div *ngIf="fromAddressString$ | async as addressString" class="col-12">

                            <p-card *ngIf="addressString === 'no_address'" class="card-error w-full">
                              <p>No address has been entered for this provider. Use <strong>Custom Address</strong> in the <strong>Pickup Location Type</strong> menu instead.</p>
                            </p-card>
                            
                            <p-card *ngIf="addressString !== 'no_address'" class="card-info w-full">
                              <div [innerHTML]="addressString | safe : 'html'"></div>
                            </p-card>
                            
                          </div>

                        </ng-container>

                      </ng-container>
                      
                      <ng-container *ngIf="formDisplay.get('from._addressType')?.value?.value === transferFromAddressTypeEnum.PlaceOfPassing">
          
                        <div *ngIf="fromAddressString$ | async as addressString" class="col-12">

                          <p-card *ngIf="addressString === 'no_address'" class="card-error w-full">
                            <p>No address has been entered for this provider. Use <strong>Custom Address</strong> in the <strong>Pickup Location Type</strong> menu instead.</p>
                          </p-card>
                          
                          <p-card *ngIf="addressString !== 'no_address'" class="card-info w-full">
                            <div [innerHTML]="addressString | safe : 'html'"></div>
                          </p-card>
                          
                        </div>
            
                      </ng-container>
  
                      <ng-container *ngIf="formDisplay.get('from._addressType')?.value?.value === transferFromAddressTypeEnum.Custom">
          
                        <div class="field col-12">
            
                          <label [appIsRequired]="'address'">Custom Address:</label>
                          <app-address #transferFromAddress class="w-full" controlName="address"></app-address>
            
                        </div>
            
                      </ng-container>

                      <ng-container *ngIf="formDisplay.get('from._addressType')?.value?.value === transferFromAddressTypeEnum.ExistingTransfer">
          
                        <div class="field col-4">

                          <label [appIsRequired]="'_addressTypeExistingTransfer'">Transfer:</label>
                          <app-select 
                            [appClearReadOnly]="'Transfer'"
                            [lineItemCount]="0"
                            [showClear]="true"
                            [menuType]="'Transfer'"
                            [options]="$any(transfersAsOptions$ | async)" 
                            [readonly]="false" 
                            controlName="_addressTypeExistingTransfer" 
                            placeHolder="Select Transfer" 
                            class="inputfield w-full"
                            dataKey="value"
                          ></app-select>
                    
                        </div>
            
                      </ng-container>

                      <ng-container *ngIf="formDisplay.get('from._addressType')?.value?.value === transferFromAddressTypeEnum.ExistingTransfer && formDisplay.get('from._addressTypeExistingTransfer')?.value ">
          
                        <div class="field col-4">

                          <label [appIsRequired]="'_addressTypeExistingTransferAddressType'">Location:</label>
                          <app-select 
                            [appClearReadOnly]="'Transfer Location'"
                            [lineItemCount]="0"
                            [showClear]="true"
                            [menuType]="'Transfer Location'"
                            [options]="$any(pickupExistingTransferAddressTypeAsOptions$ | async)" 
                            [readonly]="false" 
                            controlName="_addressTypeExistingTransferAddressType" 
                            placeHolder="Select Transfer Location" 
                            class="inputfield w-full"
                            dataKey="value"
                          ></app-select>
                    
                        </div>

                        <div *ngIf="fromAddressString$ | async as addressString" class="col-12">

                          <p-card *ngIf="addressString === 'no_address'" class="card-error w-full">
                            <p>No address has been entered for this provider. Use <strong>Custom Address</strong> in the <strong>Pickup Location Type</strong> menu instead.</p>
                          </p-card>
                          
                          <p-card *ngIf="addressString !== 'no_address'" class="card-info w-full">
                            <div [innerHTML]="addressString | safe : 'html'"></div>
                          </p-card>
                          
                        </div>
            
                      </ng-container>
                        
                      <ng-container *ngIf="formDisplay.get('from._addressType')?.value?.value === transferFromAddressTypeEnum.ExistingEvent">

                        <div class="field col-4">
            
                          <label [appIsRequired]="'_addressTypeExistingEvent'">Event:</label>
                          <app-select 
                            [options]="$any(eventsAsOptions$ | async) | sort:'asc':'name'" 
                            [showClear]="true"
                            controlName="_addressTypeExistingEvent" 
                            placeHolder="Select Event Type" 
                            class="inputfield w-full"
                            dataKey="value"
                          ></app-select>
    
                        </div>

                        <div *ngIf="fromAddressString$ | async as addressString" class="col-12">

                          <p-card *ngIf="addressString === 'no_address'" class="card-error w-full">
                            <p>No address has been entered for this provider. Use <strong>Custom Address</strong> in the <strong>Pickup Location Type</strong> menu instead.</p>
                          </p-card>
                          
                          <p-card *ngIf="addressString !== 'no_address'" class="card-info w-full">
                            <div [innerHTML]="addressString | safe : 'html'"></div>
                          </p-card>
                          
                        </div>

                      </ng-container>

                      <ng-container *ngIf="($any(arrangement.validateLinkedTransfers$ | async) | linkedTransferById: formDisplay.get('_meta.id')?.value) as linkedTransferValidation">

                        <div *ngIf="linkedTransferValidation.pickupIsValid === false" class="col-12">
                          <p-card class="block card-error w-full my-3">
                            <p class="mb-3">The Pickup address doesn't match the value it's linked to within the Arrangement.</p>
                            <p><strong>Source Address:</strong> {{ linkedTransferValidation.pickupSourceAddress | addressToString }}</p>
                            <p><strong>Transfer Address:</strong> {{ linkedTransferValidation.pickupTransferAddress | addressToString }}</p>
                            <p class="mt-3 text-center">
                              <button pButton pRipple type="button" class="p-button-rounded p-button-danger" icon="pi pi-refresh" label="Refresh Address" (click)="onRefreshFromAddress($event)"></button>
                            </p>
                          </p-card>
                        </div>

                      </ng-container>

                    </div>
                    
                  </div>

                  <!--
                  <ng-container *ngIf="(fromAddresses$ | async) as addresses">
          
                    <div class="col-12 flex align-items-center">
                      <h3 class="flex-grow-1 text-500 border-bottom-1 border-200" [appIsRequired]="'_selectContainer'">Pickup Address</h3>
                      <ng-container *ngIf="transferFromAddressOptions[0] as options">
                        <ng-container *ngIf="form.get('from._selectContainer')?.value?.value === false">
                          <p-menu #transferFromOptionsMenu [popup]="true" [model]="options" appendTo="body"></p-menu>
                          <button pButton pRipple type="button" icon="pi pi-cog" class="p-button-rounded p-button-secondary p-button-text" (click)="$any(transferFromOptionsMenu).toggle($event)"></button>
                        </ng-container>
                      </ng-container>
                    </div>
          
                    <div class="field col-12">
                      <div #pickupInput class="p-inputgroup">
                        <p-dropdown
                          [options]="addresses | sort:'asc':'name'" 
                          placeholder="Select a Pickup Location" 
                          optionLabel="name"
                          formControlName="_selectContainer"
                          class="inputfield"
                          (onChange)="onFromChange($event)"
                          [appendTo]="pickupInput"
                          [virtualScroll]="true"
                        >
                          <ng-template let-value pTemplate="selectedItem">
                            <div [innerHTML]="value.name | safe:'html'"></div>
                          </ng-template>
                          <ng-template let-option pTemplate="item">
                            <div [innerHTML]="option.name | safe:'html'"></div>
                          </ng-template>
                        </p-dropdown>
                        <button type="button" pButton pRipple icon="pi pi-refresh" title="Refresh Address list using latest data" (click)="onFromAddressClick($event)"></button> 
                      </div>
          
                    </div>
          
                    <ng-container *ngIf="form.get('from._selectContainer')?.value?.value === false">
          
                      <div class="field col-12">
          
                        <app-address #transferFromAddress class="w-full" controlName="address"></app-address>
          
                      </div>
          
                    </ng-container>
          
                  </ng-container>
                  -->
          
                </ng-container>
              
              </ng-container>

            </div>
          
          </div>

        </section>

        <section class="col-12" [class.disabled]="!hasSupplier">

          <a href="#" (click)="toggleAccordion('drop-off')" class="head text-700" [class.invalid]="($any(arrangement.validateLinkedTransfers$ | async) | linkedTransferById: formDisplay.get('_meta.id')?.value)?.dropOffIsValid === false" >

            <div class="copy">

              <h2 *ngIf="true; else noService">Drop off</h2>
  
              <ng-template #noService>
                <h2>Drop off</h2>
              </ng-template>

            </div>

            <div class="actions">

              <button 
                pButton 
                pRipple 
                type="button" 
                icon="pi pi-chevron-down" 
                class="p-button-rounded p-button-secondary p-button-text"
                ></button>

            </div>

          </a>

          <div class="content" [@slideUpDown]="accordionDropOff">
        
            <div class="content-wrapper grid">
          
              <ng-container *ngIf="$any(formDisplay.get('lineItems'))?.controls?.length > 0 || formDisplay.get('service')?.value as service">
                
                <ng-container formGroupName="to">

                  <div class="col-12">

                    <div class="grid pt-3">
                      
                      <div class="field col-4">
                        <label [appIsRequired]="'date'">Delivery Date:</label>
                        <app-date-v2 controlName="date" placeholder="Select Transfer Delivery Date" [autoPopulate]="false" class="inputfield w-full"></app-date-v2>
                      </div>

                    </div>

                    <div class="grid pt-3">

                      <div class="field col-4">
                        <div class="flex align-items-center pr-3 my-3 surface-ground border-round-lg border-1 border-200" *ngIf="formDisplay.get('to.isEarlyDelivery') as control">
                          <label class="flex-auto cursor-pointer p-3" (click)="control.patchValue(!control.value)">Is early delivery required:</label>
                          <p-inputSwitch class="flex-initial" formControlName="isEarlyDelivery"></p-inputSwitch>
                        </div>
                      </div>

                    </div>
                
                    <div class="grid pt-2">
    
                      <div class="field col-4">
            
                        <label [appIsRequired]="'_addressType'">Drop Off Location Type:</label>
                        <app-select 
                          [options]="transferToAddressTypeOptions" 
                          [showClear]="true"
                          (onSelect)="onSelectToAddressType($event)"
                          controlName="_addressType" 
                          placeHolder="Select Pickup Location Type" 
                          class="inputfield w-full"
                        ></app-select>
  
                      </div>
  
                      <ng-container *ngIf="formDisplay.get('to._addressType')?.value?.value === transferToAddressTypeEnum.ServiceProvider">
  
                        <div class="field col-4">
  
                          <label [appIsRequired]="'_addressTypeServiceProviderType'">Service Provider Type:</label>
                          <app-select 
                            [appClearReadOnly]="'Service Provider type'"
                            [lineItemCount]="0"
                            [showClear]="true"
                            [menuType]="'Service Provider Type'"
                            [options]="serviceProviderTypes | sort:'asc':'name'" 
                            [readonly]="false" 
                            (onSelect)="onSelectToServiceProviderType($event)"
                            controlName="_addressTypeServiceProviderType" 
                            placeHolder="Select Service Provider" 
                            class="inputfield w-full"
                            dataKey="value"
                          ></app-select>
                    
                        </div>
  
                      </ng-container>
  
                      <ng-container *ngIf="formDisplay.get('to._addressType')?.value?.value === transferToAddressTypeEnum.ServiceProvider && formDisplay.get('to._addressTypeServiceProviderType')?.value && (serviceProviders$ | async) as serviceProviders">
  
                        <div class="field col-4">
  
                          <label [appIsRequired]="'_addressTypeServiceProvider'">Service Provider:</label>
                          <app-select 
                            [appClearReadOnly]="'Service Provider'"
                            [lineItemCount]="0"
                            [showClear]="true"
                            [menuType]="'Service Provider'"
                            [options]="serviceProviders | serviceProviderServiceByServiceType:formDisplay.get('to._addressTypeServiceProviderType')?.value?.value | sort:'asc':'name'" 
                            [readonly]="false" 
                            (onSelect)="onSelectToServiceProvider($event)"
                            controlName="_addressTypeServiceProvider" 
                            placeHolder="Select Service Provider" 
                            class="inputfield w-full"
                            dataKey="serviceProviderId"
                          ></app-select>
                    
                        </div>
  
                        <ng-container *ngIf="formDisplay.get('to._addressTypeServiceProvider')?.value as serviceProvider">
  
                          <div *ngIf="toAddressString$ | async as addressString" class="col-12">

                            <p-card *ngIf="addressString === 'no_address'" class="card-error w-full">
                              <p>No address has been entered for this provider. Use <strong>Custom Address</strong> in the <strong>Drop Off Location Type</strong> menu instead.</p>
                            </p-card>
                            
                            <p-card *ngIf="addressString !== 'no_address'" class="card-info w-full">
                              <div [innerHTML]="addressString | safe : 'html'"></div>
                            </p-card>
                            
                          </div>
  
                        </ng-container>
  
                      </ng-container>
  
                      <ng-container *ngIf="formDisplay.get('to._addressType')?.value?.value === transferToAddressTypeEnum.Custom">
          
                        <div class="field col-12">
            
                          <label [appIsRequired]="'address'">Custom Address:</label>
                          <app-address #transferToAddress class="w-full" controlName="address"></app-address>
            
                        </div>
            
                      </ng-container>
  
                      <ng-container *ngIf="formDisplay.get('to._addressType')?.value?.value === transferToAddressTypeEnum.ExistingTransfer">
          
                        <div class="field col-4">
  
                          <label [appIsRequired]="'_addressTypeExistingTransfer'">Transfer:</label>
                          <app-select 
                            [appClearReadOnly]="'Transfer'"
                            [lineItemCount]="0"
                            [showClear]="true"
                            [menuType]="'Transfer'"
                            [options]="$any(transfersAsOptions$ | async)" 
                            [readonly]="false" 
                            controlName="_addressTypeExistingTransfer" 
                            placeHolder="Select Transfer" 
                            class="inputfield w-full"
                            dataKey="value"
                          ></app-select>
                    
                        </div>
            
                      </ng-container>
  
                      <ng-container *ngIf="formDisplay.get('to._addressType')?.value?.value === transferToAddressTypeEnum.ExistingTransfer && formDisplay.get('to._addressTypeExistingTransfer')?.value ">
          
                        <div class="field col-4">
  
                          <label [appIsRequired]="'_addressTypeExistingTransferAddressType'">Location:</label>
                          <app-select 
                            [appClearReadOnly]="'Transfer Location'"
                            [lineItemCount]="0"
                            [showClear]="true"
                            [menuType]="'Transfer Location'"
                            [options]="$any(pickupExistingTransferAddressTypeAsOptions$ | async)" 
                            [readonly]="false" 
                            controlName="_addressTypeExistingTransferAddressType" 
                            placeHolder="Select Transfer Location" 
                            class="inputfield w-full"
                            dataKey="value"
                          ></app-select>
                    
                        </div>
  
                        <div *ngIf="toAddressString$ | async as addressString" class="col-12">
  
                          <p-card *ngIf="addressString === 'no_address'" class="card-error w-full">
                            <p>No address has been entered for this provider. Use <strong>Custom Address</strong> in the <strong>Drop Off Location Type</strong> menu instead.</p>
                          </p-card>
                          
                          <p-card *ngIf="addressString !== 'no_address'" class="card-info w-full">
                            <div [innerHTML]="addressString | safe : 'html'"></div>
                          </p-card>
                          
                        </div>
            
                      </ng-container>
                        
                      <ng-container *ngIf="formDisplay.get('to._addressType')?.value?.value === transferToAddressTypeEnum.ExistingEvent">
  
                        <div class="field col-4">
            
                          <label [appIsRequired]="'_addressTypeExistingEvent'">Event:</label>
                          <app-select 
                            [options]="$any(eventsAsOptions$ | async) | sort:'asc':'name'" 
                            [showClear]="true"
                            controlName="_addressTypeExistingEvent" 
                            placeHolder="Select Event Type" 
                            class="inputfield w-full"
                            dataKey="value"
                          ></app-select>
    
                        </div>
  
                        <div *ngIf="toAddressString$ | async as addressString" class="col-12">
  
                          <p-card *ngIf="addressString === 'no_address'" class="card-error w-full">
                            <p>No address has been entered for this provider. Use <strong>Custom Address</strong> in the <strong>Drop Off Location Type</strong> menu instead.</p>
                          </p-card>
                          
                          <p-card *ngIf="addressString !== 'no_address'" class="card-info w-full">
                            <div [innerHTML]="addressString | safe : 'html'"></div>
                          </p-card>
                          
                        </div>
  
                      </ng-container>
  
                      <ng-container *ngIf="($any(arrangement.validateLinkedTransfers$ | async) | linkedTransferById: formDisplay.get('_meta.id')?.value) as linkedTransferValidation">
  
                        <div *ngIf="linkedTransferValidation.dropOffIsValid === false" class="col-12">
                          <p-card class="block card-error w-full my-3">
                            <p class="mb-3">The Drop Off address doesn't match the value it's linked to within the Arrangement.</p>
                            <p><strong>Source Address:</strong> {{ linkedTransferValidation.dropOffSourceAddress | addressToString }}</p>
                            <p><strong>Transfer Address:</strong> {{ linkedTransferValidation.dropOffTransferAddress | addressToString }}</p>
                            <p class="mt-3 text-center">
                              <button pButton pRipple type="button" class="p-button-rounded p-button-danger" icon="pi pi-refresh" label="Refresh Address" (click)="onRefreshToAddress($event)"></button>
                            </p>
                          </p-card>
                        </div>
  
                      </ng-container>
  
                    </div>

                  </div>

                  <!--
                  <ng-container *ngIf="(toAddresses$ | async) as addresses">
          
                    <div class="col-12 flex align-items-center">
                      <h3 class="flex-grow-1 text-500 border-bottom-1 border-200" [appIsRequired]="'_selectContainer'">Delivery Address</h3>
                      <ng-container *ngIf="transferToAddressOptions[0] as options">
                        <ng-container *ngIf="formDisplay.get('to._selectContainer')?.value?.value === false">
                          <p-menu #transferToOptionsMenu [popup]="true" [model]="options" appendTo="body"></p-menu>
                          <button pButton pRipple type="button" icon="pi pi-cog" class="p-button-rounded p-button-secondary p-button-text" (click)="$any(transferToOptionsMenu).toggle($event)"></button>
                        </ng-container>
                      </ng-container>
                    </div>
          
                    <div class="field col-12">
          
                      <div #deliveryInput class="p-inputgroup">
          
                        <p-dropdown
                          [options]="addresses | sort:'asc':'name'" 
                          placeholder="Select a Drop-off Location" 
                          optionLabel="name"
                          formControlName="_selectContainer"
                          class="inputfield"
                          (onChange)="onToChange($event)"
                          [appendTo]="deliveryInput"
                          [virtualScroll]="true"
                        >
                          <ng-template let-value pTemplate="selectedItem">
                            <div [innerHTML]="value.name | safe:'html'"></div>
                          </ng-template>
                          <ng-template let-option pTemplate="item">
                            <div [innerHTML]="option.name | safe:'html'"></div>
                          </ng-template>
                        </p-dropdown>
                        <button type="button" pButton pRipple icon="pi pi-refresh" title="Refresh Address list using latest data" (click)="onToAddressClick($event)"></button> 
                      </div>
          
                    </div>
          
                    <ng-container *ngIf="formDisplay.get('to._selectContainer')?.value?.value === false">
          
                      <div class="field col-12">
          
                        <app-address #transferToAddress class="w-full" controlName="address"></app-address>
          
                      </div>
          
                    </ng-container>
          
                  </ng-container>
          
                  <div class="col-12">
          
                    <div class="flex align-items-center pr-3 my-3 surface-ground border-round-lg border-1 border-200" *ngIf="formDisplay.get('to.isEarlyDelivery') as control">
                      <label class="flex-auto cursor-pointer p-3" (click)="control.patchValue(!control.value)">Is early delivery required:</label>
                      <p-inputSwitch class="flex-initial" formControlName="isEarlyDelivery"></p-inputSwitch>
                    </div>
          
                  </div>

                  -->
          
                </ng-container>
              
              </ng-container>

            </div>

          </div>

        </section>

        <section class="col-12" [class.disabled]="!hasSupplier">

          <a href="#" (click)="toggleAccordion('pricing')" class="head text-700">

            <div class="copy">

              <h2 *ngIf="true; else noService">Pricing</h2>
  
              <ng-template #noService>
                <h2>Pricing</h2>
              </ng-template>

            </div>

            <div class="actions">

              <button 
                pButton 
                pRipple 
                type="button" 
                icon="pi pi-chevron-down" 
                class="p-button-rounded p-button-secondary p-button-text"
                ></button>

            </div>

          </a>

          <div class="content" [@slideUpDown]="accordionPricing">
        
            <div class="content-wrapper grid">
          
              <ng-container *ngIf="$any(formDisplay.get('lineItems'))?.controls?.length > 0 || formDisplay.get('service')?.value as service">
          
                <div class="col-12 flex align-items-center">
                  <h3 class="flex-grow-1 text-500 border-bottom-1 border-200">Pricing Details</h3>
                </div>
                
                <div class="col-12" *ngIf="transferItemsVisibility">
                  <app-financial-line-items [service]="formDisplay.get('service')" [preventScrollingOnProductDetail]="arrangement && arrangement.id !== null"></app-financial-line-items>
                </div>
          
                <div class="col-12">
                
                  <div class="flex my-3 px-3 border-1 border-200 border-round-lg bg-blue-50">
                    <h4 class="flex-auto">Transfer Cost:</h4>
                    <h4 class="flex-initial"><strong>{{ formDisplay.get('cost.amount')?.value | currency:'AUD':'$' }}</strong></h4>
                  </div>
                
                </div>
          
                <!-- <div class="col-12">
                  <h3 class="flex-grow-1 text-500 border-bottom-1 border-200">Transfer Item Notes</h3>
                  <textarea pInputTextarea formControlName="notes" rows="3" placeholder="Please add any notes you think of for the above items" class="p-inputtext inputfield w-full"></textarea>
                </div> -->
              
              </ng-container>

            </div> 
          
          </div>

        </section>

        <section class="col-12" [class.disabled]="!hasSupplier">

          <a href="#" (click)="toggleAccordion('notes')" class="head text-700">

            <div class="copy">

              <h2 *ngIf="true; else noService">Notes</h2>
  
              <ng-template #noService>
                <h2>Notes</h2>
              </ng-template>

            </div>

            <div class="actions">

              <button 
                pButton 
                pRipple 
                type="button" 
                icon="pi pi-chevron-down" 
                class="p-button-rounded p-button-secondary p-button-text"
                ></button>

            </div>

          </a>

          <div class="content" [@slideUpDown]="accordionNotes">
        
            <div class="content-wrapper grid">
          
              <ng-container *ngIf="$any(formDisplay.get('lineItems'))?.controls?.length > 0 || formDisplay.get('service')?.value as service">
          
                <div class="col-12">
                  <h3 class="flex-grow-1 text-500 border-bottom-1 border-200">Notes</h3>
                  <textarea pInputTextarea formControlName="notes" rows="3" placeholder="Please add any notes you think of for the above items" class="p-inputtext inputfield w-full"></textarea>
                </div>
              
              </ng-container>

            </div> 
          
          </div>

        </section>

      </div>

    </div>

  </div>

</ng-container>