import { Component, Input, OnDestroy, OnInit, SkipSelf } from '@angular/core';
import { Service } from '../../classes/service';
import { Observable, Subject } from 'rxjs';
import { finalize, first, takeUntil, tap } from 'rxjs/operators';
import { MenuItem, MessageService } from 'primeng/api';
import { ModalService } from '../../services/modal.service';
import { ControlContainer, FormGroup } from '@angular/forms';
import { PanelType, SiteService } from '../../services/site.service';
import { UserService } from '../../services/user.service';
import _, { add } from 'lodash';
import { placeAndStreetAreDifferent } from '../../place-and-street-are-different';

@Component({
  selector: 'app-business-card',
  templateUrl: './business-card.component.html',
  styleUrls: ['./business-card.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]]
    }
  ]
})
export class BusinessCardComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();

  @Input() service!: Observable<Service | null>;

  items: MenuItem[];

  constructor(
    private controlContainer: ControlContainer,
    private modalService: ModalService,
    private messageService: MessageService,
    private siteService: SiteService,
    private userService: UserService,
  ) {

    this.items = [
      {
        label: 'Enquiry', 
        icon: 'pi pi-file', 
        command: () => {
          this.onSend(null, 'service-enquiry');
        },
      }, {
        label: 'Submit Order', 
        icon: 'pi pi-file', 
        command: () => {
          this.onSend(null, 'service-order');
        },
      }, {
        label: 'Reminder', 
        icon: 'pi pi-file', 
        command: () => {
          this.onSend(null, 'service-reminder');
        },
      },
    ];

  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
      
  }

  getAddress(service: Service): string {

    const address: string[] = [];

    let place = '';
    let street = '';

    if (_.hasIn(service, 'address.place') && service.address.place) {
      place = service.address.place;
    }

    if (_.hasIn(service, 'address.street') && service.address.street) {
      street = service.address.street;
    }

    if (placeAndStreetAreDifferent(place, street) === true) {
      address.push(place);
      address.push(street);
    } else {
      address.push(street);
    }

    if (_.hasIn(service, 'address.suburb') && service.address.suburb) {
      address.push(service.address.suburb);
    }

    if (_.hasIn(service, 'address.state.value') && service.address.state.value) {
      address.push(service.address.state.value);
    }

    if (_.hasIn(service, 'address.postcode') && service.address.postcode) {
      address.push(service.address.postcode);
    }

    return (address.length) ? address.join(', ') : 'N/A';

  }

  getPhone(service: Service): string {

    let phone = 'N/A';

    if (_.hasIn(service, 'contact.phone.primary') && service.contact.phone.primary) {
      phone = service.contact.phone.primary;
    }

    if (phone === 'N/A' && _.hasIn(service, 'contact.phone.secondary') && service.contact.phone.secondary) {
      phone = service.contact.phone.secondary;
    }

    return phone;

  }

  getEmail(service: Service): string {

    let email = 'N/A';

    if (_.hasIn(service, 'contact.email.primary') && service.contact.email.primary) {
      email = service.contact.email.primary;
    }

    if (email === 'N/A' && _.hasIn(service, 'contact.email.secondary') && service.contact.email.secondary) {
      email = service.contact.email.secondary;
    }

    return email;

  }

  getName(service: Service): string {

    let name: string[] = [];

    if (_.hasIn(service, 'contact.name.first') && service.contact.name.first) {
      name.push(service.contact.name.first);
    }

    if (_.hasIn(service, 'contact.name.last') && service.contact.name.last) {
      name.push(service.contact.name.last);
    }

    return (name.length) ? name.join(' ') : 'N/A';

  }

  onSend(event: Event | null, template?: string): void {
    
    this.siteService.addSubscriptionLog(this, 'business-card.component.ts->onSend->this.service');

    this.service.pipe(
      finalize(() => this.siteService.setSubscriptionLogFinalised('business-card.component.ts->onSend->this.service')),
      takeUntil(this.unsubscribe$),
      first()
    ).subscribe({
      next: (s) => {

        const service = s;
        const showSearch = true;
        const arrangement = this.controlContainer.control?.root.get('_arrangement')?.value;
        const user = this.userService.userSourceValue;
        const formData = this.controlContainer.control?.value;

        this.siteService.openPanel(PanelType.SendEmail, { service, showSearch, arrangement, user, template, formData});

      }
    });
  }

  async onCopyToClipboard(event: Event | null, text: string): Promise<void> {

    if (!navigator.clipboard) {

      this.modalService.generic({
        title: 'Error',
        copy: ['Your browser does not support copying to clipboard.'],
        buttons: [{ label: 'Close', key: 'close', class: '' }]
      });

      return;

    }

    try {

      await navigator.clipboard.writeText(text);

      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Copied to clipboard' });

    } catch (e) {

      this.modalService.generic({
        title: 'Error',
        copy: ['Your browser does not support copying to clipboard.'],
        buttons: [{ label: 'Close', key: 'close', class: '' }]
      });

    }

  }

  async onReadReceipt(event: Event | null, service: Service): Promise<void> {

    this.siteService.openPanel(PanelType.EmailTrails, {
      toServiceId: service.serviceProviderId,
      arrangementId: this.controlContainer.control?.root.get('_arrangement')?.value?.id
    });

  }

}
