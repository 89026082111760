<div *ngIf="isReady" [formGroup]="parentForm" class="grid">

  <div class="col-12">

    <p-table [value]="lineItems.controls" [resizableColumns]="false" responsiveLayout="stack">
        <ng-template pTemplate="header">
            <tr>
                <th>Item</th>
                <th pResizableColumn [style.min-width]="'212px'" [style.width]="'212px'">Quantity</th>
                <th pResizableColumn [style.min-width]="'130px'" [style.width]="'130px'">Price</th>
                <th pResizableColumn [style.min-width]="'130px'" [style.width]="'130px'">Cost</th>
                <th pResizableColumn [style.min-width]="'130px'" [style.width]="'130px'"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
            <ng-container formArrayName="lineItems">
                <tr *ngIf="!rowData.value._meta?.deleted" [formGroupName]="rowIndex">
                    <td>
                        <span class="p-column-title font-bold pr-3">Item</span>
                        <p-card *ngIf="rowData.value.isCustomItem === true || rowData.value.isExistingItem === true" class="product-price-card" styleClass="text-left surface-100 border-round-lg border-1 border-solid border-200 p-2">
                            {{ (rowData.value && rowData.value.product && rowData.value.product.name) ? rowData.value.product.name : 'NO NAME' }}
                        </p-card>
                        <p-dropdown 
                            *ngIf="rowData.value.isCustomItem !== true && rowData.value.isExistingItem !== true"
                            (onChange)="onProductChange($event, rowIndex)"
                            [options]="dropdownProducts | sort:'asc':'label'"
                            [group]="true"
                            [filter]="true"
                            placeholder="Please select a product..."
                            filterBy="label"
                            formControlName="product"
                            (change)="onBespokeProductChange($event, rowIndex)"
                            appendTo="body"
                        >
                            <ng-template let-group pTemplate="group">
                                <div class="p-d-flex p-ai-center">
                                    <span>{{ group.label }}</span>
                                </div>
                            </ng-template>
                            <ng-template let-product pTemplate="item">
                                <div class="flex"><span class="flex-auto pr-2">{{ product.label }}</span><span class="flex-initial font-bold">{{ product.value.cost.amount | currency }}</span></div>
                                <div *ngIf="product.value.description" class="white-space-normal text-500 text-sm">{{ product.value.description }}</div>
                                <div *ngIf="product.value.note" class="white-space-normal text-500 text-xs mt-1 font-italic">{{ product.value.note }}</div>
                            </ng-template>
                            <ng-template let-product pTemplate="selectedItem">
                                <div style="text-overflow:ellipsis;">{{ product.label }}</div>
                            </ng-template>
                        </p-dropdown>
                    </td>
                    <td pResizableColumn [style.min-width]="'212px'" [style.width]="'212px'">
                        <span class="p-column-title font-bold pr-3">Quantity</span>
                        <p-inputNumber 
                            inputStyleClass="form-control-inputnumber"
                            formControlName="quantity" 
                            buttonLayout="horizontal"
                            spinnerMode="horizontal" 
                            [showButtons]="true" 
                            [step]="1"
                            [minFractionDigits]="2"
                            [maxFractionDigits]="2"
                            [readonly]="rowHasProduct[rowIndex] !== true"
                            decrementButtonClass="p-button-primary" 
                            incrementButtonClass="p-button-primary" 
                            incrementButtonIcon="pi pi-plus" 
                            decrementButtonIcon="pi pi-minus" 
                            (onInput)="onQuantityChange($event, rowIndex)"
                            (onBlur)="onQuantityChange($event, rowIndex)"
                        ></p-inputNumber>
                    </td>
                    <td pResizableColumn [style.min-width]="'130px'" [style.width]="'130px'">
                        <span class="p-column-title font-bold pr-3">Price</span>
                        <ng-container *ngIf="lineItems.at(rowIndex).get('product')?.value as productValue; else elseBlock">
                            <p-card class="product-price-card" styleClass="surface-100 border-round-lg border-1 border-solid border-200 p-2">
                                {{ productValue?.cost?.amount | currency }}
                            </p-card>
                        </ng-container>
                        <ng-template #elseBlock>
                            <p-card class="product-price-card" styleClass="surface-100 border-round-lg border-1 border-solid border-200 p-2">
                                N/A
                            </p-card>
                        </ng-template>
                    </td>
                    <td pResizableColumn [style.min-width]="'130px'" [style.width]="'130px'">
                        <span class="p-column-title font-bold pr-3">Cost</span>
                        <ng-container formGroupName="cost">
                            <p-inputNumber 
                                inputStyleClass="form-control-inputnumber"
                                formControlName="amount"
                                [readonly]="true"
                                [minFractionDigits]="2"
                                [maxFractionDigits]="2"
                                prefix="$"
                            ></p-inputNumber>
                        </ng-container>
                    </td>
                    <td pResizableColumn [style.min-width]="'195px'" [style.width]="'195px'" class="justify-content-end" >
                        <button *ngIf="arrangement" [disabled]="moveDestinations.length === 0" pButton pRipple type="button" icon="pi pi-sort" class="p-button-rounded p-button-info mr-3" (click)="openMoveDestinationMenu(rowIndex, menu, $event)" pTooltip="Move this line item to other event" tooltipPosition="top" [showDelay]="1000" [hideDelay]="500"></button>
                        <p-menu #menu [popup]="true" [model]="moveDestinations" appendTo="body" styleClass="destination-menu"></p-menu>
                        <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded p-button-danger mr-3" (click)="removeRow($event, rowIndex)"></button>
                        <button pButton pRipple type="button" icon="pi pi-angle-double-right" class="p-button-rounded p-button-primary" (click)="onViewProduct($event, rowIndex)" [disabled]="rowHasProduct[rowIndex] !== true"></button>
                    </td>
                </tr>
            </ng-container>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="flex justify-content-end button-actions">
                <button pButton pRipple type="button" label="Add Custom Item" icon="pi pi-plus" class="p-button-secondary" (click)="addRow($event, true)"></button>
                <button *ngIf="service" pButton pRipple type="button" label="Add Item" icon="pi pi-plus" (click)="addRow($event)"></button>
            </div>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5">
                    <p-card class="card-info">
                        <p class="text-center"><strong>No Financial Items Found:</strong> Use the button(s) below to add your first financial item.</p>  
                    </p-card>
                </td>
            </tr>
        </ng-template>
        
    </p-table>

</div>
