
<div class="content-container mt-3">

  <pdf-viewer
    *ngIf="pdfUrl"
    [src]="pdfUrl"
    [rotation]="0"
    [original-size]="false"
    [show-all]="true"
    [fit-to-page]="true"
    [zoom]="1"
    [zoom-scale]="'page-width'"
    [stick-to-page]="false"
    [render-text]="true"
    [external-link-target]="'blank'"
    [autoresize]="true"
    [show-borders]="false"
    style="width: 100%; height: 100%;"
  ></pdf-viewer>

</div>

<div class="actions-container flex justify-content-end mt-3 column-gap-3">

  <button pButton pRipple type="button" label="Cancel" (click)="onClick({ label: 'Close', key: 'close', class: '' })" class="p-button-secondary"></button>
  <button pButton pRipple type="button" label="Save to Document Library" (click)="onClick({ label: 'Save', key: 'save', class: '' })"></button>
  
</div>