import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidatorFn } from '@angular/forms';
import { TransferType } from './forms/form-enum';
import _ from 'lodash';

@Pipe({
  name: 'transferFilter'
})
export class TransferFilterPipe implements PipeTransform {

  transform(value: AbstractControl[], type: { name: string, value: TransferType | 'view-all' }): AbstractControl[] {

    // Add index to each transfer
    value.forEach((transfer: AbstractControl, index: number) => {
      transfer._index = index;
    });

    const mapped = value.map((transfer: AbstractControl) => {
      const typeValue = transfer.get('type')?.value;
      if (type.value === 'view-all' || !type.value || _.get(typeValue, 'value') === type.value) {
        transfer._isVisible = true;
      } else {
        transfer._isVisible = false;
      }
      return transfer;
    });

    return mapped;

  }

}
