import _ from 'lodash';

export interface MakeSimplePath {
  src: string;
  target?: string;
};

export function makeSimple(src: any, paths: MakeSimplePath[]): any | null {

  const target: any = {};

  if (src === null || src === undefined) {

    return null;

  }

  paths.forEach((path) => {

    const value = _.get(src, path.src);

    _.set(target, (path.target) ? path.target : path.src, value);

  });

  return target;

}
