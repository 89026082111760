import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FinancialComponent } from './financial.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import { RippleModule } from 'primeng/ripple';
import { MenuModule } from 'primeng/menu';



@NgModule({
  declarations: [
    FinancialComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    RippleModule,
    InputNumberModule,
    MenuModule,
  ],
  exports: [
    FinancialComponent
  ]
})
export class FinancialModule { }
