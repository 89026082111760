import { NgModule, Pipe } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FinancialLineItemsComponent } from './financial-line-items.component';

import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { TreeSelectModule } from 'primeng/treeselect';
import { DropdownModule } from 'primeng/dropdown';
import { CardModule } from 'primeng/card';
import { PipeModule } from 'src/app/shared/pipe.module';

@NgModule({
  declarations: [FinancialLineItemsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RippleModule,
    InputTextModule,
    InputNumberModule,
    ButtonModule,
    TableModule,
    MenuModule,
    DividerModule,
    TreeSelectModule,
    DropdownModule,
    CardModule,
    PipeModule,
  ],
  exports: [FinancialLineItemsComponent]
})
export class FinancialLineItemsModule { }
