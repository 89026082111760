import { AbstractControl, FormArray, FormControl, FormGroup } from "@angular/forms";
import { Arrangement, TransferAddressType } from "./classes/arrangement";
import _ from 'lodash';

// @ts-ignore
import objectScan from 'object-scan';
import { LinkedTransferId, LinkedTransferIdType, TargetLinkedTransfer } from "./forms/components/transfer-item/transfer-item.component";
import { Service } from "./classes/service";
import { FormGenerator } from "./forms/form-generator";
import { FormAddressStructure } from "./forms/form-structure";

export interface LinkedTransferValidationResponse {
  transferId: string;
  isValid: boolean;
  pickupIsValid: boolean;
  pickupSourceAddress?: any;
  pickupTransferAddress?: any;
  dropOffIsValid: boolean;
  dropOffSourceAddress?: any;
  dropOffTransferAddress?: any;
}

export const enum linkedTransferIdPath {
  PlaceOfPassing = 'deceased.placeOfPassing.linkedTransferId',
  Preparation = 'preparation.linkedTransferId',
  EventVenue = 'events[?].venue.linkedTransferId',
  TransferFrom = 'transfer.transfers[?].from.linkedTransferId',
  TransferTo = 'transfer.transfers[?].to.linkedTransferId',
}

export const enum linkedTransferServicePath {
  PlaceOfPassing = '', // Place of Passing doesn't have a service provider
  Preparation = 'preparation.service',
  EventVenue = 'events[?].venue.service',
  TransferFrom = '', // Transfer from doesn't have a service provider
  TransferTo = '', // Transfer to doesn't have a service provider
}

export const linkedTransferPaths = [
  { linkedTransferIdPath: linkedTransferIdPath.PlaceOfPassing, servicePath: linkedTransferServicePath.PlaceOfPassing },
  { linkedTransferIdPath: linkedTransferIdPath.Preparation, servicePath: linkedTransferServicePath.Preparation },
  { linkedTransferIdPath: linkedTransferIdPath.EventVenue, servicePath: linkedTransferServicePath.EventVenue },
  { linkedTransferIdPath: linkedTransferIdPath.TransferFrom, servicePath: linkedTransferServicePath.TransferFrom },
  { linkedTransferIdPath: linkedTransferIdPath.TransferTo, servicePath: linkedTransferServicePath.TransferTo },
];

const queryObject = (path: string, data: any): { path: string, data: any }[] => {

  const scan = objectScan([path], {
    joined: true,
    useArraySelector: true,
  });

  return scan(data).map((p: any) => {
    return {
      path: p,
      data: _.get(data, p)
    };
  });

};

export function validateLinkedTransfers(arrangement: Arrangement): LinkedTransferValidationResponse[] {

  const isEqualAddress = (a: any, b: any): boolean => {

    const cloneA = _.cloneDeep(a);
    const cloneB = _.cloneDeep(b);

    _.unset(cloneA, 'search');
    _.unset(cloneA, 'isInternational');

    _.unset(cloneB, 'search');
    _.unset(cloneB, 'isInternational');

    const isEqual = _.isEqual(cloneA, cloneB);

    return isEqual;

  };

  const formGenerator = new FormGenerator();

  const emptyAddressFormControl = formGenerator.generate(_.clone(FormAddressStructure), {});

  const validatePlaceOfPassing = (path: string, data: any, transfers: any[]): LinkedTransferValidationResponse[] => {

    const response: LinkedTransferValidationResponse[] = [];

    const controlValues = queryObject(path, data);

    if (Array.isArray(controlValues) && controlValues.length) {

      controlValues.forEach((controlValue) => {

        const sourceAddressValue = _.get(controlValue, 'data.address', emptyAddressFormControl.value);

        const linkedTransferIds = _.get(controlValue, 'data.linkedTransferId', []);

        if (Array.isArray(linkedTransferIds) && linkedTransferIds.length) {

          linkedTransferIds.forEach((linkedTransferId: LinkedTransferId) => {

            // Find linkedTransferId in transfers (_meta.id)
            const transfer = transfers.find((t: any) => t._meta.id === linkedTransferId.id);

            if (transfer) {

              const res: LinkedTransferValidationResponse = {
                transferId: transfer._meta.id,
                isValid: true,
                pickupIsValid: true,
                dropOffIsValid: true
              }

              if (linkedTransferId.type === LinkedTransferIdType.Pickup) {

                if (!isEqualAddress(transfer.from.address, sourceAddressValue)) {

                  res.isValid = false;
                  res.pickupIsValid = false;
                  res.pickupSourceAddress = sourceAddressValue;
                  res.pickupTransferAddress = transfer.from.address;

                }

              } else if (linkedTransferId.type === LinkedTransferIdType.DropOff) {

                if (!isEqualAddress(transfer.to.address, sourceAddressValue)) {

                  res.isValid = false;
                  res.dropOffIsValid = false;
                  res.dropOffSourceAddress = sourceAddressValue;
                  res.dropOffTransferAddress = transfer.to.address;

                }

              }

              response.push(res);

            }

          });

        }

      });

    }

    return response;

  }

  const validatePreparationServiceProvider = (path: string, data: any, transfers: any[]): LinkedTransferValidationResponse[] => {

    const response: LinkedTransferValidationResponse[] = [];

    const controlValues = queryObject(path, data);

    if (Array.isArray(controlValues) && controlValues.length) {

      controlValues.forEach((controlValue) => {

        const sourceServiceValue: Service = _.get(controlValue, 'data.service') as any; // Ouch, I don't like "as any" but it's the only way to make it work

        const linkedTransferIds = _.get(controlValue, 'data.linkedTransferId', []);

        if (sourceServiceValue && Array.isArray(linkedTransferIds) && linkedTransferIds.length) {

          linkedTransferIds.forEach((linkedTransferId: LinkedTransferId) => {

            // Find linkedTransferId in transfers (_meta.id)
            const transfer = transfers.find((t: any) => t._meta.id === linkedTransferId.id);

            if (transfer) {

              const res: LinkedTransferValidationResponse = {
                transferId: transfer._meta.id,
                isValid: true,
                pickupIsValid: true,
                dropOffIsValid: true
              }

              if (linkedTransferId.type === LinkedTransferIdType.Pickup) {

                if (!isEqualAddress(transfer.from.address, sourceServiceValue.address)) {

                  res.isValid = false;
                  res.pickupIsValid = false;
                  res.pickupSourceAddress = sourceServiceValue.address;
                  res.pickupTransferAddress = transfer.from.address;

                }

              } else if (linkedTransferId.type === LinkedTransferIdType.DropOff) {

                if (!isEqualAddress(transfer.to.address, sourceServiceValue.address)) {

                  res.isValid = false;
                  res.dropOffIsValid = false;
                  res.dropOffSourceAddress = sourceServiceValue.address;
                  res.dropOffTransferAddress = transfer.to.address;

                }

              }

              response.push(res);

            }

          });

        }

      });

    }

    return response;

  }

  const validateEventVenue = (path: string, data: any, transfers: any[]): LinkedTransferValidationResponse[] => {

    const response: LinkedTransferValidationResponse[] = [];

    const controlValues = queryObject(path, data);

    if (Array.isArray(controlValues) && controlValues.length) {

      controlValues.forEach((controlValue) => {

        const sourceAddressValue = _.get(controlValue, 'data.address', emptyAddressFormControl.value);

        const linkedTransferIds = _.get(controlValue, 'data.linkedTransferId', []);

        if (Array.isArray(linkedTransferIds) && linkedTransferIds.length) {

          linkedTransferIds.forEach((linkedTransferId: LinkedTransferId) => {

            // Find linkedTransferId in transfers (_meta.id)
            const transfer = transfers.find((t: any) => t._meta.id === linkedTransferId.id);

            if (transfer) {

              const res: LinkedTransferValidationResponse = {
                transferId: transfer._meta.id,
                isValid: true,
                pickupIsValid: true,
                dropOffIsValid: true
              }

              if (linkedTransferId.type === LinkedTransferIdType.Pickup) {

                if (!isEqualAddress(transfer.from.address, sourceAddressValue)) {

                  res.isValid = false;
                  res.pickupIsValid = false;
                  res.pickupSourceAddress = sourceAddressValue;
                  res.pickupTransferAddress = transfer.from.address

                }

              } else if (linkedTransferId.type === LinkedTransferIdType.DropOff) {

                if (!isEqualAddress(transfer.to.address, sourceAddressValue)) {

                  res.isValid = false;
                  res.dropOffIsValid = false;
                  res.dropOffSourceAddress = sourceAddressValue;
                  res.dropOffTransferAddress = transfer.to.address;

                }

              }

              response.push(res);

            }

          });

        }

      });

    }

    return response;

  }

  const validateTransferFrom = (path: string, data: any, transfers: any[]): LinkedTransferValidationResponse[] => {

    const response: LinkedTransferValidationResponse[] = [];

    const controlValues = queryObject(path, data);

    if (Array.isArray(controlValues) && controlValues.length) {

      controlValues.forEach((controlValue) => {

        const sourceAddressValue = _.get(controlValue, 'data.address', emptyAddressFormControl.value);

        const linkedTransferIds = _.get(controlValue, 'data.linkedTransferId', []);

        if (Array.isArray(linkedTransferIds) && linkedTransferIds.length) {

          linkedTransferIds.forEach((linkedTransferId: LinkedTransferId) => {

            // Find linkedTransferId in transfers (_meta.id)
            const transfer = transfers.find((t: any) => t._meta.id === linkedTransferId.id);

            if (transfer) {

              const res: LinkedTransferValidationResponse = {
                transferId: transfer._meta.id,
                isValid: true,
                pickupIsValid: true,
                dropOffIsValid: true
              }

              if (linkedTransferId.type === LinkedTransferIdType.Pickup) {

                if (!isEqualAddress(transfer.from.address, sourceAddressValue)) {

                  res.isValid = false;
                  res.pickupIsValid = false;
                  res.pickupSourceAddress = sourceAddressValue;
                  res.pickupTransferAddress = transfer.from.address;

                }

              } else if (linkedTransferId.type === LinkedTransferIdType.DropOff) {

                if (!isEqualAddress(transfer.to.address, sourceAddressValue)) {

                  res.isValid = false;
                  res.dropOffIsValid = false;
                  res.dropOffSourceAddress = sourceAddressValue;
                  res.dropOffTransferAddress = transfer.to.address;

                }

              }

              response.push(res);

            }

          });

        }

      });

    }

    return response;

  }

  const validateTransferTo = (path: string, data: any, transfers: any[]): LinkedTransferValidationResponse[] => {

    const response: LinkedTransferValidationResponse[] = [];

    const controlValues = queryObject(path, data);

    if (Array.isArray(controlValues) && controlValues.length) {

      controlValues.forEach((controlValue) => {

        const sourceAddressValue = _.get(controlValue, 'data.address', emptyAddressFormControl.value);

        const linkedTransferIds = _.get(controlValue, 'data.linkedTransferId', []);

        if (Array.isArray(linkedTransferIds) && linkedTransferIds.length) {

          linkedTransferIds.forEach((linkedTransferId: LinkedTransferId) => {

            // Find linkedTransferId in transfers (_meta.id)
            const transfer = transfers.find((t: any) => t._meta.id === linkedTransferId.id);

            if (transfer) {

              const res: LinkedTransferValidationResponse = {
                transferId: transfer._meta.id,
                isValid: true,
                pickupIsValid: true,
                dropOffIsValid: true
              }

              if (linkedTransferId.type === LinkedTransferIdType.Pickup) {

                if (!isEqualAddress(transfer.from.address, sourceAddressValue)) {

                  res.isValid = false;
                  res.pickupIsValid = false;
                  res.pickupSourceAddress = sourceAddressValue;
                  res.pickupTransferAddress = transfer.from.address;

                }

              } else if (linkedTransferId.type === LinkedTransferIdType.DropOff) {

                if (!isEqualAddress(transfer.to.address, sourceAddressValue)) {

                  res.isValid = false;
                  res.dropOffIsValid = false;
                  res.dropOffSourceAddress = sourceAddressValue;
                  res.dropOffTransferAddress = transfer.to.address;

                }

              }

              response.push(res);

            }

          });

        }

      });

    }

    return response;

  }

  const response: LinkedTransferValidationResponse[] = [];

  // Do this first to remove any orphaned linkedTransferIds
  removeOrphanedLinkedTransferIds(arrangement);

  const transfersValues = arrangement.form.get('transfer.transfers')?.value;

  // Validate Place of Death
  const placeOfPassingResponse = validatePlaceOfPassing('deceased.placeOfPassing', arrangement.form.value, transfersValues);

  response.push(...placeOfPassingResponse);

  // Validate Preparation
  const preparationResponse = validatePreparationServiceProvider('preparation', arrangement.form.value, transfersValues);

  response.push(...preparationResponse);

  // Validate Events
  const eventsResponse = validateEventVenue('events[?].venue', arrangement.form.value, transfersValues);

  response.push(...eventsResponse);

  // Validate Transfers (pickup)
  const transferFrom = validateTransferFrom('transfer.transfers[?].from', arrangement.form.value, transfersValues);

  response.push(...transferFrom);

  // Validate Transfers (drop-off)
  const transferToResponse = validateTransferTo('transfer.transfers[?].to', arrangement.form.value, transfersValues);

  response.push(...transferToResponse);

  console.log('validateLinkedTransfers', response);

  return response;

}

export function removeLinkedTransferIds(transferId: string, type: LinkedTransferIdType, arrangement: Arrangement): boolean {

  let response = false;

  const linkedTransferControlPaths: string[] = [
    'deceased.placeOfPassing.linkedTransferId',
    'preparation.linkedTransferId',
    'events[?].venue.linkedTransferId',
    'transfer.transfers[?].from.linkedTransferId',
    'transfer.transfers[?].to.linkedTransferId',
  ];

  for (const linkedTransferControlPath of linkedTransferControlPaths) {

    const linkedTransfers = queryObject(linkedTransferControlPath, arrangement.form.value);

    for (const linkedTransfer of linkedTransfers) {

      if (Array.isArray(linkedTransfer.data) && linkedTransfer.data.length) {
        
        for (const linkedTransferData of linkedTransfer.data) {

          if (linkedTransferData.id === transferId && linkedTransferData.type === type) {

            const targetFormControl = arrangement.form.get(convertPathToDotNotation(linkedTransfer.path));

            if (targetFormControl) {

              const targetValue = targetFormControl.value as any[];

              const updatedValue = targetValue.filter((tv: any) => {
                return tv.id !== transferId || tv.type !== type;
              });

              targetFormControl.patchValue(updatedValue);

              response = true;

            }

          }

        }

      }

    }

  }

  return response;

}

export function removeOrphanedLinkedTransferIds(arrangement: Arrangement) {
  
  const linkedTransferControlPaths: string[] = [
    'deceased.placeOfPassing.linkedTransferId',
    'preparation.linkedTransferId',
    'events[?].venue.linkedTransferId',
    'transfer.transfers[?].from.linkedTransferId',
    'transfer.transfers[?].to.linkedTransferId',
  ];

  for (const linkedTransferControlPath of linkedTransferControlPaths) {

    const linkedTransfers = queryObject(linkedTransferControlPath, arrangement.form.value);

    const transfers = _.get(arrangement.form.value, 'transfer.transfers', []);

    for (const linkedTransfer of linkedTransfers) {

      if (Array.isArray(linkedTransfer.data) && linkedTransfer.data.length) {
        
        for (const linkedTransferData of linkedTransfer.data) {

          const transfer = transfers.find((t: any) => t._meta.id === linkedTransferData.id);

          if (!transfer) {

            const targetFormControl = arrangement.form.get(linkedTransfer.path);

            if (targetFormControl) {
              targetFormControl.patchValue([]);
            }

          }

        }

      }

    }

  }

}

export function addLinkedTransferId(targetLinkedTransfer: TargetLinkedTransfer, arrangement: Arrangement): boolean {

  let response = false;

  let targetFormControl: FormControl | null = null;

  // Each transfer can only be linked once, so lets remove any existing links before we add the new one
  const removed = removeLinkedTransferIds(targetLinkedTransfer.transferId, targetLinkedTransfer.transferType, arrangement);

  if (targetLinkedTransfer.targetElement === 'place-of-passing') {

    targetFormControl = arrangement.form.get('deceased.placeOfPassing.linkedTransferId') as FormControl;
    
  } else if (targetLinkedTransfer.targetElement === 'preparation') {

    targetFormControl = arrangement.form.get('preparation.linkedTransferId') as FormControl;

  } else if (targetLinkedTransfer.targetElement === 'service-provider') {

    // Intentionally left blank for now

  } else if (targetLinkedTransfer.targetElement === 'transfer' && targetLinkedTransfer.targetElementId) {

    const transfersFormArray = arrangement.form.get('transfer.transfers') as FormArray;

    const transferFormGroup = transfersFormArray.controls.find(
      (control: AbstractControl) => (control as FormGroup).get('_meta.id')?.value === targetLinkedTransfer.targetElementId
    ) as FormGroup | undefined;

    if (transferFormGroup) {

      let path = null;

      if (targetLinkedTransfer.transferType === LinkedTransferIdType.Pickup) {

        path = 'from';

      } else if (targetLinkedTransfer.transferType === LinkedTransferIdType.DropOff) {

        path = 'to';

      }

      if (path) {

        targetFormControl = transferFormGroup.get(`${path}.linkedTransferId`) as FormControl;

      }

    }

  } else if (targetLinkedTransfer.targetElement === 'event') {

    const eventsFormArray = arrangement.form.get('events') as FormArray;

    const eventFormGroup = eventsFormArray.controls.find(
      (control: AbstractControl) => (control as FormGroup).get('_meta.id')?.value === targetLinkedTransfer.targetElementId
    ) as FormGroup | undefined;

    if (eventFormGroup) {

      targetFormControl = eventFormGroup.get('venue.linkedTransferId') as FormControl;

    }

  } else {

    // Intentionally left blank for now

  }

  if (targetFormControl) {

    let targetFormControlValue: LinkedTransferId[] = targetFormControl.value;

    // Remove any items that don't have an 'id' or 'type'
    targetFormControlValue = targetFormControlValue.filter(item => item.id && item.type);

    // Add idsToAdd to linkedTransferIdValue
    targetFormControlValue = targetFormControlValue.concat([{ id: targetLinkedTransfer.transferId, type: targetLinkedTransfer.transferType }]);
 
    // Remove duplicates
    targetFormControlValue = _.uniqBy(targetFormControlValue, item => item.id + item.type);

    // Update the form control with final value
    targetFormControl.patchValue(targetFormControlValue);

  }

  return response;

}

export function convertPathToDotNotation(path: string): string {
  return path.replace(/\[(\d+)\]/g, '.$1');
}