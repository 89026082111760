<div class="formgrid grid">

  <div class="col-12 flex align-items-center">
    <h3 class="flex-grow-1 text-500 border-bottom-1 border-200" [ngClass]="headingCssClass">{{ headingText }}</h3>
    <ng-container *ngIf="(addressOptions | async) as options">
      <p-menu #addressOptionsMenu [popup]="true" [model]="options" appendTo="body"></p-menu>
      <button pButton pRipple type="button" icon="pi pi-cog" class="p-button-rounded p-button-secondary p-button-text" (click)="$any(addressOptionsMenu).toggle($event)"></button>
    </ng-container>
  </div>

  <ng-content select="[additionalControl]"></ng-content>

  <ng-container *ngIf="!hideControls">

    <div *ngIf="internationalAddressToggleSwitchText" class="col-12">

      <div class="flex align-items-center pr-3 my-3 surface-ground border-round-lg border-1 border-200">
        <label class="flex-auto cursor-pointer p-3" (click)="isInternationalFormControl.patchValue(!isInternationalFormControl.value)">{{ internationalAddressToggleSwitchText }}</label>
        <p-inputSwitch class="flex-initial" [formControl]="isInternationalFormControl"></p-inputSwitch>
      </div>

    </div>

    <div *ngIf="autoFillToggleSwitchText && autoFillToggleSwitchControlName" class="col-12">

      <div class="flex mb-3">
        <label class="flex-auto">{{ autoFillToggleSwitchText }}</label>
        <p-inputSwitch #addressUseAutoFill class="flex-initial" [formControlName]="autoFillToggleSwitchControlName"></p-inputSwitch>
      </div>

    </div>

    <div class="field col-12" *ngIf="autoFillAddressControlName && autoFillAddressOptions">

      <app-select #addressAutoFill [controlName]="autoFillAddressControlName" [options]="autoFillAddressOptions | sort:'asc':'name'" [filter]="true" [showClear]="true" [showOptions]="true" placeHolder="Select Place of Death Location" class="inputfield w-full"></app-select>
    
    </div>

    <div class="col-12">

      <app-address #address class="w-full" [controlName]="addressControlName"></app-address>

    </div>

  </ng-container>

</div>
