import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TransferType } from './forms/form-enum';
import _ from 'lodash';

@Pipe({
  name: 'transferFilter'
})
export class TransferFilterPipe implements PipeTransform {

  transform(value: AbstractControl[], type: { name: string, value: TransferType | 'view-all' }): AbstractControl[] {
    
    let filterResponse: AbstractControl[] = [];

    if (type.value === 'view-all' || !type.value) {
      return value;
    }

    filterResponse = value.filter((transfer: AbstractControl) => {
      const typeValue = transfer.get('type')?.value;
      if (!typeValue) {
        return false;
      } else {
        return _.get(typeValue, 'value') === type.value;
      }
    });

    return filterResponse;

  }

}
