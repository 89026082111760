import _ from 'lodash';
import { DynamicComponents } from '../dynamic/services/dynamic.service';

import { ArrangementFuneralFormat, ArrangementMeetingType, ArrangementStatus, ArrangementType, EmailType, Gender, PhoneType, RelationToDeceased, ServiceVisibility } from '../models/arrangement';
import { BirthDeathsAndMarriagesApiTarget, childLifeStatusType, clothingInstructionType, CODCertificationType, dateOfPassingType, departmentOfVeteranAffairsCauseOfDeath, eventIndex, jewelleryInstructionType, methodOfDisposalType, noteVisibilityType, nswBirthDeathsAndMarriagesCertificateTypeType, nswBirthDeathsAndMarriagesDeliveryTypeType, nswBirthDeathsAndMarriagesMarriageStatusType, nswBirthDeathsAndMarriagesMarriageType, nswBirthDeathsAndMarriagesParentRelationshipType, pensionType, PlaceOfPassingType, statutoryDeclarationApplicantType, taskStatusType, secondNoteTransferType, taskType, Unknown, YesNo, TransferFromAddressType, TransferToAddressType, AustralianStates, TransferType, preparationClothingType, preparationUnderwareType, preparationAccessoryType, preparationChildrenClothingType } from './form-enum';

const omitServiceTypesForEventServiceTypesFormValue = [
  DynamicComponents.BirthsDeathsAndMarriages,
  DynamicComponents.Burial,
  DynamicComponents.Coffin,
  DynamicComponents.Cremation,
  DynamicComponents.MedicalCertificate,
  DynamicComponents.Notification,
  DynamicComponents.Urn,
];

const omitServiceTypesForPreparationServiceTypesFormValue = [
  DynamicComponents.AudioVisual,
  DynamicComponents.BirthsDeathsAndMarriages,
  DynamicComponents.Burial,
  DynamicComponents.Catering,
  DynamicComponents.Cremation,
  DynamicComponents.EventStaff,
  DynamicComponents.Flower,
  DynamicComponents.MedicalCertificate,
  DynamicComponents.Miscellaneous,
  DynamicComponents.Music,
  DynamicComponents.Notification,
  DynamicComponents.Stationery,
  DynamicComponents.Transport,
];

const omitServiceTypesForTransferServiceTypesFormValue = [
  DynamicComponents.AudioVisual,
  DynamicComponents.BirthsDeathsAndMarriages,
  DynamicComponents.Catering,
  DynamicComponents.Coffin,
  DynamicComponents.EventStaff,
  DynamicComponents.Flower,
  DynamicComponents.MedicalCertificate,
  DynamicComponents.Music,
  DynamicComponents.Notification,
  DynamicComponents.Stationery,
  DynamicComponents.Urn,
];

export const pinnedContactList = [
  { label: 'Operations', value: 'operations@picaluna.com' }
];

export const serviceVisibilityFormValue = [
  { name: 'All Accounts', value: ServiceVisibility.All },
  { name: 'Current Account Only', value: ServiceVisibility.Account },
  { name: 'Current User Only', value: ServiceVisibility.Self },
];

export const gendersFormValue = [
  { name: 'Female', value: Gender.Female },
  { name: 'Indeterminate', value: Gender.Indeterminate },
  { name: 'Intersex', value: Gender.Intersex },
  { name: 'Male', value: Gender.Male },
  { name: 'Unknown', value: Gender.Unknown },
  { name: 'Non-Specific', value: Gender.NonSpecific },
];

export const emailTypesFormValue = [
  { name: 'Primary', value: EmailType.Primary },
  { name: 'Secondary', value: EmailType.Secondary },
  { name: 'Other', value: EmailType.Other },
];

export const arrangementMeetingTypesFormValue = [
  { name: 'In Person', value: ArrangementMeetingType.InPerson },
  { name: 'Phone', value: ArrangementMeetingType.TeleConference },
  { name: 'Video Call', value: ArrangementMeetingType.VideoCall },
];

export const phoneTypesFormValue = [
  { name: 'Primary', value: PhoneType.Primary },
  { name: 'Secondary', value: PhoneType.Secondary },
  { name: 'Other', value: PhoneType.Other },
];

export const arrangementTypesFormValue = [
  { name: 'At Need', value: ArrangementType.AtNeed },
  { name: 'Pre Need', value: ArrangementType.PreNeed },
  { name: 'Pre Paid', value: ArrangementType.PrePaid },
];

export const arrangementFuneralFormatsFormValue = [
  { name: 'Burial No Service', value: ArrangementFuneralFormat.BurialNoService },
  { name: 'Burial With A Service', value: ArrangementFuneralFormat.BurialWithAService },
  { name: 'Cremation No Service', value: ArrangementFuneralFormat.CremationNoService },
  { name: 'Cremation With A Service', value: ArrangementFuneralFormat.CremationWithAService },
];

export const arrangementStatusFormValue = [
  { name: 'Active', value: ArrangementStatus.Active },
  { name: 'Closed', value: ArrangementStatus.Archived },
  { name: 'Invoiced', value: ArrangementStatus.Invoiced },
  { name: 'Lost Lead', value: ArrangementStatus.LostLead },
  { name: 'Pending Death', value: ArrangementStatus.PendingDeath },
  { name: 'Quote', value: ArrangementStatus.Quote },
  { name: 'Ready To Reconcile', value: ArrangementStatus.ReadyToReconcile },
  { name: 'Testing', value: ArrangementStatus.Testing },
];

export const arrangementStatusAllFormValue = [
  { name: 'Active', value: ArrangementStatus.Active },
  { name: 'Archived', value: ArrangementStatus.Archived },
  { name: 'Current Lead', value: ArrangementStatus.CurrentLead },
  { name: 'Invoiced', value: ArrangementStatus.Invoiced },
  { name: 'Lost Lead', value: ArrangementStatus.LostLead },
  { name: 'Pending Death', value: ArrangementStatus.PendingDeath },
  { name: 'Quote', value: ArrangementStatus.Quote },
  { name: 'Ready To Reconcile', value: ArrangementStatus.ReadyToReconcile },
  { name: 'Testing', value: ArrangementStatus.Testing },
];

export const relationshipTypesFormValue = [
  { name: 'Unknown', value: RelationToDeceased.Unknown },

  { name: 'Informant', value: RelationToDeceased.Informant },
  { name: 'Next of Kin', value: RelationToDeceased.NextOfKin },
  { name: 'Executor', value: RelationToDeceased.Executor },
  { name: 'Celebrant', value: RelationToDeceased.Celebrant },
  { name: 'Supplier', value: RelationToDeceased.Supplier },
  { name: 'First Call Recipient', value: RelationToDeceased.FirstCallRecipient },
  { name: 'First Call Contact', value: RelationToDeceased.FirstCallContact },
  { name: 'Reporting Doctor', value: RelationToDeceased.ReportingDoctor },

  { name: 'Father', value: RelationToDeceased.Father },
  { name: 'Mother', value: RelationToDeceased.Mother },
  { name: 'Parent', value: RelationToDeceased.Parent },
  { name: 'Agent', value: RelationToDeceased.Agent },
  { name: 'Aunt', value: RelationToDeceased.Aunt },
  { name: 'Brother', value: RelationToDeceased.Brother },
  { name: 'Brother in Law', value: RelationToDeceased.BrotherInLaw },
  { name: 'Caseworker', value: RelationToDeceased.Caseworker },
  { name: 'Cousin', value: RelationToDeceased.Cousin },
  { name: 'Daughter', value: RelationToDeceased.Daughter },
  { name: 'Daughter in Law', value: RelationToDeceased.DaughterInLaw },
  { name: 'DeFacto', value: RelationToDeceased.DeFacto },
  { name: 'Director of Nursing', value: RelationToDeceased.DirectorOfNursing },
  { name: 'Executrix', value: RelationToDeceased.Executrix },
  { name: 'ExSpouse', value: RelationToDeceased.ExSpouse },
  { name: 'Father in Law', value: RelationToDeceased.FatherInLaw },
  { name: 'Foster Father', value: RelationToDeceased.FosterFather },
  { name: 'Foster Mother', value: RelationToDeceased.FosterMother },
  { name: 'Friend', value: RelationToDeceased.Friend },
  { name: 'Funeral Director', value: RelationToDeceased.FuneralDirector },
  { name: 'Goddaughter', value: RelationToDeceased.Goddaughter },
  { name: 'Godfather', value: RelationToDeceased.Godfather },
  { name: 'Godmother', value: RelationToDeceased.Godmother },
  { name: 'Godson', value: RelationToDeceased.Godson },
  { name: 'Granddaughter', value: RelationToDeceased.Granddaughter },
  { name: 'Grandfather', value: RelationToDeceased.Grandfather },
  { name: 'Grandmother', value: RelationToDeceased.Grandmother },
  { name: 'Grandnephew', value: RelationToDeceased.Grandnephew },
  { name: 'Grandniece', value: RelationToDeceased.Grandniece },
  { name: 'Grandson', value: RelationToDeceased.Grandson },
  { name: 'Great Aunt', value: RelationToDeceased.GreatAunt },
  { name: 'Great Granddaughter', value: RelationToDeceased.GreatGranddaughter },
  { name: 'Great Grandson', value: RelationToDeceased.GreatGrandson },
  { name: 'Great Uncle', value: RelationToDeceased.GreatUncle },
  { name: 'Guardian', value: RelationToDeceased.Guardian },
  { name: 'Half Brother', value: RelationToDeceased.HalfBrother },
  { name: 'Half Sister', value: RelationToDeceased.HalfSister },
  { name: 'Husband', value: RelationToDeceased.Husband },
  { name: 'Mother in Law', value: RelationToDeceased.MotherInLaw },
  { name: 'Nephew', value: RelationToDeceased.Nephew },
  { name: 'Niece', value: RelationToDeceased.Niece },
  { name: 'No Relation', value: RelationToDeceased.NoRelation },
  { name: 'Parents', value: RelationToDeceased.Parents },
  { name: 'Second Cousin', value: RelationToDeceased.SecondCousin },
  { name: 'Self', value: RelationToDeceased.Self },
  { name: 'Sister', value: RelationToDeceased.Sister },
  { name: 'Sister in Law', value: RelationToDeceased.SisterInLaw },
  { name: 'Son', value: RelationToDeceased.Son },
  { name: 'Son in Law', value: RelationToDeceased.SonInLaw },
  { name: 'Step Brother', value: RelationToDeceased.StepBrother },
  { name: 'Step Daughter', value: RelationToDeceased.StepDaughter },
  { name: 'Step Father', value: RelationToDeceased.StepFather },
  { name: 'Step Sister', value: RelationToDeceased.StepSister },
  { name: 'Step Son', value: RelationToDeceased.StepSon },
  { name: 'Step Mother', value: RelationToDeceased.StepMother },
  { name: 'Third Cousin', value: RelationToDeceased.ThirdCousin },
  { name: 'Uncle', value: RelationToDeceased.Uncle },
  { name: 'Widow', value: RelationToDeceased.Widow },
  { name: 'Widower', value: RelationToDeceased.Widower },
  { name: 'Wife', value: RelationToDeceased.Wife },
];

export const serviceTypesFormValue = [
  { name: 'Audio Visual', value: DynamicComponents.AudioVisual },
  { name: 'Births Deaths and Marriages', value: DynamicComponents.BirthsDeathsAndMarriages },
  { name: 'Burial', value: DynamicComponents.Burial },
  { name: 'Catering', value: DynamicComponents.Catering },
  { name: 'Coffin', value: DynamicComponents.Coffin },
  { name: 'Cremation', value: DynamicComponents.Cremation },
  { name: 'Event Staff', value: DynamicComponents.EventStaff },
  { name: 'Flower', value: DynamicComponents.Flower },
  { name: 'Medical Certificate', value: DynamicComponents.MedicalCertificate },
  { name: 'Miscellaneous', value: DynamicComponents.Miscellaneous },
  { name: 'Mortuary', value: DynamicComponents.Mortuary },
  { name: 'Music', value: DynamicComponents.Music },
  { name: 'Notification', value: DynamicComponents.Notification },
  { name: 'Stationery', value: DynamicComponents.Stationery },
  { name: 'Transport', value: DynamicComponents.Transport },
  { name: 'Urn', value: DynamicComponents.Urn },
  { name: 'Venue', value: DynamicComponents.Venue },
];

export const eventServiceTypesFormValue = _.filter(serviceTypesFormValue, (v: { name: string, value: DynamicComponents }, i) => {

  return _.findIndex(omitServiceTypesForEventServiceTypesFormValue, o => o === v.value) === -1;

});

export const preparationServiceTypesFormValue = _.filter(serviceTypesFormValue, (v: { name: string, value: DynamicComponents }, i) => {

  return _.findIndex(omitServiceTypesForPreparationServiceTypesFormValue, o => o === v.value) === -1;

});

export const transferServiceTypesFormValue = _.filter(serviceTypesFormValue, (v: { name: string, value: DynamicComponents }, i) => {

  return _.findIndex(omitServiceTypesForTransferServiceTypesFormValue, o => o === v.value) === -1;

});

export const addressStateFormValue = [
  { name: 'Australian Capital Territory', value: AustralianStates.ACT },
  { name: 'New South Wales', value: AustralianStates.NSW },
  { name: 'Northern Territory', value: AustralianStates.NT },
  { name: 'Queensland', value: AustralianStates.QLD },
  { name: 'South Australia', value: AustralianStates.SA },
  { name: 'Tasmania', value: AustralianStates.TAS },
  { name: 'Victoria', value: AustralianStates.VIC },
  { name: 'Western Australia', value: AustralianStates.WA },
  { name: 'Unknown', value: AustralianStates.UNKNOWN },
];

export const indigenousHeritageValue = [
  { name: 'Yes, Aboriginal', value: 'Aboriginal' },
  { name: 'Yes, Torres Straight Islander', value: 'Torres Strait Islander' },
  { name: 'Both Aboriginal and Torres Straight Islander Origin', value: 'Both Aboriginal and Torres Strait Islander' },
  { name: 'No, Neither', value: 'Neither' },
  { name: 'Unknown', value: 'Unknown' },
];

export const placeOfPassingTypeValue = [
  { name: 'Yes, a New South Wales Hospital', value: PlaceOfPassingType.NswHospital },
  { name: 'Yes, a New South Wales Nursing Home', value: PlaceOfPassingType.NswNursingHome },
  { name: 'Yes, a Victorian Hospital', value: PlaceOfPassingType.VicHospital },
  { name: 'Yes, a Victorian Nursing Home', value: PlaceOfPassingType.VicNursingHome },
  { name: 'Yes, a Queensland Hospital', value: PlaceOfPassingType.QldHospital },
  { name: 'Yes, a Queensland Nursing Home', value: PlaceOfPassingType.QldNursingHome },
  { name: 'Yes, an Australian Capital Territory Hospital', value: PlaceOfPassingType.ActHospital },
  { name: 'Yes, an Australian Capital Territory Nursing Home', value: PlaceOfPassingType.ActNursingHome },
  { name: 'Yes, a South Australia Hospital', value: PlaceOfPassingType.SaHospital },
  { name: 'Yes, a South Australia Nursing Home', value: PlaceOfPassingType.SaNursingHome },
  { name: 'Yes, a Tasmania Hospital', value: PlaceOfPassingType.TasHospital },
  { name: 'Yes, a Tasmania Nursing Home', value: PlaceOfPassingType.TasNursingHome },
  { name: 'No, Deceased passed away at their usual residence', value: PlaceOfPassingType.UsualResidence },
  { name: 'No, None of the Above', value: PlaceOfPassingType.None },
];

export const informDepartmentOfVeteranAffairsValue = [
  { name: 'No', value: YesNo.No },
  { name: 'Yes', value: YesNo.Yes },
];

export const departmentOfVeteranAffairsCauseOfDeathValue = [
  { name: 'Natural', value: departmentOfVeteranAffairsCauseOfDeath.Natural },
  { name: 'Service Related', value: departmentOfVeteranAffairsCauseOfDeath.ServiceRelated },
  { name: 'Other', value: departmentOfVeteranAffairsCauseOfDeath.Other },
];

export const deceasedRetiredAtPassingValue = [
  { name: 'No', value: YesNo.No },
  { name: 'Yes', value: YesNo.Yes },
];

export const deceasedPensionerAtPassingValue = [
  { name: 'No', value: YesNo.No },
  { name: 'Yes', value: YesNo.Yes },
];

export const pensionTypeValue = [
  { name: 'Aged', value: pensionType.Aged },
  { name: 'Carer', value: pensionType.Carer },
  { name: 'Disability Support', value: pensionType.DisabilitySupport },
  { name: 'Invalid', value: pensionType.Invalid },
  { name: 'None', value: pensionType.None },
  { name: 'Unknown', value: pensionType.Unknown },
  { name: 'Veteran', value: pensionType.Veteran },
  { name: 'Widow', value: pensionType.Widow },
];

export const centrelinkNotifyValue = [
  { name: 'No', value: YesNo.No },
  { name: 'Yes', value: YesNo.Yes },
];

export const nswBirthDeathsAndMarriagesMarriageStatusTypeValue = [
  { name: 'De Facto', value: nswBirthDeathsAndMarriagesMarriageStatusType.DeFacto },
  { name: 'Divorce', value: nswBirthDeathsAndMarriagesMarriageStatusType.Divorced },
  { name: 'Married', value: nswBirthDeathsAndMarriagesMarriageStatusType.Married },
  { name: 'Never Married', value: nswBirthDeathsAndMarriagesMarriageStatusType.NeverMarried },
  { name: 'Widowed', value: nswBirthDeathsAndMarriagesMarriageStatusType.Widowed },
  { name: 'Unknown', value: nswBirthDeathsAndMarriagesMarriageStatusType.Unknown },
];

export const nswBirthDeathsAndMarriagesMarriageTypeValue = [
  { name: 'De Facto', value: nswBirthDeathsAndMarriagesMarriageType.DeFacto },
  { name: 'Divorce', value: nswBirthDeathsAndMarriagesMarriageType.Divorced },
  { name: 'Married', value: nswBirthDeathsAndMarriagesMarriageType.Married },
  { name: 'Widowed', value: nswBirthDeathsAndMarriagesMarriageType.Widowed },
  { name: 'Unknown', value: nswBirthDeathsAndMarriagesMarriageType.Unknown },
];

export const nswBirthDeathsAndMarriagesParentRelationshipTypeValue = [
  { name: 'Father', value: nswBirthDeathsAndMarriagesParentRelationshipType.Father },
  { name: 'Mother', value: nswBirthDeathsAndMarriagesParentRelationshipType.Mother },
  { name: 'Parent', value: nswBirthDeathsAndMarriagesParentRelationshipType.Parent },
];

export const nswBirthDeathsAndMarriagesCertificateTypeValue = [
  { name: 'eRegistry Death Certificate - Ordinary', value: nswBirthDeathsAndMarriagesCertificateTypeType.eNDO },
  { name: 'eRegistry Death Certificate - Priority', value: nswBirthDeathsAndMarriagesCertificateTypeType.eNDP },
];

export const nswBirthDeathsAndMarriagesDeliveryTypeValue = [
  { name: 'Registered Mail', value: nswBirthDeathsAndMarriagesDeliveryTypeType.RegisteredMail },
  { name: 'Collect', value: nswBirthDeathsAndMarriagesDeliveryTypeType.Collect },
];

export const hospitalOrNursingHomeValue = [
  { name: 'Hospital', value: 'hospital' },
  { name: 'Nursing Home', value: 'nursing-home' },
];

export const lifeStatusValue = [
  { name: 'Alive', value: childLifeStatusType.Alive },
  { name: 'Deceased', value: childLifeStatusType.Deceased },
  { name: 'Unknown', value: childLifeStatusType.Unknown },
  { name: 'Stillborn', value: childLifeStatusType.Stillborn },
];

export const CODCertificationTypeValue = [
  { name: 'MCCD', value: CODCertificationType.MCCD },
  { name: 'MCPD', value: CODCertificationType.MCPD },
  { name: 'Coroner', value: CODCertificationType.Coroner },
  // { name: 'eMCCD', value: CODCertificationType.eMCCD },
];

export const methodOfDisposalTypeValue = [
  { name: 'Body Donation', value: methodOfDisposalType.BodyDonation },
  { name: 'Body Not Recovered', value: methodOfDisposalType.BodyNotRecovered },
  { name: 'Burial', value: methodOfDisposalType.Buried },
  { name: 'Cremation', value: methodOfDisposalType.Cremated },
  { name: 'Repatriation', value: methodOfDisposalType.Repatriated },
];

export const dateOfPassingTypeValue = [
  { name: 'On', value: dateOfPassingType.On },
  { name: 'Between', value: dateOfPassingType.Between },
  { name: 'Unknown', value: dateOfPassingType.Unknown },
  { name: 'Approx.', value: dateOfPassingType.Approx },
  { name: 'On or After', value: dateOfPassingType.OnOrAfter },
  { name: 'On or About', value: dateOfPassingType.OnOrAbout },
  { name: 'Sometime On or After', value: dateOfPassingType.SometimeOnOrAfter },
];

export const isCoronersCaseValue = [
  { name: 'No', value: YesNo.No },
  { name: 'Yes', value: YesNo.Yes },
];

export const isStillBornBabyValue = [
  { name: 'No', value: YesNo.No },
  { name: 'Yes', value: YesNo.Yes },
];

export const jewelleryInstructionValue = [
  { name: 'Remain on body of Deceased', value: jewelleryInstructionType.RemainOnBodyOfDeceased },
  { name: 'Return to Family', value: jewelleryInstructionType.ReturnToFamily },
  { name: 'Other, with details provided in the instructions below', value: jewelleryInstructionType.OtherWithDetailsProvidedInTheInstructionsBelow },
];

export const clothingInstructionValue = [
  { name: 'Destroy Clothing', value: clothingInstructionType.DestroyClothing },
  { name: 'Remain on body of Deceased', value: clothingInstructionType.RemainOnBodyOfDeceased },
  { name: 'Return to Family and replace with newly supplied clothing', value: clothingInstructionType.ReturnToFamilyAndReplaceWithNewlySuppliedClothing },
  { name: 'Destroy clothing and replace with clothing supplied by family', value: clothingInstructionType.DestroyClothingAndReplaceWithClothingSuppliedByFamily },
  { name: 'Other, with details provided in the instructions below', value: clothingInstructionType.OtherWithDetailsProvidedInTheInstructionsBelow },
];

export const noteVisibilityValue = [
  { name: 'Everyone', value: noteVisibilityType.Everyone },
  { name: 'Only Me', value: noteVisibilityType.User },
];

export const taskStatusValue = [
  { name: 'Not Started', value: taskStatusType.NotStarted },
  { name: 'In Progress', value: taskStatusType.InProgress },
  { name: 'Paused', value: taskStatusType.Paused },
  { name: 'Completed', value: taskStatusType.Completed },
  { name: 'Not Required', value: taskStatusType.NotRequired },
];

export const taskTypeValue = [
  { name: 'First Call', value: taskType.firstCall },
  { name: 'Book Arrangement Meeting', value: taskType.bookArrangementMeeting },
  { name: 'Contacts', value: taskType.contacts },
  { name: 'Event Details', value: taskType.eventDetails },
  { name: 'Preparation Details', value: taskType.preparationDetails },
  { name: 'Disposal Details', value: taskType.disposalDetails },
  { name: 'BDM', value: taskType.bdm },
  { name: 'Statutory Declaration', value: taskType.statutoryDeclaration },
  { name: 'Newspaper / RSVP', value: taskType.newspaperNotifications },
  { name: 'Cost Summary', value: taskType.costSummary },
  { name: 'Document Library', value: taskType.documentLibrary },
];

export const statutoryDeclarationApplicantTypeValue = [
  { name: 'Nearest Surviving Relative', value: statutoryDeclarationApplicantType.NearestSurvivingRelative },
  { name: 'Executor', value: statutoryDeclarationApplicantType.Executor },
  { name: 'Other', value: statutoryDeclarationApplicantType.Other },
];

export const statutoryDeclarationStillBornApplicantTypeValue = [
  { name: 'Nearest Surviving Relative', value: statutoryDeclarationApplicantType.NearestSurvivingRelative },
  { name: 'Other', value: statutoryDeclarationApplicantType.Other },
];

export const statutoryDeclarationRelativesHaveBeenInformedValue = [
  { name: 'No', value: YesNo.No },
  { name: 'Yes', value: YesNo.Yes },
];

export const statutoryDeclarationRelativesHaveObjectedValue = [
  { name: 'No', value: YesNo.No },
  { name: 'Yes', value: YesNo.Yes },
];

export const statutoryDeclarationExaminationRequiredValue = [
  { name: 'No', value: YesNo.No },
  { name: 'Yes', value: YesNo.Yes },
];

export const statutoryDeclarationBatteryPoweredDevicePresentValue = [
  { name: 'No', value: YesNo.No },
  { name: 'Yes', value: YesNo.Yes },
  { name: 'Unknown', value: Unknown.Unknown },
];

export const statutoryDeclarationBatteryPoweredDeviceRemovedValue = [
  { name: 'No', value: YesNo.No },
  { name: 'Yes', value: YesNo.Yes },
];

export const statutoryDeclarationBatteryPoweredDevicePermissionToRemoveValue = [
  { name: 'No', value: YesNo.No },
  { name: 'Yes', value: YesNo.Yes },
  { name: 'Unknown', value: Unknown.Unknown },
];

export const statutoryDeclarationRadiopharmaceuticalsValue = [
  { name: 'No', value: YesNo.No },
  { name: 'Yes', value: YesNo.Yes },
  { name: 'Unknown', value: Unknown.Unknown },
];

export const dateOfPickupTypeValue = [
  { name: 'On', value: dateOfPassingType.On },
  { name: 'Between', value: dateOfPassingType.Between },
];

export const serviceFeeIncludedValue = [
  { name: 'Yes', value: YesNo.Yes },
  { name: 'No', value: YesNo.No },
];

// This is for 2nd Note, not the transfers component
export const secondNoteTransferTypesValue = [
  { name: 'Collection', value: secondNoteTransferType.Collection },
  { name: 'Return', value: secondNoteTransferType.Return },
];

export const eventIndexesValue = [
  { name: 'First', value: eventIndex.First },
  { name: 'Second', value: eventIndex.Second },
  { name: 'Third', value: eventIndex.Third },
  { name: 'Fourth', value: eventIndex.Fourth },
  { name: 'Fifth', value: eventIndex.Fifth },
  { name: 'Sixth', value: eventIndex.Sixth },
  { name: 'Seventh', value: eventIndex.Seventh },
  { name: 'Eighth', value: eventIndex.Eighth },
  { name: 'Ninth', value: eventIndex.Ninth },
  { name: 'Tenth', value: eventIndex.Tenth },
];

export const transferFromAddressTypesValue = [
  { name: 'Place of Death', value: TransferFromAddressType.PlaceOfPassing },
  { name: 'Transfer', value: TransferFromAddressType.ExistingTransfer },
  { name: 'Event', value: TransferFromAddressType.ExistingEvent },
  { name: 'Service Provider', value: TransferFromAddressType.ServiceProvider },
  { name: 'Custom Address', value: TransferFromAddressType.Custom },
];

export const transferToAddressTypesValue = [
  { name: 'Service Provider', value: TransferToAddressType.ServiceProvider },
  { name: 'Event Venue', value: TransferToAddressType.ExistingEvent },
  { name: 'Custom Address', value: TransferToAddressType.Custom },
];

export const transferTypesValue = [
  { name: 'First Call', value: TransferType.FirstCall },
  { name: 'Preparation', value: TransferType.Preparation },
  { name: 'Disposal', value: TransferType.Disposal },
  { name: 'Event', value: TransferType.Event },
  { name: 'Unknown', value: TransferType.Unknown },
];

export const BirthDeathsAndMarriagesApiTargetValue = [
  { name: 'New South Wales', value: BirthDeathsAndMarriagesApiTarget.NewSouthWales },
  { name: 'Queensland', value: BirthDeathsAndMarriagesApiTarget.Queensland },
  { name: 'Victoria', value: BirthDeathsAndMarriagesApiTarget.Victoria },
];

export const preparationClothingTypeValue = [
  { name: 'Blouse / Shirt', value: preparationClothingType.BlouseShirt },
  { name: 'Cardigan', value: preparationClothingType.Cardigan },
  { name: 'Dress', value: preparationClothingType.Dress },
  { name: 'Jacket', value: preparationClothingType.Jacket },
  { name: 'Jumper / Vest', value: preparationClothingType.JumperVest },
  { name: 'Nightdress', value: preparationClothingType.Nightdress },
  { name: 'Pyjamas', value: preparationClothingType.Pyjamas },
  { name: 'Shoes / Slippers', value: preparationClothingType.ShoesSlippers },
  { name: 'Shorts', value: preparationClothingType.Shorts },
  { name: 'Skirt', value: preparationClothingType.Skirt },
  { name: 'Trousers', value: preparationClothingType.Trousers },
  { name: 'Other', value: preparationClothingType.Other },
];

export const preparationUnderwareTypeValue = [
  { name: 'Bra', value: preparationUnderwareType.Bra },
  { name: 'Pantyhose', value: preparationUnderwareType.Pantyhose },
  { name: 'Singlet', value: preparationUnderwareType.Singlet },
  { name: 'Slip', value: preparationUnderwareType.Slip },
  { name: 'Socks', value: preparationUnderwareType.Socks },
  { name: 'Underpants', value: preparationUnderwareType.Underpants },
  { name: 'Other', value: preparationUnderwareType.Other },
];

export const preparationAccessoryTypeValue = [
  { name: 'Belt', value: preparationAccessoryType.Belt },
  { name: 'Glasses', value: preparationAccessoryType.Glasses },
  { name: 'Handkerchief', value: preparationAccessoryType.Handkerchief },
  { name: 'Hat', value: preparationAccessoryType.Hat },
  { name: 'Suspenders', value: preparationAccessoryType.Suspenders },
  { name: 'Tie', value: preparationAccessoryType.Tie },
  { name: 'Perfume', value: preparationAccessoryType.Perfume },
  { name: 'Other', value: preparationAccessoryType.Other },
];

export const preparationChildrenClothingTypeValue = [
  { name: 'Booties', value: preparationChildrenClothingType.Booties },
  { name: 'Jumpsuit', value: preparationChildrenClothingType.Jumpsuit },
  { name: 'Mittens', value: preparationChildrenClothingType.Mittens },
  { name: 'Nappy', value: preparationChildrenClothingType.Nappy },
  { name: 'Singlet', value: preparationChildrenClothingType.Singlet },
  { name: 'BonnetHat', value: preparationChildrenClothingType.BonnetHat },
  { name: 'Other', value: preparationChildrenClothingType.Other },
];