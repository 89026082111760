export enum AustralianStates {
  NSW = 'NSW',
  VIC = 'VIC',
  QLD = 'QLD',
  ACT = 'ACT',
  SA = 'SA',
  NT = 'NT',
  WA = 'WA',
  TAS = 'TAS',
  UNKNOWN = 'Unknown',
}

export enum TransferType {
  FirstCall = 'first-call',
  Preparation = 'preparation',
  Disposal = 'disposal',
  Event = 'event',
  Unknown = 'unknown',
}

export enum TransferFromAddressType {
  PlaceOfPassing = 'place-of-passing',
  ServiceProvider = 'service-provider',
  ExistingTransfer = 'existing-transfer',
  ExistingEvent = 'existing-event',
  Custom = 'custom',
};

export enum TransferToAddressType {
  ServiceProvider = 'service-provider',
  ExistingTransfer = 'existing-transfer',
  ExistingEvent = 'existing-event',
  Custom = 'custom',
};

export enum BirthDeathsAndMarriagesApiTarget {
  NewSouthWales = 'nsw',
  Queensland = 'qld',
  Victoria = 'vic',
}

export enum PlaceOfPassingType {
  NswHospital = 'nsw-hospital',
  NswNursingHome = 'nsw-nursing-home',
  VicHospital = 'vic-hospital',
  VicNursingHome = 'vic-nursing-home',
  QldHospital = 'qld-hospital',
  QldNursingHome = 'qld-nursing-home',
  ActHospital = 'act-hospital',
  ActNursingHome = 'act-nursing-home',
  SaHospital = 'sa-hospital',
  SaNursingHome = 'sa-nursing-home',
  NtHospital = 'nt-hospital',
  NtNursingHome = 'nt-nursing-home',
  WaHospital = 'wa-hospital',
  WaNursingHome = 'wa-nursing-home',
  TasHospital = 'tas-hospital',
  TasNursingHome = 'tas-nursing-home',
  UsualResidence = 'usual-residence',
  None = 'none',
};

export enum YesNo {
  No = 'no',
  Yes = 'yes',
}

export enum Unknown {
  Unknown = 'unknown',
}

export enum Other {
  Other = 'other',
}

export enum departmentOfVeteranAffairsCauseOfDeath {
  Natural = 'natural',
  ServiceRelated = 'service-related',
  Other = 'other',
}

export enum pensionType {
  Aged = 'Aged',
  Invalid = 'Invalid',
  None = 'None',
  Unknown = 'Unknown',
  Veteran = 'Veteran',
  Widow = 'Widow',
  Carer = 'Carer',
  DisabilitySupport = 'Disability Support',
}

export enum nswBirthDeathsAndMarriagesGenderType {
  Male = 'Male',
  Female = 'Female',
  Indeterminate = 'Indeterminate',
  Intersex = 'Intersex',
  Unknown = 'Unknown',
  NonSpecific = 'Non-specific',
}

export enum nswBirthDeathsAndMarriagesMarriageStatusType {
  DeFacto = 'De Facto',
  Divorced = 'Divorced',
  Married = 'Married',
  NeverMarried = 'Never Married',
  Widowed = 'Widowed',
  Unknown = 'Unknown',
}

export enum nswBirthDeathsAndMarriagesMarriageType {
  DeFacto = 'DE FACTO',
  Divorced = 'DIVORCED',
  Married = 'MARRIED',
  Widowed = 'WIDOWED',
  Unknown = 'UNKNOWN',
}

export enum nswBirthDeathsAndMarriagesParentRelationshipType {
  Father = 'FATHER',
  Mother = 'MOTHER',
  Parent = 'PARENT',
}

export enum nswBirthDeathsAndMarriagesPartyDescriptionType {
  Bride = 'Bride',
  Bridegroom = 'Bridegroom',
  Groom = 'Groom',
  Partner = 'Partner',
}

export enum nswBirthDeathsAndMarriagesSexType {
  Male = 'Male',
  Female = 'Female',
  X = 'X',
}

export enum nswBirthDeathsAndMarriagesCertificateTypeType {
  eNDO = 'eNDO',
  eNDP = 'eNDP',
}

export enum nswBirthDeathsAndMarriagesDeliveryTypeType {
  RegisteredMail = 'Registered Mail',
  Collect = 'Collect',
}

export enum eventTransferType {
  TransferToEvent = 'transfer-to-event',
  TransferFromEvent = 'transfer-from-event',
}

export enum childLifeStatusType {
  Alive = 'Alive',
  Deceased = 'Deceased',
  Unknown = 'Unknown',
  Stillborn = 'Stillborn',
}

export enum CODCertificationType {
  MCCD = 'MCCD',
  MCPD = 'MCPD',
  Coroner = 'Coroner',
  eMCCD = 'eMCCD',
}

export enum methodOfDisposalType {
  BodyDonation = 'Body Donation',
  BodyNotRecovered = 'Body Not Recovered',
  Buried = 'Buried',
  Cremated = 'Cremated',
  Repatriated = 'Repatriated',
}

export enum dateOfPassingType {
  On = 'On',
  Between = 'Between',
  Unknown = 'Unknown',
  Approx = 'Approx.',
  OnOrAfter = 'On or After',
  OnOrAbout = 'On or About',
  SometimeOnOrAfter = 'Sometime On or After',
}

export enum jewelleryInstructionType {
  RemainOnBodyOfDeceased = 'Remain on body of Deceased',
  ReturnToFamily = 'Return to Family',
  OtherWithDetailsProvidedInTheInstructionsBelow = 'Other, with details provided in the instructions below'
}

export enum clothingInstructionType {
  DestroyClothing = 'Destroy Clothing',
  RemainOnBodyOfDeceased = 'Remain on body of Deceased',
  ReturnToFamilyAndReplaceWithNewlySuppliedClothing = 'Return to Family and replace with newly supplied clothing',
  DestroyClothingAndReplaceWithClothingSuppliedByFamily = 'Destroy clothing and replace with clothing supplied by family',
  OtherWithDetailsProvidedInTheInstructionsBelow = 'Other, with details provided in the instructions below'
}

export enum invoiceTypeType {
  ArrangementEstimate = 'ArrangementEstimate',
  ArrangementFinal = 'Final',
  Individual = 'Individual',
}

export enum invoiceStatusType {
  Pending = 'Pending',
  SentToXero = 'Sent to Xero',
  Paid = 'Paid',
}

export enum noteVisibilityType {
  Everyone = 'everyone',
  User = 'user',
}

export enum taskStatusType {
  NotStarted = 'Not Started',
  NotRequired = 'Not Required',
  InProgress = 'In Progress',
  Paused = 'Paused',
  Completed = 'Completed',
}

export enum taskType {
  firstCall = 'firstCall',
  bookArrangementMeeting = 'bookArrangementMeeting',
  contacts = 'contacts',
  eventDetails = 'eventDetails',
  preparationDetails = 'preparationDetails',
  disposalDetails = 'disposalDetails',
  bdm = 'bdm',
  statutoryDeclaration = 'statutoryDeclaration',
  newspaperNotifications = 'newspaperNotifications',
  costSummary = 'costSummary',
  documentLibrary = 'documentLibrary',
}

export enum statutoryDeclarationApplicantType {
  NearestSurvivingRelative = 'Nearest Surviving Relative',
  Executor = 'Executor',
  Other = 'Other',
}

export enum dateOfPickupType {
  On = 'On',
  Between = 'Between',
}

export enum secondNoteTransferType {
  Collection = 'Collection',
  Return = 'Return',
}

export enum eventIndex {
  First = 0,
  Second = 1,
  Third = 2,
  Fourth = 3,
  Fifth = 4,
  Sixth = 5,
  Seventh = 6,
  Eighth = 7,
  Ninth = 8,
  Tenth = 9,
}

export enum preparationClothingType {
  BlouseShirt = 'Blouse / Shirt',
  Cardigan = 'Cardigan',
  Dress = 'Dress',
  Jacket = 'Jacket',
  JumperVest = 'Jumper / Vest',
  Nightdress = 'Nightdress',
  Pyjamas = 'Pyjamas',
  ShoesSlippers = 'Shoes / Slippers',
  Shorts = 'Shorts',
  Skirt = 'Skirt',
  Trousers = 'Trousers',
  Other = 'Other',
}

export enum preparationUnderwareType {
  Bra = 'Bra',
  Pantyhose = 'Pantyhose',
  Singlet = 'Singlet',
  Slip = 'Slip',
  Socks = 'Socks',
  Underpants = 'Underpants',
  Other = 'Other',
}

export enum preparationAccessoryType {
  Belt = 'Belt',
  Glasses = 'Glasses',
  Handkerchief = 'Handkerchief',
  Hat = 'Hat',
  Suspenders = 'Suspenders',
  Tie = 'Tie',
  Perfume = 'Perfume',
  Aftershave = 'Aftershave',
  NeckScarf = 'Neck Scarf',
  Other = 'Other',
}

export enum preparationChildrenClothingType {
  Booties = 'Booties',
  Jumpsuit = 'Jumpsuit',
  Mittens = 'Mittens',
  Nappy = 'Nappy',
  Singlet = 'Singlet',
  BonnetHat = 'Bonnet / Hat',
  Other = 'Other',
}