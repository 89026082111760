<form [formGroup]="form" (ngSubmit)="onSubmit($event)">

  <div class="flex flex-column">

    <div class="content-container">

      <div class="formgrid grid">

        <div class="col-12 mt-3 mb-3">
          <p-card class="card-info w-full">
            <p class="text-center">You might not always want to print the whole Mortuary Preparation Form. You can select which sections are printed using the checkboxes below.</p>
          </p-card>
        </div>

        <div class="col-12 field-checkbox">
          <p-checkbox inputId="preparationServiceProviderFormControl" [binary]="true" [formControl]="preparationServiceProviderFormControl"></p-checkbox>
          <label for="preparationServiceProviderFormControl">1.0 - Preparation Service Provider</label>
        </div>
        
        <div class="col-12 field-checkbox">
          <p-checkbox inputId="careInstructionsFormControl" [binary]="true" [formControl]="careInstructionsFormControl"></p-checkbox>
          <label for="careInstructionsFormControl">2.1 - Care Instructions</label>
        </div>
        
        <div class="col-12 field-checkbox">
          <p-checkbox inputId="hazardousMaterialsFormControl" [binary]="true" [formControl]="hazardousMaterialsFormControl"></p-checkbox>
          <label for="hazardousMaterialsFormControl">2.2 - Hazardous Materials</label>
        </div>

        <div class="col-12 field-checkbox">
          <p-checkbox inputId="additionalServiceItemsFormControl" [binary]="true" [formControl]="additionalServiceItemsFormControl"></p-checkbox>
          <label for="additionalServiceItemsFormControl">3.0 - Additional Service Items</label>
        </div>
        
        <div class="col-12 field-checkbox">
          <p-checkbox inputId="clothingDetailsFormControl" [binary]="true" [formControl]="clothingDetailsFormControl"></p-checkbox>
          <label for="clothingDetailsFormControl">4.1.a - Clothing Details</label>
        </div>
        
        <div class="col-12 field-checkbox">
          <p-checkbox inputId="clothingInstructionsLegacyFormControl" [binary]="true" [formControl]="clothingInstructionsLegacyFormControl"></p-checkbox>
          <label for="clothingInstructionsLegacyFormControl">4.1.b - Clothing Instructions <span class="opacity-30">Legacy Arrangements</span></label>
        </div>
        
        <div class="col-12 field-checkbox">
          <p-checkbox inputId="specificClothingInstructionsFormControl" [binary]="true" [formControl]="specificClothingInstructionsFormControl"></p-checkbox>
          <label for="specificClothingInstructionsFormControl">4.2 - Specific Clothing Instructions</label>
        </div>
        
        <div class="col-12 field-checkbox">
          <p-checkbox inputId="clothingSignatoryFormControl" [binary]="true" [formControl]="clothingSignatoryFormControl"></p-checkbox>
          <label for="clothingSignatoryFormControl">4.3 - Clothing Signatory</label>
        </div>
        
        <div class="col-12 field-checkbox">
          <p-checkbox inputId="jewelleryAtTimeOfDeathFormControl" [binary]="true" [formControl]="jewelleryAtTimeOfDeathFormControl"></p-checkbox>
          <label for="jewelleryAtTimeOfDeathFormControl">5.1.a - Jewellery at time of Death</label>
        </div>
        
        <div class="col-12 field-checkbox">
          <p-checkbox inputId="jewelleryInstructionsLegacyFormControl" [binary]="true" [formControl]="jewelleryInstructionsLegacyFormControl"></p-checkbox>
          <label for="jewelleryInstructionsLegacyFormControl">5.1.b - Jewellery Instructions <span class="opacity-30">Legacy Arrangements</span></label>
        </div>
        
        <div class="col-12 field-checkbox">
          <p-checkbox inputId="jewelleryProvidedByFamilyFormControl" [binary]="true" [formControl]="jewelleryProvidedByFamilyFormControl"></p-checkbox>
          <label for="jewelleryProvidedByFamilyFormControl">5.2 - Jewellery Provided by Family</label>
        </div>
        
        <div class="col-12 field-checkbox">
          <p-checkbox inputId="jewellerySignatoryFormControl" [binary]="true" [formControl]="jewellerySignatoryFormControl"></p-checkbox>
          <label for="jewellerySignatoryFormControl">5.3 - Jewellery Signatory</label>
        </div>
        
        <div class="col-12 field-checkbox">
          <p-checkbox inputId="transfersFormControl" [binary]="true" [formControl]="transfersFormControl"></p-checkbox>
          <label for="transfersFormControl">6.0 - Transfers</label>
        </div>
        
        <div class="col-12 field-checkbox">
          <p-checkbox inputId="eventsFormControl" [binary]="true" [formControl]="eventsFormControl"></p-checkbox>
          <label for="eventsFormControl">7.0 - Events</label>
        </div>

      </div>

    </div>

    <div class="button-actions mt-3">

      <button pButton pRipple type="button" label="Cancel" (click)="onClose($event)" class="p-button-secondary"></button>
      <button pButton pRipple type="submit" label="Submit"></button>
      
    </div>

  </div>

</form>