import { Component, OnInit } from '@angular/core';
import _ from 'lodash';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-bdm-qld-authenticate',
  templateUrl: './bdm-qld-authenticate.component.html',
  styleUrls: ['./bdm-qld-authenticate.component.scss']
})
export class BdmQldAuthenticateComponent implements OnInit {

  form = new FormGroup({
    username: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });

  submitted: boolean;

  get username(): AbstractControl | null {
    return this.form.get('username');
  }

  get password(): AbstractControl | null {
    return this.form.get('password');
  }

  constructor(
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig,
  ) {

    const existingUsername = _.get(this.config.data, 'funeralDirector.api.username');

    if (existingUsername) {

      this.username?.patchValue(existingUsername);

    }

    const existingPassword = _.get(this.config.data, 'funeralDirector.api.password');

    if (existingPassword) {

      this.password?.patchValue(existingPassword);

    }

    this.submitted = false;

  }

  ngOnInit(): void {
  }

  onSubmit(event: Event): void {

    this.submitted = true;

    if (this.form.invalid) {

      return;

    }

    this.ref.close(this.form.value);

  }

  onClose(event: Event) {
    this.ref.close('cancel');
  }

}
