<div class="flex flex-column">

  <div class="formgrid grid">

    <div class="field col-12">

      <ng-container [formGroup]="form">

        <app-transfer-item [isNestedTransfer]="true"></app-transfer-item>

      </ng-container>

    </div>

    <div class="actions-container flex-1 flex justify-content-end">
    
      <button pButton pRipple type="button" label="Cancel" class="p-button-secondary" icon="pi pi-times" (click)="ref.close('cancel')"></button>
      <button pButton pRipple type="button" label="Add" class="p-button-primary" icon="pi pi-plus" (click)="ref.close('add')"></button>
      
    </div>
  
  </div>