import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ModalDataButton } from '../../services/modal.service';

@Component({
  selector: 'app-generic',
  templateUrl: './generic.component.html',
  styleUrls: ['./generic.component.scss']
})
export class GenericComponent implements OnInit {

  constructor(
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig,
  ) { }

  ngOnInit(): void {
  }

  onClick(button: ModalDataButton) {

    console.log('button', button);

    if (!button.handler) {

      return this.ref.close(button.key);

    }

    button.loading = true;

    button.handler().subscribe({

      next: (res: any) => { 

        button.loading = false;

        if (res && res.keepOpen) {
          return;
        }

        return this.ref.close(button.key);

      },

      error: (err: any) => { 

        button.loading = false;
        
        if (err && err.keepOpen) {
          return;
        }

        return this.ref.close(button.key);

      },

    })


  }

}
