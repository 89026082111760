import { environment as base } from './environment.base';

base.webAppUrl = 'https://training.fdconductor.com';
base.api.host = 'https://api-training.fdconductor.com';

base.appSync.graphql.host = 'https://qmfcvy2bengc5cgzkagxqxr7im.appsync-api.ap-southeast-2.amazonaws.com';
base.appSync.graphql.http = 'https://qmfcvy2bengc5cgzkagxqxr7im.appsync-api.ap-southeast-2.amazonaws.com/graphql';
base.appSync.graphql.wss = 'wss://qmfcvy2bengc5cgzkagxqxr7im.appsync-realtime-api.ap-southeast-2.amazonaws.com/graphql';
base.appSync.apiKey = 'da2-2fxz65iixfhyxfwo4ixqw36ahi';
// same as staging
base.stripe.publicKey = 'pk_test_51QRPxOLdUfXmfwmQ5TRSuyllbKIjanPccw6XfF3gsE4SAsSdBmppf4nU4fpA4KbrwoZ98bT501xuBBodPhqeVIaM00QMo6N9YS';

export const environment = base;
