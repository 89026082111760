import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormGenerator } from '../../forms/form-generator';
import { FormTransfersStructure } from '../../forms/form-structure';

@Component({
  selector: 'app-create-transfer',
  templateUrl: './create-transfer.component.html',
  styleUrls: ['./create-transfer.component.scss']
})
export class CreateTransferComponent implements OnInit {

  form!: FormGroup;

  constructor(
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig,
  ) {

    const formGenerator = new FormGenerator();

    this.form = formGenerator.generate(FormTransfersStructure, {});

  }

  ngOnInit(): void {
  }

}
