import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { MortuaryPreparationOptionsComponent } from './mortuary-preparation-options.component';
import { CheckboxModule } from 'primeng/checkbox';
import { CardModule } from 'primeng/card';



@NgModule({
  declarations: [MortuaryPreparationOptionsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    InputTextModule,
    PasswordModule,
    DropdownModule,
    ButtonModule,
    CheckboxModule,
    CardModule,
  ],
  exports: [MortuaryPreparationOptionsComponent]
})
export class MortuaryPreparationOptionsModule { }
