import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, SkipSelf, ViewChild } from '@angular/core';
import { ControlContainer, AbstractControl, FormControl, FormControlName, FormGroup, FormArray } from '@angular/forms';
import { MenuItem } from 'primeng/api/menuitem';
import { InputNumber } from 'primeng/inputnumber';
import { Menu } from 'primeng/menu';
import { getControlPath } from 'src/app/shared/getFormControlPath';
import { FormCompilerCodeStructure } from 'src/app/shared/forms/form-structure';
import { relativeDotPathToAbsoluteDotPath } from 'src/app/shared/relativeDotPathToAbsoluteDotPath';
import { JailedService } from 'src/app/shared/services/jailed.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { FormGenerator } from '../../form-generator';

@Component({
  selector: 'app-financial',
  templateUrl: './financial.component.html',
  styleUrls: ['./financial.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]]
    }
  ]
})
export class FinancialComponent implements OnInit, AfterViewInit {

  @Input() controlName!: string;

  @ViewChild(InputNumber, { static: true }) inputNumber!: InputNumber;
  @ViewChild(FormControlName) input!: FormControlName;

  formRoot!: AbstractControl;
  formParent!: FormGroup | FormArray | null;
  formGroup!: AbstractControl;

  placeholder: string;

  menuOptions: MenuItem[];

  constructor(
    private controlContainer: ControlContainer,
    private jailed: JailedService,
    private modalService: ModalService,
  ) {

    this.placeholder = '';

    this.menuOptions = [
      {
        label: 'Options',
        items: [
          {
            icon: 'pi pi-pencil',
            label: 'Price Compiler',
            command: () => { this.showCompiler() },
          },
        ],
      },
    ];

  }

  ngOnInit(): void {

    const control = this.controlContainer.control?.get(this.controlName);

    if (control) {

      this.formGroup = control;
      this.formParent = control.parent;
      this.formRoot = this.formGroup.root;

    }
    
    this.generatePlaceholder();

  }

  ngAfterViewInit(): void {

    // const element = this.inputNumber.el.nativeElement as HTMLElement;

    // const absolutePath = getControlPath(this.formGroup);

    // const relativePathA = 'cost/jam';
    // const relativePathB = '../jam';
    // const relativePathC = '../../jam';

    // const newAbsolutePathA = relativeDotPathToAbsoluteDotPath(relativePathA, absolutePath);
    // const newAbsolutePathB = relativeDotPathToAbsoluteDotPath(relativePathB, absolutePath);
    // const newAbsolutePathC = relativeDotPathToAbsoluteDotPath(relativePathC, absolutePath);

    // console.log('absolutePath: ', absolutePath);
    // console.log('A: ', relativePathA, newAbsolutePathA);
    // console.log('B: ', relativePathB, newAbsolutePathB);
    // console.log('C: ', relativePathC, newAbsolutePathC);

    // console.log('Form Value: ', this.formRoot.value);

    // this.jailed.execute(`

    //   let a = 10;
    //   let b = 20;
    //   let c = a + b;
      
    //   result = { c: c, d: data.aa + data.bb };

    //   done(result);

    // `, { aa: 1, bb: 2, cc: 3 }).then(result => console.log(result)).catch(e => console.error(e));

  }

  private showCompiler(): void {

    const compiler = this.formGroup.get('compiler')?.value;

    const modalRef = this.modalService.compiler((compiler) ? compiler : {});

    modalRef.onClose.subscribe({
      next: (result: any) => {
        
        if (result === null) {

          return; // DO NOTHING

        } else if (result === -1) {

          (this.formGroup as FormGroup).removeControl('compiler');

          this.generatePlaceholder();

        } else {

          (this.formGroup as FormGroup).removeControl('compiler');

          setTimeout(() => {

            const formGenerator = new FormGenerator();
  
            const formGroup = formGenerator.generate(FormCompilerCodeStructure, result);
  
            (this.formGroup as FormGroup).addControl('compiler', formGroup);

            this.generatePlaceholder();

          }, 0);

        }

      }
    });

  }

  private generatePlaceholder(): void {

    const code = this.formGroup.get('compiler.code')?.value;

    if (code) {

      this.placeholder = 'INPUT VALUE FROM COMPILER';

    } else {

      this.placeholder = ''

    }

  }

}
