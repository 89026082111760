import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowPdfComponent } from './show-pdf.component';
import { ButtonModule } from 'primeng/button';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ScrollPanelModule } from 'primeng/scrollpanel';



@NgModule({
  declarations: [ShowPdfComponent],
  imports: [
    CommonModule,
    ButtonModule,
    PdfViewerModule,
    ScrollPanelModule,
  ],
  exports: [ShowPdfComponent]
})
export class ShowPdfModule { }
