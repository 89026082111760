import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { ModalService } from "../services/modal.service";
import _ from "lodash";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { changeDetection } from "../change-detection";
import { environment } from 'src/environments/environment';
import { findInvalidFormControls } from "../find-invalid-form-controls";

export interface Validation {
  path: string;
  label: string;
  validators?: ValidatorFn[];
}

export interface ValidationWithValidators extends Validation {
  validators: ValidatorFn[];
}

export interface ValidationWithErrors extends Validation {
  errors: string[];
}

export class FormValidation {

  private form!: FormGroup;
  private modalService: ModalService;
  private requiredClass = environment.forms.dynamicRequiredClass;
  private missingValidators: ValidationWithValidators[];

  validations: Validation[];

  constructor(form: FormGroup, modalService: ModalService) {

    this.modalService = modalService;
    this.form = form;

    this.validations = [];

    this.missingValidators = [];

  }

  /**
   * Set / Replace the current form
   * Note: This also clears the current validations
   */
  setForm(form: FormGroup): void {

    if (this.validations.length) {
      this.validations = [];
    }

    this.form = form;

  }

  /**
   * Clear the current Form + Validators
   */
  clearForm(): void {

    this.form = null as any;
    this.validations = [];

  }

  /**
   * Add new validators
   * @returns 
   */
  addValidators(validators: ValidationWithValidators[]): void {

    if (!this.hasForm()) {
      
      return;

    }

    for (const validator of validators) {
      
      // Look to see if path already exists in this.validations
      const existingIndex = _.findIndex(this.validations, v => v.path === validator.path);
      
      if (existingIndex === -1) {

        this.validations.push({
          path: validator.path,
          label: validator.label,
          validators: validator.validators,
        });

      }

      changeDetection(() => {
        
        let elements = document.getElementsByClassName(`formControlPath_${validator.path}`);
  
        // if (!elements.length) {
        //   console.log('addValidators: NOT FOUND', `formControlPath_${validator.path}`);
        // }

        for (let i = 0; i < elements.length; i++) {

          const element = elements.item(i);

          if (element) {

            element.classList.add(this.requiredClass);

          }

        }
  
      });
      
      this.addValidatorFn(validator);

    }

  }

  /**
   * Remove form validators
   */
  removeFormValidators(paths: string[]): void {

    if (!this.hasForm()) {
      
      return;

    }

    paths.forEach(path => {

      changeDetection(() => {
        
        let elements = document.getElementsByClassName(`formControlPath_${path}`);
  
        // if (!elements.length) {
        //   console.log('removeFormValidators: NOT FOUND', `formControlPath_${path}`);
        // }

        for (let i = 0; i < elements.length; i++) {

          const element = elements.item(i);

          if (element) {

            element.classList.remove(this.requiredClass);

          }

        }

      });

      const pathIndex = _.findIndex(this.validations, validation => validation.path === path);

      if (pathIndex > -1) {
        this.validations.splice(pathIndex, 1);
      }

      const control = this.form.get(path);

      if (control) {
        control.clearValidators();
        control.updateValueAndValidity();
      } else {
        console.error(`We wanted to remove an existing validation and expected to find a control at '${path}' but we didn't find one.`);
      }

    });

  }

  /**
   * Clear any existing form validators
   */
  clearExistingValidators(): void {

    if (!this.hasForm()) {

      return;

    }

    this.validations.forEach(validation => {
      
      if (Array.isArray(validation.validators)) {
        
        for (const vFn of validation.validators) {
          
          do {
            
            this.form.get(validation.path)?.clearValidators();
            this.form.get(validation.path)?.updateValueAndValidity();
            
          } while (this.form.get(validation.path)?.hasValidator(vFn));
  
        }

      }

    });

    this.validations = [];

  }

  /**
   * Show any validation errors for the current form
   */
  validate(title: string = 'Form Validation Errors', mustResolve: boolean = false, buttons?: { label: string; key: string; class: string }[]): DynamicDialogRef | null {

    /**
     * There are times when validators are added to the form, but not as part of the `validations` array.
     * We remove these because they cause when trying to display the validation errors.
     */
    const removeOrphanedValidators = (errors: ValidationWithErrors[]): string[] => {

      const formPathsWithErrors = findInvalidFormControls(this.form);

      // remove from `formPathsWithErrors` if they are in the errors array
      const remainingPaths = formPathsWithErrors.filter(path => !_.find(errors, error => error.path === path));
  
      if (remainingPaths.length) {
  
        // remove validation from form for remainingPaths
        this.removeFormValidators(remainingPaths);
  
      }
  
      return remainingPaths;

    };

    if (!this.hasForm() || this.form.valid) {

      return null;

    }

    const errors = this.getValidationErrors();

    const orphanedValidators = removeOrphanedValidators(errors);

    if (!errors.length && !orphanedValidators.length) {

      return null;

    }

    const copy = ['The following empty fields need to be populated before you can continue:'];

    orphanedValidators.forEach(path => {
      errors.push({
        label: path,
        path: path,
        errors: ['Orphaned Validator Path'],
      });
    });

    if (orphanedValidators.length) {
      copy.push('<strong>Note:</strong>Some fields have validators but are not included in the Arrangement Validation class. Please notify the admin through the Slack support channel.');
    }

    const options = {
      title: title,
      copy: copy,
      list: _.map(errors, error => `<strong>${error.label}</strong>: ${error.errors.join(', ')}`),
      buttons: [
        { label: 'Close', key: 'ignore', class: 'p-button-primary' },
      ],
    };

    if (mustResolve === false) {

      options.copy = ['The following empty fields will need to be populated at some stage:']
      
      options.buttons = [
        { label: 'Enter Required Fields Later', key: 'submit-anyway', class: 'p-button-secondary' },
        { label: 'Enter Required Fields Now', key: 'close', class: 'p-button-primary' }
      ] as any;
      
    }

    const modal = this.modalService.generic(options);

    return modal;

  }

  /**
   * Do we have a form? Lets find out...
   */
  hasForm(): boolean {

    if (this.form instanceof FormGroup) {

      return true;

    }  else {
      
      console.error('No form has been set');

      return false;

    }

  }

  refreshCssClasses(): void {

    this.validations.forEach(validation => {
        
      let elements = document.getElementsByClassName(`formControlPath_${validation.path}`);

      for (let i = 0; i < elements.length; i++) {

        const element = elements.item(i);

        if (element) {

          element.classList.remove(this.requiredClass);

        }

      }

    });

    changeDetection(() => {

      this.validations.forEach(validation => {

        let elements = document.getElementsByClassName(`formControlPath_${validation.path}`);

        for (let i = 0; i < elements.length; i++) {
  
          const element = elements.item(i);
  
          if (element) {
  
            element.classList.add(this.requiredClass);
  
          }
  
        }

      });

    });

  }

  processMissingValidators(): void {

    let length = this.missingValidators.length - 1;

    for (let i = length; i >= 0; i--) {

      const validator = this.missingValidators[i];

      const control = this.form.get(validator.path);

      if (control) {

        for (const vFn of validator.validators) {

          const hasValidator = this.form.get(validator.path)?.hasValidator(vFn);

          if (!hasValidator) {
  
            this.form.get(validator.path)?.addValidators(vFn);
            this.form.get(validator.path)?.updateValueAndValidity();
  
          }

        }

        this.missingValidators.splice(i, 1);

      }

    }

  }

  /**
   * @private
   * Add the actual Validator Function to the form
   */
  private addValidatorFn (validator: ValidationWithValidators): void {

    for (const vFn of validator.validators) {

      const control = this.form.get(validator.path);

      if (control) {

        const hasValidator = this.form.get(validator.path)?.hasValidator(vFn);

        if (!hasValidator) {

          this.form.get(validator.path)?.addValidators(vFn);
          this.form.get(validator.path)?.updateValueAndValidity();

        } else {

          // console.warn(`Validator is existing for path '${validator.path}'.`);

        }

      } else {

        console.error(`We expected to find a control at '${validator.path}' but we didn't find one.`);

        this.missingValidators.push(validator);

      }

    }

  }

  /**
   * @private
   * Get the validation errors
   */
  private getValidationErrors(): ValidationWithErrors[] {

    let errors: ValidationWithErrors[] = [];

    const getFormValidationErrors = (form: FormGroup, parentKey: string = '') => {

      Object.keys(form.controls).forEach(key => {

        const formProperty = form.get(key);

        if (formProperty) {

          if (formProperty instanceof FormGroup) {
  
            getFormValidationErrors(formProperty, (parentKey) ? parentKey + '.' + key : key);
  
          } else if (formProperty instanceof FormArray) {
          
            for (let i = 0; i < formProperty.length; i++) {

              const formArrayFormGroup = (formProperty as FormArray).at(i) as FormGroup;

              if (formArrayFormGroup) {

                const path = (parentKey) ? parentKey + '.' + key : key;

                getFormValidationErrors(formArrayFormGroup, path + '.' + i);

              }

            }

          } else {
  
            const controlErrors: ValidationErrors | null = formProperty.errors;
    
            if (controlErrors) {
    
              Object.keys(controlErrors).forEach(keyError => {

                const currentPath = (parentKey) ? parentKey + '.' + key : key;

                const currentValidation = _.find(this.validations, validation => validation.path === currentPath);

                if (currentValidation) {

                  const currentError = _.find(errors, error => error.path === currentPath);

                  if (currentError) {

                    currentError.errors.push(keyError);

                  } else {

                    errors.push({
                      path: currentValidation.path,
                      label: currentValidation.label,
                      errors: [keyError],
                    });

                  }

                } else {

                  console.error(`The following path has an error, but it's not set via 'formValidationAlert()': ${currentPath}`);

                }

              });
    
            }
  
          }

        }

      });

    }

    getFormValidationErrors(this.form);

    return errors;

  }

}
