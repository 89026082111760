import { Pipe, PipeTransform } from '@angular/core';
import { LinkedTransferValidationResponse } from './validate-linked-transfers';

@Pipe({
  name: 'linkedTransferById'
})
export class LinkedTransferByIdPipe implements PipeTransform {

  transform(value: LinkedTransferValidationResponse[], transferId: string): LinkedTransferValidationResponse {

    const response: any = { transferId: transferId, isValid: true, dropOffIsValid: true, pickupIsValid: true };

    const items = value.filter((transfer) => transfer.transferId === transferId);
    
    if (items.length === 0) {
      return response;
    }

    items.forEach((item) => {

      if (item.isValid === false) {

        response.isValid = false;

        if (item.pickupIsValid === false) {

          response.pickupIsValid = false;
          response.pickupSourceAddress = item.pickupSourceAddress;
          response.pickupTransferAddress = item.pickupTransferAddress;

        }

        if (item.dropOffIsValid === false) {

          response.dropOffIsValid = false;
          response.dropOffSourceAddress = item.dropOffSourceAddress;
          response.dropOffTransferAddress = item.dropOffTransferAddress;

        }

      }

    });

    return response;

  }

}
